import React, { useState, useRef } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import UploadImage from "../../global/components/UploadImage";
import { Modal } from "antd";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";

const EditMap = ({ data, onEdit }) => {

  const dataEdit = data.Map;

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [isSuspend, setIsSuspend] = useState(dataEdit.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(dataEdit.IsSuspend)
    setOpen(false);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);

    // if (!dataEdit.Image) {
    //   enqueueSnackbar("Please upload an image.", { variant: "error" });
    //   setLoading(false);
    //   return;
    // }

    try {
      const modifiedValues = {
        ...values,
        imgs: selectedFile,
        BuildingCode: "",
        IsSuspend: isSuspend,
      };
      console.log(modifiedValues);

      // const response = await updateMaps(dataEdit.FacilityCode, dataEdit.MapCode, modifiedValues);
      // enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      // onEdit(dataEdit.FacilityCode && dataEdit.MapCode);
      // setOpen(false);

    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>


      <Box>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={dataEdit}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="MAP"
                    subtitle="Edit Data a Map"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                {/* Upload Image */}
                <UploadImage files={(files) => setSelectedFile(files)} images={`${process.env.REACT_APP_BASEURL_IMG}/${dataEdit.Image}`} />

                <TextField
                  fullWidth
                  variant="outlined"
                  label="Facility Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.FacilityName}
                  name="FacilityCode"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Map Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MapCode}
                  name="MapCode"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Map Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MapName}
                  name="MapName"
                  error={!!touched.MapName && !!errors.MapName}
                  helperText={touched.MapName && errors.MapName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>

            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};


const checkoutSchema = yup.object().shape({
  MapName: yup
    .string()
    .max(200, "Maximum of 200 characters allowed")
    .required("Please complete this Map Name."),
});

export default EditMap;
