import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditEquipmentModel from "./edit";
import DeleteEquipmentModel from "./delete";
import { Image, Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getEquipmentModel } from "../../../Api/Master/GetData";


const EquipmentModel = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getEquipmentModel();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [

    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Image',
      dataIndex: 'Image',
      render: (params) =>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            style={{ maxWidth: 100, maxHeight: "100%", objectFit: "contain" }}
            src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
          />
        </div>
    },
    {
      title: 'Model Code',
      dataIndex: 'ModelCode',
      sorter: (a, b) => a.ModelCode.localeCompare(b.ModelCode),
    },
    {
      title: 'Model Name',
      dataIndex: 'ModelName',
      sorter: (a, b) => a.ModelName.localeCompare(b.ModelName),
    },
    {
      title: 'Model Type Code',
      dataIndex: 'ModelTypeCode',
      sorter: (a, b) => a.ModelTypeCode.localeCompare(b.ModelTypeCode),
    },
    {
      title: 'Work Category Code',
      dataIndex: 'WorkCatCode',
      sorter: (a, b) => a.WorkCatCode.localeCompare(b.WorkCatCode),
    },
    {
      title: 'SubCategory Code',
      dataIndex: 'SubCatCode',
      sorter: (a, b) => a.SubCatCode.localeCompare(b.SubCatCode),
    },
    {
      title: 'ManuFacturer',
      dataIndex: 'Manufacturer',
      sorter: (a, b) => a.Manufacturer.localeCompare(b.Manufacturer),
    },
    {
      title: 'Meter Reading Unit',
      dataIndex: 'MeterReadingUnit',
      sorter: (a, b) => a.MeterReadingUnit.localeCompare(b.MeterReadingUnit),
    },
    {
      title: 'Icon Id',
      dataIndex: 'IconId',
      sorter: (a, b) => a.IconId.localeCompare(b.IconId),
    },
    {
      key: 'IsSuspend',
      title: 'Suspend',
      dataIndex: 'IsSuspend',
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center">
          {myEditAuthIdx && (
            <EditEquipmentModel data={record} onEdit={fetchData} />
          )}
          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteEquipmentModel data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    },)
  }

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      return (
        row.ModelCode.toLowerCase().includes(value.toLowerCase()) ||
        row.ModelName.toLowerCase().includes(value.toLowerCase()) ||
        row.ModelTypeCode.toLowerCase().includes(value.toLowerCase()) ||
        row.WorkCatCode.toLowerCase().includes(value.toLowerCase()) ||
        row.SubCatCode.toLowerCase().includes(value.toLowerCase()) ||
        row.Manufacturer.toLowerCase().includes(value.toLowerCase()) ||
        row.MeterReadingUnit.toLowerCase().includes(value.toLowerCase()) ||
        row.IconId.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "ModelCode", key: "ModelCode" },
    { title: "ModelName", key: "ModelName" },
    { title: "ModelTypeCode", key: "ModelTypeCode" },
    { title: "WorkCatCode", key: "WorkCatCode" },
    { title: "SubCatCode", key: "SubCatCode" },
    { title: "Manufacturer", key: "Manufacturer" },
    { title: "MeterReadingUnit", key: "MeterReadingUnit" },
    { title: "IconId", key: "IconId" },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header
          title="EQUIPMENT MODEL"
          subtitle="All Equipment Model Data"
        />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="Equipment Model"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="/master/equipmentmodel/form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData filterValue={filterValue} handleFilterChange={handleFilterChange} />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 2200 }}
      />

    </StyleContent>
  );
};

export default EquipmentModel;
