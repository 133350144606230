import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table } from "antd";
import EditWORequest from './edit';
import DeleteWORequest from './delete';
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import { getWORequest } from "../Api/GetData";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";

const WORequest = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getWORequest();
            setData(response);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setData([]);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            key: 'key',
            title: 'No',
            dataIndex: 'key',
            width: 80,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'WO Request Number',
            dataIndex: ['WORequest', 'WORNumber'],
            sorter: (a, b) => a.WORequest.WORNumber.localeCompare(b.WORequest.WORNumber),
        },
        {
            title: 'WO Request Date',
            dataIndex: ['WORequest', 'WORDate'],
            sorter: (a, b) => a.WORequest.WORDate.localeCompare(b.WORequest.WORDate),
        },

        {
            title: 'Request By',
            dataIndex: 'EmployeeName',
            sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
        },
        {
            title: 'Location Name',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
            render: (_, record) => {
                if (!record.LocationName) {
                    return "-";
                }
                return record.LocationName;
            }
        },
        {
            title: 'WO Type',
            dataIndex: ['WORequest', 'WOType'],
            sorter: (a, b) => a.WORequest.WOType.localeCompare(b.WORequest.WOType),
        },
        {
            title: 'WO Type Referensi',
            dataIndex: 'WOTypeRefName',
            sorter: (a, b) => a.WOTypeRefName.localeCompare(b.WOTypeRefName),
        },
        {
            title: 'AppStatus',
            dataIndex: ['WORequest', 'AppStatus'],
            sorter: (a, b) => a.WORequest.AppStatus.localeCompare(b.WORequest.AppStatus),
            render: (_, record) => {
                if (!record.WORequest.AppStatus) {
                    return "-";
                }
                return record.WORequest.AppStatus;
            }
        },

    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.WORequest.AppStatus !== 0 
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx && (
                            <EditWORequest data={record} onEdit={fetchData} />
                        )}

                        {myDeleteAuthIdx && (
                            <DeleteWORequest data={record} onDelete={fetchData} />
                        )}
                    </Space>
                );
            },
        })
    }

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);

        const filteredData = data.filter((row) => {

            return (
                row.FacilityName.toLowerCase().includes(value.toLowerCase()) ||
                row.WORequest.WORNumber.toLowerCase().includes(value.toLowerCase()) ||
                row.WORequest.WORDate.toLowerCase().includes(value.toLowerCase()) ||
                row.WORequest.RequestBy.toLowerCase().includes(value.toLowerCase()) ||
                row.LocationName.toLowerCase().includes(value.toLowerCase()) ||
                row.WORequest.WOType.toLowerCase().includes(value.toLowerCase()) ||
                row.WORequest.WOTypeRefCode.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
    };

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };
    const columnsExport = [
        { title: "key", key: ["WORequest", "key"] },
        { title: "FacilityName", key: "FacilityName" },
        { title: "WORNumber", key: ["WORequest", "WORNumber"] },
        { title: "WORDate", key: ["WORequest", "WORDate"] },
        { title: "RequestBy", key: ["WORequest", "RequestBy"] },
        { title: "LocationName", key: "LocationName" },
        { title: "WOType", key: ["WORequest", "WOType"] },
        { title: "WOTypeRefCode", key: ["WORequest", "WOTypeRefCode"] },
    ];

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header title="WO REQUEST" subtitle="All WO Request Data" />

                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx && (
                        <ExportData
                            data={selectedRows.length > 0 ? selectedRows : data}
                            columns={columnsExport}
                            name="WO Request"
                        />
                    )}

                    {myAddAuthIdx && (
                        <ButtonAddNew
                            link="/transaction/worequest/form"
                        />
                    )}
                </Box>

            </StyleGridHeader>

            <SearchData
                filterValue={filterValue}
                handleFilterChange={handleFilterChange}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filterValue ? filteredRows : data}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={data.length === 0 ? null : {
                    expandedRowRender: (record) => (
                        <DetailGrid>
                            <Typography variant="h5" fontWeight={600}>Description : </Typography>
                            <Typography variant="h6">{record.WORequest.Description}</Typography>
                        </DetailGrid>
                    ),
                    rowExpandable: (record) => record.WORequest.Description !== '',
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 2000 }}
            />

        </StyleContent>
    );
};

export default WORequest