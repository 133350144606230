import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


export const deleteUser = async (RefAccountId, UserName, Email) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/user?RefAccountId=${RefAccountId}&UserName=${UserName}&Email=${Email}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteGroupUser = async (KodeCabang, GroupCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/userGroup?KodeCabang=${KodeCabang}&GroupCode=${GroupCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}