import { Box, TextField } from '@mui/material'
import { Button, Modal, Table, Typography } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import SearchData from '../../global/components/FeatureTable/SearchData';
import Header from '../../../components/Header';

const InputSelect = ({
    title,
    label,
    name,
    onBlur,
    onChange,
    value,
    error,
    helperText,
    dataSource,
    columns,
    loading,
    propsSelecteRow,
    propsOpenModal,
    LinktoCreate
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectRow, setSelectRow] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
        if (propsOpenModal) {
            propsOpenModal(true);
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedRowKeys([]);
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = dataSource.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys, // Bind selected row keys to rowSelection
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectRow(selectedRows);
        },
    };
    
    const handleSubmit = () => {
        // console.log("Data Select", selectRow.map((item) => item.FacilityName).join(", "));        
        propsSelecteRow(selectRow)
        setIsModalOpen(false);
    }
    
    // Handle row click event
    // const handleRowClick = (record, rowIndex) => {
    //     setSelectedRowKeys([record.key]); // Update selected row keys
    //     propsSelecteRow(record);
    //     setSelectRow(record);
    //     // setIsModalOpen(false);
    // };

    return (
        <>
            <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={label}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                name={name}
                error={error}
                helperText={helperText}
                sx={{ gridColumn: "span 2" }}
                onClick={showModal}
                InputProps={{
                    readOnly: true,
                }}
                multiline
                rows={4}
            />

            <Modal
                centered
                title={
                    <>
                        <Header title={title} subtitle="" />
                    </>
                }
                open={isModalOpen}
                closable={false}
                footer={
                    <>
                        <Button onClick={handleSubmit}>Submit</Button>
                        <Button onClick={handleClose}>Close</Button>
                    </>}
                style={{
                    maxWidth: "90%",
                    minWidth: "80%",
                }}
                bodyStyle={{
                    maxHeight: "65vh",
                    overflow: "auto",
                }}
            >
                {dataSource.length > 0 ? (
                    <>
                        <div>
                            <SearchData
                                filterValue={searchText}
                                handleFilterChange={handleSearch}
                            />
                        </div>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={filteredData}
                            rowSelection={rowSelection}
                            // onRow={(record, rowIndex) => ({
                            //     onClick: () => {
                            //         handleRowClick(record, rowIndex);
                            //     },
                            // })}
                            scroll={
                                columns.length > 6 && { x: 2000 }
                            }
                        />
                    </>
                ) : (
                    <Box sx={{ textAlign: 'center', padding: '16px' }}>
                        <Typography variant="body1" color="textSecondary">
                            No data available.
                        </Typography>
                        <Link
                            to={LinktoCreate}
                            target="_blank"
                            onClick={handleClose}
                            syle={{ marginTop: '12px', display: 'inline-block' }}
                        >
                            Click to create in the {title} data.
                        </Link>
                    </Box>
                )}
            </Modal>

        </>
    )
}

export default InputSelect