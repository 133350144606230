import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import DeleteVehicle from "./delete";
import EditVehicle from "./edit";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getVehicle } from "../../../Api/Master/GetData";

const Vehicle = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getVehicle();
      setData(response);
    } catch (error) {
      //console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);


  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Vehicle Code',
      dataIndex: 'VehicleCode',
      fixed: 'left',
      sorter: (a, b) => a.VehicleCode.localeCompare(b.VehicleCode),
    },
    {
      title: 'Vehicle Name',
      dataIndex: 'VehicleName',
      sorter: (a, b) => a.VehicleName.localeCompare(b.VehicleName),
    },
    {
      title: 'Model Type Code',
      dataIndex: 'ModelTypeCode',
      sorter: (a, b) => a.ModelTypeCode.localeCompare(b.ModelTypeCode),
    },
    {
      title: 'No Pol',
      dataIndex: 'NoPol',
      sorter: (a, b) => a.NoPol.localeCompare(b.NoPol),
    },
    {
      title: 'Asset No',
      dataIndex: 'AssetNo',
      sorter: (a, b) => a.AssetNo.localeCompare(b.AssetNo),
    },
    {
      title: 'Purchase Date',
      dataIndex: 'PurchaseDate',
      sorter: (a, b) => a.PurchaseDate.localeCompare(b.PurchaseDate),
    },
    {
      title: 'Warranty Exp Date',
      dataIndex: 'WarrantyExpDate',
      sorter: (a, b) => a.WarrantyExpDate.localeCompare(b.WarrantyExpDate),
    },
    {
      title: 'Meter Reading',
      dataIndex: 'MeterReading',
      sorter: (a, b) => a.MeterReading - b.MeterReading,
    },
    {
      title: 'Vehicle Owner',
      dataIndex: 'VehicleOwner',
      sorter: (a, b) => a.VehicleOwner.localeCompare(b.VehicleOwner),
    },
    {
      key: 'IsSuspend',
      title: 'Suspend',
      dataIndex: 'IsSuspend',
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center" >
          {myEditAuthIdx && (
            <EditVehicle data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteVehicle data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    })
  }

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      // Sesuaikan dengan kolom yang ingin Anda filter
      return (
        row.VehicleCode.toLowerCase().includes(value.toLowerCase()) ||
        row.VehicleName.toLowerCase().includes(value.toLowerCase()) ||
        row.ModelTypeCode.toLowerCase().includes(value.toLowerCase()) ||
        row.NoPol.toLowerCase().includes(value.toLowerCase()) ||
        row.AssetNo.toLowerCase().includes(value.toLowerCase()) ||
        row.PurchaseDate.toLowerCase().includes(value.toLowerCase()) ||
        row.WarrantyExpDate.toLowerCase().includes(value.toLowerCase()) ||
        row.VehicleOwner.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "VehicleCode", key: "VehicleCode" },
    { title: "VehicleName", key: "VehicleName" },
    { title: "ModelTypeCode", key: "ModelTypeCode" },
    { title: "NoPol", key: "NoPol" },
    { title: "AssetNo", key: "AssetNo" },
    { title: "PurchaseDate", key: "PurchaseDate" },
    { title: "WarrantyExpDate", key: "WarrantyExpDate" },
    { title: "MeterReading", key: "MeterReading" },
    { title: "VehicleOwner", key: "VehicleOwner" },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="VEHICLE" subtitle="All Vehicle Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="Vehicle"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="/master/vehicle/form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 2000 }}
      />

    </StyleContent>
  );
};

export default Vehicle;
