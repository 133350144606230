import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from '@mui/icons-material/Edit';
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateType } from "../../../Api/Master/UpdateData";


const EditType = ({ data, onEdit }) => {

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [isSuspend, setIsSuspend] = useState(data.IsSuspend);

    const handleSwitchChange = (checked) => {
        setIsSuspend(checked);
        formRef.current.resetForm();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
        setIsSuspend(data.isSuspend)
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const modifivalues = {
                ...values,
                IsSuspend: isSuspend,
            };
            const response = await updateType(values.ModuleID, values.TypeCode, modifivalues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onEdit(values.ModuleID && values.TypeCode);
            setOpen(false);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusMessage
            ) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to update.", { variant: "error" });
            }
            console.error("error code", error.response);
            formRef.current.resetForm();
            setOpen(false);
        }
        setLoading(false);
    };

    return (
        <Box>

            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={data}
                    validationSchema={checkoutSchema}
                    innerRef={formRef}
                >

                    {({

                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,

                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="TYPE"
                                        subtitle="Edit Data a Type"
                                    />
                                    <SwitchComponent
                                        isSuspend={isSuspend}
                                        handleSwitchChange={handleSwitchChange}
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >
                            <StyleForm>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Module ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.ModuleID}
                                    name="ModuleID"
                                    error={!!touched.ModuleID && !!errors.ModuleID}
                                    helperText={touched.ModuleID && errors.ModuleID}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Type Code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.TypeCode}
                                    name="TypeCode"
                                    error={!!touched.TypeCode && !!errors.TypeCode}
                                    helperText={touched.TypeCode && errors.TypeCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Type Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.TypeName}
                                    name="TypeName"
                                    error={!!touched.TypeName && !!errors.TypeName}
                                    helperText={touched.TypeName && errors.TypeName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: isSuspend,
                                    }}
                                />

                            </StyleForm>

                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    TypeName: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this Type Name."),
});

export default EditType