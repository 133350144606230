import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

export const postDivision = async (DivisionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/division`, DivisionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postDepartment = async (DepartmentData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/department`, DepartmentData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postSection = async (SectionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/section`, SectionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postEmployee = async (EmployeeData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/employee`, EmployeeData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}