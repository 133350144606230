import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditDepartment from "./edit";
import DeleteDepartment from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getDepartment } from "../../../Api/Employee/GetData";

const Department = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getDepartment();
      setData(response);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      key: "key",
      title: "No",
      dataIndex: "key",
      width: 80,
      fixed: "left",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Departement Code",
      dataIndex: ["Department", "DepCode"],
      fixed: "left",
      sorter: (a, b) =>
        a.Department.DepCode.localeCompare(b.Department.DepCode),
    },
    {
      title: "Department Name",
      dataIndex: ["Department", "DepName"],
      sorter: (a, b) =>
        a.Department.DepName.localeCompare(b.Department.DepName),
    },
    {
      title: "Division Name",
      dataIndex: "DivisionName",
      sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
    },
    {
      title: 'Suspend',
      dataIndex: ['Department', 'IsSuspend'],
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center" >
          {myEditAuthIdx && (
            <EditDepartment data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.Department.IsSuspend && (
            <DeleteDepartment data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    },)
  }

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      // Sesuaikan dengan kolom yang ingin Anda filter
      return (
        row.Department.DivisionCode.toLowerCase().includes(
          value.toLowerCase()
        ) ||
        row.DivisionName.toLowerCase().includes(value.toLowerCase()) ||
        row.Department.DepCode.toLowerCase().includes(value.toLowerCase()) ||
        row.Department.DepName.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "DivisionName", key: "DivisionName" },
    { title: "DepCode", key: ["Department", "DepCode"] },
    { title: "DepName", key: ["Department", "DepName"] },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="DEPARTMENT" subtitle="All department Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="department"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew link="/employee/department/form" />
          )}
        </Box>
      </StyleGridHeader>

      <SearchData
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={
          data.every((record) => record.Description === "")
            ? null
            : {
              expandedRowRender: (record) => (
                <DetailGrid>
                  <Typography variant="h5" fontWeight={600}>
                    Description :{" "}
                  </Typography>
                  <Typography>{record.Department.Description}</Typography>
                </DetailGrid>
              ),
              rowExpandable: (record) => record.Department.Description !== "",
            }
        }
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 1000 }}
      />
    </StyleContent>
  );
};

export default Department;
