import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  // FormControlLabel,
  // Checkbox,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Authentication from "../../auth";
import { ButtonForm } from "../../global/components/ButtonAction";
import { getAdjustment, getMainAdjustmentTransaction } from "../Api/GetData";
import FormTranApproval from "../TranApproval/form";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { StyleButtonForm } from "../../global/StyleCSS";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PostAdjustmentTransaction } from "../Api/PostData";
import FormAdjustmentDetail from "./AdjustmentDetail/form";
import dayjs from "dayjs";
import { getFacility } from "../../../Api/Master/GetData";

const FormAdjustmentTransaction = () => {
  Authentication();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action");
  const faccode = searchParams.get("faccode");
  const trannumber = searchParams.get("trannumber");

  const [adjustment, setAdjustment] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [onApproval, setOnApproval] = useState(false);

  useEffect(() => {
    if (faccode || trannumber) {
      const fetchAdjustment = async () => {
        try {
          const response = await getMainAdjustmentTransaction(faccode, trannumber);
          // console.log(response);
          setAdjustment(response[0].Adjustment);
        } catch (error) {
          console.log(error);
        }
      };

      fetchAdjustment();
    }
  }, [faccode, trannumber]);

  useEffect(() => {
    if (faccode && trannumber) {
      if (adjustment) {
        setIsDisable(true);
        setSelectedFacilityCode(adjustment.FacilityCode);
        setOpenedFacility(true);
        setADNumber(adjustment.ADNumber);
        setSelectedAdjustment(adjustment.ADCode);
        setOpenAdjustment(true);
        setADDate(dayjs(adjustment.ADDate));
        setOnApproval(true);
      }
    }
  }, [faccode, trannumber, adjustment]);

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [ADNumber, setADNumber] = useState("");

  const [facilityData, setFacilityData] = useState([]);
  const [selectedFacilityCode, setSelectedFacilityCode] = useState("");
  const [OpenedFacility, setOpenedFacility] = useState(false);

  const [adjustmentData, setAdjustmentData] = useState([]);
  const [selectedAdjustment, setSelectedAdjustment] = useState("");
  const [OpenAdjustment, setOpenAdjustment] = useState(false);

  const [ADType, setADType] = useState("");

  const [ADDate, setADDate] = useState(null);

  const [Periode, setPeriode] = useState("");

  const [dataAdjustmentDetail, setDataAdjustmentDetail] = useState([]);

  useEffect(() => {
    const fetchFacility = async () => {
      setIsLoading(true);
      try {
        const response = await getFacility();
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setFacilityData(filterRes);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (OpenedFacility) {
      fetchFacility();
      setOpenedFacility(false);
    }
  }, [OpenedFacility]);

  useEffect(() => {
    const fetchAdjustment = async () => {
      setIsLoading(true);
      try {
        const response = await getAdjustment();
        setAdjustmentData(response);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }

    if (OpenAdjustment) {
      fetchAdjustment();
      setOpenAdjustment(false);
    }

  }, [OpenAdjustment]);

  useEffect(() => {
    if (ADDate) {
      const PeriodeNow = ADDate.format("YYYYMM");
      setPeriode(PeriodeNow)
    }
  }, [ADDate]);


  const FetchADNumber = useCallback(async () => {
    try {
      const response = await getMainAdjustmentTransaction(selectedFacilityCode);
      if (response.length > 0) {
        const ADNData = response.filter(
          (item) =>
            item.Adjustment.ADNumber &&
            item.Adjustment.ADNumber.startsWith("ADN")
        );
        if (ADNData.length > 0) {
          const lastCode = ADNData[ADNData.length - 1].Adjustment.ADNumber;
          const nextNumber = parseInt(lastCode.substr(3)) + 1;
          setADNumber(`ADN${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setADNumber("ADN0001");
        }
      } else {
        setADNumber("ADN0001");
      }
    } catch (error) {
      setADNumber("ADN0001");
      console.log(error);
    }
  }, [selectedFacilityCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("Periode", Periode);
      formRef.current.setFieldValue("ADNumber", ADNumber);
    }
  }, [ADNumber, Periode]);

  useEffect(() => {
    if (selectedFacilityCode && (!faccode || !trannumber)) {
      FetchADNumber();
    }
  }, [selectedFacilityCode, FetchADNumber, faccode, trannumber]);

  const handleFacilitySelection = (event, value) => {
    if (value) {
      setSelectedFacilityCode(value.FacilityCode);
    } else {
      setSelectedFacilityCode(null);
    }
  };

  const handleAdjustmentSelection = (event, value) => {
    if (value) {
      setSelectedAdjustment(value.ADCode);
      setADType(value.ADType);
    } else {
      setSelectedAdjustment(null);
    }
  };


  const handleCancel = () => {
    formRef.current.resetForm();
    setSelectedFacilityCode(null);
    setSelectedAdjustment(null);
    setAdjustmentData([]);
    setADNumber("");
    setADDate(null);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      if (dataAdjustmentDetail.length === 0) {
        enqueueSnackbar("Complete the detail Adjustment data form!", { variant: "warning" });
        setLoading(false);
        return;
      }

      const ADDateNow = ADDate.format("YYYY-MM-DD");

      const modifiedValues = {
        ...values,
        FacilityCode: selectedFacilityCode,
        ADNumber: ADNumber,
        ADCode: selectedAdjustment,
        ADDate: ADDateNow,
        ADType: ADType,
        // isPostingGL: values.isPostingGL,
        adjusmentTransactionDetails: dataAdjustmentDetail,
      };

      // console.log("Submit", modifiedValues);

      const response = await PostAdjustmentTransaction(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/transaction/adjustment");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, ADNumber: ADNumber, Periode: Periode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header
                title="ADJUSTMENT"
                subtitle="Create a Adjustment Transaction"
              />
              <StyleForm>
                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenedFacility(true)}
                  loading={isLoading}
                  options={facilityData}
                  getOptionLabel={(option) => option.FacilityName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleFacilitySelection(event, value);
                    setFieldValue(
                      "FacilityCode",
                      value ? value.FacilityName : ""
                    );
                  }}
                  value={
                    facilityData.find(
                      (option) => option.FacilityCode === selectedFacilityCode
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Facility Name*"
                      onBlur={handleBlur}
                      error={!!touched.FacilityCode && !!errors.FacilityCode}
                      helperText={touched.FacilityCode && errors.FacilityCode}
                    />
                  )}
                  noOptionsText={
                    facilityData.length === 0 ? (
                      <Link
                        to="/master/facility/form"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        there is no option, click to fill in the facilities data
                      </Link>
                    ) : (
                      "No options"
                    )
                  }
                  readOnly={isDisable}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Adjustment Number*"
                  onBlur={handleBlur}
                  value={ADNumber}
                  onChange={(e) => {
                    handleChange(e);
                    setADNumber(e.target.value);
                  }}
                  name="ADNumber"
                  error={!!touched.ADNumber && !!errors.ADNumber}
                  helperText={touched.ADNumber && errors.ADNumber}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Adjustment Date*"
                    value={ADDate}
                    onBlur={handleBlur}
                    onChange={(date) => setADDate(date)}
                    name="ADDate"
                    error={!!touched.ADDate && !!errors.ADDate}
                    helperText={touched.ADDate && errors.ADDate}
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isDisable}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Periode*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={Periode}
                  name="Periode"
                  error={!!touched.Periode && !!errors.Periode}
                  helperText={touched.Periode && errors.Periode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenAdjustment(true)}
                  loading={isLoading}
                  options={adjustmentData}
                  getOptionLabel={(option) => option.ADName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleAdjustmentSelection(event, value);
                    setFieldValue(
                      "ADCode",
                      value ? value.ADName : ""
                    );
                  }}
                  value={
                    adjustmentData.find(
                      (option) => option.ADCode === selectedAdjustment
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Adjustment Name*"
                      onBlur={handleBlur}
                      error={!!touched.ADCode && !!errors.ADCode}
                      helperText={touched.ADCode && errors.ADCode}
                    />
                  )}
                  readOnly={isDisable}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={adjustment ? (adjustment.Description || values.Description) : values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                />

                {/* <FormControlLabel
                  control={
                    <Checkbox
                      name="isPostingGL"
                      checked={values.isPostingGL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Posting to GL"
                  sx={{ justifyContent: "start", gridColumn: "span 2" }}
                /> */}
              </StyleForm>

              <Box marginTop={6}>
                <Box sx={{
                  margin: "20px 0",
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 0px 10px #00000026",
                }}>
                  <FormAdjustmentDetail
                    onSaveData={(data) => setDataAdjustmentDetail(data)}
                    FacilityCode={selectedFacilityCode}
                    ADNumber={ADNumber}
                    onEdit={false}
                    onApproval={onApproval}
                    ADType={ADType}
                  />
                </Box>
              </Box>

            </StyleContent>

            {faccode || trannumber ? (
              action === "process" ? (
                <FormTranApproval adjustment={dataAdjustmentDetail} />
              ) : (
                <Box sx={StyleButtonForm}>
                  <Link to="/transaction/tranapproval">
                    <Button type="button" color="error" variant="contained">
                      <span>Cancel</span>
                    </Button>
                  </Link>
                </Box>
              )
            ) : (
              <ButtonForm
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                loading={loading}
              />
            )}
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityCode: yup.string().required("Please complete this Facility Name."),
  ADNumber: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Adjustment Number."),
  Periode: yup
    .string()
    .required("Please complete this Periode."),
  ADCode: yup.string().required("Please complete this Adjustment Code."),
  // isPostingGL: yup.boolean(), 
});

const initialValues = {
  FacilityCode: "",
  ADNumber: "",
  Periode: "",
  ADCode: "",
  // isPostingGL: false,
};

export default FormAdjustmentTransaction;
