import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import { onlyNumber } from "../../global/Components";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import MapIcon from "../Location/map";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { Modal } from "antd";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { getEquipmentModel, getLocation } from "../../../Api/Master/GetData";
import { updateEquipment } from "../../../Api/Master/UpdateData";
import InputModal from "../../../components/Global/InputModal";
import { columnsEquipmentModel, columnsEquipmentOwner, columnsLocation } from "./columnsEquipment";
import { getEmployee } from "../../../Api/Employee/GetData";

const EditEquipment = ({ data, onEdit }) => {
  const dataEdit = data.Equipment;

  const formRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [dataEquipmentModel, setDataEquipmentModel] = useState([]);
  const [selectEquipmentModel, setSelectEquipmentModel] = useState("");
  const EquipmentModelCode = selectEquipmentModel ? selectEquipmentModel.ModelCode : '';
  const EquipmentModelName = selectEquipmentModel ? selectEquipmentModel.ModelName : '';

  const [dataLocation, setDataLocation] = useState([]);
  const [selectLocation, setSelectLocation] = useState("");
  const LocationCode = selectLocation ? selectLocation.Location.LocationCode : '';
  const LocationName = selectLocation ? selectLocation.Location.LocationName : '';

  const [dataEquipmentOwner, setDataEquipmentOwner] = useState([]);
  const [selectEquipmentOwner, setSelectEquipmentOwner] = useState("");
  const EquipmentOwnerCode = selectEquipmentOwner ? selectEquipmentOwner.Employee.EmpCode : '';
  const EquipmentOwnerName = selectEquipmentOwner ? selectEquipmentOwner.Employee.EmpName : '';


  const [InstallDate, setInstallDate] = useState(dayjs(dataEdit.InstallDate));
  const [PurchaseDate, setPurchaseDate] = useState(
    dayjs(dataEdit.PurchaseDate)
  );
  const [WarrantyExpDate, setWarrantyExpDate] = useState(
    dayjs(dataEdit.WarrantyExpDate)
  );

  const [IconName, setIconName] = useState("");
  const [IconLocY, setIconLocY] = useState(dataEdit.IconLocationY);
  const [IconLocX, setIconLocX] = useState(dataEdit.IconLocationX);
  const [IconUrl, setIconUrl] = useState(dataEdit.IconId);


  const [isSuspend, setIsSuspend] = useState(dataEdit.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
    setInstallDate(dayjs(dataEdit.InstallDate))
    setPurchaseDate(dayjs(dataEdit.PurchaseDate))
    setWarrantyExpDate(dayjs(dataEdit.WarrantyExpDate))
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(dataEdit.IsSuspend)
    setInstallDate(dayjs(dataEdit.InstallDate))
    setPurchaseDate(dayjs(dataEdit.PurchaseDate))
    setWarrantyExpDate(dayjs(dataEdit.WarrantyExpDate))
    setOpen(false);
  };

  // EQUIPMENT MODEL
  useEffect(() => {
    const fetchEquipmentModel = async () => {
      try {
        const res = await getEquipmentModel();
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataEquipmentModel(filterRes);

        const selected = res.filter((item) => item.ModelCode === dataEdit.ModelCode);
        setSelectEquipmentModel(selected[0])

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      fetchEquipmentModel();
      setIsLoading(false);
    }
  }, [dataEdit.ModelCode, open]);

  // LOCATION
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const res = await getLocation(dataEdit.FacilityCode);
        const filterRes = res.filter((item) => item.Location.IsSuspend !== true);
        setDataLocation(filterRes);

        const selected = res.filter((item) => item.Location.LocationCode === dataEdit.LocationCode);
        setSelectLocation(selected[0])

      } catch (error) {
        console.log(error);
      }
    }

    if (open && dataEdit.FacilityCode) {
      fetchLocation();
      setIsLoading(false);
    }
  }, [dataEdit.FacilityCode, dataEdit.LocationCode, open]);


  // EMPLOYEE
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const res = await getEmployee();
        const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
        setDataEquipmentOwner(filterRes);

        const selected = res.filter((item) => item.Employee.EmpCode === dataEdit.EquipmentOwner);
        setSelectEquipmentOwner(selected[0]);

      } catch (error) {
        console.log(error);
      }
    }

    if (open) {
      fetchEmployee();
      setIsLoading(false);
    }
  }, [dataEdit.EquipmentOwner, open]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("EquipmentModelName", EquipmentModelName);
      formRef.current.setFieldValue("LocationName", LocationName);
      formRef.current.setFieldValue("EquipmentOwner", EquipmentOwnerName);
    }
  }, [EquipmentModelName, EquipmentOwnerName, LocationName]);


  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      if (!IconUrl) {
        enqueueSnackbar(
          "Fill in the data first, drag and drop the icon onto the map.",
          { variant: "warning" }
        );
        setLoading(false);
        return;
      }

      const modifiedValues = {
        ...values,
        ModelCode: EquipmentModelCode,
        LocationCode: LocationCode,
        InstallDate: InstallDate.format("YYYY-MM-DD"),
        PurchaseDate: PurchaseDate.format("YYYY-MM-DD"),
        WarrantyExpDate: WarrantyExpDate.format("YYYY-MM-DD"),
        MapCode: getLocationMapCode,
        EquipmentOwner: EquipmentOwnerCode,
        IconId: IconName,
        IconLocationY: `${IconLocY}`,
        IconLocationX: `${IconLocX}`,
        url: IconUrl,
        IsSuspend: isSuspend,
      };

      const response = await updateEquipment(
        dataEdit.FacilityCode,
        dataEdit.EquipmentCode,
        modifiedValues
      );
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(dataEdit.EquipmentCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key) || (e.target.value === "" && e.key === "0")) {
      e.preventDefault();
    }
  };

  const getLocationMapCode = selectLocation ? selectLocation.Location.MapCode : "";


  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={dataEdit}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header title="EQUIPMENT" subtitle="Edit Data a Equipment" />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Facility Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.FacilityName}
                  name="FacilityCode"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Equipment Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.EquipmentCode}
                  name="EquipmentCode"
                  error={!!touched.EquipmentCode && !!errors.EquipmentCode}
                  helperText={touched.EquipmentCode && errors.EquipmentCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Equipment Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.EquipmentName}
                  name="EquipmentName"
                  error={!!touched.EquipmentName && !!errors.EquipmentName}
                  helperText={touched.EquipmentName && errors.EquipmentName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Serial Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SN}
                  name="SN"
                  error={!!touched.SN && !!errors.SN}
                  helperText={touched.SN && errors.SN}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Asset No"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.AssetNo}
                  name="AssetNo"
                  error={!!touched.AssetNo && !!errors.AssetNo}
                  helperText={touched.AssetNo && errors.AssetNo}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="EQUIPMENT MODEL"
                  label="Equipment Model Name"
                  name="EquipmentModelName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.EquipmentModelName}
                  error={!!touched.EquipmentModelName && !!errors.EquipmentModelName}
                  helperText={touched.EquipmentModelName && errors.EquipmentModelName}
                  dataSource={dataEquipmentModel}
                  columns={columnsEquipmentModel}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectEquipmentModel(values)}
                  // propsOpenModal={(values) => setOpenModalEquipmentModel(values)}
                  LinktoCreate={`/master/equipmentmodel/form`}
                />

                <InputModal
                  title="LOCATION"
                  label="Location Name"
                  name="LocationName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LocationName}
                  error={!!touched.LocationName && !!errors.LocationName}
                  helperText={touched.LocationName && errors.LocationName}
                  dataSource={dataLocation}
                  columns={columnsLocation}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectLocation(values)}
                  // propsOpenModal={(values) => setOpenModalLocation(values)}
                  LinktoCreate={`/master/location/form`}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Install Date"
                    value={InstallDate}
                    onBlur={handleBlur}
                    onChange={(date) => setInstallDate(date)}
                    name="InstallDate"
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isSuspend}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Purchase Date"
                    value={PurchaseDate}
                    onBlur={handleBlur}
                    onChange={(date) => setPurchaseDate(date)}
                    name="PurchaseDate"
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isSuspend}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Warranty Exp Date"
                    value={WarrantyExpDate}
                    onBlur={handleBlur}
                    onChange={(date) => setWarrantyExpDate(date)}
                    name="WarrantyExpDate"
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isSuspend}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Meter Reading"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MeterReading}
                  name="MeterReading"
                  error={!!touched.MeterReading && !!errors.MeterReading}
                  helperText={touched.MeterReading && errors.MeterReading}
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="EMPLOYEE"
                  label="Equipment Owner"
                  name="EquipmentOwner"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.EquipmentOwner}
                  error={!!touched.EquipmentOwner && !!errors.EquipmentOwner}
                  helperText={touched.EquipmentOwner && errors.EquipmentOwner}
                  dataSource={dataEquipmentOwner}
                  columns={columnsEquipmentOwner}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectEquipmentOwner(values)}
                  // propsOpenModal={(values) => setOpenModalEquipmentOwner(values)}
                  LinktoCreate={`/master/employee/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <MapIcon
                  propsFacilityCode={dataEdit.FacilityCode}
                  propsMapCode={getLocationMapCode}
                  propsIconName={(values) => setIconName(values)}
                  propsIconLocY={(values) => setIconLocY(values)}
                  propsIconLocX={(values) => setIconLocX(values)}
                  propsIconUrl={(values) => setIconUrl(values)}
                  propsDataEdit={dataEdit}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  // EquipmentName: yup
  //   .string()
  //   .max(50, "Maximum of 50 characters allowed")
  //   .required("Please complete this Equipment Name."),
  // ModelCode: yup.string().required("Please complete this Model Name."),
  // LocationCode: yup.string().required("Please complete this Location Name."),
  // SN: yup
  //   .string()
  //   .max(30, "Maximum of 30 characters allowed")
  //   .required("Please complete this SN."),
  // AssetNo: yup
  //   .string()
  //   .max(30, "Maximum of 30 characters allowed")
  //   .required("Please complete this Asset No."),
  // MeterReading: yup.number().required("Please complete this Meter Reading."),
  // EquipmentOwner: yup
  //   .string()
  //   .max(50, "Maximum of 50 characters allowed")
  //   .required("Please complete this Equipment Owner."),
});

export default EditEquipment;
