import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


export const postUser = async (UserData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/user`, UserData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postUserGroup = async (UserGroupData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/usergroup`, UserGroupData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postTran = async (TranData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/tran`, TranData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postTranApprover = async (TranApproverData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/tranapprovers`, TranApproverData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postTranAppMap = async (TranAppMapData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/tranappmap`, TranAppMapData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}