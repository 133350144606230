import { useSidebarContext } from '../Sidebar/SidebarContext';
import { useSelector } from 'react-redux';

const PrintAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const PrintAuthIdx = user && user.PrnAuthority.filter(item => item === authIdx).join(',')
    return PrintAuthIdx
}

export default PrintAuthorityIdx;