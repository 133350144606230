import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import Header from "../../../components/Header";
import BlockIcon from '@mui/icons-material/Block';
import { useSnackbar } from "notistack";
import { ButtonDelete } from "../../global/components/ButtonAction";
import { Modal } from "antd";
import { deleteEmployee } from "../../../Api/Employee/DeleteData";

const DeleteEmployee = ({ data, onDelete }) => {

  const dataDelete = data.Employee;

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteEmployee(dataDelete.EmpCode);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onDelete(dataDelete.EmpCode);
      setOpen(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.statusMessage) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to delete.", { variant: "error" });
      }
      console.error("error code", error.response);
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>

      <Tooltip title="Suspend" fontSize="large">
        <IconButton onClick={handleOpen}>
          <BlockIcon />
        </IconButton>
      </Tooltip>

      <Modal
        title={
          <Header
            title="EMPLOYEE"
            subtitle="Suspend Data a Employee"
          />
        }
        centered
        open={open}
        closable={false}
        footer={() => (
          <ButtonDelete
            handleClose={handleClose}
            handleDelete={handleDelete}
            loading={loading}
          />
        )}
      >

        <Box>
          <p>
            Are you sure you want to suspend data with this Employee Code {dataDelete.EmpCode} with the Employee Name {dataDelete.EmpName} ?
          </p>
        </Box>

      </Modal>

    </Box>
  );
};

export default DeleteEmployee;
