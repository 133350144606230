import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import UploadImage from "../../global/components/UploadImage";
import { Image, Modal } from "antd";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateEquipmentModel } from "../../../Api/Master/UpdateData";
import { getCategory, getIcon, getSubCategory, getType } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";

const EditEquipmentModel = ({ data, onEdit }) => {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [dataType, setDataType] = useState([]);
  const [selectType, setSelectType] = useState("");
  const TypeCode = selectType ? selectType.TypeCode : '';
  const TypeName = selectType ? selectType.TypeName : '';

  const [dataCategory, setDataCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const CategoryName = selectCategory ? selectCategory.CatName : '';
  const CategoryCode = selectCategory ? selectCategory.CatCode : '';

  const [dataSubCategory, setDataSubCategory] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState(null);
  const SubCategoryCode = selectSubCategory ? selectSubCategory.SubCategory.SubCatCode : '';
  const SubCategoryName = selectSubCategory ? selectSubCategory.SubCategory.SubCatName : '';

  const [dataIcon, setDataIcon] = useState([]);
  const [selectIcon, setSelectIcon] = useState(null);
  const IconId = selectIcon ? selectIcon.IconId : '';
  const IconName = selectIcon ? selectIcon.IconName : '';

  const [isSuspend, setIsSuspend] = useState(data.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  // DATA TYPE
  useEffect(() => {
    const fetchType = async () => {
      setIsLoading(true);
      try {
        const response = await getType("Equipment");
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setDataType(filterRes);

        const selectData = response.filter((item) => item.TypeCode === data.ModelTypeCode);
        setSelectType(selectData[0]);


      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (open) {
      fetchType();
    }
  }, [data.ModelTypeCode, open]);

  // DATA CATEGORY
  useEffect(() => {
    const fetchCategory = async () => {
      setIsLoading(true);
      try {
        const response = await getCategory("Equipment");
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setDataCategory(filterRes);

        const selectData = response.filter((item) => item.CatCode === data.WorkCatCode);
        setSelectCategory(selectData[0]);

      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (open) {
      fetchCategory();
      setDataSubCategory([]);
      setSelectSubCategory(null);
    }
  }, [data.WorkCatCode, open]);

  // DATA SUBCATEGORY
  useEffect(() => {
    if (CategoryCode) {
      const fetchDataSubCategory = async () => {
        setIsLoading(true);
        try {
          const response = await getSubCategory("Equipment", CategoryCode);
          const filterRes = response.filter((item) => item.SubCategory.IsSuspend !== true);
          setDataSubCategory(filterRes);

          const selectData = response.filter((item) => item.SubCategory.SubCatCode === data.SubCatCode);
          setSelectSubCategory(selectData[0]);

        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      };

      if (open) {
        fetchDataSubCategory();
      }
    }
  }, [CategoryCode, data.SubCatCode, open]);


  // DATA ICON
  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const response = await getIcon();
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setDataIcon(filterRes);

        const selectData = response.filter((item) => item.IconId === data.IconId);
        setSelectIcon(selectData[0]);

      } catch (error) {
        console.log(error);
      }
    }
    if (open) {
      fetchIcon();
    }
  }, [data.IconId, open]);


  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("TypeName", TypeName);
      formRef.current.setFieldValue("CategoryName", CategoryName);
      formRef.current.setFieldValue("SubCategoryName", SubCategoryName);
      formRef.current.setFieldValue("IconName", IconName);
    }
  }, [CategoryName, IconName, SubCategoryName, TypeName]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
    setLoading(false);
    setIsSuspend(data.IsSuspend)
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        imgs: selectedFile,
        ModelTypeCode: TypeCode,
        WorkCatCode: CategoryCode,
        SubCatCode: SubCategoryCode,
        IconId: IconId,
      };
      const response = await updateEquipmentModel(values.ModelCode, payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(data.ModelCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="EQUIPMENT MODEL"
                    subtitle="Edit Data a Equipment Model"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                {/* Upload Image */}
                <UploadImage
                  files={(files) => setSelectedFile(files)}
                  images={`${process.env.REACT_APP_BASEURL_IMG}/${data.Image}`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  label="Model Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ModelCode}
                  name="ModelCode"
                  error={!!touched.ModelCode && !!errors.ModelCode}
                  helperText={touched.ModelCode && errors.ModelCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Model Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ModelName}
                  name="ModelName"
                  error={!!touched.ModelName && !!errors.ModelName}
                  helperText={touched.ModelName && errors.ModelName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="TYPE"
                  label="Type Name"
                  name="TypeName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.TypeName}
                  error={!!touched.TypeName && !!errors.TypeName}
                  helperText={touched.TypeName && errors.TypeName}
                  dataSource={dataType}
                  columns={columnsType}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectType(values)}
                  propsOpenModal={(values) => console.log(values)}
                  LinktoCreate={`/master/type/form`}
                />

                <InputModal
                  title="CATEGORY"
                  label="Category Name"
                  name="CategoryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.CategoryName}
                  error={!!touched.CategoryName && !!errors.CategoryName}
                  helperText={touched.CategoryName && errors.CategoryName}
                  dataSource={dataCategory}
                  columns={columnsCategory}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectCategory(values)}
                  propsOpenModal={(values) => console.log(values)}
                  LinktoCreate={`/master/category/form`}
                />

                <InputModal
                  title="SUBCATEGORY"
                  label="SubCategory Name"
                  name="SubCategoryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SubCategoryName}
                  error={!!touched.SubCategoryName && !!errors.SubCategoryName}
                  helperText={touched.SubCategoryName && errors.SubCategoryName}
                  dataSource={dataSubCategory}
                  columns={columnsSubCategory}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectSubCategory(values)}
                  propsOpenModal={(values) => console.log(values)}
                  LinktoCreate={`/master/subcategory/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Manufacturer"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Manufacturer}
                  name="Manufacturer"
                  error={!!touched.Manufacturer && !!errors.Manufacturer}
                  helperText={touched.Manufacturer && errors.Manufacturer}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="MeterReading Unit"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MeterReadingUnit}
                  name="MeterReadingUnit"
                  error={
                    !!touched.MeterReadingUnit && !!errors.MeterReadingUnit
                  }
                  helperText={
                    touched.MeterReadingUnit && errors.MeterReadingUnit
                  }
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <InputModal
                  title="ICON"
                  label="Icon Name"
                  name="IconName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.IconName}
                  error={!!touched.IconName && !!errors.IconName}
                  helperText={touched.IconName && errors.IconName}
                  dataSource={dataIcon}
                  columns={columnsIcon}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectIcon(values)}
                  propsOpenModal={(values) => console.log(values)}
                  LinktoCreate={`/master/icon/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  ModelName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Model Name."),
  TypeName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Model Type Name."),
  CategoryName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Work Category Name."),
  SubCategoryName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this SubCategory Name."),
  Manufacturer: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Manufacturer."),
  MeterReadingUnit: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this MeterReading Unit."),
  IconName: yup
    .string()
    .required("Please complete this Icon Name."),
});

export default EditEquipmentModel;


const columnsIcon = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Image',
    dataIndex: 'Image',
    render: (params) =>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Image
          style={{ maxWidth: 100, maxHeight: "100%", objectFit: "contain" }}
          src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
        />
      </div>
  },
  {
    title: 'Icon Id',
    dataIndex: 'IconId',
    sorter: (a, b) => a.IconId.localeCompare(b.IconId),
  },
  {
    title: 'Icon Name',
    dataIndex: 'IconName',
    sorter: (a, b) => a.IconName.localeCompare(b.IconName),
  },
];


const columnsType = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Type Code',
    dataIndex: 'TypeCode',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => a.TypeCode.localeCompare(b.TypeCode),
  },
  {
    title: 'Type Name',
    dataIndex: 'TypeName',
    sorter: (a, b) => a.TypeName.localeCompare(b.TypeName),
  },
  {
    title: 'Module ID',
    dataIndex: 'ModuleID',
    sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
  },
];


const columnsCategory = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Category Code',
    dataIndex: 'CatCode',
    fixed: 'left',
    sorter: (a, b) => a.CatCode.localeCompare(b.CatCode),
  },
  {
    title: 'Category Name',
    dataIndex: 'CatName',
    sorter: (a, b) => a.CatName.localeCompare(b.CatName),
  },
  {
    title: 'Module ID',
    dataIndex: 'ModuleID',
    sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
  },
];


const columnsSubCategory = [

  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Module ID',
    dataIndex: ['SubCategory', 'ModuleID'],
    width: 200,
    sorter: (a, b) => a.SubCategory.ModuleID.localeCompare(b.SubCategory.ModuleID),
  },
  {
    title: 'Category Name',
    dataIndex: 'CatName',
    width: 200,
    sorter: (a, b) => a.CatName.localeCompare(b.CatName),
  },
  {
    title: 'SubCategory Code',
    dataIndex: ['SubCategory', 'SubCatCode'],
    width: 200,
    sorter: (a, b) => a.SubCategory.SubCatCode.localeCompare(b.SubCategory.SubCatCode),
  },
  {
    title: 'SubCategory Name',
    dataIndex: ['SubCategory', 'SubCatName'],
    width: 200,
    sorter: (a, b) => a.SubCategory.SubCatName.localeCompare(b.SubCategory.SubCatName),
  },
];
