import Login from "./login";
import Signup from "./login/Signup";

import Dashboard from "./pages/Dashboard";



// MASTER DATA
import Facility from "./pages/MasterData/Facility";
import FormFacility from "./pages/MasterData/Facility/form";

import Buildings from "./pages/MasterData/Buildings";
import FormBuildings from "./pages/MasterData/Buildings/form";

import Floor from "./pages/MasterData/Floor";
import FormFloor from "./pages/MasterData/Floor/form";

import Type from "./pages/MasterData/Type";
import FormType from "./pages/MasterData/Type/form";

import Equipment from "./pages/MasterData/Equipment";
import FormEquipment from "./pages/MasterData/Equipment/form";

import EquipmentModel from "./pages/MasterData/EquipmentModel";
import FormEquipmentModel from "./pages/MasterData/EquipmentModel/form";

import Category from "./pages/MasterData/Category";
import FormCategory from "./pages/MasterData/Category/form";

import IconMenu from "./pages/MasterData/IconMenu";
import FormIconMenu from "./pages/MasterData/IconMenu/form";

import Schedule from "./pages/MasterData/Schedule";
import FormSchedule from "./pages/MasterData/Schedule/form";

import Sensor from "./pages/MasterData/Sensor";
import FormSensor from "./pages/MasterData/Sensor/form";

import Location from "./pages/MasterData/Location";
import FormLocation from "./pages/MasterData/Location/form";

import Map from "./pages/MasterData/Map";
import FormMap from "./pages/MasterData/Map/form";

import Vehicle from "./pages/MasterData/Vehicle";
import FormVehivle from "./pages/MasterData/Vehicle/form";

import Vendor from "./pages/MasterData/Vendor";
import FormVendor from "./pages/MasterData/Vendor/form";

import Tran from "./pages/MasterData/Tran";
import FormTran from "./pages/MasterData/Tran/form";

// EMPLOYEE
import Division from "./pages/Employee/Division";
import FormDivision from "./pages/Employee/Division/form";

import Department from "./pages/Employee/Department";
import FormDepartment from "./pages/Employee/Department/form";

import Section from "./pages/Employee/Section";
import FormSection from "./pages/Employee/Section/form";

import Employee from "./pages/Employee/Employee";
import FormEmployee from "./pages/Employee/Employee/form";

// CONFIGURATION
import TranApprover from "./pages/Configuration/TranApprover";

import User from "./pages/Configuration/User";
import FormUser from "./pages/Configuration/User/form";

import UserGroup from "./pages/Configuration/UserGroup";
import FormUserGroup from "./pages/Configuration/UserGroup/form";


// TRANSACTION
import WOTransaction from "./pages/Transaction/WOTransaction";
import FormWOTransaction from "./pages/Transaction/WOTransaction/form";
import WORequest from "./pages/Transaction/WORequest";
import FormWORequest from "./pages/Transaction/WORequest/form";
import PMTransaction from "./pages/Transaction/PMTransaction";
import FormPMTransaction from "./pages/Transaction/PMTransaction/form";
import Mapping from "./pages/MasterData/Mapping";
import TranApproval from "./pages/Transaction/TranApproval";
import DMTransaction from "./pages/Transaction/DMTransaction";
import FormDMTransaction from "./pages/Transaction/DMTransaction/form";
import SubCategory from "./pages/MasterData/SubCategory";
import FormSubCategory from "./pages/MasterData/SubCategory/form";
import MPTransaction from "./pages/Transaction/MPTransaction";
import FormMPTransaction from "./pages/Transaction/MPTransaction/form";




// Route configurations as an array of objects
export const AuthRoutes = [
    // Auth Routes
    { path: "/", element: <Login /> },
    { path: "/signup", element: <Signup /> },
];

const master = "/master";
const transaction = "/transaction";

export const MainRoutes = [
    // Main App Routes
    { path: "/dashboard", element: <Dashboard /> },

    // MENU MASTER DATA

    // FACILITY
    { path: `${master}/facility`, element: <Facility /> },
    { path: `${master}/facility/form`, element: <FormFacility /> },

    // BUILDINGS
    { path: `${master}/asset`, element: <Buildings /> },
    { path: `${master}/asset/form`, element: <FormBuildings /> },

    // FLOOR
    { path: `${master}/floorlevel`, element: <Floor /> },
    { path: `${master}/floorlevel/form`, element: <FormFloor /> },

    // TYPE
    { path: `${master}/type`, element: <Type /> },
    { path: `${master}/type/form`, element: <FormType /> },

    // EQUIPMENT MODEL
    { path: `${master}/equipmentmodel`, element: <EquipmentModel /> },
    { path: `${master}/equipmentmodel/form`, element: <FormEquipmentModel /> },

    // EQUIPMENT
    { path: `${master}/equipment`, element: <Equipment /> },
    { path: `${master}/equipment/form`, element: <FormEquipment /> },

    // CATEGORY
    { path: `${master}/category`, element: <Category /> },
    { path: `${master}/category/form`, element: <FormCategory /> },

    // SUBCATEGORY
    { path: `${master}/subcategory`, element: <SubCategory /> },
    { path: `${master}/subcategory/form`, element: <FormSubCategory /> },


    // DIVISION
    { path: `${master}/division`, element: <Division /> },
    { path: `${master}/division/form`, element: <FormDivision /> },

    // DAPARTEMENT
    { path: `${master}/department`, element: <Department /> },
    { path: `${master}/department/form`, element: <FormDepartment /> },

    // SECTION
    { path: `${master}/section`, element: <Section /> },
    { path: `${master}/section/form`, element: <FormSection /> },

    // ICON
    { path: `${master}/icon`, element: <IconMenu /> },
    { path: `${master}/icon/form`, element: <FormIconMenu /> },

    // EMPLOYEE
    { path: `${master}/employee`, element: <Employee /> },
    { path: `${master}/employee/form`, element: <FormEmployee /> },

    // SCHEDULE
    { path: `${master}/schedule`, element: <Schedule /> },
    { path: `${master}/schedule/form`, element: <FormSchedule /> },

    // SENSOR
    { path: `${master}/sensor`, element: <Sensor /> },
    { path: `${master}/sensor/form`, element: <FormSensor /> },

    // LOCATION
    { path: `${master}/location`, element: <Location /> },
    { path: `${master}/location/form`, element: <FormLocation /> },

    // MAP
    { path: `${master}/map`, element: <Map /> },
    { path: `${master}/map/form`, element: <FormMap /> },

    // MAPPING
    { path: `${master}/mapping`, element: <Mapping /> },

    // VEHICLE
    { path: `${master}/vehicle`, element: <Vehicle /> },
    { path: `${master}/vehicle/form`, element: <FormVehivle /> },

    // VENDOR
    { path: `${master}/vendor`, element: <Vendor /> },
    { path: `${master}/vendor/form`, element: <FormVendor /> },

    // TRAN
    { path: `${master}/tran`, element: <Tran /> },
    { path: `${master}/tran/form`, element: <FormTran /> },

    // TRAN APPROVER
    { path: `${master}/tranapprover`, element: <TranApprover /> },

    // USER
    { path: `${master}/user`, element: <User /> },
    { path: `${master}/user/form`, element: <FormUser /> },

    // USER GROUP
    { path: `${master}/usergroup`, element: <UserGroup /> },
    { path: `${master}/usergroup/form`, element: <FormUserGroup /> },


    // TRANSACTION

    // TRANSACTION REQUEST
    { path: `${transaction}/worequest`, element: <WORequest /> },
    { path: `${transaction}/worequest/form/`, element: <FormWORequest /> },
    { path: `${transaction}/worequest/form?action=action&faccode=faccode&trannumber=trannumber`, element: <FormWORequest /> },


    // WO TANSACTION
    { path: `${transaction}/workorder`, element: <WOTransaction /> },
    { path: `${transaction}/workorder/form`, element: <FormWOTransaction /> },

    // DM TRANSACTION
    { path: `${transaction}/demand`, element: <DMTransaction /> },
    { path: `${transaction}/demand/form`, element: <FormDMTransaction /> },

    // PM TRANSACTION
    { path: `${transaction}/pmtransaction`, element: <PMTransaction /> },
    { path: `${transaction}/pmtransaction/form`, element: <FormPMTransaction /> },

    // MP TRANSACTION
    { path: `${transaction}/mptransaction`, element: <MPTransaction /> },
    { path: `${transaction}/mptransaction/form`, element: <FormMPTransaction /> },

    // TranApproval
    { path: `${transaction}/tranApproval`, element: <TranApproval /> },
    // { path: `${transaction}/pmtransaction/form`, element: <FormPMTransaction /> },
]