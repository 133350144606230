import React, { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { onlyNumber } from "../../global/Components";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getVendor } from "../../../Api/Master/GetData";
import { postVendor } from "../../../Api/Master/PostData";

const FormVendor = () => {

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [VendorCode, setVendorCode] = useState("");

  const FetchVendorCode = async () => {
    try {
      const response = await getVendor();
      if (response.length > 0) {
        const VDData = response.filter(item => item.VendorCode && item.VendorCode.startsWith('VD'));
        if (VDData.length > 0) {
          const lastCode = VDData[VDData.length - 1].VendorCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setVendorCode(`VD${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setVendorCode("VD0001");
        }
      } else {
        setVendorCode("VD0001");
      }
    } catch (error) {
      setVendorCode("VD0001");
      console.log(error);
    }
  };

  useEffect(() => {
    FetchVendorCode();
  }, []);


  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("VendorCode", VendorCode);
    }
  }, [VendorCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        VendorCode: VendorCode,
        Phone1: "0" + values.Phone1,
        Phone2: "0" + values.Phone2,
      };
      const response = await postVendor(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/vendor");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.statusMessage) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key) || (e.target.value === "" && e.key === "0")) {
      e.preventDefault();
    }
  };

  return (

    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box>
          <StyleContent>
            <Header title="VENDOR" subtitle="Create a Vendor" />
            <StyleForm>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Vendor Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e)
                  setVendorCode(e.target.value)
                }}
                value={VendorCode}
                name="VendorCode"
                inputProps={{ maxLength: 6 }}
                error={!!touched.VendorCode && !!errors.VendorCode}
                helperText={touched.VendorCode && errors.VendorCode}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                type="text"
                label="Vendor Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VendorName}
                name="VendorName"
                error={!!touched.VendorName && !!errors.VendorName}
                helperText={touched.VendorName && errors.VendorName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Address 1*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Address}
                name="Address"
                error={!!touched.Address && !!errors.Address}
                helperText={touched.Address && errors.Address}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Address 2*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Address2}
                name="Address2"
                error={!!touched.Address2 && !!errors.Address2}
                helperText={touched.Address2 && errors.Address2}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="City*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.City}
                name="City"
                error={!!touched.City && !!errors.City}
                helperText={touched.City && errors.City}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Country*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Country}
                name="Country"
                error={!!touched.Country && !!errors.Country}
                helperText={touched.Country && errors.Country}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Postal Code*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.PostalCode}
                name="PostalCode"
                error={!!touched.PostalCode && !!errors.PostalCode}
                helperText={touched.PostalCode && errors.PostalCode}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Phone Number 1*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Phone1}
                name="Phone1"
                error={!!touched.Phone1 && !!errors.Phone1}
                helperText={touched.Phone1 && errors.Phone1}
                sx={{ gridColumn: "span 2" }}
                placeholder="Example: 81234567890"
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+62</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Phone Number 2*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Phone2}
                name="Phone2"
                error={!!touched.Phone2 && !!errors.Phone2}
                helperText={touched.Phone2 && errors.Phone2}
                sx={{ gridColumn: "span 2" }}
                placeholder="Example: 81234567890"
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+62</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Email*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Email}
                name="Email"
                error={!!touched.Email && !!errors.Email}
                helperText={touched.Email && errors.Email}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Fax*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Fax}
                name="Fax"
                error={!!touched.Fax && !!errors.Fax}
                helperText={touched.Fax && errors.Fax}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Contact Person*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ContactPerson}
                name="ContactPerson"
                error={!!touched.ContactPerson && !!errors.ContactPerson}
                helperText={touched.ContactPerson && errors.ContactPerson}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="TOP Code*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.TOPCode}
                name="TOPCode"
                error={!!touched.TOPCode && !!errors.TOPCode}
                helperText={touched.TOPCode && errors.TOPCode}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Act No*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ActNo}
                name="ActNo"
                error={!!touched.ActNo && !!errors.ActNo}
                helperText={touched.ActNo && errors.ActNo}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Act Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ActName}
                name="ActName"
                error={!!touched.ActName && !!errors.ActName}
                helperText={touched.ActName && errors.ActName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Bank Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.BankName}
                name="BankName"
                error={!!touched.BankName && !!errors.BankName}
                helperText={touched.BankName && errors.BankName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Bank Branch Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.BankBranchName}
                name="BankBranchName"
                error={!!touched.BankBranchName && !!errors.BankBranchName}
                helperText={touched.BankBranchName && errors.BankBranchName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="NPWP*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.NPWP}
                name="NPWP"
                error={!!touched.NPWP && !!errors.NPWP}
                helperText={touched.NPWP && errors.NPWP}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 4" }}
                multiline
                rows={4}
              />

            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />

        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  VendorCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Vendor Code."),
  VendorName: yup
    .string()
    .max(100, "Maximum of 100 characters allowed")
    .required("Please complete this Vendor Name."),
  Address: yup
    .string()
    .max(500, "Maximum of 500 characters allowed")
    .required("Please complete this Address."),
  Address2: yup
    .string()
    .max(500, "Maximum of 500 characters allowed")
    .required("Please complete this Address2."),
  City: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this City."),
  Country: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Country."),
  PostalCode: yup
    .string()
    .max(5, "Maximum of 5 characters allowed")
    .required("Please complete this PostalCode."),
  Phone1: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 1."),
  Phone2: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 2.")
    .test(
      "phone-not-same",
      "Phone Number 1 and Phone Number 2 should not be the same",
      function (value) {
        return value !== this.parent.Phone1;
      }
    ),
  Email: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Email."),
  Fax: yup
    .string()
    .max(15, "Maximum of 15 characters allowed")
    .required("Please complete this Fax."),
  ContactPerson: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ContactPerson."),
  TOPCode: yup
    .string()
    .max(2, "Maximum of 2 characters allowed")
    .required("Please complete this TOPCode."),
  ActNo: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this ActNo."),
  ActName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ActName."),
  BankName: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this BankName."),
  BankBranchName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this BankBranchName."),
  NPWP: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this NPWP."),
});
const initialValues = {
  VendorName: "",
  Address: "",
  Address2: "",
  Country: "",
  City: "",
  PostalCode: "",
  Phone1: "",
  Phone2: "",
  Email: "",
  Fax: "",
  ContactPerson: "",
  TOPCode: "",
  ActNo: "",
  ActName: "",
  BankName: "",
  BankBranchName: "",
  NPWP: "",
  Description: "",
};

export default FormVendor;
