
import { tokens } from "../../theme";
import { useMediaQuery, useTheme } from "@mui/material";

export const StyleContent = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const styles = {
        margin: "20px",
        padding: "20px 25px",
        borderRadius: "13px",
        backgroundColor: `${colors.grey[100]} !important`,
    }

    return styles

}

export const StyleSearchGrid = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const styles = {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        marginBottom: "10px",
        '& .MuiOutlinedInput-root': {

            borderRadius: '20px',

            '&.Mui-focused fieldset': {
                borderColor: colors.grey[500],
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: colors.grey[600],
        },
    }

    return styles

}

export const SearchGrid = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const styles = {
        marginTop: 2,
        backgroundColor: `${colors.grey[500]} !important`,
        boxShadow: "0 0 2px #00000036 ",
        // border: "1px solid #00000016",
        borderRadius: "6px",
    }

    return styles

}



export const StyleGridTable = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const styles = {
        height: "70vh",
        width: "100%",
        "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "0",
            color: `${colors.primary[100]} !important`,
        },
        "& .name-column--cell": {
            border: `1px solid ${colors.primary[100]}`
        },
        "& .MuiDataGrid-columnHeaders": {
            borderTop: `1px solid ${colors.primary[100]}`,
            borderBottom: `1px solid ${colors.primary[100]}`,
            borderRadius: "0",
        },
        "& .MuiDataGrid-columnSeparator": {
            color: `${colors.primary[100]} !important`
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: `1px solid ${colors.primary[100]}`,
        },
        "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[100]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer": {
            padding: "4px 20px 0px"
        },
    };

    return styles

};


export const StyleFormInput = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const styles = {
        display: "grid",
        gap: "30px",
        color: colors.grey[900],
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            
            "&.Mui-focused fieldset": {
                borderColor: colors.grey[900],
            },
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: colors.grey[900],
        },
        p: 4,
    }

    return styles

}

export const StyleModalFormEdit = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const styles = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "90%",
        minWidth: "80%",
        maxHeight: "90%",
        background: `${colors.grey[100]} !important`,
        border: `2px solid ${colors.grey[500]} !important`,
        borderRadius: "14px",
        boxShadow: 24,
        // p: 4,
        overflow: "auto",
    }

    return styles

}

export const StyleModalDelete = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const styles = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "35%",
        bgcolor: colors.grey[100],
        border: `2px solid ${colors.grey[500]}`,
        borderRadius: "16px",
        boxShadow: 24,
        p: 4,
    }

    return styles

}


export const StyleButtonForm = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        position: "sticky",
        top: 0,
        bottom: 0,
        zIndex: 100,
        width: "100%",
        padding: "20px",
        boxShadow: "0px -5px 10px #00000016",
        backgroundColor: `${colors.grey[100]} !important`,
    }

    return style
}

export const StyleHeaderEdit = () => {
    const style = {
        display: "flex",
        justifyContent: "space-between",
        p: "32px 0 0 32px"
    }
    return style
}