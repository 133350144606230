import React, { useState } from "react";
import * as yup from "yup";
import { Box, Button, IconButton, Modal, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { StyleFormInput, StyleHeaderEdit, StyleModalFormEdit } from "../../global/StyleCSS";
import { onlyNumber } from "../../global/Components";
import { updateTran } from "../../../Api/Master/UpdateData";

const EditTran = ({ data, onEdit }) => {

  const formRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };

  const handleFormSubmit = async (values) => {
    try {
      await updateTran(values.TranIdx, values);
      enqueueSnackbar("Data Tran has been successfully updated.", { variant: "success" });
      onEdit(values.TranIdx);
      setOpen(false);
    } catch (error) {
      enqueueSnackbar("Tran data failed to update.", { variant: "error" });
      formRef.current.resetForm();
      setOpen(false);
    }
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Modal keepMounted open={open}>

        <Box sx={StyleModalFormEdit} >

          <Box sx={StyleHeaderEdit}>
            <Header title="EDIT TRAN" subtitle="Edit Data a Tran" />
          </Box>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={data}
            validationSchema={checkoutSchema}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={StyleFormInput}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="TranIdx"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TranIdx}
                    name="TranIdx"
                    InputProps={{
                      readOnly: true,
                    }}
                    error={!!touched.TranIdx && !!errors.TranIdx}
                    helperText={touched.TranIdx && errors.TranIdx}
                    sx={{ gridColumn: "span 2" }}
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="TranName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TranName}
                    name="TranName"
                    error={!!touched.TranName && !!errors.TranName}
                    helperText={touched.TranName && errors.TranName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="TranFormName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TranFormName}
                    name="TranFormName"
                    error={!!touched.TranFormName && !!errors.TranFormName}
                    helperText={touched.TranFormName && errors.TranFormName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="ShowIdx"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ShowIdx}
                    name="ShowIdx"
                    error={!!touched.ShowIdx && !!errors.ShowIdx}
                    helperText={touched.ShowIdx && errors.ShowIdx}
                    sx={{ gridColumn: "span 2" }}
                    onKeyPress={handleKeyPress}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "20px",
                    gap: "10px",
                  }}
                >
                  <Button type="button" color="error" variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" color="secondary" variant="contained">
                    Update Data
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box >
  );
};

const checkoutSchema = yup.object().shape({
  TranName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this TranName."),
  TranFormName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this TranFormName."),
  ShowIdx: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ShowIdx."),
});

export default EditTran;
