import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import EditSubCategory from "./edit";
import DeleteSubCategory from "./delete";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getSubCategory } from "../../../Api/Master/GetData";

const SubCategory = () => {

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [filterValue, setFilterValue] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getSubCategory();
            setData(response);
        } catch (error) {
            //console.log(error);
            if (error.response && error.response.status === 404) {
                setData([]);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            key: 'key',
            title: 'No',
            dataIndex: 'key',
            width: 80,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Module ID',
            dataIndex: ['SubCategory', 'ModuleID'],
            width: 200,
            sorter: (a, b) => a.SubCategory.ModuleID.localeCompare(b.SubCategory.ModuleID),
        },
        {
            title: 'Category Name',
            dataIndex: 'CatName',
            width: 200,
            sorter: (a, b) => a.CatName.localeCompare(b.CatName),
        },
        {
            title: 'SubCategory Code',
            dataIndex: ['SubCategory', 'SubCatCode'],
            width: 200,
            sorter: (a, b) => a.SubCategory.SubCatCode.localeCompare(b.SubCategory.SubCatCode),
        },
        {
            title: 'SubCategory Name',
            dataIndex: ['SubCategory', 'SubCatName'],
            width: 200,
            sorter: (a, b) => a.SubCategory.SubCatName.localeCompare(b.SubCategory.SubCatName),
        },
        {
            title: 'Suspend',
            dataIndex: ['SubCategory', 'IsSuspend'],
            width: 100,
            render: (isSuspend) => (
                <Box display="flex" justifyContent="center" >
                    {
                        isSuspend ? (
                            <Tooltip title="Suspend" >
                                <CheckCircleIcon color="success" />
                            </Tooltip >
                        ) : (
                            <Tooltip title="Unsuspend">
                                <CancelIcon color="error" />
                            </Tooltip>
                        )}
                </Box>
            )
        },
    ];

    if (myEditAuthIdx || myDeleteAuthIdx) {
        columns.push({
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, record) => (
                <Box display="flex" justifyContent="center">
                    {myEditAuthIdx && (
                        <EditSubCategory data={record} onEdit={fetchData} />
                    )}

                    {myDeleteAuthIdx && !record.SubCategory.IsSuspend && (
                        <DeleteSubCategory data={record} onDelete={fetchData} />
                    )}
                </Box>
            ),
        },)
    }

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);

        const filteredData = data.filter((row) => {
            // Sesuaikan dengan kolom yang ingin Anda filter
            return (
                row.SubCategory.ModuleID.toLowerCase().includes(value.toLowerCase()) ||
                row.CatName.toLowerCase().includes(value.toLowerCase()) ||
                row.SubCategory.SubCatCode.toLowerCase().includes(value.toLowerCase()) ||
                row.SubCategory.SubCatName.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
    };

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = [
        { title: "key", key: "key" },
        { title: "ModuleID", key: ["SubCategory", "ModuleID"] },
        { title: "CatName", key: "CatName" },
        { title: "SubCatCode", key: ["SubCategory", "SubCatCode"] },
        { title: "SubCatName", key: ["SubCategory", "SubCatName"] },
    ];

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header title="SUBCATEGORY" subtitle="All SubCategory Data" />

                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx && (
                        <ExportData
                            data={selectedRows.length > 0 ? selectedRows : data}
                            columns={columnsExport}
                            name="SubCategory"
                        />
                    )}

                    {myAddAuthIdx && (
                        <ButtonAddNew
                            link="/master/subcategory/form"
                        />
                    )}
                </Box>

            </StyleGridHeader>

            <SearchData filterValue={filterValue} handleFilterChange={handleFilterChange} />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filterValue ? filteredRows : data}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={data.length === 0 ? null : {
                    expandedRowRender: (record) => (
                        <DetailGrid>
                            <Typography variant="h5" fontWeight={600}>Description : </Typography>
                            <Typography>{record.SubCategory.Description}</Typography>
                        </DetailGrid>
                    ),
                    rowExpandable: (record) => record.SubCategory.Description !== '',
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 1000 }}
            />

        </StyleContent>
    );
};

export default SubCategory;
