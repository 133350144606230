import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table, Tabs } from "antd";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getMainAdjustmentTransaction } from "../Api/GetData";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DataDetailAdjustment from "./AdjustmentDetail";
import EditAdjustmentTransaction from "./edit";
import DeleteAdjustmentTransaction from "./delete";

const AdjustmentTransaction = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const [filterValue, setFilterValue] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getMainAdjustmentTransaction();
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.Adjustment.Description !== '' ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "14px",
                            borderBottom: '2px solid #00000015'
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography>{record.Adjustment.Description}</Typography>
                    </div>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Detail',
                            key: 'detail',
                            children: <DataDetailAdjustment record={record.Adjustment} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            width: 200,
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'Adjustment Number',
            dataIndex: ['Adjustment', 'ADNumber'],
            width: 200,
            sorter: (a, b) => a.Adjustment.ADNumber.localeCompare(b.Adjustment.ADNumber),
        },
        {
            title: 'Periode',
            dataIndex: ['Adjustment', 'Periode'],
            width: 200,
            sorter: (a, b) => a.Adjustment.Periode.localeCompare(b.Adjustment.Periode),
        },
        {
            title: 'Adjustment Date',
            dataIndex: ['Adjustment', 'ADDate'],
            width: 200,
            sorter: (a, b) => a.Adjustment.ADDate.localeCompare(b.Adjustment.ADDate),
        },
        {
            title: 'Adjustment',
            dataIndex: ['Adjustment', 'ADCode'],
            width: 200,
            sorter: (a, b) => a.Adjustment.ADCode.localeCompare(b.Adjustment.ADCode),
        },
        {
            title: 'Incoming',
            dataIndex: ['Adjustment', 'Incoming'],
            width: 200,
            render: (_, record) =>
                record.Adjustment.Incoming ? (
                    <Tooltip title="Suspend">
                        <CheckCircleIcon color="success" />
                    </Tooltip>
                ) : (
                    <Tooltip title="Unsuspend">
                        <CancelIcon color="error" />
                    </Tooltip>
                ),
        },
        {
            title: 'IsPostingGL',
            dataIndex: ['Adjustment', 'IsPostingGL'],
            width: 200,
            render: (_, record) =>
                record.Adjustment.IsPostingGL ? (
                    <Tooltip title="Suspend">
                        <CheckCircleIcon color="success" />
                    </Tooltip>
                ) : (
                    <Tooltip title="Unsuspend">
                        <CancelIcon color="error" />
                    </Tooltip>
                ),
        },
    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (record.Adjustment.AppStatus === 'CANCEL') {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditAdjustmentTransaction data={record} onEdit={fetchData} />
                        )}

                        {myDeleteAuthIdx > 0 && (
                            <DeleteAdjustmentTransaction data={record} onDelete={fetchData} />
                        )}
                    </Space>
                );
            },
        })
    }


    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);

        const filteredData = data.filter((row) => {
            return (
                row.FacilityName.toLowerCase().includes(value.toLowerCase()) ||
                row.Adjustment.ADNumber.toLowerCase().includes(value.toLowerCase()) ||
                row.Adjustment.Periode.toLowerCase().includes(value.toLowerCase()) ||
                row.Adjustment.ADDate.toLowerCase().includes(value.toLowerCase()) ||
                row.Adjustment.ADCode.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
    };

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = [
        { title: "key", key: "key" },
        { title: "FacilityName", key: "FacilityName" },
        { title: "ADNumber", key: ["Adjustment", "ADNumber"] },
        { title: "Periode", key: ["Adjustment", "Periode"] },
        { title: "ADDate", key: ["Adjustment", "ADDate"] },
        { title: "ADCode", key: ["Adjustment", "ADCode"] },
        { title: "Incoming", key: ["Adjustment", "Incoming"] },
        { title: "IsPostingGL", key: ["Adjustment", "IsPostingGL"] },
    ];

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header
                    title="ADJUSTMENT"
                    subtitle="All Adjustment data"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={selectedRows.length > 0 ? selectedRows : data}
                            columns={columnsExport}
                            name="Adjustment"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="/transaction/adjustment/form"
                        />
                    )}
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={filterValue}
                handleFilterChange={handleFilterChange}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filterValue ? filteredRows : data}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 1000 }}
            />

        </StyleContent>
    );
};

export default AdjustmentTransaction