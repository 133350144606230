import React, { useCallback, useState, useEffect, useRef } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getCategory, getModuleID, getSubCategory } from "../../../Api/Master/GetData";
import { postSubCategory } from "../../../Api/Master/PostData";

const FormSubCategory = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsModuleId = searchParams.get('moduleid');
  const paramsCategory = searchParams.get('category');


  useEffect(() => {
    if (paramsModuleId) {
      setOpenModuleID(true);
      setSelectedModuleID(paramsModuleId);

      setOpenCatCode(true);
      setSelectedCatCode(paramsCategory);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [moduleID, setModuleID] = useState([]);
  const [selectedModuleID, setSelectedModuleID] = useState("");
  const [OpenModuleID, setOpenModuleID] = useState(false);

  const [CatCode, setCatCode] = useState([]);
  const [selectedCatCode, setSelectedCatCode] = useState("");
  const [OpenCatCode, setOpenCatCode] = useState(false);

  const [SubCatCode, setSubCatCode] = useState("");

  useEffect(() => {
    const fetchModuleID = async () => {
      setIsLoading(true);
      try {
        const response = await getModuleID();
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setModuleID(filterRes);
      } catch (error) {
        console.log(error);
      }
    };

    if (OpenModuleID) {
      fetchModuleID();
      setOpenModuleID(false);
    }
  }, [OpenModuleID]);

  useEffect(() => {
    const fetchCategory = async () => {
      setIsLoading(true);
      try {
        if (selectedModuleID) {
          const response = await getCategory(selectedModuleID);
          const filterRes = response.filter((item) => item.IsSuspend !== true);
          setCatCode(filterRes);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (OpenCatCode) {
      fetchCategory();
      setOpenCatCode(false);
    }
  }, [OpenCatCode, selectedModuleID]);

  const fetchSubCatCode = useCallback(async () => {
    try {
      const response = await getSubCategory(selectedModuleID, selectedCatCode);
      if (response.length > 0) {
        const CAData = response.filter((item) =>
          item.SubCategory.SubCatCode.startsWith("SCA")
        );
        if (CAData.length > 0) {
          const lastCode = CAData[CAData.length - 1].SubCategory.SubCatCode;
          const nextNumber = parseInt(lastCode.substr(3)) + 1;
          setSubCatCode(`SCA${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setSubCatCode("SCA0001");
      console.log(error);
    }
  }, [selectedCatCode, selectedModuleID]);

  useEffect(() => {
    if (selectedModuleID && selectedCatCode) {
      fetchSubCatCode();
    }
  }, [selectedModuleID, fetchSubCatCode, selectedCatCode, SubCatCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("ModuleID", selectedModuleID);
      formRef.current.setFieldValue("CatCode", selectedCatCode);
      formRef.current.setFieldValue("SubCatCode", SubCatCode);
    }
  }, [SubCatCode, selectedCatCode, selectedModuleID]);

  const handleModuleIDSelection = (event, value) => {
    if (value) {
      setSelectedModuleID(value.ModuleID);
      setSelectedCatCode("");
    } else {
      setSelectedModuleID("");
    }
  };

  const handleCatCodeSelection = (event, value) => {
    if (value) {
      setSelectedCatCode(value.CatCode);
    } else {
      setSelectedCatCode("");
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        ModuleID: selectedModuleID,
        CatCode: selectedCatCode,
        SubCatCode: SubCatCode,
      };

      const response = await postSubCategory(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/subcategory");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, ModuleID: selectedModuleID, CatCode: selectedCatCode, SubCatCode: SubCatCode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header
                title="FORM SUBCATEGORY"
                subtitle="Create a SubCategory"
              />
              <StyleForm>
                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenModuleID(true)}
                  loading={isLoading}
                  options={moduleID}
                  getOptionLabel={(option) => option.ModuleID}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleModuleIDSelection(event, value);
                    setFieldValue("ModuleID", value ? value.ModuleID : "");
                  }}
                  value={
                    moduleID.find(
                      (option) => option.ModuleID === selectedModuleID
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Module Id*"
                      onBlur={handleBlur}
                      error={!!touched.ModuleID && !!errors.ModuleID}
                      helperText={touched.ModuleID && errors.ModuleID}
                    />
                  )}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenCatCode(true)}
                  loading={isLoading}
                  options={CatCode}
                  getOptionLabel={(option) => option.CatName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleCatCodeSelection(event, value);
                    setFieldValue("CatCode", value ? value.CatName : "");
                  }}
                  value={
                    CatCode.find(
                      (option) => option.CatCode === selectedCatCode
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category Name*"
                      onBlur={handleBlur}
                      error={!!touched.CatCode && !!errors.CatCode}
                      helperText={touched.CatCode && errors.CatCode}
                    />
                  )}
                  noOptionsText={
                    selectedModuleID ? (
                      CatCode.length === 0 ? (
                        <Link
                          to="/master/category/form"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          there is no option, please fill in the Category data
                        </Link>
                      ) : (
                        "No options"
                      )
                    ) : (
                      "Selecte the ModuleID first"
                    )
                  }
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="SubCategory Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setSubCatCode(e.target.value);
                  }}
                  value={SubCatCode}
                  name="SubCatCode*"
                  error={!!touched.SubCatCode && !!errors.SubCatCode}
                  helperText={touched.SubCatCode && errors.SubCatCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 7 }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="SubCategory Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SubCatName}
                  name="SubCatName"
                  error={!!touched.SubCatName && !!errors.SubCatName}
                  helperText={touched.SubCatName && errors.SubCatName}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  ModuleID: yup.string().required("Please complete this Module ID."),
  CatCode: yup.string().required("Please complete this Category Name."),
  SubCatCode: yup.string().required("Please complete this SubCategory Code."),
  SubCatName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this SubCategory Name."),
});
const initialValues = {
  ModuleID: "",
  CatCode: "",
  SubCatCode: "",
  SubCatName: "",
  Description: "",
};

export default FormSubCategory;
