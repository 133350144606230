import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getBuilding, getFacility, getFloor, getMap } from "../../../Api/Master/GetData";
import { postFloor } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { Image } from "antd";

const FormFloor = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsFacCode = searchParams.get('faccode');
  const paramsBuildCode = searchParams.get('buildcode');

  useEffect(() => {
    if (paramsFacCode && paramsBuildCode) {
      const fetchData = async () => {
        try {
          const resFac = await getFacility(paramsFacCode);
          setSelectFacility(resFac[0]);

          const resBuild = await getBuilding(paramsFacCode, paramsBuildCode);
          setSelectBuilding(resBuild[0]);
        } catch (error) {
          console.log(error);
        }
      }

      fetchData();

    }
  }, [paramsBuildCode, paramsFacCode]);

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [floorLevelCode, setFloorLevelCode] = useState("");

  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const [openModalFacility, setOpenModalFacility] = useState("");
  const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
  const FacilityName = selectFacility ? selectFacility.FacilityName : '';

  const [dataBuilding, setDataBuilding] = useState([]);
  const [selectBuilding, setSelectBuilding] = useState("");
  const [openModalBuilding, setOpenModalBuilding] = useState("");
  const BuildingCode = selectBuilding ? selectBuilding.Building.BuildingCode : '';
  const BuildingName = selectBuilding ? selectBuilding.Building.BuildingName : '';

  const [dataMap, setDataMap] = useState([]);
  const [selectMap, setSelectMap] = useState("");
  const [openModalMap, setOpenModalMap] = useState("");
  const MapCode = selectMap ? selectMap.Map.MapCode : '';
  const MapName = selectMap ? selectMap.Map.MapName : '';

  // DATA FACILITY
  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const res = await getFacility();
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataFacility(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalFacility) {
      setIsLoading(false);
      fetchFacility();
      setOpenModalFacility(false);
      setDataBuilding([]);
      setSelectBuilding("");
      setDataMap([]);
      setFloorLevelCode("");
      setSelectMap("");
    }
  }, [openModalFacility]);


  // DATA BUILDING
  useEffect(() => {
    const fetchBuilding = async () => {
      try {
        const res = await getBuilding(FacilityCode);
        const filterRes = res.filter((item) => item.Building.IsSuspend !== true);
        setDataBuilding(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalBuilding && FacilityCode) {
      setIsLoading(false);
      fetchBuilding();
      setOpenModalBuilding(false);
    }
  }, [FacilityCode, openModalBuilding]);


  // DATA MAP
  useEffect(() => {
    const fetchMap = async () => {
      try {
        const res = await getMap(FacilityCode);
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataMap(filterRes);
      } catch (error) {
        console.log(error);
      }
    };

    if (openModalMap) {
      setIsLoading(false);
      fetchMap();
      setOpenModalMap(false);
    }
  }, [FacilityCode, openModalMap]);


  const FetchFloorLevelCode = useCallback(async () => {
    try {
      const response = await getFloor(FacilityCode, BuildingCode);
      if (response.length > 0) {
        const FLData = response.filter((item) =>
          item.FloorLevel.FloorLevelCode.startsWith("FL")
        );
        if (FLData.length > 0) {
          const lastCode = FLData[FLData.length - 1].FloorLevel.FloorLevelCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setFloorLevelCode(`FL${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setFloorLevelCode("FL0001");
      console.log(error.response.statusText);
    }
  }, [BuildingCode, FacilityCode]);

  useEffect(() => {
    if (FacilityCode && BuildingCode) {
      FetchFloorLevelCode();
    }
  }, [FacilityCode, FetchFloorLevelCode, BuildingCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("FacilityName", FacilityName);
      formRef.current.setFieldValue("BuildingName", BuildingName);
      formRef.current.setFieldValue("MapName", MapName);
      formRef.current.setFieldValue("FloorLevelCode", floorLevelCode);
    }
  }, [BuildingName, FacilityName, MapName, floorLevelCode]);


  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        FacilityCode: FacilityCode,
        BuildingCode: BuildingCode,
        MapCode: MapCode,
        FloorLevelCode: floorLevelCode,
      };
      const response = await postFloor(payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/floorlevel");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
      validateOnChange={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="FORM FLOOR LEVEL" subtitle="Create a Floor Level" />
            <StyleForm>

              <InputModal
                title="FACILITY"
                label="Facility Name"
                name="FacilityName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.FacilityName}
                error={!!touched.FacilityName && !!errors.FacilityName}
                helperText={touched.FacilityName && errors.FacilityName}
                dataSource={dataFacility}
                columns={columnsFacility}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectFacility(values)}
                propsOpenModal={(values) => setOpenModalFacility(values)}
                LinktoCreate={`/master/facility/form`}
              />

              <InputModal
                title="BUILDING"
                label="Building Name"
                name="BuildingName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.BuildingName}
                error={!!touched.BuildingName && !!errors.BuildingName}
                helperText={touched.BuildingName && errors.BuildingName}
                dataSource={dataBuilding}
                columns={columnsBuilding}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectBuilding(values)}
                propsOpenModal={(values) => setOpenModalBuilding(values)}
                LinktoCreate={`/master/building/form?faccode=${FacilityCode}`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Floor Level Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setFloorLevelCode(e.target.value);
                }}
                value={values.FloorLevelCode}
                name="FloorLevelCode"
                error={!!touched.FloorLevelCode && !!errors.FloorLevelCode}
                helperText={touched.FloorLevelCode && errors.FloorLevelCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 6 }}
              />

              <InputModal
                title="MAP"
                label="Map Name"
                name="MapName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.MapName}
                error={!!touched.MapName && !!errors.MapName}
                helperText={touched.MapName && errors.MapName}
                dataSource={dataMap}
                columns={columnsMap}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectMap(values)}
                propsOpenModal={(values) => setOpenModalMap(values)}
                LinktoCreate={`/master/map/form?faccode=${FacilityCode}`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Floor Level Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.FloorLevelName}
                name="FloorLevelName"
                error={!!touched.FloorLevelName && !!errors.FloorLevelName}
                helperText={touched.FloorLevelName && errors.FloorLevelName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />
            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityName: yup.string().required("Please complete this Facility Name."),
  BuildingName: yup.string().required("Please complete this Building Name."),
  MapName: yup.string().required("Please complete this Map Name."),
  FloorLevelName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this  Floor Level Name."),
  FloorLevelCode: yup
    .string()
    .required("Please complete this  Floor Level Code."),
});

const initialValues = {
  FacilityName: "",
  BuildingName: "",
  MapName: "",
  FloorLevelCode: "",
  FloorLevelName: "",
  Description: "",
};

export default FormFloor;

const columnsFacility = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    key: 'FacilityCode',
    title: 'Facility Code',
    dataIndex: 'FacilityCode',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
  },
  {
    key: 'FacilityName',
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
  },
  {
    key: 'Address',
    title: 'Address',
    dataIndex: 'Address',
    width: 400,
    sorter: (a, b) => a.Address.localeCompare(b.Address),
  },
  {
    key: 'City',
    title: 'City',
    dataIndex: 'City',
    sorter: (a, b) => a.City.localeCompare(b.City),
  },
  {
    key: 'Country',
    title: 'Country',
    dataIndex: 'Country',
    sorter: (a, b) => a.Country.localeCompare(b.Country),
  },
  {
    key: 'Phone1',
    title: 'Phone Number 1',
    dataIndex: 'Phone1',
    sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
  },
  {
    key: 'Phone2',
    title: 'Phone Number 2',
    dataIndex: 'Phone2',
    sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
  },
  {
    key: 'Fax',
    title: 'Fax',
    dataIndex: 'Fax',
    sorter: (a, b) => a.Fax.localeCompare(b.Fax),
  },
  {
    key: 'ContactPerson',
    title: 'Contact Person',
    dataIndex: 'ContactPerson',
    sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
  },
];


const columnsBuilding = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Building Code',
    dataIndex: ['Building', 'BuildingCode'],
    width: 160,
    fixed: 'left',
    sorter: (a, b) =>
      a.Building.BuildingCode.localeCompare(b.Building.BuildingCode),
  },
  {
    title: 'Building Name',
    dataIndex: ['Building', 'BuildingName'],
    sorter: (a, b) =>
      a.Building.BuildingName.localeCompare(b.Building.BuildingName),
  },
  {
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) =>
      a.Building.FacilityName.localeCompare(b.Building.FacilityName),
  },
  {
    title: 'Address',
    dataIndex: ['Building', 'Address'],
    width: 400,
    sorter: (a, b) => a.Building.Address.localeCompare(b.Building.Address),
  },
  {
    title: 'Phone Number 1',
    dataIndex: ['Building', 'Phone1'],
    sorter: (a, b) => a.Building.Phone1.localeCompare(b.Building.Phone1),
  },
  {
    title: 'Phone Number 2',
    dataIndex: ['Building', 'Phone2'],
    sorter: (a, b) => a.Building.Phone2.localeCompare(b.Building.Phone2),
  },
  {
    title: 'Contact Person',
    dataIndex: ['Building', 'ContactPerson'],
    sorter: (a, b) =>
      a.Building.ContactPerson.localeCompare(b.Building.ContactPerson),
  },
];

const columnsMap = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Image',
    dataIndex: ['Map', 'Image'],
    render: (params) =>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Image
          style={{ maxWidth: 100, maxHeight: "100%", objectFit: "contain" }}
          src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
        />
      </div>
  },
  {
    title: 'Map Code',
    dataIndex: ['Map', 'MapCode'],
    sorter: (a, b) => a.Map.MapCode.localeCompare(b.Map.MapCode),
  },
  {
    title: 'Map Name',
    dataIndex: ['Map', 'MapName'],
    sorter: (a, b) => a.Map.MapName.localeCompare(b.Map.MapName),
  },
  {
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
  },
];