import React, { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { onlyNumber } from "../../global/Components";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getFacility } from "../../../Api/Master/GetData";
import { postFacility } from "../../../Api/Master/PostData";

const FormFacility = () => {

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [facilityCode, setFacilityCode] = useState("");

  const fetchFacilityCode = async () => {
    try {
      const response = await getFacility();
      if (response.length > 0) {
        const FAData = response.filter(
          (item) => item.FacilityCode && item.FacilityCode.startsWith("FA")
        );
        if (FAData.length > 0) {
          const lastCode = FAData[FAData.length - 1].FacilityCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setFacilityCode(`FA${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setFacilityCode("FA0001");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFacilityCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("FacilityCode", facilityCode);
    }
  }, [facilityCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        FacilityCode: facilityCode,
        Phone1: "0" + values.Phone1,
        Phone2: "0" + values.Phone2,
      };

      const response = await postFacility(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/facility");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key) || (e.target.value === "" && e.key === "0")) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box>
          <StyleContent>
            <Header title="FORM FACILITY" subtitle="Create a Facility" />
            <StyleForm>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Facility Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setFacilityCode(e.target.value);
                }}
                value={facilityCode}
                name="FacilityCode"
                error={!!touched.FacilityCode && !!errors.FacilityCode}
                helperText={touched.FacilityCode && errors.FacilityCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 6 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Facility Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.FacilityName}
                name="FacilityName"
                error={!!touched.FacilityName && !!errors.FacilityName}
                helperText={touched.FacilityName && errors.FacilityName}
                sx={{ gridColumn: "span 2" }}
                autoFocus
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Address*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Address}
                name="Address"
                error={!!touched.Address && !!errors.Address}
                helperText={touched.Address && errors.Address}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="City*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.City}
                name="City"
                error={!!touched.City && !!errors.City}
                helperText={touched.City && errors.City}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Postal Code*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.PostalCode}
                name="PostalCode"
                error={!!touched.PostalCode && !!errors.PostalCode}
                helperText={touched.PostalCode && errors.PostalCode}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Country*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Country}
                name="Country"
                error={!!touched.Country && !!errors.Country}
                helperText={touched.Country && errors.Country}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Phone Number 1*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Phone1}
                name="Phone1"
                error={!!touched.Phone1 && !!errors.Phone1}
                helperText={touched.Phone1 && errors.Phone1}
                sx={{ gridColumn: "span 2" }}
                placeholder="Example: 81234567890"
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+62</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Phone Number 2*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Phone2}
                name="Phone2"
                error={!!touched.Phone2 && !!errors.Phone2}
                helperText={touched.Phone2 && errors.Phone2}
                sx={{ gridColumn: "span 2" }}
                placeholder="Example: 81234567890"
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+62</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Fax*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Fax}
                name="Fax"
                error={!!touched.Fax && !!errors.Fax}
                helperText={touched.Fax && errors.Fax}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Contact Person*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ContactPerson}
                name="ContactPerson"
                error={!!touched.ContactPerson && !!errors.ContactPerson}
                helperText={touched.ContactPerson && errors.ContactPerson}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 4" }}
                multiline
                rows={4}
              />
            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Facility Code."),
  FacilityName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Facility Name."),
  Address: yup
    .string()
    .max(200, "Maximum of 200 characters allowed")
    .required("Please complete this Address."),
  City: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this City."),
  PostalCode: yup
    .string()
    .max(5, "Maximum of 5 characters allowed")
    .required("Please complete this Postal Code."),
  Country: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Country."),
  Phone1: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 1."),
  Phone2: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 2.")
    .test(
      "phone-not-same",
      "Phone Number 1 and Phone Number 2 should not be the same",
      function (value) {
        return value !== this.parent.Phone1;
      }
    ),
  Fax: yup
    .string()
    .max(15, "Maximum of 15 characters allowed")
    .required("Please complete this Fax."),
  ContactPerson: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Contact Person."),
});
const initialValues = {
  FacilityCode: "",
  FacilityName: "",
  Address: "",
  City: "",
  PostalCode: "",
  Country: "",
  Phone1: "",
  Phone2: "",
  Fax: "",
  ContactPerson: "",
  Description: "",
};

export default FormFacility;
