import React, { useCallback, useState, useEffect, useRef } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getCategory, getModuleID } from "../../../Api/Master/GetData";
import { postCategory } from "../../../Api/Master/PostData";

const FormCategory = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsModuleId = searchParams.get('moduleid');

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [moduleID, setModuleID] = useState([]);
  const [selectedModuleID, setSelectedModuleID] = useState(null);
  const [OpenModuleID, setOpenModuleID] = useState(false);

  const [CatCode, setCatCode] = useState("");

  useEffect(() => {
    if (paramsModuleId) {
      setSelectedModuleID(paramsModuleId);
      setOpenModuleID(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchModuleID = async () => {
      setIsLoading(true);
      try {
        const response = await getModuleID('category');
        setModuleID(response);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (OpenModuleID) {
      fetchModuleID();
      setOpenModuleID(false);
    }
  }, [OpenModuleID]);

  const FetchCatCode = useCallback(async () => {
    try {
      const response = await getCategory(selectedModuleID);
      if (response.length > 0) {
        const CAData = response.filter(
          (item) => item.CatCode && item.CatCode.startsWith("CA")
        );
        if (CAData.length > 0) {
          const lastCode = CAData[CAData.length - 1].CatCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setCatCode(`CA${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setCatCode("CA0001");
      console.log(error);
    }
  }, [selectedModuleID]);

  useEffect(() => {
    if (selectedModuleID) {
      FetchCatCode();
    }
  }, [selectedModuleID, FetchCatCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("CatCode", CatCode);
      formRef.current.setFieldValue("ModuleID", selectedModuleID);
    }
  }, [CatCode, selectedModuleID]);

  const handleModuleIDSelection = (event, value) => {
    if (value) {
      setSelectedModuleID(value.ModuleID);
    } else {
      setSelectedModuleID(null);
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        ModuleID: selectedModuleID,
        CatCode: CatCode,
      };

      const response = await postCategory(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/category");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, ModuleID: selectedModuleID, CatCode: CatCode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header title="FORM CATEGORY" subtitle="Create a Category" />
              <StyleForm>
                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenModuleID(true)}
                  loading={isLoading}
                  options={moduleID}
                  getOptionLabel={(option) => option.ModuleID}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleModuleIDSelection(event, value);
                    setFieldValue("ModuleID", value ? value.ModuleID : "");
                  }}
                  value={
                    moduleID.find(
                      (option) => option.ModuleID === selectedModuleID
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Module Id*"
                      onBlur={handleBlur}
                      error={!!touched.ModuleID && !!errors.ModuleID}
                      helperText={touched.ModuleID && errors.ModuleID}
                    />
                  )}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Category Code*"
                  onBlur={handleBlur}
                  value={CatCode}
                  onChange={(e) => {
                    handleChange(e);
                    setCatCode(e.target.value);
                  }}
                  name="CatCode"
                  error={!!touched.CatCode && !!errors.CatCode}
                  helperText={touched.CatCode && errors.CatCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 6 }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Category Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.CatName}
                  name="CatName"
                  error={!!touched.CatName && !!errors.CatName}
                  helperText={touched.CatName && errors.CatName}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  ModuleID: yup.string().required("Please complete this Module ID."),
  CatCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Category Code."),
  CatName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Category Name."),
});
const initialValues = {
  ModuleID: "",
  CatCode: "",
  CatName: "",
  Description: "",
};

export default FormCategory;
