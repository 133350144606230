import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { tokens } from "../../../theme";

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = ({ index }) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}



const TabsSchedule = ({ onInputChange, editData }) => {

    // console.log(editData);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const mapToNumber = (value) => {
        switch (value) {
            case 'daily':
                return 0;
            case 'weekly':
                return 1;
            case 'monthly':
                return 2;
            default:
                return 0; // Return -1 for unknown values or handle as needed
        }
    };

    const [value, setValue] = useState(!editData ? 0 : mapToNumber(editData.PeriodeType));

    const [dailyValue, setDailyValue] = useState(!editData ? '' : editData.OccursEvery);

    const [weeklyValue, setWeeklyValue] = useState(!editData ? '' : editData.OccursEvery);
    const [checkedDays, setCheckedDays] = useState(!editData ? [] : editData.Days.split(','));

    const [monthlyValue, setMonthlyValue] = useState('');
    const [checkedMonths, setCheckedMonths] = useState(!editData ? [] : editData.Month.split(','));

    const [daysValue, setDaysValue] = useState([]);
    const [radioType, setRadioType] = useState(
        !editData ? 'first' :
            editData.Days === '' ? 'first' : 'end'
    );
    const [disableDates, setDisableDates] = useState(!editData ? false : editData.Days === '' ? false : true);
    const [disableWeeks, setDisableWeeks] = useState(!editData ? true : editData.Days === '' ? true : false);
    const [disableDays, setDisableDays] = useState(!editData ? true : editData.Days === '' ? true : false);

    const [selectedMonthly1, setSelectedMontly1] = useState(!editData ? null : editData.OccursEvery);
    const [selectedMonthly2, setSelectedMontly2] = useState(!editData ? null : editData.OccursEvery);
    const [selectedMonthly3, setSelectedMontly3] = useState(!editData ? null : editData.Days);

    const resetForm = () => {
        setDailyValue('');
        setWeeklyValue('');
        setCheckedDays([]);
        setMonthlyValue('');
        setCheckedMonths([]);
        setDaysValue([]);
        setRadioType('first');
        setDisableDates(false);
        setDisableWeeks(true);
        setDisableDays(true);
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        resetForm();
    };

    const handleScheduleData = (time, event, newValue) => {
        if (time === 'days') {
            setDailyValue(event.target.value);
            onInputChange('daily', event.target.value);
        } else if (time === 'weeks') {
            const { name, checked, value: inputValue } = event.target;
            let updatedCheckedDays = [...checkedDays];
            let updatedValue = weeklyValue;

            if (name === 'OccursEvery') {
                updatedValue = inputValue;
            } else {
                if (checked) {
                    updatedCheckedDays.push(name);
                } else {
                    const index = updatedCheckedDays.indexOf(name);
                    if (index !== -1) {
                        updatedCheckedDays.splice(index, 1);
                    }
                }
            }

            setWeeklyValue(updatedValue);
            setCheckedDays(updatedCheckedDays);
            onInputChange('weekly', updatedValue, updatedCheckedDays);
        } else if (time === 'month') {
            const { name, checked } = event.target;
            let updatedCheckedMonths = [...checkedMonths];
            let updatedValue = monthlyValue;

            if (newValue.value > 0) {
                updatedValue = newValue.value;
            } else {
                if (checked) {
                    updatedCheckedMonths.push(name);
                } else {
                    const index = updatedCheckedMonths.indexOf(name);
                    if (index !== -1) {
                        updatedCheckedMonths.splice(index, 1);
                    }
                }
            }

            setMonthlyValue(updatedValue);
            setCheckedMonths(updatedCheckedMonths);
            onInputChange('monthly', updatedValue, [daysValue], updatedCheckedMonths);
        }
    }

    const handleSetMontlyData = (event, newValue) => {
        if (newValue) {
            setSelectedMontly1(newValue.value);
        } else {
            setSelectedMontly1(null);
        }
    };

    const handleSetMontlyData2 = (event, newValue) => {
        if (newValue) {
            setSelectedMontly2(newValue.value);
        } else {
            setSelectedMontly2(null);
        }
    };

    const handleSetMontlyData3 = (event, newValue) => {
        if (newValue) {
            setSelectedMontly3(newValue.label);
        } else {
            setSelectedMontly3(null);
        }
    };

    const handleDaySelection = (newValue) => {
        const selectedDay = newValue ? newValue.label : null;
        setDaysValue(selectedDay);
        onInputChange('monthly', monthlyValue, [selectedDay], checkedMonths);
    };


    const handleMonthlyRadioChange = (event) => {
        const value = event.target.value;
        // Mengatur tipe radio yang dipilih
        setRadioType(value);
        if (value === 'first') {
            setDisableWeeks(true);
            setDisableDays(true);
            setDisableDates(false);
            setDaysValue([]);
        } else if (value === 'end') {
            setDisableDates(true);
            setDisableWeeks(false);
            setDisableDays(false);
        }
    };


    const handleAllMonthsChecked = (event) => {
        const checked = event.target.checked;
        let updatedCheckedMonths = [];

        if (checked) {
            updatedCheckedMonths = months.map((month) => month.label);
        }
        setCheckedMonths(updatedCheckedMonths);
        onInputChange('monthly', monthlyValue, [daysValue], updatedCheckedMonths);
    };

    // Memeriksa apakah semua bulan dipilih atau tidak
    const isAllMonthsChecked = checkedMonths.length === months.length;
    const isNoMonthsChecked = checkedMonths.length === 0;

    // const [selectedBuildingCode, setSelectedBuildingCode] = useState(2);

    // const handleBuildingSelection = (event, value) => {
    //     if (value) {
    //         setSelectedBuildingCode(value.BuildingCode);
    //     } else {
    //         setSelectedBuildingCode(null);
    //     }
    // };

    return (
        <Box
            sx={{
                boxShadow: "0 0 10px #00000026",
                padding: "10px 0",
                display: "flex",
                borderRadius: "8px",
                alignItems: "center",
                marginTop: "20px",
                flexDirection: isNonMobile ? "row" : "column",
                "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    "&.Mui-focused fieldset": {
                        borderColor: colors.grey[900],
                    },
                },
            }}
        >
            <Tabs
                orientation={isMobile ? "horizontal" : "vertical"}
                variant={isMobile ? "scrollable" : "standard"}
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                    borderRight: isMobile ? 0 : 1,
                    borderBottom: isMobile ? 1 : 0,
                    borderColor: "divider",
                    width: isMobile ? "100%" : "auto",
                }}
            >
                <Tab label="Daily" {...a11yProps({ index: 0 })} />
                <Tab label="Weekly" {...a11yProps({ index: 1 })} />
                <Tab label="Monthly" {...a11yProps({ index: 2 })} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {/* Konten untuk tab Daily */}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        maxWidth: isMobile ? "100%" : "100%"
                    }}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        label="Occurs Every"
                        name="OccursEvery"
                        inputProps={{ min: 0 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">Days</InputAdornment>
                            ),
                        }}
                        value={dailyValue}
                        onChange={(e) => handleScheduleData('days', e)}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/* Konten untuk tab Weekly */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                            width: "100%",
                            maxWidth: isMobile ? "100%" : "100%",
                        }}
                    >
                        <TextField
                            variant="outlined"
                            type="number"
                            label="Occurs Every"
                            name="OccursEvery"
                            inputProps={{ min: 0 }}
                            value={weeklyValue}
                            onChange={(e) => handleScheduleData('weeks', e)}
                        />
                        <Typography>
                            Week(s) On
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                {days.map((day, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={checkedDays.includes(day.label)}
                                                onChange={(e) => handleScheduleData('weeks', e)}
                                                name={day.label}
                                            />
                                        }
                                        label={day.label}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                {/* Konten untuk tab Monthly */}
                <Box>

                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            // defaultValue="first"
                            value={radioType}
                            onChange={handleMonthlyRadioChange}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        width: "100%",
                                        maxWidth: isMobile ? "100%" : "500px",
                                    }}
                                >
                                    <FormControlLabel
                                        value="first"
                                        control={<Radio />}
                                        label="Occurs on the"
                                    />

                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        options={dates}
                                        disabled={disableDates}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, newValue) => {
                                            handleScheduleData('month', event, newValue)
                                            handleSetMontlyData(event, newValue)
                                        }}
                                        value={!editData ?
                                            dates.find((option) => option.value === selectedMonthly1) || null
                                            : editData.Days === '' ? dates.find((option) => option.value === selectedMonthly1) || null : null
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="date"
                                            />
                                        )}
                                    />

                                    <Typography>day of the month.</Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        width: "100%",
                                        maxWidth: isMobile ? "100%" : "500px",
                                    }}
                                >
                                    <FormControlLabel
                                        value="end"
                                        control={<Radio />}
                                        label="Occurs on the"
                                    />
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        options={weeks}
                                        disabled={disableWeeks}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, newValue) => {
                                            handleScheduleData('month', event, newValue)
                                            handleSetMontlyData2(event, newValue)
                                        }}
                                        value={!editData ?
                                            weeks.find((option) => option.value === selectedMonthly2) || null
                                            : editData.PeriodeType === "monthly" ?
                                                weeks.find((option) => option.value === selectedMonthly2) || null
                                                : null
                                        }
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="weeks"
                                            />
                                        }
                                    />
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        options={days}
                                        disabled={disableDays}
                                        // getOptionLabel={(option) => option.label}
                                        onChange={(event, newValue) => {
                                            handleDaySelection(newValue)
                                            handleSetMontlyData3(event, newValue)
                                        }}
                                        value={
                                            days.find((option) => option.label === selectedMonthly3) || null
                                        }
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="days"
                                            />
                                        }
                                    />

                                    <Typography variant="body1" >of the month.</Typography>
                                </Box>
                            </Box>
                        </RadioGroup>
                    </FormControl>


                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "10px",
                            borderBottom: "1px solid #00000046",
                        }}
                    >
                        <Typography variant="body1" fontWeight={600}>SELECT MONTHS</Typography>
                        <Checkbox
                            checked={isAllMonthsChecked}
                            indeterminate={!isAllMonthsChecked && !isNoMonthsChecked}
                            onChange={handleAllMonthsChecked}
                        />
                    </Box>

                    <Box>
                        <FormControl component="fieldset">
                            <FormGroup
                                aria-label="position"
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                    gap: '20px',
                                }}
                            >
                                {months.map((month, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={checkedMonths.includes(month.label)}
                                                onChange={(event, newValue) => handleScheduleData('month', event, newValue)}
                                                name={month.label}
                                            />
                                        }
                                        label={month.label}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Box>

                </Box>
            </TabPanel>
        </Box>
    );
}

const dates = [
    {
        label: "1st",
        value: 1,
    },
    {
        label: "2nd",
        value: 2,
    },
    {
        label: "3rd",
        value: 3,
    },
    {
        label: "4th",
        value: 4,
    },
    {
        label: "5th",
        value: 5,
    },
    {
        label: "6th",
        value: 6,
    },
    {
        label: "7th",
        value: 7,
    },
    {
        label: "8th",
        value: 8,
    },
    {
        label: "9th",
        value: 9,
    },
    {
        label: "10th",
        value: 10,
    },
    {
        label: "11th",
        value: 11,
    },
    {
        label: "12th",
        value: 12,
    },
    {
        label: "13th",
        value: 13,
    },
    {
        label: "14th",
        value: 14,
    },
    {
        label: "15th",
        value: 15,
    },
    {
        label: "16th",
        value: 16,
    },
    {
        label: "17th",
        value: 17,
    },
    {
        label: "18th",
        value: 18,
    },
    {
        label: "19th",
        value: 19,
    },
    {
        label: "20th",
        value: 20,
    },
    {
        label: "21th",
        value: 21,
    },
    {
        label: "22th",
        value: 22,
    },
    {
        label: "23th",
        value: 23,
    },
    {
        label: "24th",
        value: 24,
    },
    {
        label: "25th",
        value: 25,
    },
    {
        label: "26th",
        value: 26,
    },
    {
        label: "27th",
        value: 27,
    },
    {
        label: "28th",
        value: 28,
    },
    {
        label: "29th",
        value: 29,
    },
    {
        label: "30th",
        value: 30,
    },
    {
        label: "31th",
        value: 31,
    },
];

const weeks = [
    {
        label: "1st",
        value: 1,
    },
    {
        label: "2nd",
        value: 2,
    },
    {
        label: "3rd",
        value: 3,
    },
    {
        label: "4th",
        value: 4,
    }
];

const days = [
    { label: "Monday" },
    { label: "Tuesday" },
    { label: "Wednesday" },
    { label: "Thursday" },
    { label: "Friday" },
    { label: "Saturday" },
    { label: "Sunday" },
];

const months = [
    { label: "January" },
    { label: "February" },
    { label: "March" },
    { label: "April" },
    { label: "May" },
    { label: "June" },
    { label: "July" },
    { label: "August" },
    { label: "September" },
    { label: "October" },
    { label: "November" },
    { label: "December" },
];

export default TabsSchedule;
