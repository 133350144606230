import FormUser from "../pages/Configuration/User/form";
import FormUserGroup from "../pages/Configuration/UserGroup/form";
import FormDepartment from "../pages/Employee/Department/form";
import FormDivision from "../pages/Employee/Division/form";
import FormEmployee from "../pages/Employee/Employee/form";
import FormSection from "../pages/Employee/Section/form";
import FormBuildings from "../pages/MasterData/Buildings/form";
import FormCategory from "../pages/MasterData/Category/form";
import FormEquipment from "../pages/MasterData/Equipment/form";
import FormEquipmentModel from "../pages/MasterData/EquipmentModel/form";
import FormFacility from "../pages/MasterData/Facility/form";
import FormFloor from "../pages/MasterData/Floor/form";
import FormIconMenu from "../pages/MasterData/IconMenu/form";
import FormLocation from "../pages/MasterData/Location/form";
import FormMap from "../pages/MasterData/Map/form";
import FormPart from "../pages/MasterData/Part/form";
import FormSchedule from "../pages/MasterData/Schedule/form";
import FormSensor from "../pages/MasterData/Sensor/form";
import FormSubCategory from "../pages/MasterData/SubCategory/form";
import FormType from "../pages/MasterData/Type/form";
import FormVehicle from "../pages/MasterData/Vehicle/form";
import FormVendor from "../pages/MasterData/Vendor/form";
import FormAdjustmentTransaction from "../pages/Transaction/Adjustment/form";
import FormDMTransaction from "../pages/Transaction/DMTransaction/form";
import FormMPTransaction from "../pages/Transaction/MPTransaction/form";
import FormPMTransaction from "../pages/Transaction/PMTransaction/form";
import FormWORequest from "../pages/Transaction/WORequest/form";
import FormWOTransaction from "../pages/Transaction/WOTransaction/form";

export const FormTranMenu = {
    // MASTER DATA
    1101: <FormFacility />,
    1102: <FormBuildings />,
    1103: <FormMap />,
    1104: <FormFloor />,
    1105: <FormIconMenu />,
    1106: <FormCategory />,
    1107: <FormSubCategory />,
    1108: <FormLocation />,
    1109: <FormPart />,
    1110: <FormType />,
    1111: <FormEquipmentModel />,
    1112: <FormEquipment />,
    1113: <FormVehicle />,
    1114: <FormSchedule />,
    1115: <FormVendor />,
    1116: <FormSensor />,


    // EMPLOYEE
    1201: <FormDivision />,
    1202: <FormDepartment />,
    1203: <FormSection />,
    1204: <FormEmployee />,


    // CONFIGURATION
    1301: <FormUserGroup />,
    1302: <FormUser />,
    // 1303: <FormTranApprover />,


    // TRANSACTION
    // 1401: <FormTranApproval />,
    1402: <FormWORequest />,
    1403: <FormWOTransaction />,
    1404: <FormDMTransaction />,
    1405: <FormPMTransaction />,
    1406: <FormMPTransaction />,
    1407: <FormAdjustmentTransaction />,
}