import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

export const StatusLogin = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/user/login`, token);
    return response;
}

// MASTER DATA
export const postFacility = async (FacilityData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/facility`, FacilityData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postBuilding = async (BuildingData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/building`, BuildingData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postFloor = async (FloorData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/floor`, FloorData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postType = async (FloorData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/type`, FloorData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postEquipmentModel = async (EquipmentModelData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/equipmentmodel`, EquipmentModelData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}

export const postEquipment = async (EquipmentData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/equipment`, EquipmentData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postCategory = async (CategotyData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/category`, CategotyData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postSubCategory = async (CategotyData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/subcategory`, CategotyData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postIcon = async (IconData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/icon`, IconData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'

        }
    });
    return response;
}

export const postSchedule = async (ScheduleData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/schedule`, ScheduleData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postSensor = async (SensorData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/sensor`, SensorData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postMaps = async (MapsData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/maps`, MapsData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}

export const postLocation = async (LocationData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/location`, LocationData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postVehicle = async (VehicleData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/vehicle`, VehicleData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postVendor = async (VendorData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/vendor`, VendorData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const postPart = async (PartData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/part`, PartData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}



// TRANSACTION

export const postWORequest = async (WORequestData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/worequest`, WORequestData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}