import { Box, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import TextInputModal from '../../global/components/TextInputModal';
import { getEmployee } from '../../../Api/Employee/GetData';

const columns = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Employee Name',
        dataIndex: ['Employee', 'EmpName'],
        width: 200,
        fixed: 'left',
        sorter: (a, b) => a.Employee.EmpName.localeCompare(b.Employee.EmpName),
    },
    {
        title: 'Division Name',
        dataIndex: 'DivisionName',
        width: 200,
        sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
    },
    {
        title: 'Department Name',
        dataIndex: 'DepName',
        width: 200,
        sorter: (a, b) => a.DepName.localeCompare(b.DepName),
    },
    {
        title: 'Section Name',
        dataIndex: 'SectionName',
        width: 200,
        sorter: (a, b) => a.SectionName.localeCompare(b.SectionName),
    },
    {
        title: 'IsTechnician',
        dataIndex: ['Employee', 'IsTechnician'],
        width: 200,
        sorter: (a, b) => a.Employee.IsTechnician.localeCompare(b.Employee.IsTechnician),
        render: (isTechnician) => (
            <span>
                {isTechnician ? 'Technician' : 'Not a technician'}
            </span>
        ),
    },
];

const InputModal = ({ setValues, setValuesEdit, setHandleBlur, setHandleChange, setError, setHelperText }) => {

    console.log(setValuesEdit);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [value, setValue] = useState('');

    const [data, setData] = useState([]);

    const [filterValue, setFilterValue] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getEmployee();
            const filterRes = response.filter((item => item.Employee.IsSuspend !== true))
            setData(filterRes);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open]);

    // useEffect(() => {
    //     if (setClear) {
    //         setValue('');
    //         setSelectedRowKeys([]);
    //         setSelectedRow(null);
    //         if (setValuesEdit) {
    //             setValue(setValuesEdit.EmployeeName);
    //         }
    //     }
    // }, [setClear, setValuesEdit]);

    useEffect(() => {
        if (setValuesEdit) {
            setValue(setValuesEdit.VehicleOwner);
            const response = data.filter(item => item.Employee && item.Employee.EmpCode === setValuesEdit.VehicleOwner);
            if (response.length > 0) {
                setSelectedRowKeys([response[0].key]);
            }
        }
    }, [data, setValuesEdit]);

    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRow(selectedRows[0]);
        },
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = async () => {
        if (selectedRow) {
            try {
                setLoading(true);
                console.log('Selected Row:', selectedRow);
                const Values = selectedRow.Employee.EmpName;
                setValue(Values);
                setValues(selectedRow);
                setOpen(false);
            } catch (error) {
                console.log(error);
            }
        } else {
            enqueueSnackbar("No data selected!", { variant: "warning" });
        }
        setLoading(false);
    }

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);

        const filteredData = data.filter((row) => {
            return (
                row.Employee.EmpName.toLowerCase().includes(value.toLowerCase()) ||
                row.DivisionName.toLowerCase().includes(value.toLowerCase()) ||
                row.DepName.toLowerCase().includes(value.toLowerCase()) ||
                row.SectionName.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
    };

    return (
        <Box sx={{ gridColumn: "span 2" }}>

            <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Vehicle Owner*"
                onBlur={setHandleBlur}
                value={value}
                onChange={setHandleChange}
                name="VehicleOwner"
                onClick={handleOpen}
                error={setError}
                helperText={setHelperText}
            />

            <TextInputModal
                open={open}
                title="EMPLOYEE"
                subtitle="All Employee Data"
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                loading={loading}
                filterValue={filterValue}
                handleFilterChange={handleFilterChange}
                rowSelection={rowSelection}
                columns={columns}
                filteredRows={filteredRows}
                setData={data}
                Linkto={`/master/employee`}
            />

        </Box>
    )
}

export default InputModal