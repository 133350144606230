import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import {
    Autocomplete,
    Box,
    IconButton,
    TextField,
    Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { getAdjustment } from "../Api/GetData";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormAdjustmentDetail from "./AdjustmentDetail/form";
import dayjs from "dayjs";
import { updateAdjustmentTransaction } from "../Api/UpdateData";

const EditAdjustmentTransaction = ({ data, onEdit }) => {
    const dataEdit = data.Adjustment;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [adjustmentData, setAdjustmentData] = useState([]);
    const [selectedAdjustment, setSelectedAdjustment] = useState(dataEdit.ADCode);

    const [ADType, setADType] = useState(dataEdit.ADType);

    const [ADDate, setADDate] = useState(dayjs(dataEdit.ADDate));

    const [Periode, setPeriode] = useState(dataEdit.Periode);

    const [dataAdjustmentDetail, setDataAdjustmentDetail] = useState([]);

    useEffect(() => {
        if (open) {
            const fetchAdjustment = async () => {
                setIsLoading(true);
                try {
                    const response = await getAdjustment();
                    setAdjustmentData(response);
                } catch (error) {
                    console.log(error);
                }
                setIsLoading(false);
            }
            fetchAdjustment();
        }
    }, [open]);


    useEffect(() => {
        if (ADDate) {
            const PeriodeNow = ADDate.format("YYYYMM");
            setPeriode(PeriodeNow)
        }
    }, [ADDate]);


    const handleAdjustmentSelection = (event, value) => {
        if (value) {
            setSelectedAdjustment(value.ADCode);
            setADType(value.ADType);
        } else {
            setSelectedAdjustment(null);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const ADDateNow = ADDate.format("YYYY-MM-DD");

            const modifiedValues = {
                ...values,
                ADCode: selectedAdjustment,
                ADDate: ADDateNow,
                ADType: ADType,
                Periode: Periode,
                adjusmentTransactionDetails: dataAdjustmentDetail,
            };

            console.log(modifiedValues);

            // const response = await updateAdjustmentTransaction(dataEdit.FacilityCode, dataEdit.ADNumber, modifiedValues);
            // enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            // onEdit(dataEdit.FacilityCode && dataEdit.ADNumber);
            // setOpen(false);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusMessage
            ) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error);
        }
        setLoading(false);
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    validationSchema={checkoutSchema}
                    innerRef={formRef}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="ADJUSTMENT"
                                        subtitle="Edit Data a Adjustment"
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >
                            <StyleForm>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Facility Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={data.FacilityName}
                                    name="FacilityCode"
                                    error={!!touched.FacilityName && !!errors.FacilityName}
                                    helperText={touched.FacilityName && errors.FacilityName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Adjustment Number*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.ADNumber}
                                    name="ADNumber"
                                    error={!!touched.ADNumber && !!errors.ADNumber}
                                    helperText={touched.ADNumber && errors.ADNumber}
                                    sx={{ gridColumn: "span 2" }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Adjustment Date*"
                                        value={ADDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setADDate(date)}
                                        name="ADDate"
                                        error={!!touched.ADDate && !!errors.ADDate}
                                        helperText={touched.ADDate && errors.ADDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Periode*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={Periode}
                                    name="Periode"
                                    error={!!touched.Periode && !!errors.Periode}
                                    helperText={touched.Periode && errors.Periode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    loading={isLoading}
                                    options={adjustmentData}
                                    getOptionLabel={(option) => option.ADName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handleAdjustmentSelection(event, value);
                                        setFieldValue(
                                            "ADCode",
                                            value ? value.ADName : ""
                                        );
                                    }}
                                    value={
                                        adjustmentData.find(
                                            (option) => option.ADCode === selectedAdjustment
                                        ) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Adjustment Name*"
                                            onBlur={handleBlur}
                                            error={!!touched.ADCode && !!errors.ADCode}
                                            helperText={touched.ADCode && errors.ADCode}
                                        />
                                    )}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                />
                            </StyleForm>

                            <Box sx={{
                                margin: "0 20px"
                            }}>
                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormAdjustmentDetail
                                        onSaveData={(data) => setDataAdjustmentDetail(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        ADNumber={dataEdit.ADNumber}
                                        onEdit={true}
                                    />
                                </Box>
                            </Box>
                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    FacilityCode: yup.string().required("Please complete this Facility Name."),
    ADNumber: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this Adjustment Number."),
    Periode: yup
        .string()
        .required("Please complete this Periode."),
    ADCode: yup.string().required("Please complete this Adjustment Code."),
});

export default EditAdjustmentTransaction;