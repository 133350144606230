import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import MapIcon from "./map";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getBuilding, getCategory, getFacility, getFloor, getLocation } from "../../../Api/Master/GetData";
import { postLocation } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";

const FormLocation = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsFacCode = searchParams.get('faccode');

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const [openModalFacility, setOpenModalFacility] = useState("");
  const FacilityName = selectFacility ? selectFacility.FacilityName : '';
  const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';

  const [dataBuilding, setDataBuilding] = useState([]);
  const [selectBuilding, setSelectBuilding] = useState("");
  const [openModalBuilding, setOpenModalBuilding] = useState("");
  const BuildingName = selectBuilding ? selectBuilding.Building.BuildingName : '';
  const BuildingCode = selectBuilding ? selectBuilding.Building.BuildingCode : '';

  const [dataFloor, setDataFloor] = useState([]);
  const [selectFloor, setSelectFloor] = useState("");
  const [openModalFloor, setOpenModalFloor] = useState("");
  const FloorName = selectFloor ? selectFloor.FloorLevel.FloorLevelName : '';
  const FloorCode = selectFloor ? selectFloor.FloorLevel.FloorLevelCode : '';

  const [dataCategory, setDataCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [openModalCategory, setOpenModalCategory] = useState("");
  const CategoryName = selectCategory ? selectCategory.CatName : '';
  const CategoryCode = selectCategory ? selectCategory.CatCode : '';

  const [LocationCode, setLocationCode] = useState("");

  const [IconName, setIconName] = useState('');
  const [IconLocY, setIconLocY] = useState('');
  const [IconLocX, setIconLocX] = useState('');
  const [IconUrl, setIconUrl] = useState('');


  // DATA FACILITY
  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const res = await getFacility();
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataFacility(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalFacility) {
      setIsLoading(false);
      fetchFacility();
      setOpenModalFacility(false);
      setDataBuilding([]);
      setSelectBuilding("");
      setDataFloor([]);
      setSelectFloor("");

      setIconName('');
      setIconLocY('');
      setIconLocX('');
      setIconUrl('');
    }
  }, [openModalFacility]);


  // DATA BUILDING
  useEffect(() => {
    const fetchBuilding = async () => {
      try {
        const res = await getBuilding(FacilityCode);
        const filterRes = res.filter((item) => item.Building.IsSuspend !== true);
        setDataBuilding(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalBuilding && FacilityCode) {
      setIsLoading(false);
      fetchBuilding();
      setOpenModalBuilding(false);
      setDataFloor([]);
      setSelectFloor("");

      setIconName('');
      setIconLocY('');
      setIconLocX('');
      setIconUrl('');
    }
  }, [FacilityCode, openModalBuilding]);


  // DATA FLOOR
  useEffect(() => {
    const fetchFloor = async () => {
      try {
        const res = await getFloor(FacilityCode, BuildingCode);
        const filterRes = res.filter((item) => item.FloorLevel.IsSuspend !== true);
        setDataFloor(filterRes);
      } catch (error) {
        console.log(error);
      }
    }
    if (openModalFloor && FacilityCode && BuildingCode) {
      setIsLoading(false);
      fetchFloor();
      setOpenModalFloor();

      setIconName('');
      setIconLocY('');
      setIconLocX('');
      setIconUrl('');
    }
  }, [BuildingCode, FacilityCode, openModalFloor]);


  // DATA CATEGORY
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await getCategory('Location');
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataCategory(filterRes);
      } catch (error) {
        console.log(error);
      }
    }
    if (openModalCategory) {
      setIsLoading(false);
      fetchCategory();
    }
  }, [openModalCategory]);


  const FetchLocationCode = useCallback(async () => {
    try {
      const response = await getLocation(FacilityCode);
      if (response.length > 0) {
        const LCData = response.filter(item => item.Location.LocationCode && item.Location.LocationCode.startsWith('LC'));
        if (LCData.length > 0) {
          const lastCode = LCData[LCData.length - 1].Location.LocationCode;
          const nextNumber = parseInt(lastCode.substr(3)) + 1;
          setLocationCode(`LC${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setLocationCode('LC0001');
      console.log(error);
    }
  }, [FacilityCode]);

  useEffect(() => {
    if (FacilityCode) {
      FetchLocationCode();
    }
  }, [FacilityCode, FetchLocationCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("FacilityName", FacilityName);
      formRef.current.setFieldValue("BuildingName", BuildingName);
      formRef.current.setFieldValue("FloorName", FloorName);
      formRef.current.setFieldValue("CategoryName", CategoryName);
      formRef.current.setFieldValue("LocationCode", LocationCode);
    }
  }, [BuildingName, CategoryName, FacilityName, FloorName, LocationCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      if (!IconUrl) {
        enqueueSnackbar("Fill in the data first, drag and drop the icon onto the map.", { variant: "warning" });
        setLoading(false);
        return
      }

      const payload = {
        ...values,
        FacilityCode: FacilityCode,
        LocationCode: LocationCode,
        BuildingCode: BuildingCode,
        FloorLevel: FloorCode,
        MapCode: getFloorLevelMapCode,
        LocCatCode: CategoryCode,
        IconId: IconName,
        IconLocationY: `${IconLocY}`,
        IconLocationX: `${IconLocX}`,
        url: IconUrl,
      };
      // console.log(payload);
      const response = await postLocation(payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/location");

    } catch (error) {
      if (error.response && error.response.data && error.response.data.statusMessage) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const getFloorLevelMapCode = dataFloor.filter((item) =>
    item.FloorLevel.FloorLevelCode === FloorCode).map((item) =>
      item.FloorLevel.MapCode).join('');

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <Box>
            <StyleContent>
              <Header title="LOCATION" subtitle="Create a Location" />
              <StyleForm>

                <InputModal
                  title="FACILITY"
                  label="Facility Name"
                  name="FacilityName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FacilityName}
                  error={!!touched.FacilityName && !!errors.FacilityName}
                  helperText={touched.FacilityName && errors.FacilityName}
                  dataSource={dataFacility}
                  columns={columnsFacility}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectFacility(values)}
                  propsOpenModal={(values) => setOpenModalFacility(values)}
                  LinktoCreate={`/master/facility/form`}
                />

                <InputModal
                  title="BUILDING"
                  label="Building Name"
                  name="BuildingName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.BuildingName}
                  error={!!touched.BuildingName && !!errors.BuildingName}
                  helperText={touched.BuildingName && errors.BuildingName}
                  dataSource={dataBuilding}
                  columns={columnsBuilding}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectBuilding(values)}
                  propsOpenModal={(values) => setOpenModalBuilding(values)}
                  LinktoCreate={`/master/building/form?faccode=${FacilityCode}`}
                />

                <InputModal
                  title="FLOOR"
                  label="Floor Name"
                  name="FloorName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FloorName}
                  error={!!touched.FloorName && !!errors.FloorName}
                  helperText={touched.FloorName && errors.FloorName}
                  dataSource={dataFloor}
                  columns={columnsFloor}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectFloor(values)}
                  propsOpenModal={(values) => setOpenModalFloor(values)}
                  LinktoCreate={`/master/floorlevel/form?faccode=${FacilityCode}&buildcode=${BuildingCode}`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Location Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    setLocationCode(e.target.value)
                  }}
                  value={LocationCode}
                  name="LocationCode"
                  error={!!touched.LocationCode && !!errors.LocationCode}
                  helperText={touched.LocationCode && errors.LocationCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 6 }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Location Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LocationName}
                  name="LocationName"
                  error={!!touched.LocationName && !!errors.LocationName}
                  helperText={touched.LocationName && errors.LocationName}
                  sx={{ gridColumn: "span 2" }}
                />

                <InputModal
                  title="CATEGORY"
                  label="Category Name"
                  name="CategoryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.CategoryName}
                  error={!!touched.CategoryName && !!errors.CategoryName}
                  helperText={touched.CategoryName && errors.CategoryName}
                  dataSource={dataCategory}
                  columns={columnsCategory}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectCategory(values)}
                  propsOpenModal={(values) => setOpenModalCategory(values)}
                  LinktoCreate={`/master/category/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                />

                <MapIcon
                  propsFacilityCode={FacilityCode}
                  propsMapCode={getFloorLevelMapCode}
                  propsIconName={(values) => setIconName(values)}
                  propsIconLocY={(values) => setIconLocY(values)}
                  propsIconLocX={(values) => setIconLocX(values)}
                  propsIconUrl={(values) => setIconUrl(values)}
                />

              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />

          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityName: yup
    .string()
    .required("Please complete this Facility Name."),
  BuildingName: yup
    .string()
    .required("Please complete this Building Name."),
  FloorName: yup
    .string()
    .required("Please complete this Floor Level."),
  LocationCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Location Code."),
  LocationName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Location Name."),
  CategoryName: yup
    .string()
    .required("Please complete this Category."),
});

const initialValues = {
  FacilityName: "",
  BuildingName: "",
  FloorName: "",
  LocationCode: "",
  LocationName: "",
  CategoryName: "",
  MapCode: "",
  IconId: "",
  IconLocationX: "",
  IconLocationY: "",
  Description: "",
};

export default FormLocation;


const columnsFacility = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    sorter: (a, b) => a.key - b.key,
  },
  {
    key: 'FacilityCode',
    title: 'Facility Code',
    dataIndex: 'FacilityCode',
    sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
  },
  {
    key: 'FacilityName',
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
  },
  {
    key: 'Address',
    title: 'Address',
    dataIndex: 'Address',
    sorter: (a, b) => a.Address.localeCompare(b.Address),
  },
  {
    key: 'City',
    title: 'City',
    dataIndex: 'City',
    sorter: (a, b) => a.City.localeCompare(b.City),
  },
  {
    key: 'Country',
    title: 'Country',
    dataIndex: 'Country',
    sorter: (a, b) => a.Country.localeCompare(b.Country),
  },
  {
    key: 'Phone1',
    title: 'Phone Number 1',
    dataIndex: 'Phone1',
    sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
  },
  {
    key: 'Phone2',
    title: 'Phone Number 2',
    dataIndex: 'Phone2',
    sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
  },
  {
    key: 'Fax',
    title: 'Fax',
    dataIndex: 'Fax',
    sorter: (a, b) => a.Fax.localeCompare(b.Fax),
  },
  {
    key: 'ContactPerson',
    title: 'Contact Person',
    dataIndex: 'ContactPerson',
    sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
  },
];


const columnsBuilding = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Building Code',
    dataIndex: ['Building', 'BuildingCode'],
    sorter: (a, b) =>
      a.Building.BuildingCode.localeCompare(b.Building.BuildingCode),
  },
  {
    title: 'Building Name',
    dataIndex: ['Building', 'BuildingName'],
    sorter: (a, b) =>
      a.Building.BuildingName.localeCompare(b.Building.BuildingName),
  },
  {
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) =>
      a.Building.FacilityName.localeCompare(b.Building.FacilityName),
  },
  {
    title: 'Address',
    dataIndex: ['Building', 'Address'],
    sorter: (a, b) => a.Building.Address.localeCompare(b.Building.Address),
  },
  {
    title: 'Phone Number 1',
    dataIndex: ['Building', 'Phone1'],
    sorter: (a, b) => a.Building.Phone1.localeCompare(b.Building.Phone1),
  },
  {
    title: 'Phone Number 2',
    dataIndex: ['Building', 'Phone2'],
    sorter: (a, b) => a.Building.Phone2.localeCompare(b.Building.Phone2),
  },
  {
    title: 'Contact Person',
    dataIndex: ['Building', 'ContactPerson'],
    sorter: (a, b) =>
      a.Building.ContactPerson.localeCompare(b.Building.ContactPerson),
  },
];

const columnsFloor = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Floor Level Code',
    dataIndex: ['FloorLevel', 'FloorLevelCode'],
    sorter: (a, b) => a.FloorLevel.FloorLevelCode.localeCompare(b.FloorLevel.FloorLevelCode),
  },
  {
    title: 'Floor Level Name',
    dataIndex: ['FloorLevel', 'FloorLevelName'],
    sorter: (a, b) => a.FloorLevel.FloorLevelName.localeCompare(b.FloorLevel.FloorLevelName),
  },
  {
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
  },
  {
    title: 'Building Name',
    dataIndex: 'BuildingName',
    sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
  },
  {
    title: 'Map Name',
    dataIndex: 'MapName',
    sorter: (a, b) => a.MapName.localeCompare(b.MapName),
  },
];

const columnsCategory = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Category Code',
    dataIndex: 'CatCode',
    sorter: (a, b) => a.CatCode.localeCompare(b.CatCode),
  },
  {
    title: 'Category Name',
    dataIndex: 'CatName',
    sorter: (a, b) => a.CatName.localeCompare(b.CatName),
  },
  {
    title: 'Module ID',
    dataIndex: 'ModuleID',
    sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
  },
];