import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import {
  Box,
  TextField,
  Tooltip,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { onlyNumber } from "../../global/Components";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { getDepartment, getDivision, getSection } from "../../../Api/Employee/GetData";
import { updateEmployee } from "../../../Api/Employee/UpdateData";

const EditEmployee = ({ data, onEdit }) => {
  const dataEdit = data.Employee;

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [divisionCode, setDivisionCode] = useState([]);
  const [selectedDivisionCode, setSelectedDivisionCode] = useState(
    dataEdit.DivisionCode
  );

  const [depCode, setDepCode] = useState([]);
  const [selectedDepCode, setSelectedDepCode] = useState(dataEdit.DepCode);

  const [sectionCode, setSectionCode] = useState([]);
  const [selectedSectionCode, setSelectedSectionCode] = useState(
    dataEdit.SectionCode
  );

  const [selectedTechnician, setSelectedTechnician] = useState(
    dataEdit.IsTechnician
  );
  const [isSuspend, setIsSuspend] = useState(dataEdit.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
    setSelectedDivisionCode(dataEdit.DivisionCode)
    setSelectedDepCode(dataEdit.DepCode)
    setSelectedSectionCode(dataEdit.SectionCode)
    setSelectedTechnician(dataEdit.IsTechnician)
  };

  useEffect(() => {
    const fetchDivision = async () => {
      if (open) {
        try {
          const response = await getDivision();
          const filterRes = response.filter((item) => item.IsSuspend !== true);
          setDivisionCode(filterRes);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchDivision();
  }, [open]);

  useEffect(() => {
    const fetchDepartment = async () => {
      if (open) {
        try {
          const response = await getDepartment(selectedDivisionCode);
          const filterRes = response.filter((item) => item.Department.IsSuspend !== true);
          setDepCode(filterRes);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchDepartment();
  }, [open, selectedDivisionCode]);

  useEffect(() => {
    const fetchSection = async () => {
      if (open) {
        try {
          const response = await getSection(selectedDivisionCode, selectedDepCode);
          const filterRes = response.filter((item) => item.Section.IsSuspend !== true);
          setSectionCode(filterRes);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchSection();
  }, [open, selectedDepCode, selectedDivisionCode]);

  const handleDivisionSelection = (event, value) => {
    if (value) {
      setSelectedDivisionCode(value.DivisionCode);
      setSelectedDepCode("");
      setSelectedSectionCode("");
    } else {
      setSelectedDivisionCode("");
    }
  };

  const handleDepSelection = (event, value) => {
    if (value) {
      setSelectedDepCode(value.Department.DepCode);
      setSelectedSectionCode("");
    } else {
      setSelectedDepCode("");
    }
  };

  const handleSectionSelection = (event, value) => {
    if (value) {
      setSelectedSectionCode(value.Section.SectionCode);
    } else {
      setSelectedSectionCode("");
    }
  };

  const handleTechnicionSelection = (event, value) => {
    if (value) {
      setSelectedTechnician(value.CodeTechnician);
    } else {
      setSelectedTechnician("");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(dataEdit.IsSuspend)
    setSelectedDivisionCode(dataEdit.DivisionCode)
    setSelectedDepCode(dataEdit.DepCode)
    setSelectedSectionCode(dataEdit.SectionCode)
    setSelectedTechnician(dataEdit.IsTechnician)
    setOpen(false);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        DivisionCode: selectedDivisionCode,
        DepCode: selectedDepCode,
        SectionCode: selectedSectionCode,
        IsTechnician: selectedTechnician,
        IsSuspend: isSuspend,
      };

      const response = await updateEmployee(dataEdit.EmpCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(dataEdit.EmpCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={dataEdit}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="EMPLOYEE"
                    subtitle="Edit Data a Employee"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Employee Code*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.EmpCode}
                  name="EmpCode"
                  error={!!touched.EmpCode && !!errors.EmpCode}
                  helperText={touched.EmpCode && errors.EmpCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Employee Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.EmpName}
                  name="EmpName"
                  error={!!touched.EmpName && !!errors.EmpName}
                  helperText={touched.EmpName && errors.EmpName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Address}
                  name="Address"
                  error={!!touched.Address && !!errors.Address}
                  helperText={touched.Address && errors.Address}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone Number 1*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone1}
                  name="Phone1"
                  error={!!touched.Phone1 && !!errors.Phone1}
                  helperText={touched.Phone1 && errors.Phone1}
                  sx={{ gridColumn: "span 2" }}
                  placeholder="Example: 081234567890"
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone Number 2 (optional)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Phone2}
                  name="Phone2"
                  error={!!touched.Phone2 && !!errors.Phone2}
                  helperText={touched.Phone2 && errors.Phone2}
                  sx={{ gridColumn: "span 2" }}
                  onKeyPress={handleKeyPress}
                  placeholder="Example: 081234567890"
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="NIK*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.NIK}
                  name="NIK"
                  error={!!touched.NIK && !!errors.NIK}
                  helperText={touched.NIK && errors.NIK}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={divisionCode}
                  getOptionLabel={(option) => option.DivisionName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleDivisionSelection(event, value);
                    setFieldValue(
                      "DivisionName",
                      value ? value.DivisionName : ""
                    );
                  }}
                  value={
                    divisionCode.find(
                      (option) => option.DivisionCode === selectedDivisionCode
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division Name*"
                      onBlur={handleBlur}
                      error={!!touched.DivisionName && !!errors.DivisionName}
                      helperText={touched.DivisionName && errors.DivisionName}
                    />
                  )}
                  readOnly={isSuspend}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={depCode}
                  getOptionLabel={(option) => option.Department.DepName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleDepSelection(event, value);
                    setFieldValue(
                      "DepName",
                      value ? value.Department.DepName : ""
                    );
                  }}
                  value={
                    depCode.find(
                      (option) => option.Department.DepCode === selectedDepCode
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department Name*"
                      onBlur={handleBlur}
                      error={!!touched.DepName && !!errors.DepName}
                      helperText={touched.DepName && errors.DepName}
                    />
                  )}
                  readOnly={isSuspend}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={sectionCode}
                  getOptionLabel={(option) => option.Section.SectionName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleSectionSelection(event, value);
                    setFieldValue(
                      "SectionName",
                      value ? value.Section.SectionName : ""
                    );
                  }}
                  value={
                    sectionCode.find(
                      (option) =>
                        option.Section.SectionCode === selectedSectionCode
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Section Name*"
                      onBlur={handleBlur}
                      error={!!touched.SectionName && !!errors.SectionName}
                      helperText={touched.SectionName && errors.SectionName}
                    />
                  )}
                  readOnly={isSuspend}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={TechnicianType}
                  getOptionLabel={(option) => option.NameTechnician}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleTechnicionSelection(event, value);
                    setFieldValue(
                      "TechnicianType",
                      value ? value.NameTechnician : ""
                    );
                  }}
                  value={
                    TechnicianType.find(
                      (option) => option.CodeTechnician === selectedTechnician
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Technician Type*"
                      onBlur={handleBlur}
                      error={
                        !!touched.TechnicianType && !!errors.TechnicianType
                      }
                      helperText={
                        touched.TechnicianType && errors.TechnicianType
                      }
                    />
                  )}
                  readOnly={isSuspend}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  EmpName: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Employee Name."),
  Address: yup
    .string()
    .max(200, "Maximum of 200 characters allowed")
    .required("Please complete this Address."),
  Phone1: yup
    .string()
    .max(14, "Maximum of 14 characters allowed")
    .required("Please complete this Phone Number 1."),
  // Phone2: yup
  //   .string()
  //   .max(14, "Maximum of 14 characters allowed")
  //   .required("Please complete this Phone Number 2.")
  //   .test(
  //     "phone-not-same",
  //     "Phone Number 1 and Phone Number 2 should not be the same",
  //     function (value) {
  //       return value !== this.parent.Phone1;
  //     }
  //   ),
  // NIK: yup
  //   .string()
  //   .max(30, "Maximum of 30 characters allowed")
  //   .required("Please complete this NIK."),
  // DivisionCode: yup.string().required("Please complete this Division Name."),
  // DepCode: yup.string().required("Please complete this Department Name."),
  // SectionCode: yup.string().required("Please complete this Section Name."),
  // TechnicianType: yup
  //   .string()
  //   .required("Please complete this Technician Type."),
});

const TechnicianType = [
  {
    CodeTechnician: true,
    NameTechnician: "Technician",
  },
  {
    CodeTechnician: false,
    NameTechnician: "Not a Technician",
  },
];

export default EditEmployee;
