import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { StyleButtonForm, StyleContent, StyleFormInput } from "../../global/StyleCSS";
import { onlyNumber } from "../../global/Components";
import { getTran } from "../../../Api/Configuration/GetData";
import { postTran } from "../../../Api/Configuration/PostData";

const FormTran = () => {
  const formRef = React.useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [TranIdx, setTranIdx] = useState("");
  const [isTranIdxChanged, setIsTranIdxChanged] = useState(false);

  const FetchTranCode = async () => {
    try {
      const response = await getTran();
      if (response.length > 0) {
        const lastCode = response[response.length - 1].TranIdx;
        let nextNumber = 1;
        if (lastCode) {
          nextNumber = parseInt(lastCode.substr(3)) + 1;
        }
        setTranIdx(`TID${nextNumber.toString().padStart(3, "0")}`);
      } else {
        setTranIdx("TID001");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchTranCode();
  }, []);

  const handleTranIdxChange = (e) => {
    setTranIdx(e.target.value);
    setIsTranIdxChanged(true);
  };

  const handleCancel = () => {
    formRef.current.resetForm();
  };

  const handleFormSubmit = async (values) => {
    try {
      const modifiedValues = {
        ...values,
        TranIdx: TranIdx,
      };

      await postTran(modifiedValues);
      enqueueSnackbar("Tran data saved successfully.", { variant: "success" });
      navigate("/master/tran");
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data;
        enqueueSnackbar(errorMessage.msg, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response.data);
    }
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={StyleContent}>
            <Header title="FORM TRAN" subtitle="Create a Division" />
            <Box sx={StyleFormInput}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Tran Idx"
                onBlur={handleBlur}
                onChange={handleTranIdxChange}
                value={TranIdx}
                name="TranIdx"
                inputProps={{
                  maxLength: 6,
                }}
                error={
                  !!touched.TranIdx && !!errors.TranIdx && isTranIdxChanged
                }
                helperText={
                  !!touched.TranIdx && !!errors.TranIdx && isTranIdxChanged
                }
                sx={{ gridColumn: "span 2" }}
                autoFocus
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Tran Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.TranName}
                name="TranName"
                error={!!touched.TranName && !!errors.TranName}
                helperText={touched.TranName && errors.TranName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Tran Form Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.TranFormName}
                name="TranFormName"
                error={!!touched.TranFormName && !!errors.TranFormName}
                helperText={touched.TranFormName && errors.TranFormName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Show Idx"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ShowIdx}
                name="ShowIdx"
                error={!!touched.ShowIdx && !!errors.ShowIdx}
                helperText={touched.ShowIdx && errors.ShowIdx}
                sx={{ gridColumn: "span 2" }}
                onKeyPress={handleKeyPress}
              />

            </Box>
          </Box>
          <Box
            sx={StyleButtonForm}
          >
            <Button
              type="button"
              color="error"
              variant="contained"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="secondary" variant="contained">
              Save Data
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  TranIdx: yup.string().when("isFacilityCodeChanged", {
    is: true,
    then: yup.string().required("Please complete this TranIdx."),
  }),
  TranName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Tran Name."),
  TranFormName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Tran Form Name."),
  ShowIdx: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Show Idx."),
});
const initialValues = {
  TranIdx: "",
  TranName: "",
  TranFormName: "",
  ShowIdx: ""
};

export default FormTran;
