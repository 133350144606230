import React, { useEffect, useState } from 'react'
import { Table, Typography } from 'antd';
import { getMPDocumentTrasaction } from '../../Api/GetData';

const DataMPDoc = ({ record }) => {

    const [dataDoc, setDataDoc] = useState([]);

    useEffect(() => {
        const fetchDataDoc = async () => {
            try {
                const response = await getMPDocumentTrasaction();
                const filterData = response.filter((item) =>
                    item.FacilityCode === record.FacilityCode &&
                    item.MPNumber === record.MPNumber
                )
                setDataDoc(filterData);
            } catch (error) {
                console.log(error.response.data);
            }
        }
        fetchDataDoc();
    }, [record]);

    const columns = [
        {
            key: 'DetailNo',
            title: 'No',
            dataIndex: 'DetailNo',
            width: 60,
        },
        {
            title: 'Document Name',
            dataIndex: 'DocName',
        },
        {
            title: 'Document File',
            dataIndex: 'DocFile',
        },
        {
            title: 'Document Exp Date',
            dataIndex: 'DocExpDate',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
        },
    ];

    return (
        <div key="document-tab" style={{ margin: "5px 0" }}>
            <Typography.Title level={3} style={{ margin: " 0  0 10px" }}>
                Document
            </Typography.Title>
            <Table
                bordered
                columns={columns}
                dataSource={dataDoc}
                pagination={false}
                scroll={{ x: 800 }}
            />
        </div>
    );
}

export default DataMPDoc