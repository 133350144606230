import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { Autocomplete, Box, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Modal } from 'antd';
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ButtonUpdate } from "../../global/components/ButtonAction";
import FormPMTask from "./PM_Task/form";
import FormPMRE from "./PM_RE/form";
import { onlyNumber } from "../../global/Components";
import FormPMDoc from "./PM_Doc/form";
import FormPMCT from "./PM_CT/form";
import FormPMSC from "./PM_SC/form";
import { updatePMTransaction } from "../Api/UpdateData";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { getCategory, getEquipment, getLocation, getSubCategory, getVehicle } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";
import { columnsCategory, columnsEquipment, columnsLocation, columnsRequestBy, columnsSubCategory, columnsVehicle } from "./ColumnsPM";
import { getEmployee } from "../../../Api/Employee/GetData";

const EditPMTransaction = ({ data, onEdit }) => {

    const dataEdit = data.PMTransaction;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [PMDate, setPMDate] = useState(dayjs(dataEdit.PMDate));

    const [isLoading, setIsLoading] = useState(true);

    const [dataRequestBy, setDataRequestBy] = useState([]);
    const [selectRequestBy, setSelectRequestBy] = useState("");
    const [openModalRequestBy, setOpenModalRequestBy] = useState(true);
    const RequestByCode = selectRequestBy ? selectRequestBy.Employee.EmpCode : '';
    const RequestByName = selectRequestBy ? selectRequestBy.Employee.EmpName : '';

    const [dataCategory, setDataCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState("");
    const [openModalCategory, setOpenModalCategory] = useState(true);
    const CategoryCode = selectCategory ? selectCategory.CatCode : '';
    const CategoryName = selectCategory ? selectCategory.CatName : '';

    const [dataSubCategory, setDataSubCategory] = useState([]);
    const [selectSubCategory, setSelectSubCategory] = useState("");
    const [openModalSubCategory, setOpenModalSubCategory] = useState(true);
    const SubCategoryCode = selectSubCategory ? selectSubCategory.SubCategory.SubCatCode : '';
    const SubCategoryName = selectSubCategory ? selectSubCategory.SubCategory.SubCatName : '';

    const [selectedPMType, setSelectedPMType] = useState(dataEdit.PMType);

    const [dataPMTypeRef, setDataPMTypeRef] = useState([]);
    const [selectPMTypeRef, setSelectPMTypeRef] = useState("");
    const [PMTypeRefCode, setPMTypeRefCode] = useState("");
    const [PMTypeRefName, setPMTypeRefName] = useState("");
    const [LocationCode, setLocationCode] = useState("-");

    const [selectedScheduleType, setSelectedScheduleType] = useState(dataEdit.ScheduleType);

    const [scheduleStart, setScheduleStart] = useState(dayjs(dataEdit.ScheduleStart));
    const [scheduleEnd, setScheduleEnd] = useState(dayjs(dataEdit.ScheduleEnd));

    const [isFloatingChecked, setIsFloatingChecked] = useState(dataEdit.IsFLoating);

    const [dataTask, setDataTask] = useState([]);
    const [dataRE, setDataRE] = useState([]);
    const [dataDoc, setDataDoc] = useState([]);
    const [dataCT, setDataCT] = useState([]);
    const [dataSC, setDataSC] = useState([]);


    // EMPLOYEE || REQUEST BY
    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const res = await getEmployee();
                const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
                setDataRequestBy(filterRes);

                const selected = res.filter((item) => item.Employee.EmpCode === dataEdit.RequestBy);
                setSelectRequestBy(selected[0]);

            } catch (error) {
                console.log(error);
            }
        }

        if (open && openModalRequestBy) {
            fetchEmployee();
            setOpenModalRequestBy(false);
            setIsLoading(false);
        }

    }, [dataEdit.RequestBy, open, openModalRequestBy]);


    // PM TYPE
    useEffect(() => {
        const fetchPMTypeRef = async () => {
            try {
                switch (selectedPMType) {
                    case 'Location':
                        const resLoc = await getLocation(dataEdit.FacilityCode);
                        const filterResLoc = resLoc.filter((item) => item.Location.IsSuspend !== true);
                        setDataPMTypeRef(filterResLoc);

                        const selectedLoc = resLoc.filter(item => item.Location.LocationCode === dataEdit.PMTypeRefCode);
                        setSelectPMTypeRef(selectedLoc[0])

                        break;
                    case 'Equipment':
                        const resEquip = await getEquipment(dataEdit.FacilityCode);
                        const filterResEquip = resEquip.filter((item) => item.Equipment.IsSuspend !== true);
                        setDataPMTypeRef(filterResEquip);

                        const selectedEquip = resEquip.filter(item => item.Equipment.EquipmentCode === dataEdit.PMTypeRefCode)
                        setSelectPMTypeRef(selectedEquip[0])

                        break;
                    case 'Vehicle':
                        const resVehicle = await getVehicle();
                        const filterResVehicle = resVehicle.filter((item) => item.IsSuspend !== true);
                        setDataPMTypeRef(filterResVehicle);

                        const selectedVehicle = resVehicle.filter(item => item.VehicleCode === dataEdit.PMTypeRefCode);
                        setSelectPMTypeRef(selectedVehicle[0])

                        break;
                    default:
                        // Handle default case if necessary
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (dataEdit.FacilityCode) {
            fetchPMTypeRef();
            setIsLoading(false);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEdit.FacilityCode, selectedPMType]);


    useEffect(() => {
        if (selectPMTypeRef) {
            switch (selectedPMType) {
                case 'Location':
                    setPMTypeRefCode(selectPMTypeRef.Location.LocationCode);
                    setPMTypeRefName(selectPMTypeRef.Location.LocationName);
                    setLocationCode(selectPMTypeRef.Location.LocationCode);
                    break;
                case 'Equipment':
                    setPMTypeRefCode(selectPMTypeRef.Equipment.EquipmentCode);
                    setPMTypeRefName(selectPMTypeRef.Equipment.EquipmentName);
                    setLocationCode(selectPMTypeRef.Equipment.LocationCode);
                    break;
                case 'Vehicle':
                    setPMTypeRefCode(selectPMTypeRef.VehicleCode);
                    setPMTypeRefName(selectPMTypeRef.VehicleName);
                    break;
                default:
                    // Handle default case if necessary
                    break;
            }
        }
    }, [selectPMTypeRef, selectedPMType]);


    // CATEGORY
    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const res = await getCategory("Work");
                const filterRes = res.filter((item) => item.IsSuspend !== true);
                setDataCategory(filterRes);

                const selected = res.filter((item) => item.CatCode === dataEdit.WorkCatCode);
                setSelectCategory(selected[0]);

            } catch (error) {
                console.log(error);
            }
        }

        if (open && openModalCategory) {
            fetchCategory();
            setOpenModalCategory(false);
            setIsLoading(false);
        }

    }, [dataEdit.WorkCatCode, open, openModalCategory]);


    // SUBCATEGORY
    useEffect(() => {
        const fetchSubCategory = async () => {
            try {
                const res = await getSubCategory("Work", CategoryCode);
                const filterRes = res.filter((item) => item.SubCategory.IsSuspend !== true);
                setDataSubCategory(filterRes);

                const selected = res.filter((item) => item.SubCategory.SubCatCode === dataEdit.WorkSubCatCode);
                setSelectSubCategory(selected[0]);

            } catch (error) {
                console.log(error);
            }
        }

        if (open && openModalSubCategory && CategoryCode) {
            fetchSubCategory();
            setOpenModalSubCategory(false);
            setIsLoading(false);
        }

    }, [CategoryCode, dataEdit.WorkSubCatCode, open, openModalSubCategory]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("RequestBy", RequestByName);
            formRef.current.setFieldValue("PMTypeRefName", PMTypeRefName);
            formRef.current.setFieldValue("CategoryName", CategoryName);
            formRef.current.setFieldValue("SubCategoryName", SubCategoryName);
        }
    }, [CategoryName, PMTypeRefName, RequestByName, SubCategoryName]);


    const handleCheckboxChange = (event) => {
        setIsFloatingChecked(event.target.checked);
    };


    const handleKeyPress = (e) => {
        if (!onlyNumber.test(e.key)) {
            e.preventDefault();
        }
    };

    const handlePMTypeSelection = (event, value) => {
        if (value) {
            setSelectedPMType(value.PMTypeName);
            setPMTypeRefCode([]);
            setSelectPMTypeRef("");
            setPMTypeRefCode("");
            setPMTypeRefName("");
            setLocationCode("");
        } else {
            setSelectedPMType(null)
        }
    };

    const handleScheduleTypeSelection = (event, value) => {
        if (value) {
            setSelectedScheduleType(value.TypeName);
        } else {
            setSelectedScheduleType(null);
        }
    };


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {

            // const cleanedEstimationCost = values.EstimationCost.replace(/\D/g, '');
            // const estimationCostNumber = parseInt(cleanedEstimationCost, 10);

            const PMDateNow = PMDate.format('YYYY-MM-DD');
            const ScheduleStartNow = scheduleStart.format('YYYY-MM-DD');
            const ScheduleEndNow = scheduleEnd.format('YYYY-MM-DD');

            const modifiedValues = {
                ...values,
                PMDate: PMDateNow,
                RequestBy: RequestByCode,
                PMTypeRefCode: PMTypeRefCode,
                LocationCode: LocationCode,
                WorkCatCode: CategoryCode,
                WorkSubCatCode: SubCategoryCode,
                ScheduleType: selectedScheduleType,
                ScheduleStart: ScheduleStartNow,
                ScheduleEnd: ScheduleEndNow,
                // EstimationCost: estimationCostNumber,
                tasks: dataTask,
                resources: dataRE,
                documents: dataDoc,
                contacts: dataCT,
                schedules: dataSC,
            };
            // console.log(modifiedValues);

            const response = await updatePMTransaction(dataEdit.FacilityCode, dataEdit.PMNumber, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onEdit(dataEdit.FacilityCode && dataEdit.PMNumber);
            setOpen(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response.data);
        }
        setLoading(false);
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    validationSchema={checkoutSchema}
                    innerRef={formRef}
                >

                    {({

                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="PM TRANSACTION"
                                        subtitle="Edit Data a PM Transaction"
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >

                            <StyleForm>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Facility Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={data.FacilityName}
                                    name="FacilityCode"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="PM Number"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PMNumber}
                                    name="PMNumber"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="PM Date*"
                                        value={PMDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setPMDate(date)}
                                        name="PMDate"
                                        error={!!touched.PMDate && !!errors.PMDate}
                                        helperText={touched.PMDate && errors.PMDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="PM Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PMName}
                                    name="PMName"
                                    error={!!touched.PMName && !!errors.PMName}
                                    helperText={touched.PMName && errors.PMName}
                                    sx={{ gridColumn: "span 2" }}
                                />

                                <InputModal
                                    title="EMPLOYEE"
                                    label="Request By"
                                    name="RequestBy"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    dataSource={dataRequestBy}
                                    columns={columnsRequestBy}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectRequestBy(values)}
                                    propsOpenModal={(values) => setOpenModalRequestBy(values)}
                                    LinktoCreate={`/master/employee/form`}
                                />

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={PMTypeData}
                                    getOptionLabel={(option) => option.PMTypeName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handlePMTypeSelection(event, value);
                                        setFieldValue("PMType", value ? value.PMTypeName : "");
                                    }}
                                    value={
                                        PMTypeData.find((option) => option.PMTypeName === selectedPMType) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="PM Type*"
                                            onBlur={handleBlur}
                                            error={!!touched.PMType && !!errors.PMType}
                                            helperText={touched.PMType && errors.PMType}
                                        />
                                    )}
                                />

                                <InputModal
                                    title="PM TYPE REFERENCE"
                                    label="PM Type Reference"
                                    name="PMTypeRefName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.PMTypeRefName}
                                    error={!!touched.PMTypeRefName && !!errors.PMTypeRefName}
                                    helperText={touched.PMTypeRefName && errors.PMTypeRefName}
                                    dataSource={dataPMTypeRef}
                                    columns={
                                        (selectedPMType === "Location" && columnsLocation) ||
                                        (selectedPMType === "Equipment" && columnsEquipment) ||
                                        (selectedPMType === "Vehicle" && columnsVehicle)
                                    }
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectPMTypeRef(values)}
                                    // propsOpenModal={(values) => setOpenModalPMTypeRef(values)}
                                    LinktoCreate={
                                        (selectedPMType === "Location" && `/master/location/form?faccode=${dataEdit.FacilityCode}`) ||
                                        (selectedPMType === "Equipment" && `/master/equipment/form?faccode=${dataEdit.FacilityCode}`) ||
                                        (selectedPMType === "Vehicle" && "/master/vehicle/form")
                                    }
                                />

                                <InputModal
                                    title="CATEGORY"
                                    label="Category Name"
                                    name="CategoryName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.CategoryName}
                                    error={!!touched.CategoryName && !!errors.CategoryName}
                                    helperText={touched.CategoryName && errors.CategoryName}
                                    dataSource={dataCategory}
                                    columns={columnsCategory}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectCategory(values)}
                                    propsOpenModal={(values) => setOpenModalCategory(values)}
                                    LinktoCreate={`/master/category/form?moduleid=Work`}
                                />

                                <InputModal
                                    title="SUBCATEGORY"
                                    label="SubCategory Name"
                                    name="SubCategoryName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.SubCategoryName}
                                    error={!!touched.SubCategoryName && !!errors.SubCategoryName}
                                    helperText={touched.SubCategoryName && errors.SubCategoryName}
                                    dataSource={dataSubCategory}
                                    columns={columnsSubCategory}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectSubCategory(values)}
                                    propsOpenModal={(values) => setOpenModalSubCategory(values)}
                                    LinktoCreate={`/master/subcategory/form?moduleid=Work&category=${CategoryCode}`}
                                />

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={ScheduleTypeData}
                                    getOptionLabel={(option) => option.TypeName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handleScheduleTypeSelection(event, value);
                                        setFieldValue("ScheduleType", value ? value.TypeName : "");
                                    }}
                                    value={
                                        ScheduleTypeData.find((option) => option.TypeName === selectedScheduleType) ||
                                        null
                                    }
                                    renderInput={(params) => (
                                        <TextField

                                            {...params}
                                            label="ScheduleType*"
                                            onBlur={handleBlur}
                                            error={!!touched.ScheduleType && !!errors.ScheduleType}
                                            helperText={touched.ScheduleType && errors.ScheduleType}
                                        />
                                    )}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="PM Schedule Start*"
                                        value={scheduleStart}
                                        onBlur={handleBlur}
                                        onChange={(date) => setScheduleStart(date)}
                                        name="ScheduleStart"
                                        error={!!touched.ScheduleStart && !!errors.ScheduleStart}
                                        helperText={touched.ScheduleStart && errors.ScheduleStart}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="PM Schedule End*"
                                        value={scheduleEnd}
                                        onBlur={handleBlur}
                                        onChange={(date) => setScheduleEnd(date)}
                                        name="ScheduleEnd"
                                        error={!!touched.ScheduleEnd && !!errors.ScheduleEnd}
                                        helperText={touched.ScheduleEnd && errors.ScheduleEnd}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Day To Complete*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.DayToComplete}
                                    name="DayToComplete"
                                    error={!!touched.DayToComplete && !!errors.DayToComplete}
                                    helperText={touched.DayToComplete && errors.DayToComplete}
                                    sx={{ gridColumn: "span 2" }}
                                    onKeyPress={handleKeyPress}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">Days</InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Estimation Cost*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.EstimationCost}
                                    name="EstimationCost"
                                    error={!!touched.EstimationCost && !!errors.EstimationCost}
                                    helperText={touched.EstimationCost && errors.EstimationCost}
                                    sx={{ gridColumn: "span 2" }}
                                    onKeyPress={handleKeyPress}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">Rp.</InputAdornment>
                                        ),
                                    }}
                                />


                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                    rows={4}
                                />

                                <FormControlLabel
                                    control={
                                        <Tooltip title="Allow Skip cheked maka autoskip schedule, Allow Skip unchecked maka tidak autoskip schedule">
                                            <Checkbox
                                                checked={isFloatingChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                        </Tooltip>
                                    }
                                    label="Allow Skip"
                                />

                            </StyleForm>

                            <Box sx={{
                                margin: "0 20px"
                            }}>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormPMTask
                                        onSaveData={(data) => setDataTask(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        PMNumber={dataEdit.PMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        margin: "20px 0",
                                        padding: 2,
                                        borderRadius: 2,
                                        boxShadow: "0px 0px 10px #00000026",
                                    }}
                                >
                                    <FormPMRE
                                        onSaveData={(data) => setDataRE(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        PMNumber={dataEdit.PMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        margin: "20px 0",
                                        padding: 2,
                                        borderRadius: 2,
                                        boxShadow: "0px 0px 10px #00000026",
                                    }}
                                >
                                    <FormPMDoc
                                        onSaveData={(data) => setDataDoc(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        PMNumber={dataEdit.PMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        margin: "20px 0",
                                        padding: 2,
                                        borderRadius: 2,
                                        boxShadow: "0px 0px 10px #00000026",
                                    }}
                                >
                                    <FormPMCT
                                        onSaveData={(data) => setDataCT(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        PMNumber={dataEdit.PMNumber}
                                        onEdit={true}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        margin: "20px 0",
                                        padding: 2,
                                        borderRadius: 2,
                                        boxShadow: "0px 0px 10px #00000026",
                                    }}
                                >
                                    <FormPMSC
                                        onSaveData={(data) => setDataSC(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        PMNumber={dataEdit.PMNumber}
                                        ScheduleType={selectedScheduleType}
                                        onEdit={true}
                                    />
                                </Box>

                            </Box>

                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    // PMDate: yup
    //     .string()
    //     .max(50, "Maximum of 50 characters allowed")
    //     .required("Please complete this  PMDate."),
    PMName: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this  PM Name."),
    RequestBy: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this  Request By."),
    PMType: yup
        .string()
        .required("Please complete this  PM Type."),
    PMTypeRefCode: yup
        .string()
        .required("Please complete this  PM Type Referensi."),
    WorkCatCode: yup
        .string()
        .required("Please complete this  Work Category Name."),
    WorkSubCatCode: yup
        .string()
        .required("Please complete this  Work SubCategory Name."),
    ScheduleType: yup
        .string()
        .required("Please complete this  Schedule Type."),
    // ScheduleStart: yup
    //     .string()
    //     .max(50, "Maximum of 50 characters allowed")
    //     .required("Please complete this  ScheduleStart."),
    // ScheduleEnd: yup
    //     .string()
    //     .max(50, "Maximum of 50 characters allowed")
    //     .required("Please complete this  ScheduleEnd."),
    DayToComplete: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this  Day To Complete."),
    EstimationCost: yup
        .string()
        .max(50, "Maximum of 50 characters allowed")
        .required("Please complete this  Estimation Cost."),
});

const PMTypeData = [
    {
        PMTypeName: "Location"
    },
    {
        PMTypeName: "Equipment"
    },
    {
        PMTypeName: "Vehicle"
    }
];

const ScheduleTypeData = [
    {
        TypeName: "Calendar"
    },
    {
        TypeName: "Meter"
    }
]

export default EditPMTransaction