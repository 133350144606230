import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Link } from "react-router-dom";
import { Modal } from "antd";
import FormWOTransaction from "../WOTransaction/form";
import FormWORequest from "../WORequest/form";

const ProcessTranApproval = ({ data, onDelete }) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>

            <Tooltip title="Process" fontSize="large">
                <Link to={`/transaction/${data.TranName.replace(/\s/g, '').toLowerCase()}/form?action=process&faccode=${data.FacilityCode}&trannumber=${data.TranNumber}`} >
                    <IconButton>
                        <AutorenewIcon />
                    </IconButton>
                </Link>
            </Tooltip>

            {/* <Tooltip title="Process" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <AutorenewIcon />
                </IconButton>
            </Tooltip>

            <Modal
                centered
                open={open}
                closable={false}
                style={{
                    maxWidth: "90%",
                    minWidth: "80%",
                }}
                bodyStyle={{
                    maxHeight: "90vh",
                    overflow: "auto",
                }}
                footer={false}
            >
                <FormWORequest action="process" faccode={data.FacilityCode} trannumber={data.trannumber} />
            </Modal> */}

        </Box>
    );
};



export default ProcessTranApproval