import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
    user: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

export const Signup = createAsyncThunk("user/Signup", async (user, thunkAPI) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/users`, {
            RefAccountId: "RAID00005",
            AccountId: "AID00001",
            UserName: user.Username,
            Email: user.Email,
            UserPwd: user.Password,
            confPwd: user.ConfPassword,
            UserGroup: "user"
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // API memberikan respon error
            const message = error.response.data.statusMessage;
            return thunkAPI.rejectWithValue(message);
        } else if (error.request) {
            // Tidak ada respon dari API (API terputus)
            return thunkAPI.rejectWithValue("Tidak dapat terhubung ke server. Periksa koneksi internet Anda.");
        } else {
            // Kesalahan lainnya
            return thunkAPI.rejectWithValue("Terjadi kesalahan saat mencoba mengirim permintaan.");
        }
    }
});

export const LoginUser = createAsyncThunk("user/LoginUser", async (user, thunkAPI) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/user/login`, {
            Email: user.Email,
            UserPwd: user.Password
        });
        localStorage.clear();
        Cookies.set("accessToken", response.data.Token);
        return response.data;
    } catch (error) {
        if (error.response) {
            // API memberikan respon error
            const message = error.response.data;
            return thunkAPI.rejectWithValue(message);
        } else if (error.request) {
            // Tidak ada respon dari API (API terputus)
            return thunkAPI.rejectWithValue("Tidak dapat terhubung ke server. Periksa koneksi internet Anda.");
        } else {
            // Kesalahan lainnya
            return thunkAPI.rejectWithValue("Terjadi kesalahan saat mencoba mengirim permintaan.");
        }
    }
});

export const getMe = createAsyncThunk("user/getMe", async (token, thunkAPI) => {
    try {
        // const token = Cookies.get('accessToken');
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/user/check-session`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // API memberikan respon error
            const message = error.response.data;
            return thunkAPI.rejectWithValue(message);
        } else if (error.request) {
            // Tidak ada respon dari API (API terputus)
            return thunkAPI.rejectWithValue("Tidak dapat terhubung ke server. Periksa koneksi internet Anda.");
        } else {
            // Kesalahan lainnya
            return thunkAPI.rejectWithValue("Terjadi kesalahan saat mencoba mengirim permintaan.");
        }
    }
});

export const LogOut = createAsyncThunk("user/LogOut", async (token, thunkAPI) => {
    try {
        await axios.get(`${process.env.REACT_APP_BASEURL}/user/logout`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    } catch (error) {
        if (error.response) {
            // API memberikan respon error
            const message = error.response.data.statusMessage;
            return thunkAPI.rejectWithValue(message);
        } else if (error.request) {
            // Tidak ada respon dari API (API terputus)
            return thunkAPI.rejectWithValue("Tidak dapat terhubung ke server. Periksa koneksi internet Anda.");
        } else {
            // Kesalahan lainnya
            return thunkAPI.rejectWithValue("Terjadi kesalahan saat mencoba mengirim permintaan.");
        }
    }
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        //SignUp User
        builder.addCase(Signup.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(Signup.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(Signup.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })


        // Login User
        builder.addCase(LoginUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(LoginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(LoginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })

        // Get User Login
        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })
    }
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;