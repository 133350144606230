import { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./SidebarContext";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useSelector } from "react-redux";
import { MenuIcons } from "./MenuIcons";
import { getTran } from "../../../Api/Configuration/GetData";

const Item = ({ title, to, icon, selected, setSelected, TranIdx, onSelect }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = () => {
    setSelected(title);
    localStorage.setItem("selectedMenu", title);
    if (onSelect) {
      onSelect(TranIdx);
    }
  };

  return (
    <MenuItem
      active={selected === title}
      style={{ color: `${colors.grey[100]} !important`, margin: "5px 0" }}
      onClick={handleClick}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography style={{ fontWeight: 600 }}>
        {title}
      </Typography>
    </MenuItem>
  );
};

const Title = ({ title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Typography
      variant="h6"
      color={colors.primary[500]}
      sx={{ m: "15px 20px 5px 20px", fontWeight: 600 }}
    >
      {title}
    </Typography>
  )
}

const SubTitle = ({ title, active }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Typography
      variant="h6"
      color={active ? colors.primary[500] : colors.primary[900]}
      sx={{ fontWeight: 600 }}
    >
      {title}
    </Typography>
  )
}

const MyProSidebar = ({ Authority }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const { user } = useSelector((state) => state.auth);

  const [TranMaster, setTranMaster] = useState([]);
  const [TranEmployee, setTranEmployee] = useState([]);
  const [TranConfiguration, setTranConfiguration] = useState([]);
  const [TranTransaction, setTranTransaction] = useState([]);
  const [TranReport, setTranReport] = useState([]);
  const { authIdx } = useSidebarContext();

  useEffect(() => {
    const fetchTran = async () => {
      try {
        const response = await getTran();
        setTranMaster(response.filter((item) => item.TranType === "Master").map((row) => ({ ...row, Icons: MenuIcons[row.TranIdx] })));
        setTranEmployee(response.filter((item) => item.TranType === "Employee").map((row) => ({ ...row, Icons: MenuIcons[row.TranIdx] })));
        setTranConfiguration(response.filter((item) => item.TranType === "Configuration").map((row) => ({ ...row, Icons: MenuIcons[row.TranIdx] })));
        setTranTransaction(response.filter((item) => item.TranType === "Transaction").map((row) => ({ ...row, Icons: MenuIcons[row.TranIdx] })));
        setTranReport(response.filter((item) => item.TranType === "Report").map((row) => ({ ...row, Icons: MenuIcons[row.TranIdx] })));
      } catch (error) {
        console.log(error);
      }
    }

    fetchTran();

    const savedSelectedMenu = localStorage.getItem("selectedMenu");
    if (savedSelectedMenu) {
      setSelected(savedSelectedMenu);
    }

    const savedOpenSubMenu = localStorage.getItem("openSubMenu");
    if (savedOpenSubMenu) {
      setOpenSubMenu(savedOpenSubMenu);
    }
  }, []);

  // Safe check for user and GroupTran
  const tranIdx = user?.GroupTran || [];

  const handleSelect = (TranIdx) => {
    if (Authority) {
      Authority(TranIdx);
    }
  };

  const handleSubMenuClick = (menu) => {
    const newOpenSubMenu = openSubMenu === menu ? null : menu;
    setOpenSubMenu(newOpenSubMenu);
    localStorage.setItem("openSubMenu", newOpenSubMenu);
  };

  // Helper function to check if items should be displayed
  const shouldDisplayItems = (items) => {
    return items.filter(item => tranIdx.includes(item.TranIdx)).length > 0;
  };

  // Helper function to filter items based on permissions
  const filterItemsByPermission = (items) => {
    return tranIdx.length > 0 ? items.filter(item => tranIdx.includes(item.TranIdx)) : items;
  };

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 100,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          backgroundColor: `${colors.grey[500]} !important`,
          borderRadius: "10px 0 0 10px",
        },
        "& .menu-item.active": {
          color: `${colors.primary[500]} !important`,
          backgroundColor: `${colors.grey[500]} !important`,
          borderRadius: "10px 0 0 10px",
        },
        "& .submenu-title-content": {
          color: "black !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.grey[100]}
        image={sidebarImage}
      >
        <Menu iconshape="square">
          {!collapsed ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="10%"
            >
              <Box
                display="flex"
                alignItems="center"
                gap="10px"
              >
                <img
                  src="/assets/icon/venus.png"
                  alt="Logo"
                  width="35px"
                />
                <Typography variant="h3" color={colors.grey[900]} fontWeight={600}>
                  CMMS
                </Typography>
              </Box>
              <IconButton
                onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}
              >
                <MenuOutlinedIcon />
              </IconButton>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginY="10%"
            >
              <img
                src="/assets/icon/venus.png"
                alt="Logo"
                width="32px"
              />
              <IconButton
                onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}
              >
                <MenuOutlinedIcon />
              </IconButton>
            </Box>
          )}
          <Box paddingLeft={collapsed ? undefined : "5%"} marginBottom={2}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
              TranIdx="dashboard"
              onSelect={handleSelect}
            />

            {shouldDisplayItems(TranMaster) && (
              <SubMenu
                label={<SubTitle title="MASTER DATA" active={openSubMenu === "MASTER DATA"} />}
                open={openSubMenu === "MASTER DATA"}
                onClick={() => handleSubMenuClick("MASTER DATA")}
              >
                {filterItemsByPermission(TranMaster).map((item, index) => (
                  <Item
                    key={index}
                    title={item.TranName}
                    to={`/master/${item.TranName.replace(/\s/g, '').toLowerCase()}`}
                    icon={item.Icons}
                    selected={selected}
                    setSelected={setSelected}
                    TranIdx={item.TranIdx}
                    onSelect={handleSelect}
                  />
                ))}
              </SubMenu>
            )}

            {shouldDisplayItems(TranEmployee) && (
              <SubMenu
                label={<SubTitle title="EMPLOYEE" active={openSubMenu === "EMPLOYEE"} />}
                open={openSubMenu === "EMPLOYEE"}
                onClick={() => handleSubMenuClick("EMPLOYEE")}
              >
                {filterItemsByPermission(TranEmployee).map((item, index) => (
                  <Item
                    key={index}
                    title={item.TranName}
                    to={`/employee/${item.TranName.replace(/\s/g, '').toLowerCase()}`}
                    icon={item.Icons}
                    selected={selected}
                    setSelected={setSelected}
                    TranIdx={item.TranIdx}
                    onSelect={handleSelect}
                  />
                ))}
              </SubMenu>
            )}

            {shouldDisplayItems(TranConfiguration) && (
              <SubMenu
                label={<SubTitle title="CONFIGURATION" active={openSubMenu === "CONFIGURATION"} />}
                open={openSubMenu === "CONFIGURATION"}
                onClick={() => handleSubMenuClick("CONFIGURATION")}
              >
                {filterItemsByPermission(TranConfiguration).map((item, index) => (
                  <Item
                    key={index}
                    title={item.TranName}
                    to={`/configuration/${item.TranName.replace(/\s/g, '').toLowerCase()}`}
                    icon={item.Icons}
                    selected={selected}
                    setSelected={setSelected}
                    TranIdx={item.TranIdx}
                    onSelect={handleSelect}
                  />
                ))}
              </SubMenu>
            )}

            {shouldDisplayItems(TranTransaction) && (
              <SubMenu
                label={<SubTitle title="TRANSACTION" active={openSubMenu === "TRANSACTION"} />}
                open={openSubMenu === "TRANSACTION"}
                onClick={() => handleSubMenuClick("TRANSACTION")}
              >
                {filterItemsByPermission(TranTransaction).map((item, index) => (
                  <Item
                    key={index}
                    title={item.TranName}
                    to={`/transaction/${item.TranName.replace(/\s/g, '').toLowerCase()}`}
                    icon={item.Icons}
                    selected={selected}
                    setSelected={setSelected}
                    TranIdx={item.TranIdx}
                    onSelect={handleSelect}
                  />
                ))}
              </SubMenu>
            )}

            {shouldDisplayItems(TranReport) && (
              <SubMenu
                label={<SubTitle title="REPORT" active={openSubMenu === "REPORT"} />}
                open={openSubMenu === "REPORT"}
                onClick={() => handleSubMenuClick("REPORT")}
              >
                {filterItemsByPermission(TranReport).map((item, index) => (
                  <Item
                    key={index}
                    title={item.TranName}
                    to={`/report/${item.TranName.replace(/\s/g, '').toLowerCase()}`}
                    icon={item.Icons}
                    selected={selected}
                    setSelected={setSelected}
                    TranIdx={item.TranIdx}
                    onSelect={handleSelect}
                  />
                ))}
              </SubMenu>
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;