import { Box, Tooltip } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import Carousel from 'react-material-ui-carousel';

const MapsDashboard = ({ setData }) => {
    const [value, setValue] = useState(0);
    const [imageDimensions, setImageDimensions] = useState({});
    const imageRefs = useRef([]);
    const containerRef = useRef(null);
    const [dragging, setDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [initialMousePosition, setInitialMousePosition] = useState({ x: 0, y: 0 });
    const [isCtrlPressed, setIsCtrlPressed] = useState(false);

    useEffect(() => {
        setData.Maps.forEach((item, index) => {
            const img = new Image();
            img.onload = () => {
                setImageDimensions((prev) => ({
                    ...prev,
                    [index]: { width: img.width, height: img.height }
                }));
            };
            img.src = `${process.env.REACT_APP_BASEURL_IMG}/${item.Image}`;
            imageRefs.current[index] = img;
        });

        const handleKeyDown = (e) => {
            if (e.ctrlKey) {
                setIsCtrlPressed(true);
            }
        };

        const handleKeyUp = (e) => {
            if (!e.ctrlKey) {
                setIsCtrlPressed(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [setData]);

    const handleMouseDown = (e, index) => {
        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        const imgWidth = imageDimensions[index]?.width || 0;
        const imgHeight = imageDimensions[index]?.height || 0;

        if (imgWidth > containerWidth || imgHeight > containerHeight) {
            if (isCtrlPressed) {
                setDragging(true);
                setInitialMousePosition({ x: e.clientX, y: e.clientY });
            }
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            const dx = e.clientX - initialMousePosition.x;
            const dy = e.clientY - initialMousePosition.y;
            setOffset((prev) => ({
                x: prev.x + dx,
                y: prev.y + dy
            }));
            setInitialMousePosition({ x: e.clientX, y: e.clientY });
        }
    };

    const handleChangeMaps = (event, newValue) => {
        if (!dragging) {
            setValue(newValue);
            setOffset({ x: 0, y: 0 }); // Reset the offset when changing maps
        }
    };

    const FacilityCode = setData.FacilityCode;
    const MapsCode = setData.Maps;

    return (
        <Box
            ref={containerRef}
            sx={{
                width: "100%",
                height: "auto",
                position: "relative",
                overflow: "hidden", // Ensure the overflow is hidden
                cursor: dragging ? 'grabbing' : isCtrlPressed ? 'grab' : 'default', // Change cursor style
            }}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
        >
            <Carousel
                index={value}
                onChangeIndex={handleChangeMaps}
                duration={800}
                swipe={!dragging} // Disable swipe while dragging
            >
                {MapsCode.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            backgroundImage: `url(${process.env.REACT_APP_BASEURL_IMG}/${item.Image})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                            position: "relative",
                            width: imageDimensions[index]?.width || "100%",
                            height: imageDimensions[index]?.height || "auto",
                            marginBottom: '20px', // Add margin for spacing between maps
                            transform: `translate(${offset.x}px, ${offset.y}px)`, // Apply the offset for dragging
                            transition: dragging ? 'none' : 'transform 0.1s ease-out', // Smooth transition when not dragging
                        }}
                        onMouseDown={(e) => handleMouseDown(e, index)}
                    >
                        {item.Icons.map((icon, iconIndex) => (
                            <Tooltip key={iconIndex} title={icon.LocationName} placement="top">
                                <img
                                    src={icon.Url}
                                    alt="Dropped Icon"
                                    width={30}
                                    style={{
                                        position: "absolute",
                                        top: `${icon.IconLocationY}px`,
                                        left: `${icon.IconLocationX}px`,
                                        cursor: "default",
                                    }}
                                />
                            </Tooltip>
                        ))}
                    </Box>
                ))}
            </Carousel>
        </Box>
    );
};

export default MapsDashboard;
