import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditFacility from "./edit";
import DeleteFacility from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getFacility } from "../../../Api/Master/GetData";

const Facility = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getFacility();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
    // const interval = setInterval(() => {
    //   fetchData();
    // }, 60000);

    // return () => {
    //   clearInterval(interval);
    // };
  }, [fetchData]);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      key: 'FacilityCode',
      title: 'Facility Code',
      dataIndex: 'FacilityCode',
      width: 150,
      fixed: 'left',
      sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
      key: 'FacilityName',
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      key: 'Address',
      title: 'Address',
      dataIndex: 'Address',
      width: 400,
      sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
      key: 'City',
      title: 'City',
      dataIndex: 'City',
      sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
      key: 'Country',
      title: 'Country',
      dataIndex: 'Country',
      sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
      key: 'Phone1',
      title: 'Phone Number 1',
      dataIndex: 'Phone1',
      sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
      key: 'Phone2',
      title: 'Phone Number 2',
      dataIndex: 'Phone2',
      sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
      key: 'Fax',
      title: 'Fax',
      dataIndex: 'Fax',
      sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
      key: 'ContactPerson',
      title: 'Contact Person',
      dataIndex: 'ContactPerson',
      sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
    {
      key: 'IsSuspend',
      title: 'Suspend',
      dataIndex: 'IsSuspend',
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'Action',
      width: 120,
      fixed: 'right',
      render: (_, record) => (
        <Box display="flex" justifyContent="center">
          {myEditAuthIdx && (
            <EditFacility data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteFacility data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    });
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  // const handleFilterChange = (event) => {
  //   const value = event.target.value;
  //   setFilterValue(value);

  //   const filteredData = data.filter((row) => {
  //     return (
  //       row.FacilityName.toLowerCase().includes(value.toLowerCase()) ||
  //       row.Address.toLowerCase().includes(value.toLowerCase()) ||
  //       row.City.toLowerCase().includes(value.toLowerCase()) ||
  //       row.Country.toLowerCase().includes(value.toLowerCase())
  //     );
  //   });

  //   setFilteredRows(filteredData);
  // };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    ...columns
      .filter((item) => item.key !== "Action" && item.key !== "key") // Menghilangkan kolom dengan key "action"
      .map((item) => ({
        title: item.title,
        key: item.key,
      })),
    // { title: "key", key: "key" },
    // { title: "Facility Name", key: "FacilityName" },
    // { title: "Address", key: "Address" },
    // { title: "City", key: "City" },
    // { title: "Country", key: "Country" },
    // { title: "Phone Number 1", key: "Phone1" },
    // { title: "Phone Number 2", key: "Phone2" },
    // { title: "Fax", key: "Fax" },
    // { title: "Contact Person", key: "ContactPerson" },
    // { title: "Suspend", key: "IsSuspend" },
    // { title: "Description", key: "Description" },
  ];

  console.log("Columns Facility", columnsExport);

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="FACILITY" subtitle="All Facility data" />
        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="Facility"
            />
          )}

          {myAddAuthIdx && <ButtonAddNew link="/master/facility/form" />}
        </Box>
      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={
          data.length === 0
            ? null
            : {
              expandedRowRender: (record) => (
                <DetailGrid>
                  <Typography variant="h5" fontWeight={600}>
                    Description :{" "}
                  </Typography>
                  <Typography>{record.Description}</Typography>
                </DetailGrid>
              ),
              rowExpandable: (record) => record.Description !== "",
            }
        }
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 2500 }}
      />
    </StyleContent>
  );
};

export default Facility;
