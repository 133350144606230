import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table, Tabs } from "antd";
import DataPMTask from "./PM_Task";
import DataPMRE from "./PM_RE";
import DataPMCT from "./PM_CT";
import DataPMDoc from "./PM_Doc";
import DataPMSC from "./PM_SC";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getPMMainTrasaction } from "../Api/GetData";
import EditPMTransaction from "./edit";
import DeletePMTransaction from "./delete";
import StyleContent from "../../../components/Global/StyleContent";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";

const PMTransaction = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const [filterValue, setFilterValue] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getPMMainTrasaction();
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.PMTransaction.Description !== '' ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            marginBottom: "14px",
                            borderBottom: '2px solid #00000015'
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography>{record.PMTransaction.Description}</Typography>
                    </div>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Task',
                            key: 'task',
                            children: <DataPMTask record={record.PMTransaction} />,
                        },
                        {
                            label: 'Resource',
                            key: 'resource',
                            children: <DataPMRE record={record.PMTransaction} />,
                        },
                        {
                            label: 'Document',
                            key: 'document',
                            children: <DataPMDoc record={record.PMTransaction} />,
                        },
                        {
                            label: 'Contact',
                            key: 'contact',
                            children: <DataPMCT record={record.PMTransaction} />,
                        },
                        {
                            label: 'Schedule',
                            key: 'schedule',
                            children: <DataPMSC record={record.PMTransaction} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'FacilityName',
            dataIndex: 'FacilityName',
            width: 200,
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        // {
        //     title: 'PM Number',
        //     dataIndex: ['PMTransaction', 'PMNumber'],
        //     width: 200,
        //     sorter: (a, b) => a.PMTransaction.PMNumber.localeCompare(b.PMTransaction.PMNumber),
        // },
        // {
        //     title: 'PM Date',
        //     dataIndex: ['PMTransaction', 'PMDate'],
        //     width: 200,
        //     sorter: (a, b) => a.PMTransaction.PMDate.localeCompare(b.PMTransaction.PMDate),
        // },
        {
            title: 'PM Name',
            dataIndex: ['PMTransaction', 'PMName'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.PMName.localeCompare(b.PMTransaction.PMName),
        },
        // {
        //     title: 'Request By',
        //     dataIndex: ['PMTransaction', 'RequestBy'],
        //     width: 200,
        //     sorter: (a, b) => a.PMTransaction.RequestBy.localeCompare(b.PMTransaction.RequestBy),
        // },
        {
            title: 'Location Name',
            dataIndex: 'LocationName',
            width: 200,
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
            render: (_, record) => {
                if (!record.LocationName) {
                    return "-";
                }
                return record.LocationName;
            }
        },
        {
            title: 'PM Type',
            dataIndex: ['PMTransaction', 'PMType'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.PMType.localeCompare(b.PMTransaction.PMType),
        },
        {
            title: 'PM Type Referensi',
            dataIndex: 'PMTypeRefName',
            width: 200,
            sorter: (a, b) => a.PMTypeRefName.localeCompare(b.PMTypeRefName),
        },
        {
            title: 'Work Category',
            dataIndex: 'WorkCategory',
            width: 200,
            sorter: (a, b) => a.WorkCategory.localeCompare(b.WorkCategory),
        },
        {
            title: 'Work SubCategory',
            dataIndex: 'WorkSubCategory',
            width: 200,
            sorter: (a, b) => a.WorkSubCategory.localeCompare(b.WorkSubCategory),
        },
        {
            title: 'Schedule Type',
            dataIndex: ['PMTransaction', 'ScheduleType'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.ScheduleType.localeCompare(b.PMTransaction.ScheduleType),
        },
        {
            title: 'Schedule Start',
            dataIndex: ['PMTransaction', 'ScheduleStart'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.ScheduleStart.localeCompare(b.PMTransaction.ScheduleStart),
        },
        {
            title: 'Schedule End',
            dataIndex: ['PMTransaction', 'ScheduleEnd'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.ScheduleEnd.localeCompare(b.PMTransaction.ScheduleEnd),
        },
        {
            title: 'Day ToComplete',
            dataIndex: ['PMTransaction', 'DayToComplete'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.DayToComplete.localeCompare(b.PMTransaction.DayToComplete),
        },
        {
            title: 'Estimation Cost',
            dataIndex: ['PMTransaction', 'EstimationCost'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.EstimationCost.localeCompare(b.PMTransaction.EstimationCost),
        },
        {
            title: 'AppStatus',
            dataIndex: ['PMTransaction', 'AppStatus'],
            width: 200,
            sorter: (a, b) => a.PMTransaction.AppStatus.localeCompare(b.PMTransaction.AppStatus),
        },
    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.PMTransaction.AppStatus !== 0 
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditPMTransaction data={record} onEdit={fetchData} />
                        )}

                        {myDeleteAuthIdx > 0 && (
                            <DeletePMTransaction data={record.PMTransaction} onDelete={fetchData} />
                        )}
                    </Space>
                );
            },
        })
    }

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);

        const filteredData = data.filter((row) => {
            return (
                row.FacilityName.toLowerCase().includes(value.toLowerCase()) ||
                row.PMTransaction.PMNumber.toLowerCase().includes(value.toLowerCase()) ||
                row.PMTransaction.PMName.toLowerCase().includes(value.toLowerCase()) ||
                row.PMTransaction.RequestBy.toLowerCase().includes(value.toLowerCase()) ||
                row.LocationName.toLowerCase().includes(value.toLowerCase()) ||
                row.PMTransaction.PMType.toLowerCase().includes(value.toLowerCase()) ||
                row.PMTypeRefName.toLowerCase().includes(value.toLowerCase()) ||
                row.WorkCategory.toLowerCase().includes(value.toLowerCase()) ||
                row.WorkSubCategory.toLowerCase().includes(value.toLowerCase()) ||
                row.PMTransaction.ScheduleType.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
    };

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = [
        { title: "key", key: "key" },
    ];

    return (
        <StyleContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Header
                    title="PM CONFIGURATION"
                    subtitle="All PM Configuration"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={selectedRows.length > 0 ? selectedRows : data}
                            columns={columnsExport}
                            name="PM Configuration"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="/transaction/pmconfiguration/form"
                        />
                    )}
                </Box>
            </Box>

            <SearchData
                filterValue={filterValue}
                handleFilterChange={handleFilterChange}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filterValue ? filteredRows : data}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 1000 }}
            />

        </StyleContent>
    );
};

export default PMTransaction