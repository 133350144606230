
export const columnsFacility = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        key: 'FacilityCode',
        title: 'Facility Code',
        dataIndex: 'FacilityCode',
        width: 150,
        fixed: 'left',
        sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
        key: 'FacilityName',
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        key: 'Address',
        title: 'Address',
        dataIndex: 'Address',
        width: 400,
        sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
        key: 'City',
        title: 'City',
        dataIndex: 'City',
        sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
        key: 'Country',
        title: 'Country',
        dataIndex: 'Country',
        sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
        key: 'Phone1',
        title: 'Phone Number 1',
        dataIndex: 'Phone1',
        sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
        key: 'Phone2',
        title: 'Phone Number 2',
        dataIndex: 'Phone2',
        sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
        key: 'Fax',
        title: 'Fax',
        dataIndex: 'Fax',
        sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
        key: 'ContactPerson',
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
]


export const columnsBuilding = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Building Code',
        dataIndex: ['Building', 'BuildingCode'],
        width: 160,
        fixed: 'left',
        sorter: (a, b) =>
            a.Building.BuildingCode.localeCompare(b.Building.BuildingCode),
    },
    {
        title: 'Building Name',
        dataIndex: ['Building', 'BuildingName'],
        sorter: (a, b) =>
            a.Building.BuildingName.localeCompare(b.Building.BuildingName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) =>
            a.Building.FacilityName.localeCompare(b.Building.FacilityName),
    },
    {
        title: 'Address',
        dataIndex: ['Building', 'Address'],
        width: 400,
        sorter: (a, b) => a.Building.Address.localeCompare(b.Building.Address),
    },
    {
        title: 'Phone Number 1',
        dataIndex: ['Building', 'Phone1'],
        sorter: (a, b) => a.Building.Phone1.localeCompare(b.Building.Phone1),
    },
    {
        title: 'Phone Number 2',
        dataIndex: ['Building', 'Phone2'],
        sorter: (a, b) => a.Building.Phone2.localeCompare(b.Building.Phone2),
    },
    {
        title: 'Contact Person',
        dataIndex: ['Building', 'ContactPerson'],
        sorter: (a, b) =>
            a.Building.ContactPerson.localeCompare(b.Building.ContactPerson),
    }
];

export const columnsUserGroup = [
        {
            key: 'key',
            title: 'No',
            dataIndex: 'key',
            width: 80,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Kode Cabang',
            dataIndex: 'KodeCabang',
            sorter: (a, b) => a.KodeCabang.localeCompare(b.KodeCabang),
        },
        {
            title: 'Group Code',
            dataIndex: 'GroupCode',
            sorter: (a, b) => a.GroupCode.localeCompare(b.GroupCode),
        },
        {
            title: 'Group Name',
            dataIndex: 'GroupName',
            sorter: (a, b) => a.GroupName.localeCompare(b.GroupName),
        },
    ]