import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import MapIcon from "./map";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { getCategory, getFloor } from "../../../Api/Master/GetData";
import { updateLocation } from "../../../Api/Master/UpdateData";
import InputModal from "../../../components/Global/InputModal";

const EditLocation = ({ data, onEdit }) => {

  const dataEdit = data.Location;

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [floorLevelCode, setFloorLevelCode] = useState([]);

  const [dataCategory, setDataCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const CategoryName = selectCategory ? selectCategory.CatName : '';
  const CategoryCode = selectCategory ? selectCategory.CatCode : '';

  const [IconName, setIconName] = useState(dataEdit.IconId);
  const [IconLocY, setIconLocY] = useState(dataEdit.IconLocationY);
  const [IconLocX, setIconLocX] = useState(dataEdit.IconLocationX);
  const [IconUrl, setIconUrl] = useState(dataEdit.url);

  const [isSuspend, setIsSuspend] = useState(dataEdit.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  // DATA CATEGORY
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await getCategory('Location');
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataCategory(filterRes);

        const selectData = res.filter((item) => item.CatCode === dataEdit.LocCatCode);
        setSelectCategory(selectData[0]);
      } catch (error) {
        console.log(error);
      }
    }
    if (open) {
      fetchCategory();
    }
  }, [dataEdit.LocCatCode, open]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("CategoryName", CategoryName);
    }
  }, [CategoryName]);

  useEffect(() => {
    if (open) {
      const fetchFloorLevel = async () => {
        try {
          const response = await getFloor(dataEdit.FacilityCode, dataEdit.BuildingCode);
          const filterRes = response.filter((item) => item.FloorLevel.IsSuspend !== true);
          setFloorLevelCode(filterRes);
        } catch (error) {
          console.log(error);
        }
      }
      if (dataEdit.FacilityCode && dataEdit.BuildingCode) {
        fetchFloorLevel()
      }
    }
  }, [dataEdit.FacilityCode, open, dataEdit.BuildingCode]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(dataEdit.IsSuspend)
    setLoading(false);
    setOpen(false);
  };


  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        MapCode: getFloorLevelMapCode,
        LocCatCode: CategoryCode,
        IconId: IconName,
        IconLocationY: `${IconLocY}`,
        IconLocationX: `${IconLocX}`,
        IsSuspend: isSuspend,
        url: IconUrl,
      };
      const response = await updateLocation(dataEdit.FacilityCode, dataEdit.LocationCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(dataEdit.FacilityCode && dataEdit.LocationCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const getFloorLevelMapCode = floorLevelCode.filter((item) =>
    item.FloorLevel.FloorLevelCode === dataEdit.FloorLevel).map((item) =>
      item.FloorLevel.MapCode).join('');

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={dataEdit}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({

            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="LOCATION"
                    subtitle="Edit Data a Facility"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Facility Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.FacilityName}
                  name="FacilityCode"
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: true
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Building Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.BuildingName}
                  name="BuildingCode"
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: true
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Floor Level"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={data.FloorLevelName}
                  name="FloorLevel"
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: true
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Location Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LocationCode}
                  name="LocationCode"
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: true
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Location Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LocationName}
                  name="LocationName"
                  error={!!touched.LocationName && !!errors.LocationName}
                  helperText={touched.LocationName && errors.LocationName}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    readOnly: isSuspend
                  }}
                />

                <InputModal
                  title="CATEGORY"
                  label="Category Name"
                  name="CategoryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.CategoryName}
                  error={!!touched.CategoryName && !!errors.CategoryName}
                  helperText={touched.CategoryName && errors.CategoryName}
                  dataSource={dataCategory}
                  columns={columnsCategory}
                  propsSelecteRow={(values) => setSelectCategory(values)}
                  // propsOpenModal={(values) => setOpenModalCategory(values)}
                  LinktoCreate={`/master/category/form`}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                  inputProps={{
                    readOnly: isSuspend
                  }}
                />

                <MapIcon
                  propsFacilityCode={dataEdit.FacilityCode}
                  propsMapCode={getFloorLevelMapCode}
                  propsIconName={(values) => setIconName(values)}
                  propsIconLocY={(values) => setIconLocY(values)}
                  propsIconLocX={(values) => setIconLocX(values)}
                  propsIconUrl={(values) => setIconUrl(values)}
                  propsDataEdit={dataEdit}
                />

              </StyleForm>

            </Modal>
          )}
        </Formik>
      </Box>
    </Box >
  );
};

const checkoutSchema = yup.object().shape({
  LocationName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Location Name."),
  LocCatCode: yup
    .string()
    .required("Please complete this Category."),
});

export default EditLocation;



const columnsCategory = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Category Code',
    dataIndex: 'CatCode',
    sorter: (a, b) => a.CatCode.localeCompare(b.CatCode),
  },
  {
    title: 'Category Name',
    dataIndex: 'CatName',
    sorter: (a, b) => a.CatName.localeCompare(b.CatName),
  },
  {
    title: 'Module ID',
    dataIndex: 'ModuleID',
    sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
  },
];