import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { tokens } from "../../../theme";

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = ({ index }) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}



const TabMeter = ({ onInputChange, editData }) => {

    // console.log(editData);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const mapToNumber = (value) => {
        switch (value) {
            case 'runtime':
                return 0;
            default:
                return 0; // Return -1 for unknown values or handle as needed
        }
    };

    const [value, setValue] = useState(!editData ? 0 : mapToNumber(editData.PeriodeType));

    const [RuntimeValue, setRuntimeValue] = useState(!editData ? '' : editData.OccursEvery);


    const resetForm = () => {
        setRuntimeValue('');
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        resetForm();
    };

    const handleScheduleData = (time, event, newValue) => {
        if (time === 'current') {
            setRuntimeValue(event.target.value);
            onInputChange('runtime', event.target.value);
        }
    }

    return (
        <Box
            sx={{
                boxShadow: "0 0 10px #00000026",
                padding: "10px 0",
                display: "flex",
                borderRadius: "8px",
                alignItems: "center",
                marginTop: "20px",
                flexDirection: isNonMobile ? "row" : "column",
                "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    "&.Mui-focused fieldset": {
                        borderColor: colors.grey[900],
                    },
                },
            }}
        >
            <Tabs
                orientation={isMobile ? "horizontal" : "vertical"}
                variant={isMobile ? "scrollable" : "standard"}
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                    borderRight: isMobile ? 0 : 1,
                    borderBottom: isMobile ? 1 : 0,
                    borderColor: "divider",
                    width: isMobile ? "100%" : "auto",
                }}
            >
                <Tab label="Runtime" {...a11yProps({ index: 0 })} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {/* Konten untuk tab Runtime */}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        maxWidth: isMobile ? "100%" : "100%"
                    }}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        label="Occurs Every"
                        name="OccursEvery"
                        inputProps={{ min: 0 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">Run Time Hours</InputAdornment>
                            ),
                        }}
                        value={RuntimeValue}
                        onChange={(e) => handleScheduleData('current', e)}
                    />
                </Box>
            </TabPanel>

        </Box>
    );
}

export default TabMeter;
