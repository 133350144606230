import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
      grey: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
      },
      primary: {
        100: "#FFFFFF",
        200: "#a1a4ab",
        300: "#727681",
        400: "#1F2A40",
        500: "#141b2d",
        600: "#101624",
        700: "#0c101b",
        800: "#080b12",
        900: "#040509",
      },
      greenAccent: {
        100: "#dbf5ee",
        200: "#b7ebde",
        300: "#94e2cd",
        400: "#70d8bd",
        500: "#4cceac",
        600: "#3da58a",
        700: "#2e7c67",
        800: "#1e5245",
        900: "#0f2922",
      },
      redAccent: {
        100: "#f8dcdb",
        200: "#f1b9b7",
        300: "#e99592",
        400: "#e2726e",
        500: "#db4f4a",
        600: "#af3f3b",
        700: "#832f2c",
        800: "#58201e",
        900: "#2c100f",
      },
      blueAccent: {
        100: "#cfcfe5",
        200: "#9f9fcc",
        300: "#6f70b2",
        400: "#0f107f",
        500: "#3f4099",
        600: "#0c0d66",
        700: "#090a4c",
        800: "#060633",
        900: "#030319",
      },
    }
    : {
      grey: {
        100: "#ffffff",
        200: "#f9fbfc",
        300: "#f6f8fa",
        400: "#f3f6f9",
        500: "#f0f4f7",
        600: "#c0c3c6",
        700: "#909294",
        800: "#606263",
        900: "#303131"
      },
      primary: {
        100: "#cecee7",
        200: "#9d9dcf",
        300: "#6c6db8",
        400: "#3b3ca0",
        500: "#0a0b88",
        600: "#08096d",
        700: "#060752",
        800: "#040436",
        900: "#02021b"
      },
      greenAccent: {
        100: "#dbefdc",
        200: "#b7dfb9",
        300: "#94cf96",
        400: "#70bf73",
        500: "#4caf50",
        600: "#3d8c40",
        700: "#2e6930",
        800: "#1e4620",
        900: "#0f2310"
      },
      redAccent: {
        100: "#2c100f",
        200: "#58201e",
        300: "#832f2c",
        400: "#af3f3b",
        500: "#db4f4a",
        600: "#e2726e",
        700: "#e99592",
        800: "#f1b9b7",
        900: "#f8dcdb",
      },
      blueAccent: {
        100: "#d3eafd",
        200: "#a6d5fa",
        300: "#7ac0f8",
        400: "#4dabf5",
        500: "#2196f3",
        600: "#1a78c2",
        700: "#145a92",
        800: "#0d3c61",
        900: "#071e31"
      },
    }),
});



// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.blueAccent[500],
          },
          error: {
            main: colors.redAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          netral: {
            main: colors.greenAccent[500]
          },
          background: {
            default: colors.primary[500],
          },
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.grey[700],
          },
          error: {
            main: colors.redAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          netral: {
            main: colors.greenAccent[500]
          },
          background: {
            default: colors.grey[500],
            // default: "#EEF2F6",
          },
        }),
    },
    typography: {
      // fontFamily: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      h1: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 40,
      },
      h2: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 32,
      },
      h3: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 24,
      },
      h4: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
      },
      h5: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 16,
      },
      h6: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 14,
      },
    }

  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
