import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ButtonForm } from "../../global/components/ButtonAction";
import { onlyNumber } from "../../global/Components";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getType, getVehicle } from "../../../Api/Master/GetData";
import { postVehicle } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { getEmployee } from "../../../Api/Employee/GetData";

const FormVehicle = () => {

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(false);

  const [vehicleCode, setVehicleCode] = useState("");

  const [purchaseDate, setPurchaseDate] = useState(null);
  const [warrantyExpDate, setWarrantyExpDate] = useState(null);

  const [Type, setType] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [OpenedType, setOpenedType] = useState(false);

  const [dataVehicleOwner, setDataVehicleOwner] = useState([]);
  const [selectVehicleOwner, setSelectVehicleOwner] = useState("");
  const [openModalVehicleOwner, setOpenModalVehicleOwner] = useState("");
  const VehicleOwnerCode = selectVehicleOwner ? selectVehicleOwner.Employee.EmpCode : '';
  const VehicleOwnerName = selectVehicleOwner ? selectVehicleOwner.Employee.EmpName : '';

  useEffect(() => {
    const fetchDataType = async () => {
      try {
        const response = await getType('Vehicle');
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setType(filterRes)
      } catch (error) {
        console.log(error);
      }
    }
    if (OpenedType) {
      fetchDataType();
      setOpenedType(false)
      setIsLoading(false);
    }
  }, [OpenedType]);


  // EMPLOYEE
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const res = await getEmployee();
        const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
        setDataVehicleOwner(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalVehicleOwner) {
      fetchEmployee();
      setIsLoading(false);
      setOpenModalVehicleOwner(false);
    }
  }, [openModalVehicleOwner]);


  const handleTypeSelection = (event, value) => {
    if (value) {
      setSelectedType(value.TypeCode);
    } else {
      selectedType(null);
    }
  }

  const FetchVehicleCode = async () => {
    try {
      const response = await getVehicle();
      if (response.length > 0) {
        const VEData = response.filter(item => item.VehicleCode && item.VehicleCode.startsWith('VE'));
        if (VEData.length > 0) {
          const lastCode = VEData[VEData.length - 1].VehicleCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setVehicleCode(`VE${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setVehicleCode("VE0001");
        }
      } else {
        setVehicleCode("VE0001");
      }
    } catch (error) {
      setVehicleCode("VE0001");
      console.log(error);
    }
  };

  useEffect(() => {
    FetchVehicleCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("VehicleCode", vehicleCode);
      formRef.current.setFieldValue("VehicleOwner", VehicleOwnerName);
    }
  }, [VehicleOwnerName, vehicleCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      const purchaseDateNow = purchaseDate.format('YYYY-MM-DD');
      const warrantyExpDateNow = warrantyExpDate.format('YYYY-MM-DD');

      const modifiedValues = {
        ...values,
        VehicleCode: vehicleCode,
        PurchaseDate: purchaseDateNow,
        WarrantyExpDate: warrantyExpDateNow,
        VehicleOwner: VehicleOwnerCode,
        ModelTypeCode: selectedType,
      };
      // console.log(modifiedValues);
      const response = await postVehicle(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/vehicle");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.statusMessage) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="VEHICLE" subtitle="Create a Vehicle" />

            <StyleForm>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Vehicle Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e)
                  setVehicleCode(e.target.value)
                }}
                value={vehicleCode}
                name="VehicleCode"
                InputProps={{
                  maxLength: 6,
                }}
                error={!!touched.VehicleCode && !!errors.VehicleCode}
                helperText={touched.VehicleCode && errors.VehicleCode}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Vehicle Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VehicleName}
                name="VehicleName"
                error={!!touched.VehicleName && !!errors.VehicleName}
                helperText={touched.VehicleName && errors.VehicleName}
                sx={{ gridColumn: "span 2" }}
                autoFocus
              />

              <Autocomplete
                fullWidth
                disablePortal
                onOpen={() => setOpenedType(true)}
                loading={isLoading}
                options={Type}
                getOptionLabel={(option) => option.TypeName}
                sx={{ gridColumn: "span 2" }}
                onChange={(event, value) => {
                  handleTypeSelection(event, value);
                  setFieldValue("ModelTypeCode", value ? value.TypeName : "");
                }}
                value={
                  Type.find((option) => option.TypeCode === selectedType) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Model Type Name*"
                    onBlur={handleBlur}
                    error={!!touched.ModelTypeCode && !!errors.ModelTypeCode}
                    helperText={touched.ModelTypeCode && errors.ModelTypeCode}
                  />
                )}
                noOptionsText={
                  Type.length === 0 ?
                    <Link to="/master/type/form?moduleid=Vehicle" target="_blank" style={{ textDecoration: 'none' }}>
                      there is no option, please fill in the type vehicle data
                    </Link>
                    : "No options"
                }
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="No Pol*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.NoPol}
                name="NoPol"
                error={!!touched.NoPol && !!errors.NoPol}
                helperText={touched.NoPol && errors.NoPol}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Asset No*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.AssetNo}
                name="AssetNo"
                error={!!touched.AssetNo && !!errors.AssetNo}
                helperText={touched.AssetNo && errors.AssetNo}
                sx={{ gridColumn: "span 2" }}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Purchase Date*"
                  value={purchaseDate}
                  onBlur={handleBlur}
                  onChange={(date) => setPurchaseDate(date)}
                  name="PurchaseDate"
                  error={!!touched.PurchaseDate && !!errors.PurchaseDate}
                  helperText={touched.PurchaseDate && errors.PurchaseDate}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Warranty Exp Date*"
                  value={warrantyExpDate}
                  onBlur={handleBlur}
                  onChange={(date) => setWarrantyExpDate(date)}
                  name="WarrantyExpDate"
                  error={!!touched.WarrantyExpDate && !!errors.WarrantyExpDate}
                  helperText={touched.WarrantyExpDate && errors.WarrantyExpDate}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Meter Reading*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.MeterReading}
                name="MeterReading"
                error={!!touched.MeterReading && !!errors.MeterReading}
                helperText={touched.MeterReading && errors.MeterReading}
                sx={{ gridColumn: "span 2" }}
                onKeyPress={handleKeyPress}
              />

              <InputModal
                title="EMPLOYEE"
                label="Vehicle Owner"
                name="VehicleOwner"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.VehicleOwner}
                error={!!touched.VehicleOwner && !!errors.VehicleOwner}
                helperText={touched.VehicleOwner && errors.VehicleOwner}
                dataSource={dataVehicleOwner}
                columns={columnsVehicleOwner}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectVehicleOwner(values)}
                propsOpenModal={(values) => setOpenModalVehicleOwner(values)}
                LinktoCreate={`/employee/employee/form`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />

            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />

        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  VehicleCode: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Vehicle Code"),
  VehicleName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Name"),
  ModelTypeCode: yup
    .string()
    .required("Please complete this Model Type Code"),
  NoPol: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this No Pol"),
  AssetNo: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Asset No"),
  MeterReading: yup
    .string()
    .required("Please complete this Meter Reading"),
  VehicleOwner: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Owner"),
});
const initialValues = {
  VehicleCode: "",
  VehicleName: "",
  ModelTypeCode: "",
  NoPol: "",
  AssetNo: "",
  MeterReading: "",
  VehicleOwner: "",
  Description: ""
};

export default FormVehicle;


export const columnsVehicleOwner = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Employee Name',
    dataIndex: ['Employee', 'EmpName'],
    sorter: (a, b) => a.Employee.EmpName.localeCompare(b.Employee.EmpName),
  },
  {
    title: 'Address',
    dataIndex: ['Employee', 'Address'],
    width: 400,
    sorter: (a, b) => a.Employee.Address.localeCompare(b.Employee.Address),
  },
  {
    title: 'Phone Number 1',
    dataIndex: ['Employee', 'Phone1'],
    sorter: (a, b) => a.Employee.Phone1.localeCompare(b.Employee.Phone1),
  },
  {
    title: 'Phone Number 2',
    dataIndex: ['Employee', 'Phone2'],
    sorter: (a, b) => a.Employee.Phone2.localeCompare(b.Employee.Phone2),
  },
  {
    title: 'NIK',
    dataIndex: ['Employee', 'NIK'],
    sorter: (a, b) => a.Employee.NIK.localeCompare(b.Employee.NIK),
  },
  {
    title: 'Division Name',
    dataIndex: 'DivisionName',
    sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
  },
  {
    title: 'Department Name',
    dataIndex: 'DepName',
    sorter: (a, b) => a.DepName.localeCompare(b.DepName),
  },
  {
    title: 'Section Name',
    dataIndex: 'SectionName',
    sorter: (a, b) => a.SectionName.localeCompare(b.SectionName),
  },
  {
    title: 'IsTechnician',
    dataIndex: ['Employee', 'IsTechnician'],
    sorter: (a, b) => a.Employee.IsTechnician.localeCompare(b.Employee.IsTechnician),
    render: (isTechnician) => (
      <span>
        {isTechnician ? 'Technician' : 'Not a technician'}
      </span>
    ),
  }
];
