import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import UploadImage from "../../global/components/UploadImage";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateIcon } from "../../../Api/Master/UpdateData";


const EditIcons = ({ data, onEdit }) => {

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  // const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSuspend, setIsSuspend] = useState(data.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };
  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     setImageUrl(reader.result);
  //   };

  //   reader.readAsDataURL(file);
  //   setSelectedFile(event.target.files[0]);
  // };

  // const clearImage = () => {
  //   setImageUrl(null);
  // }


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(data.IsSuspend)
    setOpen(false);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);

    // if (!selectedFile) {
    //   enqueueSnackbar("Please upload an image.", { variant: "error" });
    //   setLoading(false);
    //   return;
    // }

    try {
      const modifiedValues = {
        ...values,
        imgs: selectedFile,
        IsSuspend: isSuspend,
      };

      const response = await updateIcon(data.IconId, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(values.IconId);
      setOpen(false);

    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>

      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>


      <Box>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="ICON"
                    subtitle="Edit Data a Icon"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                {/* Upload Image */}
                <UploadImage files={(files) => setSelectedFile(files)} images={`${process.env.REACT_APP_BASEURL_IMG}/${data.Image}`} />

                {/* <div style={{
                    gridColumn: "span 4",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}>
                    <Typography variant="h3">Upload Image</Typography>
                    <label htmlFor="upload-image">
                      <img
                        src={!imageUrl ? `${process.env.REACT_APP_BASEURL_IMG}/${data.Image}` : imageUrl}
                        alt="Uploaded"
                        height="200"
                        width="100%"
                      />
                    </label>
                    <div style={{
                      display: "flex",
                      gap: "10px"
                    }}>
                      <label htmlFor="upload-image">
                        <Button variant="contained" component="span" color="primary" startIcon={<Upload />}>
                          Upload Image
                        </Button>
                        <Field
                          id="upload-image"
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={handleFileUpload}
                          name="image"
                        />
                      </label>
                      {imageUrl &&
                        <Button variant="contained" onClick={clearImage} color="error" startIcon={<DeleteIcon />}>
                          Delete
                        </Button>
                      }
                    </div>
                  </div> */}

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Icon Id*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.IconId}
                  name="IconId"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Icon Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.IconName}
                  name="IconName"
                  error={!!touched.IconName && !!errors.IconName}
                  helperText={touched.IconName && errors.IconName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                  multiline
                  rows={4}
                />
              </StyleForm>

            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  IconName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Icon Name."),
});

export default EditIcons;
