import { Box, Typography } from '@mui/material';
import { Modal, Table } from 'antd';
import Header from '../../../components/Header';
import SearchData from '../../global/components/FeatureTable/SearchData';
import { ButtonUpdate } from '../../global/components/ButtonAction';
import { Link } from 'react-router-dom';

const TextInputModal = ({
    open,
    title,
    subtitle,
    handleClose,
    handleSubmit,
    loading,
    filterValue,
    handleFilterChange,
    rowSelection,
    columns,
    filteredRows,
    setData,
    setDataApp,
    Linkto,
    LinktoApp
}) => {

    const isDataEmpty = (filterValue ? filteredRows : setData).length === 0;

    return (
        <Box>
            <Modal
                title={
                    <Header
                        title={title}
                        subtitle={subtitle}
                    />
                }
                centered
                open={open}
                closable={false}
                style={{
                    maxWidth: "90%",
                    minWidth: "80%",
                }}
                bodyStyle={{
                    maxHeight: "65vh",
                    overflow: "auto",
                }}
                footer={
                    <ButtonUpdate
                        handleClose={handleClose}
                        handleSubmit={handleSubmit}
                        loading={loading}
                    />
                }
            >
                {isDataEmpty ? (
                    <Box sx={{ textAlign: 'center', padding: '16px' }}>
                        {setDataApp ? (
                            setDataApp.length === 0 ? (
                                <>
                                    <Typography variant="body1" color="textSecondary">
                                        No data available.
                                    </Typography>
                                    <Link
                                        to={Linkto}
                                        target="_blank"
                                        onClick={handleClose}
                                        syle={{ marginTop: '12px', display: 'inline-block' }}
                                    >
                                        Click to create in the {title} data.
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1" color="textSecondary">
                                        No data available.
                                    </Typography>
                                    <Link
                                        to={LinktoApp}
                                        target="_blank"
                                        onClick={handleClose}
                                        syle={{ marginTop: '12px', display: 'inline-block' }}
                                    >
                                        click to Approval {title} data.
                                    </Link>
                                </>
                            )
                        ) : (
                            <>
                                <Typography variant="body1" color="textSecondary">
                                    No data available.
                                </Typography>
                                <Link
                                    to={Linkto}
                                    target="_blank"
                                    onClick={handleClose}
                                    syle={{ marginTop: '12px', display: 'inline-block' }}
                                >
                                    Click to create in the {title} data.
                                </Link>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box>
                        <SearchData
                            filterValue={filterValue}
                            handleFilterChange={handleFilterChange}
                        />

                        <Table
                            rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                            }}
                            columns={columns}
                            dataSource={filterValue ? filteredRows : setData}
                            pagination={{
                                showSizeChanger: true,
                                defaultPageSize: 10,
                            }}
                            scroll={{ x: 1500 }}
                        />
                    </Box>
                )
                }
            </Modal >
        </Box >
    );
};

export default TextInputModal;
