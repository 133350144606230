import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import TableTran from "./tableTran";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateUserGroup } from "../../../Api/Configuration/UpdateData";

const EditUserGroup = ({ data, onEdit }) => {

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [tranData, setTranData] = useState([]);

  const [isSuspend, setIsSuspend] = useState(data.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    formRef.current.resetForm();
  };

  const handleSaveData = (selectedData) => {
    setTranData(selectedData);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      // if (tranData.length === 0) {
      //   enqueueSnackbar("Tran Authority cannot be empty!", { variant: "warning" });
      //   setLoading(false);
      //   return;
      // }

      const TranIdx = tranData.map((item) => item.TranIdx).join(',');

      const TranAdd = tranData.filter((item) => item.Add).map((item) => item.TranIdx).join(',');
      const TranEdit = tranData.filter((item) => item.Edit).map((item) => item.TranIdx).join(',');
      const TranDelete = tranData.filter((item) => item.Delete).map((item) => item.TranIdx).join(',');
      const TranPrint = tranData.filter((item) => item.Print).map((item) => item.TranIdx).join(',');
      const TranProcess = tranData.filter((item) => item.Process).map((item) => item.TranIdx).join(',');


      const modifiedValues = {
        ...values,
        GroupTran: TranIdx,
        AddAuthority: TranAdd,
        EditAuthority: TranEdit,
        DelAuthority: TranDelete,
        PrnAuthority: TranPrint,
        PrsAuthority: TranProcess,
        IsSuspend: isSuspend,
      };

      const response = await updateUserGroup(data.KodeCabang, data.GroupCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(values.GroupCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>


      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="USER GROUP"
                    subtitle="Edit Data a User"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="KodeCabang"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.KodeCabang}
                  name="KodeCabang"
                  error={!!touched.KodeCabang && !!errors.KodeCabang}
                  helperText={touched.KodeCabang && errors.KodeCabang}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Group Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.GroupCode}
                  name="GroupCode"
                  error={!!touched.GroupCode && !!errors.GroupCode}
                  helperText={touched.GroupCode && errors.GroupCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="GroupName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.GroupName}
                  name="GroupName"
                  error={!!touched.GroupName && !!errors.GroupName}
                  helperText={touched.GroupName && errors.GroupName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  error={!!touched.Description && !!errors.Description}
                  helperText={touched.Description && errors.Description}
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                {open ? (

                  <Box sx={{ gridColumn: "span 4" }}>
                    <TableTran
                      onSaveData={handleSaveData}
                      groupTran={data}
                    // selectUserGroup={data.GroupCode}
                    />
                  </Box>

                ) : null}

              </StyleForm>

            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  KodeCabang: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Kode Cabang."),
  GroupName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Group Name."),
});

export default EditUserGroup;
