import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


// MASTER DATA
export const updateTran = async (TranCode, TranData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/tran?TranIdx=${TranCode}`, TranData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateFacility = async (FacilityCode, FacilityData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/facility?FacilityCode=${FacilityCode}`, FacilityData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateBuilding = async (FacilityCode, BuildingCode, BuildingData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/building?FacilityCode=${FacilityCode}&BuildingCode=${BuildingCode}`, BuildingData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateFloorLevel = async (FacilityCode, FloorLevelCode, FloorLevelData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/floor?FacilityCode=${FacilityCode}&FloorLevelCode=${FloorLevelCode}`, FloorLevelData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateType = async (ModuleID, TypeCode, TypeData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/type?ModuleID=${ModuleID}&TypeCode=${TypeCode}`, TypeData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateEquipmentModel = async (ModelCode, EquipmentModelData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/equipmentmodel?ModelCode=${ModelCode}`, EquipmentModelData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    });
    return response;
}

export const updatePart = async (PartCode, PartData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/part?PartCode=${PartCode}`, PartData, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response;
}

export const updateEquipment = async (FacilityCode, EquipmentCode, EquipmentData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/equipment?FacilityCode=${FacilityCode}&EquipmentCode=${EquipmentCode}`, EquipmentData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateCategory = async (ModuleID, CategoryCode, CateogryData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/category?ModuleID=${ModuleID}&CatCode=${CategoryCode}`, CateogryData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}


export const updateSubCategory = async (ModuleID, CategoryCode, SubCategoryCode, CateogryData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/subcategory?ModuleID=${ModuleID}&CatCode=${CategoryCode}&SubCatCode=${SubCategoryCode}`, CateogryData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateIcon = async (IconId, IconData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/Icon?IconId=${IconId}`, IconData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    });
    return response;
}

export const updateSchedule = async (ScheduleCode, ScheduleData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/schedule?ScheduleCode=${ScheduleCode}`, ScheduleData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateSensor = async (SensorCode, SensorData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/sensor?SensorCode=${SensorCode}`, SensorData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateMaps = async (FacilityCode, MapCode, MapData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/maps?FacilityCode=${FacilityCode}&MapCode=${MapCode}`, MapData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    });
    return response;
}

export const updateLocation = async (FacilityCode, LocationCode, LocationData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/location?FacilityCode=${FacilityCode}&LocationCode=${LocationCode}`, LocationData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateVehicle = async (VehicleCode, VehicleData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/vehicle?VehicleCode=${VehicleCode}`, VehicleData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateVendor = async (VendorCode, VendorData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/vendor?VendorCode=${VendorCode}`, VendorData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

