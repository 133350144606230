import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { getComplineRate } from '../API';

const ComplineRate = () => {
    const [dataSource, setDataSource] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getComplineRate();
                setDataSource(res[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    const data = dataSource ? [
        ["Type", "Value"],
        ["OnTime", dataSource.OnTime],
        ["Failed", dataSource.Failed]
    ] : [
        ["Type", "Value"],
        ["OnTime", 0],
        ["Failed", 0]
    ];

    const options = {
        is3D: true,
        sliceVisibilityThreshold: 0.02,
        legend: {
            position: "bottom",
            alignment: "center",
            textStyle: {
                color: "#233238",
                fontSize: 12,
            },
        },
        pieSliceText: "value",
    };

    return (
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width="100%"
            height="260px"
        />
    );
};

export default ComplineRate;
