import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditFloor from "./edit";
import DeleteFloor from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getFloor } from "../../../Api/Master/GetData";

const Floor = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getFloor();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Floor Level Code',
      dataIndex: ['FloorLevel', 'FloorLevelCode'],
      width: 200,
      fixed: 'left',
      sorter: (a, b) => a.FloorLevel.FloorLevelCode.localeCompare(b.FloorLevel.FloorLevelCode),
    },
    {
      title: 'Floor Level Name',
      dataIndex: ['FloorLevel', 'FloorLevelName'],
      sorter: (a, b) => a.FloorLevel.FloorLevelName.localeCompare(b.FloorLevel.FloorLevelName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'Building Name',
      dataIndex: 'BuildingName',
      sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
      title: 'Map Name',
      dataIndex: 'MapName',
      sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    },
    {
      title: 'Suspend',
      dataIndex: ['FloorLevel', 'IsSuspend'],
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (_, record) => (
        <Box display="flex" justifyContent="center">
          {myEditAuthIdx && (
            <EditFloor data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.FloorLevel.IsSuspend && (
            <DeleteFloor data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    })
  }

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      // Sesuaikan dengan kolom yang ingin Anda filter
      return (
        row.FacilityName.toLowerCase().includes(value.toLowerCase()) ||
        row.BuildingName.toLowerCase().includes(value.toLowerCase()) ||
        row.FloorLevel.FloorLevelCode.toLowerCase().includes(value.toLowerCase()) ||
        row.FloorLevel.FloorLevelName.toLowerCase().includes(value.toLowerCase()) ||
        row.MapName.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: ["FloorLevel", "key"] },
    { title: "FacilityName", key: "FacilityName" },
    { title: "BuildingName", key: "BuildingName" },
    { title: "FloorLevelCode", key: ["FloorLevel", "FloorLevelCode"] },
    { title: "FloorLevelName", key: ["FloorLevel", "FloorLevelName"] },
    { title: "MapName", key: "MapName" },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="FLOOR LEVEL" subtitle="All Floor Level Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="Floor Level"
            />
          )}
          {myAddAuthIdx && (
            <ButtonAddNew
              link="/master/floorlevel/form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.FloorLevel.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.FloorLevel.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 1500 }}
      />

    </StyleContent>
  );
};

export default Floor;