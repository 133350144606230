import { Box, Button, Typography } from '@mui/material'
import { Field } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'

const UploadImage = ({ files, images }) => {

  const [imageUrl, setImageUrl] = useState(!images ? null : images);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ['png', 'jpg', 'jpeg'];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
        files(file);
      } else {
        enqueueSnackbar('The uploaded file must be an image with the extension .png, .jpg, or .jpeg', { variant: 'error' });
        event.target.value = null;
        files(null);
      }
    }
  };

  const clearImage = () => {
    if (!images) {
      setImageUrl(null);
    } else {
      setImageUrl(images);
    }
  }

  return (
    <Box
      sx={{
        gridColumn: "span 4",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Typography variant="h3">Upload Image</Typography>
      <label htmlFor="upload-image">
        <img
          src={!imageUrl ? "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png" : imageUrl}
          alt="Uploaded"
          height="200"
          width="100%"
        />
      </label>
      <div style={{
        display: "flex",
        gap: "10px"
      }}>
        <label htmlFor="upload-image">
          <Button variant="contained" component="span" color="primary">
            Upload Image
          </Button>
          <Field
            id="upload-image"
            hidden
            accept="image/*"
            type="file"
            onChange={handleFileUpload}
            name="image"
          />
        </label>
        {imageUrl &&
          <Button variant="contained" onClick={clearImage} color="error">
            Delete
          </Button>
        }
      </div>
    </Box>
  )
}

export default UploadImage