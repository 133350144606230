import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";

export const customLocale = {
    emptyText: (
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>
                    No Data
                </span>
            }
        >
            <Button color="secondary" variant="contained">Create Now</Button>
        </Empty>
    ),
};

export const customExpandIcon = ({ expanded, onExpand, record }) => {
    if (record.Description) {
        return expanded ? (
            <DownOutlined
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
            />
        ) : (
            <RightOutlined
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
            />
        );
    }
    return null;
};