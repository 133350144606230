import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getEquipmentVehicle, getIcon, getSensor, getType } from "../../../Api/Master/GetData";
import { postSensor } from "../../../Api/Master/PostData";

const FormSensor = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [OpenedType, setOpenedType] = useState(false);

  const [IconId, setIconId] = useState([]);
  const [selectedIconId, setSelectedIconId] = useState("");
  const [OpenedIconId, setOpenedIconId] = useState(false);

  const [objectCode, setObjectCode] = useState([]);
  const [selectedObjectCode, setSelectedObjectCode] = useState("");
  const [OpenedObjectCode, setOpenedObjectCode] = useState(false);

  const [SensorCode, setSensorCode] = useState("");

  useEffect(() => {
    const fetchDataType = async () => {
      setIsLoading(true);
      try {
        const response = await getType("Sensor");
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setType(filterRes);
      } catch (error) {
        setType([]);
      }
      setIsLoading(false);
    };
    if (OpenedType) {
      fetchDataType();
      setOpenedType(false);
    }
  }, [OpenedType]);

  useEffect(() => {
    const fetchDataIconId = async () => {
      setIsLoading(true);
      try {
        const response = await getIcon();
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setIconId(filterRes);
      } catch (error) {
        setIconId([]);
      }
      setIsLoading(false);
    };
    if (OpenedIconId) {
      fetchDataIconId();
      setOpenedIconId(false);
    }
  }, [OpenedIconId]);

  useEffect(() => {
    const fetchDataObjectCode = async () => {
      setIsLoading(true);
      try {
        const response = await getEquipmentVehicle();
        setObjectCode(response);
      } catch (error) {
        setObjectCode([]);
      }
      setIsLoading(false);
    };
    if (OpenedObjectCode) {
      fetchDataObjectCode();
      setOpenedObjectCode(false);
    }
  }, [OpenedObjectCode]);

  const handleTypeSelection = (event, value) => {
    if (value) {
      setSelectedType(value.TypeCode);
    } else {
      setSelectedType("");
    }
  };

  const handleIconIdSelection = (event, value) => {
    if (value) {
      setSelectedIconId(value.IconId);
    } else {
      setSelectedIconId("");
    }
  };

  const handleObjectCodeSelection = (event, value) => {
    if (value) {
      setSelectedObjectCode(value.SensorCode);
    } else {
      setSelectedObjectCode("");
    }
  };

  const FetchSensorCode = async () => {
    try {
      const response = await getSensor();
      if (response.length > 0) {
        const SRData = response.filter(
          (item) => item.SensorCode && item.SensorCode.startsWith("SR")
        );
        if (SRData.length > 0) {
          const lastCode = SRData[SRData.length - 1].SensorCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setSensorCode(`SR${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setSensorCode("SR0001");
        }
      } else {
        setSensorCode("SR0001");
      }
    } catch (error) {
      setSensorCode("SR0001");
      console.log(error);
    }
  };

  useEffect(() => {
    FetchSensorCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("SensorCode", SensorCode);
    }
  }, [SensorCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        SensorCode: SensorCode,
        SensorTypeCode: selectedType,
        WarningIconId: selectedIconId,
        ObjectCode: selectedObjectCode,
      };
      const response = await postSensor(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/Sensor");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, SensorCode: SensorCode }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header title="SENSOR" subtitle="Create a data Sensor" />
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setSensorCode(e.target.value);
                  }}
                  value={SensorCode}
                  name="SensorCode"
                  error={!!touched.SensorCode && !!errors.SensorCode}
                  helperText={touched.SensorCode && errors.SensorCode}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 6 }}
                />

                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorName}
                  name="SensorName"
                  error={!!touched.SensorName && !!errors.SensorName}
                  helperText={touched.SensorName && errors.SensorName}
                  sx={{ gridColumn: "span 2" }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenedType(true)}
                  loading={isLoading}
                  options={type}
                  getOptionLabel={(option) => option.TypeName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleTypeSelection(event, value);
                    setFieldValue(
                      "SensorTypeCode",
                      value ? value.TypeName : ""
                    );
                  }}
                  value={
                    type.find((option) => option.TypeCode === selectedType) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sensor Type Name*"
                      onBlur={handleBlur}
                      error={
                        !!touched.SensorTypeCode && !!errors.SensorTypeCode
                      }
                      helperText={
                        touched.SensorTypeCode && errors.SensorTypeCode
                      }
                    />
                  )}
                  noOptionsText={
                    type.length === 0 ? (
                      <Link
                        to="/master/type/form?moduleid=Sensor"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        there is no option, please fill in the Type Sensor data
                      </Link>
                    ) : (
                      "No options"
                    )
                  }
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenedIconId(true)}
                  loading={isLoading}
                  options={IconId}
                  getOptionLabel={(option) => option.IconName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleIconIdSelection(event, value);
                    setFieldValue("WarningIconId", value ? value.IconName : "");
                  }}
                  value={
                    IconId.find((option) => option.IconId === selectedIconId) ||
                    null
                  }
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`${process.env.REACT_APP_BASEURL_IMG}/${option.Image} 2x`}
                        src={`${process.env.REACT_APP_BASEURL_IMG}/${option.Image}`}
                        alt="icon"
                      />
                      {option.IconName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Warning Icon Id*"
                      onBlur={handleBlur}
                      error={!!touched.WarningIconId && !!errors.WarningIconId}
                      helperText={touched.WarningIconId && errors.WarningIconId}
                    />
                  )}
                  noOptionsText={
                    IconId.length === 0 ? (
                      <Link
                        to="/master/icon/form"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        there is no option, please fill in the Icon data
                      </Link>
                    ) : (
                      "No options"
                    )
                  }
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Lower Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LLimit}
                  name="LLimit"
                  error={!!touched.LLimit && !!errors.LLimit}
                  helperText={touched.LLimit && errors.LLimit}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Upper Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ULimit}
                  name="ULimit"
                  error={!!touched.ULimit && !!errors.ULimit}
                  helperText={touched.ULimit && errors.ULimit}
                  sx={{ gridColumn: "span 2" }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  onOpen={() => setOpenedObjectCode(true)}
                  loading={isLoading}
                  options={objectCode}
                  getOptionLabel={(option) => option.SensorName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleObjectCodeSelection(event, value);
                    setFieldValue("ObjectCode", value ? value.SensorName : "");
                  }}
                  value={
                    objectCode.find(
                      (option) => option.SensorCode === selectedObjectCode
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Object Code*"
                      onBlur={handleBlur}
                      error={!!touched.ObjectCode && !!errors.ObjectCode}
                      helperText={touched.ObjectCode && errors.ObjectCode}
                    />
                  )}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  SensorCode: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Sensor Code."),
  SensorName: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Sensor Name."),
  SensorTypeCode: yup
    .string()
    .required("Please complete this Sensor Type Code."),
  WarningIconId: yup
    .string()
    .required("Please complete this Icon Id."),
  LLimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this LLimit."),
  ULimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ULimit."),
  ObjectCode: yup
    .string()
    .required("Please complete this Object Code."),
});
const initialValues = {
  SensorCode: "",
  SensorName: "",
  SensorTypeCode: "",
  WarningIconId: "",
  LLimit: "",
  ULimit: "",
  ObjectCode: "",
  Description: "",
};

export default FormSensor;
