import * as yup from "yup";
import { useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Signup, reset } from "../../features/authSlice";
import { enqueueSnackbar } from "notistack";
import { Registration } from "../api";

/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const SignupForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      if (values.Password !== values.ConfPassword) {
        enqueueSnackbar("Password do not match!", { variant: "error" });
        setLoading(false);
        return;
      }

      const modifiedValues = {
        UserName: values.Username,
        Email: values.Email,
        UserPwd: values.Password,
        CfmPwd: values.ConfPassword,
      };

      console.log(modifiedValues);
      await Registration(modifiedValues);
      enqueueSnackbar("User data Registration successfully.", { variant: "success" });
      navigate("/");
      setLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.statusMessage;
      if (error.response && error.response.data && error.response.data) {
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const initialValues = {
    Username: "",
    Email: "",
    Password: "",
    ConfPassword: ""
  };

  const checkoutSchema = yup.object().shape({
    Username: yup
      .string()
      .max(50, "Maximum of 50 characters allowed")
      .required("Please complete this Username."),
    Email: yup
      .string()
      .email('Invalid e-mail format')
      .max(50, "Maximum of 50 characters allowed")
      .required("Please complete this Address Email."),
    Password: yup
      .string()
      .min(8, "Minimum of 8 characters allowed")
      .max(50, "Maximum of 50 characters allowed")
      .required("Please complete this Password."),
    ConfPassword: yup
      .string()
      .min(8, "Minimum of 8 characters allowed")
      .max(50, "Maximum of 50 characters allowed")
      .required("Please complete this Confirm Password."),
  });


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box>
          {/* {isError && enqueueSnackbar(message, { variant: 'error' })} */}
          <Box
            component={motion.div}
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
              component={motion.div}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Username}
                name="Username"
                error={!!touched.Username && !!errors.Username}
                helperText={touched.Username && errors.Username}
                autoFocus
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Address Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Email}
                name="Email"
                error={!!touched.Email && !!errors.Email}
                helperText={touched.Email && errors.Email}
              />

              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Password}
                name="Password"
                error={!!touched.Password && !!errors.Password}
                helperText={touched.Password && errors.Password}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? (
                          <Icon icon="eva:eye-fill" />
                        ) : (
                          <Icon icon="eva:eye-off-fill" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

              />

              <TextField
                fullWidth
                variant="outlined"
                type={showConfPassword ? "text" : "password"}
                label="Confirm Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ConfPassword}
                name="ConfPassword"
                error={!!touched.ConfPassword && !!errors.ConfPassword}
                helperText={touched.ConfPassword && errors.ConfPassword}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfPassword((prev) => !prev)}
                      >
                        {showConfPassword ? (
                          <Icon icon="eva:eye-fill" />
                        ) : (
                          <Icon icon="eva:eye-off-fill" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

              />
            </Box>

            <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                onClick={handleSubmit}
              >
                {loading ? "Loading..." : "Sign Up"}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default SignupForm;
