import Buildings from "../pages/MasterData/Buildings";
import Facility from "../pages/MasterData/Facility";
import Map from "../pages/MasterData/Map";
import Floor from "../pages/MasterData/Floor";
import IconMenu from "../pages/MasterData/IconMenu";
import Category from "../pages/MasterData/Category";
import SubCategory from "../pages/MasterData/SubCategory";
import Location from "../pages/MasterData/Location";
import Part from "../pages/MasterData/Part";
import Type from "../pages/MasterData/Type";
import EquipmentModel from "../pages/MasterData/EquipmentModel";
import Equipment from "../pages/MasterData/Equipment";
import Vehicle from "../pages/MasterData/Vehicle";
import Schedule from "../pages/MasterData/Schedule";
import Vendor from "../pages/MasterData/Vendor";
import Sensor from "../pages/MasterData/Sensor";
import Division from "../pages/Employee/Division";
import Department from "../pages/Employee/Department";
import Section from "../pages/Employee/Section";
import Employee from "../pages/Employee/Employee";
import User from "../pages/Configuration/User";
import UserGroup from "../pages/Configuration/UserGroup";
import TranApprover from "../pages/Configuration/TranApprover";
import TranApproval from "../pages/Transaction/TranApproval";
import WORequest from "../pages/Transaction/WORequest";
import WOTransaction from "../pages/Transaction/WOTransaction";
import DMTransaction from "../pages/Transaction/DMTransaction";
import PMTransaction from "../pages/Transaction/PMTransaction";
import MPTransaction from "../pages/Transaction/MPTransaction";
import AdjustmentTransaction from "../pages/Transaction/Adjustment";
import EquipmentHistory from "../pages/Report/EquipmentHistory";
import PMSchedule from "../pages/Report/PMSchedule";
import VehicleHistory from "../pages/Report/VehicleHistory";
import LocationHistory from "../pages/Report/LocationHistory";
import WORequestStatus from "../pages/Report/WORequestStatus";
import WorkOrderStatus from "../pages/Report/WorkOrderStatus";
import DemandStatus from "../pages/Report/DemandStatus";
import PreventivStatus from "../pages/Report/PreventiveStatus";
import MPTransactionStatus from "../pages/Report/MPTransactionStatus";

export const ViewTran = {

    // MASTER DATA
    1101: <Facility />,
    1102: <Buildings />,
    1103: <Map />,
    1104: <Floor />,
    1105: <IconMenu />,
    1106: <Category />,
    1107: <SubCategory />,
    1108: <Location />,
    1109: <Part />,
    1110: <Type />,
    1111: <EquipmentModel />,
    1112: <Equipment />,
    1113: <Vehicle />,
    1114: <Schedule />,
    1115: <Vendor />,
    1116: <Sensor />,


    // EMPLOYEE
    1201: <Division />,
    1202: <Department />,
    1203: <Section />,
    1204: <Employee />,


    // CONFIGURATION
    1301: < UserGroup />,
    1302: < User />,
    1303: < TranApprover />,


    // TRANSACTION
    1401: < TranApproval />,
    1402: < WORequest />,
    1403: < WOTransaction />,
    1404: < DMTransaction />,
    1405: < PMTransaction />,
    1406: < MPTransaction />,
    1407: < AdjustmentTransaction />,


    // REPORT
    1501: < EquipmentHistory />,
    1502: < VehicleHistory />,
    1503: < LocationHistory />,
    1504: < PMSchedule />,
    1505: < WORequestStatus />,
    1506: < WorkOrderStatus />,
    1507: < DemandStatus />,
    1508: < PreventivStatus />,
    1509: < MPTransactionStatus />,

}