import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getIcon, getMap } from "../../../Api/Master/GetData";

const MapIcon = ({ propsMapCode, propsIconName, propsIconLocY, propsIconLocX, propsIconUrl, propsDataEdit }) => {

    const [MapCode, setMapCode] = useState([]);
    const [availableIcons, setAvailableIcons] = useState([]);
    const [imageDimensions, setImageDimensions] = useState({ width: null, height: null });
    const [IconId, setIconId] = useState(!propsDataEdit ? null : propsDataEdit.IconId);

    const getLocationMap = MapCode.filter((item) => item.Map.MapCode === propsMapCode).map((item) => item.Map.Image);
    const locationMap = `${process.env.REACT_APP_BASEURL_IMG}/${getLocationMap}`;

    const fetchCode = useCallback(async () => {
        try {
            if (propsMapCode) {
                const resMap = await getMap();
                setMapCode(resMap);

                const resIcon = await getIcon();
                setAvailableIcons(resIcon);
            }
        } catch (error) {
            console.log();
        }
    }, [propsMapCode]);

    useEffect(() => {
        fetchCode();
    }, [fetchCode]);

    const handleDragStart = (event, iconLink, iconId) => {
        event.dataTransfer.setData("text/plain", iconLink);
        setIconId(iconId.IconId);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const iconLink = event.dataTransfer.getData("text/plain");

        const existingIcons = event.target.querySelectorAll("img");
        existingIcons.forEach((icon) => {
            icon.parentNode.removeChild(icon);
        });

        const x = event.clientX - event.target.getBoundingClientRect().left - 10;
        const y = event.clientY - event.target.getBoundingClientRect().top - 10;

        const newIcon = document.createElement("img");
        newIcon.src = iconLink;
        newIcon.alt = "Dropped Icon";
        newIcon.width = 30;
        newIcon.style.position = "absolute";
        newIcon.style.top = `${y}px`;
        newIcon.style.left = `${x}px`;

        event.target.appendChild(newIcon);

        propsIconName(IconId)
        propsIconLocY(y)
        propsIconLocX(x)
        propsIconUrl(iconLink)
    };

    useEffect(() => {
        const image = new Image();
        image.src = locationMap;

        image.onload = () => {
            const width = image.width;
            const height = image.height;
            setImageDimensions({ width, height });
        };
    }, [locationMap]);

    useEffect(() => {
        if (propsMapCode && propsDataEdit && imageDimensions.width && imageDimensions.height) {

            const defaultIcon = document.createElement("img");
            defaultIcon.src = propsDataEdit.url;
            defaultIcon.alt = "Default Icon";
            defaultIcon.width = 30;
            defaultIcon.style.position = "absolute";
            defaultIcon.style.top = `${propsDataEdit.IconLocationY}px`;
            defaultIcon.style.left = `${propsDataEdit.IconLocationX}px`;

            const mapContainer = document.getElementById("map-container");
            mapContainer.appendChild(defaultIcon);

            // propsIconName(propsDataEdit.IconId)
            // propsIconLocY(propsDataEdit.IconLocationY)
            // propsIconLocX(propsDataEdit.IconLocationX)
            // propsIconUrl(propsDataEdit.url)
        }
    }, [propsMapCode, imageDimensions, propsDataEdit]);

    return (
        <>
            {propsMapCode === "" ? null :
                <Box
                    sx={{
                        gridColumn: "span 4",
                        backgroundColor: "#ffffff",
                        width: "100%",
                        height: "100%",
                        boxShadow: "0px 0px 15px #00000016",
                        p: 4,
                        borderRadius: "8px",
                    }}>
                    <Typography
                        variant="h2"
                        fontWeight="bold"
                        sx={{ mb: "10px" }}
                    >
                        drag n drop icon to map
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            p: 1,
                            borderRadius: "10px",
                            border: "1px solid #00000026",
                        }}
                    >
                        {availableIcons.map((item, itemI) => (
                            <div
                                key={itemI}
                                className="img"
                                draggable
                                onDragStart={(event) => handleDragStart(event, `${process.env.REACT_APP_BASEURL_IMG}/${item.Image}`, item)}
                            >
                                <img src={`${process.env.REACT_APP_BASEURL_IMG}/${item.Image}`} alt="icon" width={30} />
                            </div>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            mt: 1,
                            borderRadius: "8px",
                            border: "1px solid #00000026",
                            p: 2,
                            width: imageDimensions.width,
                            aspectRatio: imageDimensions.width && imageDimensions.height ? `${imageDimensions.width} / ${imageDimensions.height}` : "auto",
                        }}
                    >
                        <Box
                            id="map-container"
                            sx={{
                                backgroundImage: `url(${locationMap})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 100%",
                                position: "relative",
                                width: "100%",
                                height: "100%",
                            }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            {/* Tambahkan elemen lain jika diperlukan */}
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
};

export default MapIcon;
