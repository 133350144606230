import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import Authentication from "../../auth";
import { Space, Table, Tabs } from "antd";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getMPMainTrasaction } from "../Api/GetData";
import DataMPRE from "./MP_RE";
import DataMPPart from "./MP_Part";
import DataMPDoc from "./MP_Doc";
import DataMPCT from "./MP_CT";
import EditMPTransaction from "./edit";
import DeleteMPTransaction from "./delete";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";

const RecordDetail = ({ children }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "8px",
                marginBottom: "14px",
                borderBottom: '2px solid #00000015',
                padding: "10px 0"
            }}
        >
            {children}
            {/* <Typography variant="h5" fontWeight={600}>Description : </Typography>
        <Typography variant="h6">{record.MPTransaction.Description}</Typography> */}
        </div>
    )
}

const MPTransaction = () => {

    Authentication();

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const [filterValue, setFilterValue] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getMPMainTrasaction();
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.MPTransaction.Description !== '' ?
                    <RecordDetail>
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography variant="h6">{record.MPTransaction.Description}</Typography>
                    </RecordDetail>
                    : ''
                }
                {record.MPTransaction.Comments !== '' ?
                    <RecordDetail>
                        <Typography variant="h5" fontWeight={600}>Comments : </Typography>
                        <Typography variant="h6">{record.MPTransaction.Comments}</Typography>
                    </RecordDetail>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Resource',
                            key: 'resource',
                            children: <DataMPRE record={record.MPTransaction} />,
                        },
                        {
                            label: 'Part',
                            key: 'Part',
                            children: <DataMPPart record={record.MPTransaction} />,
                        },
                        {
                            label: 'Document',
                            key: 'document',
                            children: <DataMPDoc record={record.MPTransaction} />,
                        },
                        {
                            label: 'Contact',
                            key: 'contact',
                            children: <DataMPCT record={record.MPTransaction} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            width: 200,
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'MPNumber',
            dataIndex: ['MPTransaction', 'MPNumber'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.MPNumber.localeCompare(b.MPTransaction.MPNumber),
        },
        {
            title: 'MPDate',
            dataIndex: ['MPTransaction', 'MPDate'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.MPDate.localeCompare(b.MPTransaction.MPDate),
        },
        {
            title: 'MPStatus',
            dataIndex: ['MPTransaction', 'MPStatus'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.MPStatus.localeCompare(b.MPTransaction.MPStatus),
        },
        {
            title: 'MPType',
            dataIndex: ['MPTransaction', 'MPType'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.MPType.localeCompare(b.MPTransaction.MPType),
        },
        {
            title: 'PMNumber',
            dataIndex: ['MPTransaction', 'PMNumber'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.PMNumber.localeCompare(b.MPTransaction.PMNumber),
        },
        {
            title: 'DueDate',
            dataIndex: ['MPTransaction', 'DueDate'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.DueDate.localeCompare(b.MPTransaction.DueDate),
        },
        {
            title: 'CurrentMeterReading',
            dataIndex: ['MPTransaction', 'CurrentMeterReading'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.CurrentMeterReading.localeCompare(b.MPTransaction.CurrentMeterReading),
        },
        {
            title: 'Start Schedule Date',
            dataIndex: ['MPTransaction', 'StartScheduleDate'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.StartScheduleDate.localeCompare(b.MPTransaction.StartScheduleDate),
        },
        {
            title: 'Finish Schedule Date',
            dataIndex: ['MPTransaction', 'FinishScheduleDate'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.FinishScheduleDate.localeCompare(b.MPTransaction.FinishScheduleDate),
        },
        {
            title: 'LocationName',
            dataIndex: 'LocationName',
            width: 200,
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
        },
        {
            title: 'WorkSubCatCode',
            dataIndex: ['MPTransaction', 'WorkSubCatCode'],
            width: 200,
            sorter: (a, b) => a.MPTransaction.WorkSubCatCode.localeCompare(b.MPTransaction.WorkSubCatCode),
        },
    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.MPTransaction.AppStatus === 'CANCEL' ||
                    record.MPTransaction.AppStatus === "VERIFIED" ||
                    record.MPTransaction.AppStatus === "APPROVED" ||
                    record.MPTransaction.AppStatus === "CONFIRMED"
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditMPTransaction data={record} onEdit={fetchData} />
                        )}
                        {myDeleteAuthIdx > 0 && (
                            <DeleteMPTransaction data={record.MPTransaction} onDelete={fetchData} />
                        )}
                    </Space>
                )
            },
        },
        )
    }

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);

        const filteredData = data.filter((row) => {
            // Sesuaikan dengan kolom yang ingin Anda filter
            return (
                row.FacilityName.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.MPNumber.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.MPDate.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.MPStatus.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.MPType.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.PMNumber.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.DueDate.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.RescheduleDate.toLowerCase().includes(value.toLowerCase()) ||
                row.LocationName.toLowerCase().includes(value.toLowerCase()) ||
                row.MPTransaction.WorkSubCatCode.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
    };

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = [
        { title: "key", key: "key" },
        { title: "FacilityName", key: "FacilityName" },
        { title: "MPNumber", key: ["MPTransaction", "MPNumber"] },
        { title: "MPDate", key: ["MPTransaction", "MPDate"] },
        { title: "MPStatus", key: ["MPTransaction", "MPStatus"] },
        { title: "MPType", key: ["MPTransaction", "MPType"] },
        { title: "PMNumber", key: ["MPTransaction", "PMNumber"] },
        { title: "DueDate", key: ["MPTransaction", "DueDate"] },
        { title: "RescheduleDate", key: ["MPTransaction", "RescheduleDate"] },
        { title: "LocationName", key: "LocationName" },
        { title: "WorkSubCatCode", key: ["MPTransaction", "WorkSubCatCode"] },
    ];

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header
                    title="MP TRANSACTION"
                    subtitle="All MP Transaction"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={selectedRows.length > 0 ? selectedRows : data}
                            columns={columnsExport}
                            name="MP Transaction"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="/transaction/mptransaction/form"
                        />
                    )}
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={filterValue}
                handleFilterChange={handleFilterChange}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filterValue ? filteredRows : data}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender,
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 1000 }}
            />

        </StyleContent>
    );
};

export default MPTransaction