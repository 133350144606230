import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import Authentication from "../../auth";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getPMSchedule } from "../../../Api/Report/GetData";
import dayjs from "dayjs";



const dataSource = [
  {
    FacilityName: "Citra Xperience Kemayoran",
    PMNumber: "PM001",
    RequestBy: "Dimas",
    PMType: "Location",
    PMTypeRef: "Living Room",
    ScheduleType: "Calender",
    PMScheduleStart: "23-10-2024",
    PMScheduleEnd: "25-10-2025",
    DayToComplete: "2",
    EstimasiCost: "200000",
    Status: "Pending",
  },
  {
    FacilityName: "Citra Xperience Kemayoran",
    PMNumber: "PM002",
    RequestBy: "Raka",
    PMType: "Equipment",
    PMTypeRef: "AC",
    ScheduleType: "Calender",
    PMScheduleStart: "13-10-2024",
    PMScheduleEnd: "15-10-2025",
    DayToComplete: "1",
    EstimasiCost: "150000",
    Status: "Process",
  },
]

const PreventivStatus = () => {

  Authentication();

  const data = dataSource.map((row, index) => ({ ...row, key: index + 1 }));

  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await getPMSchedule();
  //     setData(response);
  //   } catch (error) {
  //     console.log(error.response.data);
  //   }
  //   setIsLoading(false)
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);


  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'FacilityName',
      dataIndex: 'FacilityName',
      fixed: 'left',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'PMNumber',
      dataIndex: 'PMNumber',
      sorter: (a, b) => a.PMNumber.localeCompare(b.PMNumber),
    },
    {
      title: 'RequestBy',
      dataIndex: 'RequestBy',
      sorter: (a, b) => a.RequestBy.localeCompare(b.RequestBy),
    },
    {
      title: 'PMType',
      dataIndex: 'PMType',
      sorter: (a, b) => a.PMType.localeCompare(b.PMType),
    },
    {
      title: 'PMTypeRef',
      dataIndex: 'PMTypeRef',
      sorter: (a, b) => a.PMTypeRef.localeCompare(b.PMTypeRef),
    },
    {
      title: 'ScheduleType',
      dataIndex: 'ScheduleType',
      sorter: (a, b) => a.ScheduleType.localeCompare(b.ScheduleType),
    },
    {
      title: 'PMScheduleStart',
      dataIndex: 'PMScheduleStart',
      sorter: (a, b) => a.PMScheduleStart.localeCompare(b.PMScheduleStart),
    },
    {
      title: 'PMScheduleEnd',
      dataIndex: 'PMScheduleEnd',
      sorter: (a, b) => a.PMScheduleEnd.localeCompare(b.PMScheduleEnd),
    },
    {
      title: 'DayToComplete',
      dataIndex: 'DayToComplete',
      sorter: (a, b) => a.DayToComplete.localeCompare(b.DayToComplete),
    },
    {
      title: 'EstimasiCost',
      dataIndex: 'EstimasiCost',
      sorter: (a, b) => a.EstimasiCost.localeCompare(b.EstimasiCost),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="PREVENRIVE STATUS"
          subtitle="All Preventive Status"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 1800 }}
      />

    </StyleContent>
  );
};

export default PreventivStatus