import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getMe } from "../features/authSlice";
import Cookies from "js-cookie";

const Authentication = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);

    useEffect(() => {
        const token = Cookies.get('accessToken');
        if (token) {
            dispatch(getMe(token));
        } else {
            navigate("/");
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);

}

export default Authentication