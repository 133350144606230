import { useState, useEffect } from "react";

export const DateTimeNow = () => {

    const date = new Date().toISOString().substring(0, 10);

    const [time, setTime] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const waktu = new Date();
            const jam = waktu.getHours();
            const menit = waktu.getMinutes();
            const detik = waktu.getSeconds();
            const waktuFormat = `${jam < 10 ? '0' + jam : jam}:${menit < 10 ? '0' + menit : menit}:${detik < 10 ? '0' + detik : detik}`;
            setTime(waktuFormat);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return `${date}T${time}.000Z`;
};


export const TimeNow = () => {

    const [time, setTime] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const waktu = new Date();
            const jam = waktu.getHours();
            const menit = waktu.getMinutes();
            const detik = waktu.getSeconds();
            const waktuFormat = `${jam < 10 ? '0' + jam : jam}${menit < 10 ? '0' + menit : menit}${detik < 10 ? '0' + detik : detik}`;
            setTime(waktuFormat);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return time;
};

export const onlyNumber = /^[0-9]*$/;

export const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

