import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


export const deleteDivision = async (DivisionCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/division?DivisionCode=${DivisionCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteDepartment = async (DivisionCode, DepartmentCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/department?DivisionCode=${DivisionCode}&DepCode=${DepartmentCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteSection = async (DivisionCode, DepartmentCode, SectionCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/section?DivisionCode=${DivisionCode}&DepCode=${DepartmentCode}&SectionCode=${SectionCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteEmployee = async (EmployeeCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/employee?EmployeeCode=${EmployeeCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}