import React from "react";
import { Switch } from "antd";
import { Typography } from "@mui/material";

const SwitchComponent = ({ isSuspend, handleSwitchChange }) => {

    return (
        <div style={{ display: "flex", gap: 6 }}>
            <Typography variant="h6">Suspend</Typography>
            <Switch
                checkedChildren="Suspend"
                unCheckedChildren="Unsuspend"
                checked={isSuspend}
                onChange={handleSwitchChange}
            />
        </div>
    );
};

export default SwitchComponent;
