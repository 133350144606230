import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditSensor from "./edit";
import DeleteSensor from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getSensor } from "../../../Api/Master/GetData";

const Sensor = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getSensor();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Sensor Code',
      dataIndex: 'SensorCode',
      sorter: (a, b) => a.SensorCode.localeCompare(b.SensorCode),
    },
    {
      title: 'Sensor Name',
      dataIndex: 'SensorName',
      sorter: (a, b) => a.SensorName.localeCompare(b.SensorName),
    },
    {
      title: 'Sensor Type Code',
      dataIndex: 'SensorTypeCode',
      sorter: (a, b) => a.SensorTypeCode.localeCompare(b.SensorTypeCode),
    },
    {
      title: 'Warning Icon Id',
      dataIndex: 'WarningIconId',
      sorter: (a, b) => a.WarningIconId.localeCompare(b.WarningIconId),
    },
    {
      title: 'Lower Limit',
      dataIndex: 'LLimit',
      sorter: (a, b) => a.LLimit.localeCompare(b.LLimit),
    },
    {
      title: 'Uper Limit',
      dataIndex: 'ULimit',
      sorter: (a, b) => a.ULimit.localeCompare(b.ULimit),
    },
    {
      title: 'Object Code',
      dataIndex: 'ObjectCode',
      sorter: (a, b) => a.ObjectCode.localeCompare(b.ObjectCode),
    },
    {
      key: 'IsSuspend',
      title: 'Suspend',
      dataIndex: 'IsSuspend',
      width: 100,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Box display="flex" justifyContent="center" >
          {myEditAuthIdx && (
            <EditSensor data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteSensor data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    })
  }

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      // Sesuaikan dengan kolom yang ingin Anda filter
      return (
        row.SensorCode.toLowerCase().includes(value.toLowerCase()) ||
        row.SensorCode.toLowerCase().includes(value.toLowerCase()) ||
        row.SensorCode.toLowerCase().includes(value.toLowerCase()) ||
        row.SensorCode.toLowerCase().includes(value.toLowerCase()) ||
        row.SensorCode.toLowerCase().includes(value.toLowerCase()) ||
        row.SensorCode.toLowerCase().includes(value.toLowerCase()) ||
        row.SensorCode.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "SensorCode", key: "SensorCode" },
    { title: "SensorName", key: "SensorName" },
    { title: "SensorTypeCode", key: "SensorTypeCode" },
    { title: "WarningIconId", key: "WarningIconId" },
    { title: "LLimit", key: "LLimit" },
    { title: "ULimit", key: "ULimit" },
    { title: "ObjectCode", key: "ObjectCode" },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="SENSOR" subtitle="All Sensor Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="Sensor"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="/master/Sensor/form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData filterValue={filterValue} handleFilterChange={handleFilterChange} />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 1500 }}
      />

    </StyleContent>
  );
};

export default Sensor;
