import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL;

export const getUsers = async () => {
    const response = await axios.get(`${baseUrl}/users`);
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const Registration = async (userData) => {
    const response = await axios.post(`${baseUrl}/user/register`, userData);
    return response;

}