import React, { useEffect, useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, InputNumber } from 'antd';

import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab"
import { Button } from '@mui/material';
import { getPMSchduleTrasaction } from '../../Api/GetData';
import { getSchedule } from '../../../../Api/Master/GetData';
import InputDetailModal from '../../../../components/Global/InputDetailModal';

// const { Option } = Select;
const { Title } = Typography;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    onData,
    onDataSchedule,
    onEdit,
    ...restProps
}) => {

    useEffect(() => {
        if (onEdit) {
            setOpenSchedule(true);
        }
    }, [onEdit]);


    const [isLoading, setIsLoading] = useState(true);

    const [dataSchedule, setDataSchedule] = useState([]);
    const [selectSchedule, setSelectSchedule] = useState("");
    const [openSchedule, setOpenSchedule] = useState(null);

    useEffect(() => {
        const fetchSchedule = async () => {
            try {

                const ScheduleCode = onData.map(item => item.ScheduleCode);
                
                const res = await getSchedule();
                const filter = res.filter(item => !ScheduleCode.includes(item.ScheduleCode));
                setDataSchedule(filter);

                if (onEdit) {
                    const selected = res.filter(item => item.ScheduleCode === record.ScheduleCode);
                    setSelectSchedule(selected[0]);
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (openSchedule) {
            fetchSchedule();
            setOpenSchedule(false);
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openSchedule]);

    useEffect(() => {
        if (selectSchedule) {
            onDataSchedule(selectSchedule);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectSchedule]);

    return (
        <td {...restProps}>
            {editing ? (
                <div>

                    {dataIndex === 'CurrentValue' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                        >
                            <InputNumber placeholder={title} min={1} style={{ width: '100%' }} />
                        </Form.Item>

                    ) : (

                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >

                            {dataIndex === 'ScheduleName' && editing ? (

                                // <Select
                                //     maxTagCount="responsive"
                                //     showSearch
                                //     placeholder={title}
                                //     onDropdownVisibleChange={fetchSchedule}
                                //     notFoundContent={
                                //         <Link to={`/master/schedule/form?scheduletype=${ScheduleType}`} target="_blank" style={{ textDecoration: 'none' }}>There is no option, click to fill in schedule data</Link>
                                //     }
                                // >
                                //     {Schedule.map((item) => (
                                //         <Option key={item.key} value={item.ScheduleName}>
                                //             {item.ScheduleName}
                                //         </Option>
                                //     ))}
                                // </Select>

                                <InputDetailModal
                                    title="SCHEDULE"
                                    label="Schedule Type"
                                    name={dataIndex}
                                    dataSource={dataSchedule}
                                    loading={isLoading}
                                    columns={columnsSchedule}
                                    onData={(values) => setSelectSchedule(values)}
                                    onOpenModal={(values) => setOpenSchedule(values)}
                                    onDetail={true}
                                />

                            ) : (

                                <Input placeholder={title} />
                            )}
                        </Form.Item>
                    )}

                </div>
            ) : (
                children
            )
            }
        </td >
    );
};


const FormPMSC = ({ onSaveData, FacilityCode, PMNumber, ScheduleType, onEdit, onApproval }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [dataSchedule, setDataSchedule] = useState(null);

    // console.log(dataSchedule);

    useEffect(() => {
        if (form && dataSchedule) {
            form.setFieldsValue(
                {
                    ScheduleName: dataSchedule.ScheduleName
                }
            )
        }
    }, [dataSchedule, form]);


    useEffect(() => {
        if (onEdit || onApproval) {
            const fetchData = async () => {
                try {
                    const response = await getPMSchduleTrasaction(FacilityCode, PMNumber);
                    const filter = response.map((row, index) => ({
                        ...row, key: index + 1
                    })).reverse()
                    setData(filter)
                    setCount(filter.length === 0 ? 0 : filter.map((item) => item.key)[0])
                    onSaveData(filter)
                } catch (error) {
                    if (!onApproval) {
                        setData([]);
                        setCount(0);
                        onSaveData([]);
                        console.log(error);
                    }
                }
            }
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FacilityCode, PMNumber]);

    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        const scheduleData = record.ScheduleName ? record.ScheduleName : [];

        form.setFieldsValue({
            Description: '',
            ...record,
            ScheduleName: scheduleData,
        });
        setEditingKey(record.key);
    };



    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1, DetailNo: row.DetailNo - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.VendorCode) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();

            // Check if the ScheduleName already exists in the data
            const isDuplicate = data.some(
                (item) => item.key !== record.key && item.ScheduleName === row.ScheduleName
            );

            if (isDuplicate) {
                enqueueSnackbar("Schedule Name already exists!", { variant: "error" });
                return; // Stop saving if duplicate found
            }

            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);

            const ScheduleCode = dataSchedule.ScheduleCode;

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    ScheduleCode: ScheduleCode,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            } else {
                newData.push({
                    ...row,
                    ScheduleCode: ScheduleCode,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;

        if (editingKey) {
            enqueueSnackbar("Complete the input form !", { variant: "warning" });
            return; // Stop saving if duplicate found
        }

        const newData = {
            key: num,
            FacilityCode: FacilityCode,
            PMNumber: PMNumber,
            DetailNo: num,
            ScheduleCode: '',
            ScheduleName: '',
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {

            const newData = data.map(item => ({
                ...item,
                // ScheduleCode: Schedule.find(sch => sch.ScheduleName === item.ScheduleName)?.ScheduleCode,
            }));
            onSaveData(newData);
            console.log("PostData", newData);
            enqueueSnackbar("Success add form table data!!", { variant: "success" });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCancelAllData = () => {
        setData([]);
        setCount(0);
        onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Schedule Name',
            dataIndex: 'ScheduleName',
            editable: true,
        },
        {
            title: 'Current Value',
            dataIndex: 'CurrentValue',
            editable: true,
            hidden: ScheduleType === 'Calendar',
        },
    ];

    if (!onApproval) {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <DeleteOutlined style={{ fontSize: '18px' }} />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        },)
    }


    const filteredColumns = columns.filter(col => !col.hidden);

    const mergedColumns = filteredColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onData: data,
                onDataSchedule: (values) => setDataSchedule(values),
                onEdit: onEdit,
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 10px 10px"
                }}
            >
                <Title level={3} style={{ margin: "0" }}>
                    Schedule
                </Title>
                {!onApproval && (
                    <Button
                        onClick={handleAdd}
                        color="primary"
                        variant="contained"
                        disabled={!!editingKey || !FacilityCode || !PMNumber || !ScheduleType}
                    >
                        + Add Data
                    </Button>
                )}
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
            />
            {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                    >
                        <Button
                            type="button"
                            color="error"
                            variant="contained"
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <LoadingButton
                        color="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        variant="contained"
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </LoadingButton>

                </div>
            )}
        </Form>
    );
};

export default FormPMSC;


const columnsSchedule = [

    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Schedule Code',
        dataIndex: 'ScheduleCode',
        sorter: (a, b) => a.ScheduleCode.localeCompare(b.ScheduleCode),
    },
    {
        title: 'Schedule Name',
        dataIndex: 'ScheduleName',
        sorter: (a, b) => a.ScheduleName.localeCompare(b.ScheduleName),
    },
    {
        title: 'Periode Type',
        dataIndex: 'PeriodeType',
        sorter: (a, b) => a.PeriodeType.localeCompare(b.PeriodeType),
    },
    {
        title: 'Occurs Every',
        dataIndex: 'OccursEvery',
        sorter: (a, b) => a.OccursEvery - b.OccursEvery,
    },
    {
        title: 'Days',
        dataIndex: 'Days',
        // render: renderDays,
    },
    {
        title: 'Month',
        dataIndex: 'Month',
        // render: renderMonths,

    }
]
