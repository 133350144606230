import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { Autocomplete, Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ButtonUpdate } from "../../global/components/ButtonAction";
import { updateWORequest } from "../Api/UpdateData";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { getEquipment, getLocation, getVehicle } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";
import { columnsEquipment, columnsLocation, columnsRequestBy, columnsVehicle } from "./ColumnsWOReq";
import { getEmployee } from "../../../Api/Employee/GetData";

const EditWORequest = ({ data, onEdit }) => {

    const dataEdit = data.WORequest;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const [selectedWOType, setSelectedWOType] = useState(dataEdit.WOType);

    const [WORDate, setWORDate] = useState(dayjs(dataEdit.WORDate));

    const [isLoading, setIsLoading] = useState(true);

    const [dataRequestBy, setDataRequestBy] = useState([]);
    const [selectRequestBy, setSelectRequestBy] = useState("");
    const RequestByCode = selectRequestBy ? selectRequestBy.Employee.EmpCode : '';
    const RequestByName = selectRequestBy ? selectRequestBy.Employee.EmpName : '';


    const [dataWOTypeRef, setDataWOTypeRef] = useState([]);
    const [selectWOTypeRef, setSelectWOTypeRef] = useState("");
    const [WOTypeRefCode, setWOTypeRefCode] = useState("");
    const [WOTypeRefName, setWOTypeRefName] = useState("");
    const [LocationCode, setLocationCode] = useState("-");



    // EMPLOYEE || REQUEST BY
    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const res = await getEmployee();
                const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
                setDataRequestBy(filterRes);

                const selected = res.filter(item => item.Employee.EmpCode === dataEdit.RequestBy);
                setSelectRequestBy(selected[0]);

            } catch (error) {
                console.log(error);
            }
        }

        if (open) {
            fetchEmployee();
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);



    // WO TYPE REFERENCE
    useEffect(() => {
        const fetchWOTypeRef = async () => {
            try {
                switch (selectedWOType) {
                    case 'Location':
                        const resLoc = await getLocation(dataEdit.FacilityCode);
                        const filterResLoc = resLoc.filter((item) => item.Location.IsSuspend !== true);
                        setDataWOTypeRef(filterResLoc);

                        const selectedLoc = resLoc.filter(item => item.Location.LocationCode === dataEdit.WOTypeRefCode);
                        setSelectWOTypeRef(selectedLoc[0])

                        break;
                    case 'Equipment':
                        const resEquip = await getEquipment(dataEdit.FacilityCode);
                        const filterResEquip = resEquip.filter((item) => item.Equipment.IsSuspend !== true);
                        setDataWOTypeRef(filterResEquip);

                        const selectedEquip = resEquip.filter(item => item.Equipment.EquipmentCode === dataEdit.WOTypeRefCode)
                        setSelectWOTypeRef(selectedEquip[0])

                        break;
                    case 'Vehicle':
                        const resVehicle = await getVehicle();
                        const filterResVehicle = resVehicle.filter((item) => item.IsSuspend !== true);
                        setDataWOTypeRef(filterResVehicle);

                        const selectedVehicle = resVehicle.filter(item => item.VehicleCode === dataEdit.WOTypeRefCode);
                        setSelectWOTypeRef(selectedVehicle[0])

                        break;
                    default:
                        // Handle default case if necessary
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (open && dataEdit.FacilityCode) {
            fetchWOTypeRef();
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEdit.FacilityCode, open, selectedWOType]);


    useEffect(() => {
        if (selectWOTypeRef) {
            switch (selectedWOType) {
                case 'Location':
                    setWOTypeRefCode(selectWOTypeRef.Location.LocationCode);
                    setWOTypeRefName(selectWOTypeRef.Location.LocationName);
                    setLocationCode(selectWOTypeRef.Location.LocationCode);
                    break;
                case 'Equipment':
                    setWOTypeRefCode(selectWOTypeRef.Equipment.EquipmentCode);
                    setWOTypeRefName(selectWOTypeRef.Equipment.EquipmentName);
                    setLocationCode(selectWOTypeRef.Equipment.LocationCode);
                    break;
                case 'Vehicle':
                    setWOTypeRefCode(selectWOTypeRef.VehicleCode);
                    setWOTypeRefName(selectWOTypeRef.VehicleName);
                    break;
                default:
                    // Handle default case if necessary
                    break;
            }
        }
    }, [selectWOTypeRef, selectedWOType]);


    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("WOTypeRefName", WOTypeRefName);
            formRef.current.setFieldValue("RequestBy", RequestByName);
        }
    }, [RequestByName, WOTypeRefName]);


    const handleWOTypeSelection = (event, value) => {
        if (value) {
            setSelectedWOType(value.WOTypeName);
            setWOTypeRefCode([]);
            setSelectWOTypeRef("");
            setWOTypeRefCode("");
            setWOTypeRefName("");
            setLocationCode("");
        } else {
            setSelectedWOType(null)
        }
    };


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const WORDateNow = WORDate.format('YYYY-MM-DD');
            const modifiedValues = {
                ...values,
                WORDate: WORDateNow,
                WOTypeRefCode: WOTypeRefCode,
                LocationCode: LocationCode,
                RequestBy: RequestByCode,
                AppStatus: '',
            };
            // console.log(modifiedValues);
            const response = await updateWORequest(values.FacilityCode, values.WORNumber, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onEdit(values.FacilityCode && values.WORMNumber);
            setOpen(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error);
            formRef.current.resetForm();
            setOpen(false);
        }
        setLoading(false);
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    validationSchema={checkoutSchema}
                    innerRef={formRef}
                >

                    {({

                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="WO REQUEST"
                                        subtitle="Edit Data a WO Request"
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >

                            <StyleForm>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Facility Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={data.FacilityName}
                                    name="FacilityCode"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="WO Request Number*"
                                    onBlur={handleBlur}
                                    value={values.WORNumber}
                                    onChange={handleChange}
                                    name="WORNumber"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="WO Request Date*"
                                        value={WORDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setWORDate(date)}
                                        name="WORDate"
                                        error={!!touched.WORDate && !!errors.WORDate}
                                        helperText={touched.WORDate && errors.WORDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <InputModal
                                    title="EMPLOYEE"
                                    label="Request By"
                                    name="RequestBy"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    dataSource={dataRequestBy}
                                    columns={columnsRequestBy}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectRequestBy(values)}
                                    LinktoCreate={`/employee/employee/form`}
                                />

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={WOTypeData}
                                    getOptionLabel={(option) => option.WOTypeName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handleWOTypeSelection(event, value);
                                        setFieldValue("WOType", value ? value.WOTypeName : "");
                                    }}
                                    value={
                                        WOTypeData.find((option) => option.WOTypeName === selectedWOType) ||
                                        null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="WO Type*"
                                            onBlur={handleBlur}
                                            error={!!touched.WOType && !!errors.WOType}
                                            helperText={touched.WOType && errors.WOType}
                                        />
                                    )}
                                />

                                <InputModal
                                    title="WO TYPE REFERENCE"
                                    label="WO Type Reference"
                                    name="WOTypeRefName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WOTypeRefName}
                                    error={!!touched.WOTypeRefName && !!errors.WOTypeRefName}
                                    helperText={touched.WOTypeRefName && errors.WOTypeRefName}
                                    dataSource={dataWOTypeRef}
                                    columns={
                                        (selectedWOType === "Location" && columnsLocation) ||
                                        (selectedWOType === "Equipment" && columnsEquipment) ||
                                        (selectedWOType === "Vehicle" && columnsVehicle)
                                    }
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectWOTypeRef(values)}
                                    LinktoCreate={
                                        (selectedWOType === "Location" && `/master/location/form?faccode=${dataEdit.FacilityCode}`) ||
                                        (selectedWOType === "Equipment" && `/master/equipment/form?faccode=${dataEdit.FacilityCode}`) ||
                                        (selectedWOType === "Vehicle" && "/master/vehicle/form")
                                    }
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 4" }}
                                    multiline
                                    rows={4}
                                />

                            </StyleForm>

                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};



const checkoutSchema = yup.object().shape({
    RequestBy: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this Request By."),
    WOType: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Type."),
    WOTypeRefCode: yup
        .string()
        .max(30, "Maximum of 30 characters allowed")
        .required("Please complete this WO Type Ref Code."),
});

const WOTypeData = [
    {
        WOTypeName: "Location"
    },
    {
        WOTypeName: "Equipment"
    },
    {
        WOTypeName: "Vehicle"
    }
]

export default EditWORequest