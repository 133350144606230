import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getModuleID, getType } from "../../../Api/Master/GetData";
import { postType } from "../../../Api/Master/PostData";

const FormType = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsModuleId = searchParams.get('moduleid');

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [moduleID, setModuleID] = useState([]);
  const [selectedModuleID, setSelectedModuleID] = useState("");
  const [OpenModuleID, setOpenModuleID] = useState(false);

  const [typeCode, setTypeCode] = useState("");

  useEffect(() => {
    if (paramsModuleId) {
      setSelectedModuleID(paramsModuleId);
      setOpenModuleID(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("ModuleID", selectedModuleID);
    }
  }, [selectedModuleID]);

  useEffect(() => {

    const fetchModuleID = async () => {
      setIsLoading(true);
      try {
        const response = await getModuleID('type');
        setModuleID(response);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }

    if (OpenModuleID) {
      fetchModuleID()
      setOpenModuleID(false)
    }
  }, [OpenModuleID]);

  const fetchTypeCode = useCallback(async () => {
    try {
      const response = await getType(selectedModuleID);
      if (response.length > 0) {
        const TYData = response.filter(item => item.TypeCode.startsWith('TY'));
        if (TYData.length > 0) {
          const lastCode = TYData[TYData.length - 1].TypeCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setTypeCode(`TY${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setTypeCode("TY0001");
      console.log(error.response.statusText);
    }
  }, [selectedModuleID]);

  useEffect(() => {
    if (selectedModuleID) {
      fetchTypeCode();
    }
  }, [selectedModuleID, fetchTypeCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("TypeCode", typeCode);
    }
  }, [typeCode]);

  const handleModuleIDSelection = (event, value) => {
    if (value) {
      setSelectedModuleID(value.ModuleID);
    } else {
      setSelectedModuleID("");
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        ModuleID: selectedModuleID,
        TypeCode: typeCode,
      };

      const response = await postType(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/type");

    } catch (error) {
      if (error.response && error.response.data && error.response.data.statusMessage) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };


  return (

    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{ ...initialValues, ModuleID: selectedModuleID, TypeCode: typeCode }}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="FORM TYPE" subtitle="Create a Type" />

            <StyleForm>

              <Autocomplete
                fullWidth
                disablePortal
                onOpen={() => setOpenModuleID(true)}
                loading={isLoading}
                options={moduleID}
                getOptionLabel={(option) => option.ModuleID}
                sx={{ gridColumn: "span 2" }}
                onChange={(event, value) => {
                  handleModuleIDSelection(event, value);
                  setFieldValue("ModuleID", value ? value.ModuleID : "");
                }}
                value={moduleID.find((option) => option.ModuleID === selectedModuleID) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Module Id*"
                    onBlur={handleBlur}
                    error={!!touched.ModuleID && !!errors.ModuleID}
                    helperText={touched.ModuleID && errors.ModuleID}
                  />
                )}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Type Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setTypeCode(e.target.value);
                }}
                value={values.TypeCode}
                name="TypeCode"
                error={!!touched.TypeCode && !!errors.TypeCode}
                helperText={touched.TypeCode && errors.TypeCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 6 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Type Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.TypeName}
                name="TypeName"
                error={!!touched.TypeName && !!errors.TypeName}
                helperText={touched.TypeName && errors.TypeName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />
            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />

        </Box>
      )}
    </Formik>

  );
};

const checkoutSchema = yup.object().shape({
  ModuleID: yup
    .string()
    .required("Please complete this Module ID."),
  TypeCode: yup
    .string()
    .required("Please complete this Type Code."),
  TypeName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Type Name."),
});

const initialValues = {
  ModuleID: "",
  TypeCode: "",
  TypeName: "",
  Description: "",
};

export default FormType;
