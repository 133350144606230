import { useSidebarContext } from '../Sidebar/SidebarContext';
import { useSelector } from 'react-redux';

const AddAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const AddAuthIdx = user && user.AddAuthority.filter(item => item === authIdx).join(',')
    return AddAuthIdx
}

export default AddAuthorityIdx;