import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditUser from "./edit";
import DeleteUser from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import { Link } from "react-router-dom";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getUser } from "../../../Api/Configuration/GetData";

const User = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getUser();
      setData(response);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [showGroupTran, setShowGroupTran] = useState(false);
  const [showAddAut, setShowAddAut] = useState(false);
  const [showEditAut, setShowEditAut] = useState(false);
  const [showDeleteAuth, setShowDeleteAuth] = useState(false);
  const [showPrintAuth, setShowPrintAuth] = useState(false);
  const [showProcessAuth, setShowProcessAuth] = useState(false);
  const [showFacilityAuth, setShowFacilityAuth] = useState(false);
  const [showBuildingAuth, setShowBuildingAuth] = useState(false);


  const toggleShow = (key, type) => {
    if (type === 'GroupTran') {
      setShowGroupTran(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'AddAut') {
      setShowAddAut(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'EditAut') {
      setShowEditAut(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'DeleteAuth') {
      setShowDeleteAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'PrintAuth') {
      setShowPrintAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'ProcessAuth') {
      setShowProcessAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'FacilityAuth') {
      setShowFacilityAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    } else if (type === 'BuildingAuth') {
      setShowBuildingAuth(prevState => ({
        ...prevState,
        [key]: !prevState[key] || false,
      }));
    }
  };


  const renderItems = (text, record, items, type) => {
    const itemNames = text.split(',');
    const showAll = items[record.key];

    const visibleItems = showAll ? itemNames : itemNames.slice(0, 3);

    return (
      <div>
        {visibleItems.map((item, index) => (
          <p key={index}>{`- ${item.trim()}`}</p>
        ))}
        {itemNames.length > 3 && (
          <div>
            {!showAll ? (
              <Link onClick={() => toggleShow(record.key, type)}>show all..</Link>
            ) : (
              <Link onClick={() => toggleShow(record.key, type)}>hide..</Link>
            )}
          </div>
        )}
      </div>
    );
  };

  const GroupTran = (text, record) => renderItems(text, record, showGroupTran, 'GroupTran');
  const AddAut = (text, record) => renderItems(text, record, showAddAut, 'AddAut');
  const EditAut = (text, record) => renderItems(text, record, showEditAut, 'EditAut');
  const DeleteAuth = (text, record) => renderItems(text, record, showDeleteAuth, 'DeleteAuth');
  const PrintAuth = (text, record) => renderItems(text, record, showPrintAuth, 'PrintAuth');
  const ProcessAuth = (text, record) => renderItems(text, record, showProcessAuth, 'ProcessAuth');
  const FacilityAuth = (text, record) => renderItems(text, record, showFacilityAuth, 'FacilityAuth');
  const BuildingAuth = (text, record) => renderItems(text, record, showBuildingAuth, 'BuildingAuth');

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Ref AccountId',
      dataIndex: 'RefAccountId',
      sorter: (a, b) => a.RefAccountId.localeCompare(b.RefAccountId),
    },
    {
      title: 'User Name',
      dataIndex: 'UserName',
      sorter: (a, b) => a.UserName.localeCompare(b.UserName),
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: 'Group Tran',
      dataIndex: 'GroupTranNames',
      sorter: (a, b) => a.GroupTranNames.localeCompare(b.GroupTranNames),
      render: GroupTran,
    },
    {
      title: 'Add Authority',
      dataIndex: 'AddAuthorityNames',
      sorter: (a, b) => a.AddAuthorityNames.localeCompare(b.AddAuthorityNames),
      render: AddAut,
    },
    {
      title: 'Edit Authority',
      dataIndex: 'EditAuthorityNames',
      sorter: (a, b) => a.EditAuthorityNames.localeCompare(b.EditAuthorityNames),
      render: EditAut,
    },
    {
      title: 'Delete Authority',
      dataIndex: 'DelAuthorityNames',
      sorter: (a, b) => a.DelAuthorityNames.localeCompare(b.DelAuthorityNames),
      render: DeleteAuth,
    },
    {
      title: 'Print Authority',
      dataIndex: 'PrnAuthorityNames',
      sorter: (a, b) => a.PrnAuthorityNames.localeCompare(b.PrnAuthorityNames),
      render: PrintAuth,
    },
    {
      title: 'Process Authority',
      dataIndex: 'PrsAuthorityNames',
      sorter: (a, b) => a.PrsAuthorityNames.localeCompare(b.PrsAuthorityNames),
      render: ProcessAuth,
    },
    {
      title: 'Facility Authority',
      dataIndex: 'FacilityAuthorityNames',
      sorter: (a, b) => a.FacilityAuthorityNames.localeCompare(b.FacilityAuthorityNames),
      render: FacilityAuth,
    },
    {
      title: 'Building Authority',
      dataIndex: 'BuildingAuthorityNames',
      sorter: (a, b) => a.BuildingAuthorityNames.localeCompare(b.BuildingAuthorityNames),
      render: BuildingAuth,
    },
    {
      title: 'User Group',
      dataIndex: 'UserGroup',
      sorter: (a, b) => a.UserGroup.localeCompare(b.UserGroup),
    },

    {
      key: 'IsSuspend',
      title: 'Suspend',
      dataIndex: 'IsSuspend',
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center" >
          {myEditAuthIdx && (
            <EditUser data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteUser data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    })
  }

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {

      return (
        row.RefAccountId.toLowerCase().includes(value.toLowerCase()) ||
        row.UserName.toLowerCase().includes(value.toLowerCase()) ||
        row.Email.toLowerCase().includes(value.toLowerCase()) ||
        row.GroupTranNames.toLowerCase().includes(value.toLowerCase()) ||
        row.AddAuthorityNames.toLowerCase().includes(value.toLowerCase()) ||
        row.EditAuthorityNames.toLowerCase().includes(value.toLowerCase()) ||
        row.DelAuthorityNames.toLowerCase().includes(value.toLowerCase()) ||
        row.PrnAuthorityNames.toLowerCase().includes(value.toLowerCase()) ||
        row.PrsAuthorityNames.toLowerCase().includes(value.toLowerCase()) ||
        row.FacilityAuthorityNames.toLowerCase().includes(value.toLowerCase()) ||
        row.BuildingAuthorityNames.toLowerCase().includes(value.toLowerCase()) ||
        row.UserGroup.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "RefAccountId", key: "RefAccountId" },
    { title: "UserName", key: "UserName" },
    { title: "Email", key: "Email" },
    { title: "GroupTranNames", key: "GroupTranNames" },
    { title: "AddAuthorityNames", key: "AddAuthorityNames" },
    { title: "EditAuthorityNames", key: "EditAuthorityNames" },
    { title: "DelAuthorityNames", key: "DelAuthorityNames" },
    { title: "PrnAuthorityNames", key: "PrnAuthorityNames" },
    { title: "PrsAuthorityNames", key: "PrsAuthorityNames" },
    { title: "FacilityAuthorityNames", key: "FacilityAuthorityNames" },
    { title: "BuildingAuthorityNames", key: "BuildingAuthorityNames" },
    { title: "UserGroup", key: "UserGroup" },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="USER" subtitle="All Users Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="User"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="/configuration/user/form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{
          x: 2500,
        }}
      />

    </StyleContent>
  );
};

export default User;
