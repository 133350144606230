import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import Header from "../../../components/Header";
import BlockIcon from '@mui/icons-material/Block';
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { ButtonDelete } from "../../global/components/ButtonAction";

const DeleteUser = ({ data, onDelete }) => {

  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (user && user.RefAccountId === data.RefAccountId) {
        enqueueSnackbar("The user has been used, you cannot delete user data!!!", { variant: "warning" });
        setOpen(false);
      } else {
        const response = await DeleteUser(data.RefAccountId, data.UserName, data.Email);
        enqueueSnackbar(response.data.statusMessage, { variant: "success" });
        onDelete(data.RefAccountId);
        setOpen(false);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      setOpen(false);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>

      <Tooltip title="Suspend" fontSize="large">
        <IconButton onClick={handleOpen}>
          <BlockIcon />
        </IconButton>
      </Tooltip>

      <Modal
        title={
          <Header
            title="USER"
            subtitle="Suspend Data a AccountId"
          />
        }
        centered
        open={open}
        closable={false}
        footer={() => (
          <ButtonDelete
            handleClose={handleClose}
            handleDelete={handleDelete}
            loading={loading}
          />
        )}
      >

        <Box>
          <p>
            Are you sure you want to suspend data with this Ref Account Id {data.RefAccountId} with the Username {data.UserName} ?
          </p>
        </Box>

      </Modal>

    </Box>
  );
};

export default DeleteUser;
