import React, { useState, createContext, useContext, useEffect } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";
import Topbar from "../Topbar";

const SidebarContext = createContext({});

export const MyProSidebarProvider = ({ children }) => {
  const [sidebarRTL, setSidebarRTL] = useState(false);
  const [sidebarBackgroundColor, setSidebarBackgroundColor] =
    useState(undefined);
  const [sidebarImage, setSidebarImage] = useState(undefined);
  const [authIdx, setAuthIdx] = useState(() => {
    // Get initial value from localStorage if available
    const savedAuthIdx = localStorage.getItem("authIdx");
    return savedAuthIdx ? JSON.parse(savedAuthIdx) : "";
  });

  useEffect(() => {
    // Save authIdx to localStorage whenever it changes
    localStorage.setItem("authIdx", JSON.stringify(authIdx));
  }, [authIdx]);
  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{
          sidebarBackgroundColor,
          setSidebarBackgroundColor,

          sidebarImage,
          setSidebarImage,

          sidebarRTL,
          setSidebarRTL,

          authIdx,
          setAuthIdx,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: sidebarRTL ? "row-reverse" : "row",
          }}
        >
          <MyProSidebar Authority={setAuthIdx} />
          <div style={{ height: 'calc(100vh)', width: "100%", overflow: 'auto', }}>
            <main>
              <Topbar />
              {children}
            </main>
          </div>
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
