import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { getWorkOrderStatus } from '../API';

const WorkOrder = () => {

    const [dataSource, setDataSource] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getWorkOrderStatus();
                const payload = res.map((item) => ({
                    News: item.NEW,
                    Process: item.PROCESS,
                    Finish: item.FINISH,
                    Pending: item.PENDING,
                }))
                setDataSource(payload[0]);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();

    }, []);


    const data = dataSource ? [
        ["Type", "Value", { role: "style" }],
        ["News", dataSource.News, "#2389FF"],
        ["Process", dataSource.Process, "#0CCBCC"],
        ["Finish", dataSource.Finish, "#67C638"],
        ["Pending", dataSource.Pending, "#F33433"],
    ] : [
        ["Type", "Value", { role: "style" }],
        ["News", 0, "#2389FF"],
        ["Process", 0, "#0CCBCC"],
        ["Finish", 0, "#67C638"],
        ["Pending", 0, "#F33433"],
    ];

    const options = {
        // title: "Work Order Status",
        legend: "none",
        // legend: {
        //     position: "top",
        //     textStyle: {
        //         color: "#233238",
        //         fontSize: 12,
        //     },
        // },
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="300px"
            data={data}
            options={options}
        />
    );
};

export default WorkOrder;
