import { Box, Button } from "@mui/material"
import { StyleButtonForm } from "../../StyleCSS"
import { LoadingButton } from "@mui/lab"
import { Link, useNavigate } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';

export const ButtonForm = ({ handleSubmit, loading }) => {

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(-1);
    }

    return (
        <Box
            sx={StyleButtonForm}
        >
            <Button
                type="button"
                color="error"
                variant="contained"
                onClick={handleCancel}
            >
                <span>Cancel</span>
            </Button>
            <LoadingButton
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                variant="contained"
            >
                <span>Submit</span>
            </LoadingButton>
        </Box>
    )
}

export const ButtonUpdate = ({ handleClose, handleSubmit, loading }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                position: "sticky",
                top: 0,
                bottom: 0,
                zIndex: 100,
                width: "100%",
                padding: "20px",
                // boxShadow: "0px -5px 10px #00000016",
                // backgroundColor: `#FAFAFA !important`,
            }}
        >
            <Button
                type="button"
                color="error"
                variant="contained"
                onClick={handleClose}
            >
                <span>Cancel</span>
            </Button>
            <LoadingButton
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                variant="contained"
            >
                <span>Submit</span>
            </LoadingButton>
        </Box>
    )
}

export const ButtonDelete = ({ handleClose, handleDelete, loading }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
                gap: "10px",
            }}
        >
            <Button
                type="button"
                color="error"
                variant="contained"
                onClick={handleClose}
            >
                <span>Cancel</span>
            </Button>
            <LoadingButton
                color="primary"
                onClick={handleDelete}
                loading={loading}
                variant="contained"
            >
                <span>Submit</span>
            </LoadingButton>
        </Box>
    )
}


export const ButtonAddNew = ({ link }) => {
    return (
        <Link to={link} style={{ textDecoration: 'none' }}>
            <Button color="primary" variant="contained">
                <AddIcon sx={{ mr: 1 }} /> Add New
            </Button>
        </Link>
    )

}