import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import UploadImage from "../../global/components/UploadImage";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getFacility, getMap } from "../../../Api/Master/GetData";
import { postMaps } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";

const FormMap = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsFacCode = searchParams.get('faccode');

  useEffect(() => {
    if (paramsFacCode) {
      const fetchfacility = async () => {
        try {
          const res = await getFacility();
          const filterRes = res.filter((item) => item.IsSuspend !== true && item.FacilityCode === paramsFacCode);
          setSelectFacility(filterRes[0])
        } catch (error) {
          console.log(error);
        }
      }
      fetchfacility();
    }
  }, [paramsFacCode]);

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const [openModal, setOpenModal] = useState("");
  const FacilityName = selectFacility ? selectFacility.FacilityName : '';
  const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';

  const [MapCode, setMapCode] = useState("");

  const fetchfacility = async () => {
    try {
      setIsLoading(true);
      const res = await getFacility();
      const filterRes = res.filter((item) => item.IsSuspend !== true);
      setDataFacility(filterRes);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (openModal) {
      fetchfacility();
      setOpenModal(false);
    }
  }, [openModal]);

  const FetchMapCode = useCallback(async () => {
    try {
      const response = await getMap(FacilityCode);
      if (response.length > 0) {
        const MPData = response.filter(item => item.Map.MapCode.startsWith('MP'));
        if (MPData.length > 0) {
          const lastCode = MPData[MPData.length - 1].Map.MapCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setMapCode(`MP${nextNumber.toString().padStart(4, "0")}`);
        }
      }
    } catch (error) {
      setMapCode('MP0001');
      console.log(error);
    }
  }, [FacilityCode]);

  useEffect(() => {
    if (FacilityCode) {
      FetchMapCode();
    }
  }, [FetchMapCode, FacilityCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("FacilityName", FacilityName);
      formRef.current.setFieldValue("MapCode", MapCode);
    }
  }, [FacilityName, MapCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);

    if (!selectedFile) {
      enqueueSnackbar("Please upload an image.", { variant: "error" });
      setLoading(false);
      return;
    }

    try {
      const payload = {
        ...values,
        FacilityCode: FacilityCode,
        MapCode: MapCode,
        BuildingCode: "",
        imgs: selectedFile,
      };
      const response = await postMaps(payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/map");

    } catch (error) {
      if (error.response && error.response.data && error.response.data.statusMessage) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Box>
            <StyleContent>
              <Header title="MAP" subtitle="Create a Map" />
              <StyleForm>

                {/* Upload Image */}
                <UploadImage files={(files) => setSelectedFile(files)} />

                <InputModal
                  title="FACILITY"
                  label="Facility Name"
                  name="FacilityName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.FacilityName}
                  error={!!touched.FacilityName && !!errors.FacilityName}
                  helperText={touched.FacilityName && errors.FacilityName}
                  dataSource={dataFacility}
                  columns={columns}
                  loading={isLoading}
                  propsSelecteRow={(values) => setSelectFacility(values)}
                  propsOpenModal={(values) => setOpenModal(values)}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Map Code*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    setMapCode(e.target.value)
                  }}
                  value={MapCode}
                  name="MapCode"
                  error={!!touched.MapCode && !!errors.MapCode}
                  helperText={!!touched.MapCode && errors.MapCode}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Map Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MapName}
                  name="MapName"
                  error={!!touched.MapName && !!errors.MapName}
                  helperText={touched.MapName && errors.MapName}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleSubmit={handleSubmit}
              loading={loading}
            />

          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityName: yup
    .string()
    .required("Please complete this Facility Name."),
  MapCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Map Code."),
  MapName: yup
    .string()
    .max(200, "Maximum of 200 characters allowed")
    .required("Please complete this Map Name."),
});
const initialValues = {
  FacilityName: "",
  MapCode: "",
  MapName: "",
  Description: "",
};

export default FormMap;

const columns = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    key: 'FacilityCode',
    title: 'Facility Code',
    dataIndex: 'FacilityCode',
    width: 150,
    fixed: 'left',
    sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
  },
  {
    key: 'FacilityName',
    title: 'Facility Name',
    dataIndex: 'FacilityName',
    sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
  },
  {
    key: 'Address',
    title: 'Address',
    dataIndex: 'Address',
    width: 400,
    sorter: (a, b) => a.Address.localeCompare(b.Address),
  },
  {
    key: 'City',
    title: 'City',
    dataIndex: 'City',
    sorter: (a, b) => a.City.localeCompare(b.City),
  },
  {
    key: 'Country',
    title: 'Country',
    dataIndex: 'Country',
    sorter: (a, b) => a.Country.localeCompare(b.Country),
  },
  {
    key: 'Phone1',
    title: 'Phone Number 1',
    dataIndex: 'Phone1',
    sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
  },
  {
    key: 'Phone2',
    title: 'Phone Number 2',
    dataIndex: 'Phone2',
    sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
  },
  {
    key: 'Fax',
    title: 'Fax',
    dataIndex: 'Fax',
    sorter: (a, b) => a.Fax.localeCompare(b.Fax),
  },
  {
    key: 'ContactPerson',
    title: 'Contact Person',
    dataIndex: 'ContactPerson',
    sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
  },
];