import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Modal } from 'antd';
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ButtonUpdate } from "../../global/components/ButtonAction";
import FormWORE from "./WO_RE/form";
import FormWODM from "./WO_Doc/form";
import FormWOCT from "./WO_CT/form";
import { updateWOTransaction } from "../Api/UpdateData";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { getCategory, getSubCategory } from "../../../Api/Master/GetData";
import InputModal from "../../../components/Global/InputModal";
import { getWORequest } from "../Api/GetData";
import { columnsCategory, columnsSubCategory, columnsWORequest } from "./ColumnsWOT";

const EditWOTransaction = ({ data, onEdit }) => {

    const dataEdit = data.WOTransaction;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [WODate, setWODate] = useState(dayjs(dataEdit.WODate));
    const [DueDateWO, setDueDateWO] = useState(dayjs(dataEdit.DueDateWO));

    const [dataWORequest, setDataWORequest] = useState([]);
    const [selectWORequest, setSelectWORequest] = useState("");
    const WORNumber = selectWORequest ? selectWORequest.WORequest.WORNumber : '';
    const RequestBy = selectWORequest ? selectWORequest.WORequest.RequestBy : '';
    const EmployeeName = selectWORequest ? selectWORequest.EmployeeName : '';
    const WOType = selectWORequest ? selectWORequest.WORequest.WOType : '';
    const WOTypeRefName = selectWORequest ? selectWORequest.WOTypeRefName : '';
    const LocationCode = selectWORequest ? selectWORequest.WORequest.LocationCode : '';
    const LocationName = selectWORequest.LocationName ? selectWORequest.LocationName : '-';

    const [dataCategory, setDataCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState("");
    const CategoryCode = selectCategory ? selectCategory.CatCode : '';
    const CategoryName = selectCategory ? selectCategory.CatName : '';

    const [dataSubCategory, setDataSubCategory] = useState([]);
    const [selectSubCategory, setSelectSubCategory] = useState("");
    const SubCategoryCode = selectSubCategory ? selectSubCategory.SubCategory.SubCatCode : '';
    const SubCategoryName = selectSubCategory ? selectSubCategory.SubCategory.SubCatName : '';


    const [dataRE, setDataRE] = useState([]);
    const [dataDM, setDataDM] = useState([]);
    const [dataCT, setDataCT] = useState([]);


    // WO REQUEST
    useEffect(() => {
        const fetchWORequest = async () => {
            try {
                const res = await getWORequest(dataEdit.FacilityCode);

                const filterRes = res.filter((item) =>
                    item.WORequest.TranStatus === 2
                )
                setDataWORequest(filterRes);

                const selected = res.filter(item => item.WORequest.WORNumber === dataEdit.WORNumber);
                setSelectWORequest(selected[0]);

            } catch (error) {
                console.log(error);
            }
        }
        if (open && dataEdit.FacilityCode) {
            fetchWORequest();
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEdit.FacilityCode, open]);


    // CATEGORY
    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const res = await getCategory("Work");
                setDataCategory(res);

                const selected = res.filter(item => item.CatCode === dataEdit.WorkCatCode);
                setSelectCategory(selected[0]);

            } catch (error) {
                console.log(error);
            }
        }

        if (open) {
            fetchCategory();
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    // SUBCATEGORY
    useEffect(() => {
        const fetchSubCategory = async () => {
            try {
                const res = await getSubCategory("Work", CategoryCode);
                setDataSubCategory(res);

                const selected = res.filter(item => item.SubCategory.SubCatCode === dataEdit.WorkSubCatCode);
                setSelectSubCategory(selected[0]);

            } catch (error) {
                console.log(error);
            }
        }

        if (open && CategoryCode) {
            fetchSubCategory();
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CategoryCode, open]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("WORNumber", WORNumber);
            formRef.current.setFieldValue("RequestBy", EmployeeName);
            formRef.current.setFieldValue("WOType", WOTypeRefName);
            formRef.current.setFieldValue("LocationName", LocationName);
            formRef.current.setFieldValue("CategoryName", CategoryName);
            formRef.current.setFieldValue("SubCategoryName", SubCategoryName);
        }
    }, [CategoryName, EmployeeName, LocationName, SubCategoryName, WORNumber, WOTypeRefName]);



    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {

            const WODateNow = WODate.format('YYYY-MM-DD');

            const modifiedValues = {
                ...values,
                RequestBy: RequestBy,
                WOType: WOType,
                LocationCode: LocationCode,
                WorkCatCode: CategoryCode,
                WorkSubCatCode: SubCategoryCode,
                WODate: WODateNow,
                DueDateWO: DueDateWO,
                contacts: dataCT,
                documents: dataDM,
                resources: dataRE,
            };

            const response = await updateWOTransaction(dataEdit.FacilityCode, dataEdit.WONumber, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onEdit(dataEdit.FacilityCode && dataEdit.WONumber);
            setOpen(false);
        } catch (error) {
            enqueueSnackbar("Transaction WO Request data failed to update.", { variant: "error" });
            formRef.current.resetForm();
            setOpen(false);
        }
        setLoading(false);
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    validationSchema={checkoutSchema}
                    innerRef={formRef}
                >
                    {({

                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="WO TRANSACTION"
                                        subtitle="Edit Data a WO Transaction"
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                minHeight: "50vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >

                            <StyleForm>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Facility Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={data.FacilityName}
                                    name="FacilityCode"
                                    error={!!touched.FacilityCode && !!errors.FacilityCode}
                                    helperText={touched.FacilityCode && errors.FacilityCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="WO Number*"
                                    onBlur={handleBlur}
                                    value={values.WONumber}
                                    onChange={handleChange}
                                    name="WONumber"
                                    error={!!touched.WONumber && !!errors.WONumber}
                                    helperText={!!touched.WONumber && !!errors.WONumber}
                                    sx={{ gridColumn: "span 2" }}
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="WO Date*"
                                        value={WODate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setWODate(date)}
                                        name="WODate"
                                        error={!!touched.WODate && !!errors.WODate}
                                        helperText={touched.WODate && errors.WODate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <InputModal
                                    title="WO REQUEST"
                                    label="WO Request"
                                    name="WORNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WORNumber}
                                    error={!!touched.WORNumber && !!errors.WORNumber}
                                    helperText={touched.WORNumber && errors.WORNumber}
                                    dataSource={dataWORequest}
                                    columns={columnsWORequest}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectWORequest(values)}
                                    LinktoCreate={`/transaction/worequest/form?faccode=${dataEdit.FacilityCode}`}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Request By"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.RequestBy}
                                    name="RequestBy"
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="WO Type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.WOType}
                                    name="WOType"
                                    error={!!touched.WOType && !!errors.WOType}
                                    helperText={touched.WOType && errors.WOType}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Location Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.LocationName}
                                    name="LocationName"
                                    error={!!touched.LocationName && !!errors.LocationName}
                                    helperText={touched.LocationName && errors.LocationName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />

                                <InputModal
                                    title="CATEGORY"
                                    label="Category Name"
                                    name="CategoryName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.CategoryName}
                                    error={!!touched.CategoryName && !!errors.CategoryName}
                                    helperText={touched.CategoryName && errors.CategoryName}
                                    dataSource={dataCategory}
                                    columns={columnsCategory}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectCategory(values)}
                                    LinktoCreate={`/master/category/form?moduleid=Work`}
                                />

                                <InputModal
                                    title="SUBCATEGORY"
                                    label="SubCategory Name"
                                    name="SubCategoryName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.SubCategoryName}
                                    error={!!touched.SubCategoryName && !!errors.SubCategoryName}
                                    helperText={touched.SubCategoryName && errors.SubCategoryName}
                                    dataSource={dataSubCategory}
                                    columns={columnsSubCategory}
                                    loading={isLoading}
                                    propsSelecteRow={(values) => setSelectSubCategory(values)}
                                    LinktoCreate={`/master/subcategory/form?moduleid=Work`}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Due Date WO"
                                        value={DueDateWO}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDueDateWO(date)}
                                        name="DueDateWO"
                                        error={!!touched.DueDateWO && !!errors.DueDateWO}
                                        helperText={touched.DueDateWO && errors.DueDateWO}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 4" }}
                                    multiline
                                    rows={4}
                                />

                            </StyleForm>

                            <Box sx={{ px: 3, }}>

                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormWORE
                                        onSaveData={(data) => setDataRE(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        WONumber={dataEdit.WONumber}
                                        onEdit={true}
                                    />
                                </Box>
                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormWODM
                                        onSaveData={(data) => setDataDM(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        WONumber={dataEdit.WONumber}
                                        onEdit={true}
                                    />
                                </Box>
                                <Box sx={{
                                    margin: "20px 0",
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 10px #00000026",
                                }}>
                                    <FormWOCT
                                        onSaveData={(data) => setDataCT(data)}
                                        FacilityCode={dataEdit.FacilityCode}
                                        WONumber={dataEdit.WONumber}
                                        onEdit={true}
                                    />
                                </Box>

                            </Box>

                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
});

export default EditWOTransaction