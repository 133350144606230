import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditBuildings from "./edit";
import DeleteBuildings from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getBuilding } from "../../../Api/Master/GetData";

const Buildings = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getBuilding();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Building Code',
      dataIndex: ['Building', 'BuildingCode'],
      width: 160,
      fixed: 'left',
      sorter: (a, b) =>
        a.Building.BuildingCode.localeCompare(b.Building.BuildingCode),
    },
    {
      title: 'Building Name',
      dataIndex: ['Building', 'BuildingName'],
      sorter: (a, b) =>
        a.Building.BuildingName.localeCompare(b.Building.BuildingName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) =>
        a.Building.FacilityName.localeCompare(b.Building.FacilityName),
    },
    {
      title: 'Address',
      dataIndex: ['Building', 'Address'],
      width: 400,
      sorter: (a, b) => a.Building.Address.localeCompare(b.Building.Address),
    },
    {
      title: 'Phone Number 1',
      dataIndex: ['Building', 'Phone1'],
      sorter: (a, b) => a.Building.Phone1.localeCompare(b.Building.Phone1),
    },
    {
      title: 'Phone Number 2',
      dataIndex: ['Building', 'Phone2'],
      sorter: (a, b) => a.Building.Phone2.localeCompare(b.Building.Phone2),
    },
    {
      title: 'Contact Person',
      dataIndex: ['Building', 'ContactPerson'],
      sorter: (a, b) =>
        a.Building.ContactPerson.localeCompare(b.Building.ContactPerson),
    },
    {
      title: 'Suspend',
      dataIndex: ['Building', 'IsSuspend'],
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Suspend" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            ) : (
              <Tooltip title="Unsuspend">
                <CancelIcon color="error" />
              </Tooltip>
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center">
          {myEditAuthIdx && (
            <EditBuildings data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.Building.IsSuspend && (
            <DeleteBuildings data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    });
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "Facility Name", key: "FacilityName" },
    { title: "Building Code", key: ["Building", "BuildingCode"] },
    { title: "Building Name", key: ["Building", "BuildingName"] },
    { title: "Address", key: ["Building", "Address"] },
    { title: "Number Phone 1", key: ["Building", "Phone1"] },
    { title: "Number Phone 2", key: ["Building", "Phone2"] },
    { title: "Contact Person", key: ["Building", "ContactPerson"] },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="BUILDING" subtitle="All Building Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="Building"
            />
          )}
          {myAddAuthIdx && <ButtonAddNew link="/master/building/form" />}
        </Box>
      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={
          data.length === 0
            ? null
            : {
              expandedRowRender: (record) => (
                <DetailGrid>
                  <Typography variant="h5" fontWeight={600}>
                    Description :
                  </Typography>
                  <Typography>{record.Building.Description}</Typography>
                </DetailGrid>
              ),
              rowExpandable: (record) => record.Building.Description !== "",
            }
        }
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 2500 }}
      />
    </StyleContent>
  );
};

export default Buildings;
