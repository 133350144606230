import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


// MASTER DATA
export const deleteTran = async (tranIdx) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/tran?TranIdx=${tranIdx}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}


export const deleteFacility = async (facilityCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/facility?FacilityCode=${facilityCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteBuilding = async (FacilityCode, BuildingCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/building?FacilityCode=${FacilityCode}&BuildingCode=${BuildingCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteFloor = async (FacilityCode, BuildingCode, FloorLevelCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/floor?FacilityCode=${FacilityCode}&BuildingCode=${BuildingCode}&FloorLevelCode=${FloorLevelCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteType = async (ModuleID, TypeCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/type?ModuleID=${ModuleID}&TypeCode=${TypeCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deletePart = async (PartCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/part?PartCode=${PartCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteEquipmentModel = async (ModelCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/equipmentmodel?ModelCode=${ModelCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteEquipment = async (EquipmentCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/equipment?EquipmentCode=${EquipmentCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteCategory = async (ModuleID, CategoryCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/category?ModuleID=${ModuleID}&CatCode=${CategoryCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteSubCategory = async (ModuleID, CategoryCode, SubCategoryCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/subcategory?ModuleID=${ModuleID}&CatCode=${CategoryCode}&SubCatCode=${SubCategoryCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteIcon = async (IconId) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/Icon?IconId=${IconId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteSchedule = async (ScheduleCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/schedule?ScheduleCode=${ScheduleCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteSensor = async (SensorCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/sensor?SensorCode=${SensorCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteMap = async (FacilityCode, MapCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/maps?FacilityCode=${FacilityCode}&MapCode=${MapCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteLocation = async (FacilityCode, LocationCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/location?FacilityCode=${FacilityCode}&LocationCode=${LocationCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteVehicle = async (VehicleCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/vehicle?VehicleCode=${VehicleCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const deleteVendor = async (VendorCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/vendor?VendorCode=${VendorCode}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}