import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import { Link } from "react-router-dom";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { getEquipmentVehicle, getIcon, getType } from "../../../Api/Master/GetData";
import { updateSensor } from "../../../Api/Master/UpdateData";

const EditSensor = ({ data, onEdit }) => {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState([]);
  const [selectedType, setSelectedType] = useState(data.SensorTypeCode);

  const [IconId, setIconId] = useState([]);
  const [selectedIconId, setSelectedIconId] = useState(data.WarningIconId);

  const [objectCode, setObjectCode] = useState([]);
  const [selectedObjectCode, setSelectedObjectCode] = useState(data.ObjectCode);


  const [isSuspend, setIsSuspend] = useState(data.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    setSelectedType(data.SensorTypeCode)
    setSelectedIconId(data.WarningIconId)
    setSelectedObjectCode(data.ObjectCode)
    formRef.current.resetForm();
  };

  useEffect(() => {
    const fetchDataType = async () => {
      try {
        const response = await getType("Sensor");
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setType(filterRes);
      } catch (error) {
        setType([]);
      }
    };
    fetchDataType();
  }, []);

  useEffect(() => {
    const fetchDataIconId = async () => {
      try {
        const response = await getIcon();
        const filterRes = response.filter((item) => item.IsSuspend !== true);
        setIconId(filterRes);
      } catch (error) {
        setIconId([]);
      }
    };
    fetchDataIconId();
  }, []);

  useEffect(() => {
    const fetchDataObjectCode = async () => {
      try {
        const response = await getEquipmentVehicle();
        setObjectCode(response);
      } catch (error) {
        setObjectCode([]);
      }
    };
    fetchDataObjectCode();
  }, []);

  const handleTypeSelection = (event, value) => {
    if (value) {
      setSelectedType(value.TypeCode);
    } else {
      setSelectedType("");
    }
  };

  const handleIconIdSelection = (event, value) => {
    if (value) {
      setSelectedIconId(value.IconId);
    } else {
      setSelectedIconId("");
    }
  };

  const handleObjectCodeSelection = (event, value) => {
    if (value) {
      setSelectedObjectCode(value.SensorCode);
    } else {
      setSelectedObjectCode("");
    }
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(data.IsSuspend)
    setOpen(false);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        IsSuspend: isSuspend,
      };
      const response = await updateSensor(data.SensorCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(data.SensorCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="SENSOR"
                    subtitle="Edit Data a Sensor"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Code*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorCode}
                  name="SensorCode"
                  error={!!touched.SensorCode && !!errors.SensorCode}
                  helperText={touched.SensorCode && errors.SensorCode}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sensor Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.SensorName}
                  name="SensorName"
                  error={!!touched.SensorName && !!errors.SensorName}
                  helperText={touched.SensorName && errors.SensorName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={type}
                  getOptionLabel={(option) => option.TypeName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleTypeSelection(event, value);
                    setFieldValue(
                      "SensorTypeCode",
                      value ? value.TypeName : ""
                    );
                  }}
                  value={
                    type.find((option) => option.TypeCode === selectedType) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sensor Type Name*"
                      onBlur={handleBlur}
                      error={
                        !!touched.SensorTypeCode && !!errors.SensorTypeCode
                      }
                      helperText={
                        touched.SensorTypeCode && errors.SensorTypeCode
                      }
                    />
                  )}
                  noOptionsText={
                    type.length === 0 ? (
                      <Link
                        to="/master/type/form"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        there is no option, please fill in the Type Sensor data
                      </Link>
                    ) : (
                      "No options"
                    )
                  }
                  readOnly={isSuspend}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={IconId}
                  getOptionLabel={(option) => option.IconName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleIconIdSelection(event, value);
                    setFieldValue("WarningIconId", value ? value.IconName : "");
                  }}
                  value={
                    IconId.find((option) => option.IconId === selectedIconId) ||
                    null
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`${process.env.REACT_APP_BASEURL_IMG}/${option.Image} 2x`}
                        src={`${process.env.REACT_APP_BASEURL_IMG}/${option.Image}`}
                        alt="icon"
                      />
                      {option.IconName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Warning Icon Id*"
                      onBlur={handleBlur}
                      error={!!touched.WarningIconId && !!errors.WarningIconId}
                      helperText={touched.WarningIconId && errors.WarningIconId}
                    />
                  )}
                  noOptionsText={
                    IconId.length === 0 ? (
                      <Link
                        to="/master/icon/form"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        there is no option, please fill in the Icon data
                      </Link>
                    ) : (
                      "No options"
                    )
                  }
                  readOnly={isSuspend}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Lower Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.LLimit}
                  name="LLimit"
                  error={!!touched.LLimit && !!errors.LLimit}
                  helperText={touched.LLimit && errors.LLimit}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Upper Limit*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ULimit}
                  name="ULimit"
                  error={!!touched.ULimit && !!errors.ULimit}
                  helperText={touched.ULimit && errors.ULimit}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={objectCode}
                  getOptionLabel={(option) => option.SensorName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleObjectCodeSelection(event, value);
                    setFieldValue("ObjectCode", value ? value.SensorName : "");
                  }}
                  value={
                    objectCode.find(
                      (option) => option.SensorCode === selectedObjectCode
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Object Code*"
                      onBlur={handleBlur}
                      error={!!touched.ObjectCode && !!errors.ObjectCode}
                      helperText={touched.ObjectCode && errors.ObjectCode}
                    />
                  )}
                  // noOptionsText={
                  //   IconId.length === 0 ?
                  //     <Link to="/master/icon/form" target="_blank" style={{ textDecoration: 'none' }}>there is no option, please fill in the Icon data</Link>
                  //     : "No options"
                  // }
                  readOnly={isSuspend}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  SensorName: yup
    .string()
    .max(50, "Maximu m of 50 characters allowed")
    .required("Please complete this Sensor Name."),
  SensorTypeCode: yup
    .string()
    .max(50, "Maximu m of 50 characters allowed")
    .required("Please complete this Sensor Type Code."),
  WarningIconId: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Icon Id."),
  LLimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this LLimit."),
  ULimit: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this ULimit."),
  ObjectCode: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Object Code."),
});

export default EditSensor;
