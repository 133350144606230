import { Box, IconButton, InputBase, Paper } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { SearchGrid, StyleSearchGrid } from '../../StyleCSS';

const SearchData = ({ filterValue, handleFilterChange }) => {
    return (
        <>
            <Box sx={StyleSearchGrid} >

                <Paper
                    component="form"
                    sx={SearchGrid}
                >
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        sx={{ m: "0 10px", flex: 1 }}
                        placeholder="Search..."
                        value={filterValue}
                        onChange={handleFilterChange}
                        autoFocus
                        type="search"
                    />
                </Paper>
            </Box>
        </>
    )
}

export default SearchData