import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { tokens } from '../../../../theme';

const StyleForm = ({ children }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    return (
        <Box sx={{
            display: "grid",
            gap: "30px",
            color: colors.grey[900],
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            "& .MuiOutlinedInput-root": {
                borderRadius: "8px",

                "&.Mui-focused fieldset": {
                    borderColor: colors.grey[900],
                },
            },
            "& .MuiInputLabel-root.Mui-focused": {
                color: colors.grey[900],
            },
            px: 3,
            py: 1,
        }}>
            {children}
        </Box>
    )
}

export default StyleForm