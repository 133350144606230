import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import TableTran from "../UserGroup/tableTran";

import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getUser, getUserGroup } from "../../../Api/Configuration/GetData";
import { getBuilding, getFacility } from "../../../Api/Master/GetData";
import { postUser } from "../../../Api/Configuration/PostData";
import InputModal from "../../../components/Global/InputModal";
import InputSelect from "./InputSelect";
import { columnsBuilding, columnsFacility, columnsUserGroup } from "./ColumnsUser";

const FormUser = () => {

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [refAccountId, setRefAccountId] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [tranData, setTranData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [dataUserGroup, setDataUserGroup] = useState([]);
  const [selectUserGroup, setSelectUserGroup] = useState("");
  const [openModalUserGroup, setOpenModalUserGroup] = useState("");
  const UserGroupCode = selectUserGroup ? selectUserGroup.GroupCode : '';
  const UserGroupName = selectUserGroup ? selectUserGroup.GroupName : '';

  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const [openModalFacility, setOpenModalFacility] = useState("");
  const FacilityCode = selectFacility ? selectFacility.map((item) => item.FacilityCode).join(", ") : '';
  const FacilityName = selectFacility ? selectFacility.map((item) => item.FacilityName).join(", ") : '';


  const [dataBuilding, setDataBuilding] = useState([]);
  const [selectBuilding, setSelectBuilding] = useState("");
  const [openModalBuilding, setOpenModalBuilding] = useState("");
  const BuildingCode = selectBuilding ? selectBuilding.map((item) => item.Building.BuildingCode).join(", ") : '';
  const BuildingName = selectBuilding ? selectBuilding.map((item) => item.Building.BuildingName).join(", ") : '';


  // USER GROUP
  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const res = await getUserGroup();
        setDataUserGroup(res);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalUserGroup) {
      fetchUserGroup();
      setOpenModalUserGroup(false);
      setIsLoading(false);
    }

  }, [openModalUserGroup]);


  // FACILITY
  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const res = await getFacility();
        setDataFacility(res);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalFacility) {
      fetchFacility();
      setOpenModalFacility(false);
      setIsLoading(false);

      setDataBuilding([]);
      setSelectBuilding("");
    }

  }, [openModalFacility]);


  // BUILDING
  useEffect(() => {
    const fetchBuilding = async () => {
      try {
        const res = await getBuilding();
        const filterBuilding = res.filter((item) =>
          FacilityCode.includes(item.Building.FacilityCode)
        );
        setDataBuilding(filterBuilding);
      } catch (error) {
        console.log(error);
      }
    }

    if (FacilityCode && openModalBuilding) {
      fetchBuilding();
      setOpenModalBuilding(false);
      setIsLoading(false);
    }

  }, [openModalBuilding, FacilityCode]);


  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("UserGroupName", UserGroupName);
      formRef.current.setFieldValue("FacilityAuthority", FacilityName);
      formRef.current.setFieldValue("BuildingAuthority", BuildingName);
    }
  }, [BuildingName, FacilityName, UserGroupName]);


  const handleSaveData = (selectedData) => {
    setTranData(selectedData);
  };
  // const GroupTran =
  //   userGroup
  // .filter((item) => item.GroupCode === selectedUserGroup)
  // .map((item) => item.GroupTran)
  // .join('')
  // .split(';')
  // .filter((item) => item !== '')
  // .map((item) => item.trim());

  // console.log(user && user.AccountId);

  const FetchUserCode = useCallback(async () => {
    try {
      const response = await getUser();
      if (response.length > 0) {
        const RAIData = response.filter(
          (item) => item.RefAccountId && item.RefAccountId.startsWith("RAID")
        );
        if (RAIData.length > 0) {
          const lastCode = RAIData[RAIData.length - 1].RefAccountId;
          const nextNumber = parseInt(lastCode.substr(4)) + 1;
          setRefAccountId(`RAID${nextNumber.toString().padStart(5, "0")}`);
        } else {
          setRefAccountId("RAID00001");
        }
      } else {
        setRefAccountId("RAID00001");
      }
    } catch (error) {
      setRefAccountId("RAID00001");
      console.log(error);
    }
  }, []);

  useEffect(() => {
    FetchUserCode();
  }, [FetchUserCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("RefAccountId", refAccountId);
    }
  }, [refAccountId]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      if (tranData.length === 0) {
        enqueueSnackbar("Tran Authority cannot be empty!", {
          variant: "warning",
        });
        setLoading(false);
        return;
      }

      const TranIdx = tranData.map((item) => item.TranIdx).join(",");

      const TranAdd = tranData
        .filter((item) => item.Add)
        .map((item) => item.TranIdx)
        .join(",");
      const TranEdit = tranData
        .filter((item) => item.Edit)
        .map((item) => item.TranIdx)
        .join(",");
      const TranDelete = tranData
        .filter((item) => item.Delete)
        .map((item) => item.TranIdx)
        .join(",");
      const TranPrint = tranData
        .filter((item) => item.Print)
        .map((item) => item.TranIdx)
        .join(",");
      const TranProcess = tranData
        .filter((item) => item.Process)
        .map((item) => item.TranIdx)
        .join(",");

      const payload = {
        ...values,
        GroupTran: TranIdx,
        AddAuthority: TranAdd,
        EditAuthority: TranEdit,
        DelAuthority: TranDelete,
        PrnAuthority: TranPrint,
        PrsAuthority: TranProcess,
        FacilityAuthority: FacilityCode,
        BuildingAuthority: BuildingCode,
        UserGroup: UserGroupCode,
        RefAccountId: refAccountId,
      };
      // console.log("Submit Data", payload);
      const response = await postUser(payload);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/configuration/user");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="USER" subtitle="Create a User" />
            <StyleForm>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="RefAccount Id*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setRefAccountId(e.target.value);
                }}
                value={refAccountId}
                name="RefAccountId"
                inputProps={{ maxLength: 9 }}
                error={!!touched.RefAccountId && !!errors.RefAccountId}
                helperText={touched.RefAccountId && errors.RefAccountId}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Username*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.UserName}
                name="UserName"
                error={!!touched.UserName && !!errors.UserName}
                helperText={touched.UserName && errors.UserName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Email*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Email}
                name="Email"
                error={!!touched.Email && !!errors.Email}
                helperText={touched.Email && errors.Email}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="User Password*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.UserPwd}
                name="UserPwd"
                error={!!touched.UserPwd && !!errors.UserPwd}
                helperText={touched.UserPwd && errors.UserPwd}
                sx={{ gridColumn: "span 2" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? (
                          <Icon icon="eva:eye-fill" />
                        ) : (
                          <Icon icon="eva:eye-off-fill" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <InputModal
                title="USER GROUP"
                label="User Group Name"
                name="UserGroupName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.UserGroupName}
                error={!!touched.UserGroupName && !!errors.UserGroupName}
                helperText={touched.UserGroupName && errors.UserGroupName}
                dataSource={dataUserGroup}
                columns={columnsUserGroup}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectUserGroup(values)}
                propsOpenModal={(values) => setOpenModalUserGroup(values)}
                LinktoCreate={`/configuration/usergroup/form`}
              />

              <InputSelect
                title="FACILITY"
                label="Facility Authority"
                name="FacilityAuthority"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.FacilityAuthority}
                error={!!touched.FacilityAuthority && !!errors.FacilityAuthority}
                helperText={touched.FacilityAuthority && errors.FacilityAuthority}
                dataSource={dataFacility}
                columns={columnsFacility}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectFacility(values)}
                propsOpenModal={(values) => setOpenModalFacility(values)}
                LinktoCreate={`/master/facility/form`}
              />

              <InputSelect
                title="FACILITY"
                label="Building Authority"
                name="BuildingAuthority"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.BuildingAuthority}
                error={!!touched.BuildingAuthority && !!errors.BuildingAuthority}
                helperText={touched.BuildingAuthority && errors.BuildingAuthority}
                dataSource={dataBuilding}
                columns={columnsBuilding}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectBuilding(values)}
                propsOpenModal={(values) => setOpenModalBuilding(values)}
                LinktoCreate={`/master/building/form`}
              />

              {/* <Autocomplete
                multiple
                onOpen={() => setOpenBuilding(true)}
                loading={isLoading}
                options={buildingData}
                disableCloseOnSelect
                getOptionLabel={(option) => option.Building.BuildingName}
                sx={{ gridColumn: "span 2" }}
                onChange={(event, newValue) => {
                  const selectedBuildingCodes = newValue.map(
                    (building) => building.Building.BuildingCode
                  );
                  setBuildingAuthority(selectedBuildingCodes.join(","));
                  setFieldValue("BuildingAuthority", buildingAuthority);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Building.BuildingName}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Building Authority*"
                    onBlur={handleBlur}
                    error={
                      !!touched.BuildingAuthority && !!errors.BuildingAuthority
                    }
                    helperText={
                      touched.BuildingAuthority && errors.BuildingAuthority
                    }
                  />
                )}
              /> */}

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />
            </StyleForm>

            <TableTran
              onSaveData={handleSaveData}
              groupTran={dataUserGroup}
              selectUserGroup={UserGroupCode}
            />
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  RefAccountId: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this RefAccount Id."),
  UserName: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this User Name."),
  Email: yup
    .string()
    .email()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Email."),
  UserPwd: yup
    .string()
    .min(8, "Minimal of 8 characters allowed")
    .max(255, "Maximum of 255 characters allowed")
    .required("Please complete this User Password."),
  UserGroupName: yup
    .string()
    .required("Please complete this User Group."),
  // FacilityAuthority: yup
  //   .string()
  //   .required("Please complete this Facility Authority."),
  // BuildingAuthority: yup
  //   .string()
  //   .required("Please complete this Building Authority."),
});
const initialValues = {
  RefAccountId: "",
  UserName: "",
  Email: "",
  UserPwd: "",
  UserGroupName: "",
  FacilityAuthority: "",
  BuildingAuthority: "",
  Description: "",
};

export default FormUser;
