import React, { useState } from 'react';
import { Form, Input, Popconfirm, Table, Tooltip, Typography, Select } from 'antd';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { LoadingButton } from "@mui/lab"
import { Button } from '@mui/material';
import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { getTranApprover, getTranAppStatus, getUser } from '../../../Api/Configuration/GetData';
import { postTranApprover } from '../../../Api/Configuration/PostData';
import InputDetailModal from '../../../components/Global/InputDetailModal';

const { Option } = Select;
const { TextArea } = Input;


const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    Approver,
    onUser,
    ApproverLevel,
    ...restProps
}) => {

    const [isLoading, setIsLoading] = useState(true);

    const [dataUser, setDataUser] = useState([]);
    const [selectUser, setSelectUser] = useState("");
    const [openUser, setOpenUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await getUser();

                const dataApprover = Approver.map(item => item.Approver)
                const filter = res.filter(item => !dataApprover.includes(item.UserName))

                setDataUser(filter);
            } catch (error) {
                console.log(error);
            }
        }

        if (openUser) {
            fetchUser();
            setOpenUser(false);
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openUser]);

    useEffect(() => {
        if (selectUser) {
            onUser(selectUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectUser]);


    return (
        <td {...restProps}>
            {editing ? (
                <div>

                    {dataIndex === 'Description' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                        >

                            <TextArea rows={4} placeholder={title} />
                        </Form.Item>

                    ) : (

                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {dataIndex === 'Approver' && editing ? (

                                // <Select
                                //     maxTagCount="responsive"
                                //     showSearch
                                //     placeholder={title}
                                // >
                                //     {Approver.map((user) => (
                                //         <Option key={user.key} value={user.UserName}>
                                //             {user.UserName}
                                //         </Option>
                                //     ))}
                                // </Select>

                                <InputDetailModal
                                    title="USER"
                                    label="UserName"
                                    name={dataIndex}
                                    dataSource={dataUser}
                                    loading={isLoading}
                                    columns={columnsUser}
                                    onData={(values) => setSelectUser(values)}
                                    onOpenModal={(values) => setOpenUser(values)}
                                    onDetail={true}
                                />

                            ) : dataIndex === 'ApproverLevel' && editing ? (

                                <Select
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder={title}
                                >
                                    {ApproverLevel.map((status) => (
                                        <Option key={status.key} value={status.StatusName}>
                                            {status.StatusName}
                                        </Option>
                                    ))}
                                </Select>

                            ) : (

                                <Input placeholder={title} />

                            )}
                        </Form.Item>
                    )}

                </div>
            ) : (
                children
            )
            }
        </td >
    );
};


const TableForm = ({ onSaveData, tranIdx, approverLevel }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');

    const [appStatus, setAppStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(false);

    const [dataUser, setDataUser] = useState(null);

    useEffect(() => {
        if (form && dataUser) {
            form.setFieldsValue({
                Approver: dataUser.UserName
            })
        }
    }, [dataUser, form]);


    useEffect(() => {
        const fetchData = async () => {

            try {
                const resApp = await getTranApprover();
                const filterApp = resApp.filter((item) => item.TranIdx === tranIdx).map((row, index) => ({ ...row, key: index + 1 }))
                setData(filterApp.reverse());
                setCount(filterApp.length === 0 ? 0 : filterApp.map((item) => item.key)[0])
            } catch (error) {
                console.log(error);
                setData([]);
                setCount(0)

            }

            const resAppStatus = await getTranAppStatus();
            setAppStatus(resAppStatus);

        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // console.log("Data User", appStatus);


    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        const approver = record.Approver ? record.Approver : [];
        const approverLevelArray = record.ApproverLevel ? record.ApproverLevel.split(', ') : [];

        form.setFieldsValue({
            Description: '',
            ...record,
            Approver: approver,
            ApproverLevel: approverLevelArray,
        });
        setEditingKey(record.key);

        // console.log("DataFormTran", data);
    };


    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        // onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.Approver) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);

            // Check for duplicate Approvers
            const duplicateApprover = newData.find(item => item.Approver === row.Approver && item.key !== record.key);
            if (duplicateApprover) {
                enqueueSnackbar("Duplicate Approver found!", { variant: "error" });
                return; // Stop saving if duplicate found
            }

            if (index > -1) {
                const item = newData[index];
                row.ApproverLevel = Array.isArray(row.ApproverLevel) ? row.ApproverLevel.join(',') : row.ApproverLevel;
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
            } else {
                row.ApproverLevel = Array.isArray(row.ApproverLevel) ? row.ApproverLevel.join(',') : row.ApproverLevel;
                newData.push(row);
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;

        // if (!facilityCode || !tranIdx || !key) {
        //     enqueueSnackbar("Complete the Form data!", { variant: "warning" });
        //     return;
        // } else if (data.length > 0 && !data[data.length - 1].Approver && !data[data.length - 1].ApproverLevel) {
        //     enqueueSnackbar("Complete Column data!", { variant: "warning" });
        //     return;
        // }

        const newData = {
            key: num,
            TranIdx: tranIdx,
            Approver: '',
            ApproverLevel: '',
            Description: '',
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {
            await postTranApprover(data);
            console.log("PostData", data);
            enqueueSnackbar("Success add form table data!!", { variant: "success" });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCancelAllData = () => {
        setData(data);
        setCount(count);
        // onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 50,
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Approver',
            dataIndex: 'Approver',
            editable: true,
            width: 250,
            // render: (text, record) => {
            //     const selectedUser = user.find(user => user.RefAccountId === record.Approver);
            //     return selectedUser ? selectedUser.UserName : ''; // Menampilkan UserName
            // },
        },
        {
            title: 'Approver Level',
            dataIndex: 'ApproverLevel',
            editable: true,
            width: 500,
            // render: (text, record) => {
            //     const selectedStatuses = appStatus.filter(status => record.ApproverLevel.includes(status.StatusLevel));
            //     const statusNames = selectedStatuses.map(status => status.StatusName);
            //     return statusNames.join(', ');
            // },
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            editable: true,
            width: 300,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <Tooltip title="Save">
                                <SaveFilled />
                            </Tooltip>
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <Tooltip title="Cancel">
                                <CloseOutlined />
                            </Tooltip>
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <Tooltip title="Edit">
                                <EditFilled />
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <Tooltip title="Delete">
                                    <DeleteOutlined style={{ fontSize: '18px' }} />
                                </Tooltip>
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                Approver: data,
                ApproverLevel: appStatus,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onUser: (values) => setDataUser(values),
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    margin: "0 10px 10px"
                }}
            >
                {/* <Title level={3} style={{ margin: "0" }}>
                    Resource
                </Title> */}
                <Button
                    onClick={handleAdd}
                    color="primary"
                    variant="contained"
                    disabled={!!editingKey}
                >
                    + Add Data
                </Button>
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
                scroll={{
                    x:1000
                }}
            />
            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                    margin: "10px 10px 0"
                }}
            >
                <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                // okText="OK"
                // cancelText="Cancel"
                // okButtonProps={{ style: { backgroundColor: 'green', color: 'white' } }} // Mengubah warna tombol OK
                // cancelButtonProps={{ style: { backgroundColor: 'red', color: 'white' } }} // Mengubah warna tombol Cancel
                >
                    <Button
                        type="button"
                        color="error"
                        variant="contained"
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Cancel</span>
                    </Button>
                </Popconfirm>
                <LoadingButton
                    color="primary"
                    onClick={handleSaveAllData}
                    loading={loading}
                    variant="contained"
                    disabled={!!editingKey || !!isDisable}
                >
                    <span>Save</span>
                </LoadingButton>

            </div>
        </Form>
    );
};
export default TableForm;


const columnsUser = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Ref AccountId',
        dataIndex: 'RefAccountId',
        sorter: (a, b) => a.RefAccountId.localeCompare(b.RefAccountId),
    },
    {
        title: 'User Name',
        dataIndex: 'UserName',
        sorter: (a, b) => a.UserName.localeCompare(b.UserName),
    },
    {
        title: 'Email',
        dataIndex: 'Email',
        sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
]