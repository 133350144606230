import { Image } from "antd";

export const columnsFacility = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        key: 'FacilityCode',
        title: 'Facility Code',
        dataIndex: 'FacilityCode',
        width: 150,
        fixed: 'left',
        sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
        key: 'FacilityName',
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        key: 'Address',
        title: 'Address',
        dataIndex: 'Address',
        width: 400,
        sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
        key: 'City',
        title: 'City',
        dataIndex: 'City',
        sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
        key: 'Country',
        title: 'Country',
        dataIndex: 'Country',
        sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
        key: 'Phone1',
        title: 'Phone Number 1',
        dataIndex: 'Phone1',
        sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
        key: 'Phone2',
        title: 'Phone Number 2',
        dataIndex: 'Phone2',
        sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
        key: 'Fax',
        title: 'Fax',
        dataIndex: 'Fax',
        sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
        key: 'ContactPerson',
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
];

export const columnsEquipmentModel = [

    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Image',
        dataIndex: 'Image',
        render: (params) =>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Image
                    style={{ maxWidth: 100, maxHeight: "100%", objectFit: "contain" }}
                    src={`${process.env.REACT_APP_BASEURL_IMG}/${params}`}
                />
            </div>
    },
    {
        title: 'Model Code',
        dataIndex: 'ModelCode',
        sorter: (a, b) => a.ModelCode.localeCompare(b.ModelCode),
    },
    {
        title: 'Model Name',
        dataIndex: 'ModelName',
        sorter: (a, b) => a.ModelName.localeCompare(b.ModelName),
    },
    {
        title: 'Model Type Code',
        dataIndex: 'ModelTypeCode',
        sorter: (a, b) => a.ModelTypeCode.localeCompare(b.ModelTypeCode),
    },
    {
        title: 'Work Category Code',
        dataIndex: 'WorkCatCode',
        sorter: (a, b) => a.WorkCatCode.localeCompare(b.WorkCatCode),
    },
    {
        title: 'SubCategory Code',
        dataIndex: 'SubCatCode',
        sorter: (a, b) => a.SubCatCode.localeCompare(b.SubCatCode),
    },
    {
        title: 'ManuFacturer',
        dataIndex: 'Manufacturer',
        sorter: (a, b) => a.Manufacturer.localeCompare(b.Manufacturer),
    },
    {
        title: 'Meter Reading Unit',
        dataIndex: 'MeterReadingUnit',
        sorter: (a, b) => a.MeterReadingUnit.localeCompare(b.MeterReadingUnit),
    },
    {
        title: 'Icon Id',
        dataIndex: 'IconId',
        sorter: (a, b) => a.IconId.localeCompare(b.IconId),
    }
];


export const columnsLocation = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Location Code',
        dataIndex: ['Location', 'LocationCode'],
        fixed: 'left',
        sorter: (a, b) => a.Location.LocationCode.localeCompare(b.Location.LocationCode),
    },
    {
        title: 'Location Name',
        dataIndex: ['Location', 'LocationName'],
        sorter: (a, b) => a.Location.LocationName.localeCompare(b.Location.LocationName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'Building Name',
        dataIndex: 'BuildingName',
        sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
        title: 'Floor Level Name',
        dataIndex: 'FloorLevelName',
        sorter: (a, b) => a.FloorLevelName.localeCompare(b.FloorLevelName),
    },
    {
        title: 'Map Name',
        dataIndex: 'MapName',
        sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    },
    {
        title: 'IconId',
        dataIndex: ['Location', 'IconId'],
        sorter: (a, b) => a.Location.IconId.localeCompare(b.Location.IconId),
    },
    {
        title: 'Icon Location Y',
        dataIndex: ['Location', 'IconLocationY'],
        sorter: (a, b) => a.Location.IconLocationY.localeCompare(b.Location.IconLocationY),
    },
    {
        title: 'Icon Location X',
        dataIndex: ['Location', 'IconLocationX'],
        sorter: (a, b) => a.Location.IconLocationX.localeCompare(b.Location.IconLocationX),
    }
];

export const columnsEquipmentOwner = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Employee Name',
      dataIndex: ['Employee', 'EmpName'],
      sorter: (a, b) => a.Employee.EmpName.localeCompare(b.Employee.EmpName),
    },
    {
      title: 'Address',
      dataIndex: ['Employee', 'Address'],
      width: 400,
      sorter: (a, b) => a.Employee.Address.localeCompare(b.Employee.Address),
    },
    {
      title: 'Phone Number 1',
      dataIndex: ['Employee', 'Phone1'],
      sorter: (a, b) => a.Employee.Phone1.localeCompare(b.Employee.Phone1),
    },
    {
      title: 'Phone Number 2',
      dataIndex: ['Employee', 'Phone2'],
      sorter: (a, b) => a.Employee.Phone2.localeCompare(b.Employee.Phone2),
    },
    {
      title: 'NIK',
      dataIndex: ['Employee', 'NIK'],
      sorter: (a, b) => a.Employee.NIK.localeCompare(b.Employee.NIK),
    },
    {
      title: 'Division Name',
      dataIndex: 'DivisionName',
      sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
    },
    {
      title: 'Department Name',
      dataIndex: 'DepName',
      sorter: (a, b) => a.DepName.localeCompare(b.DepName),
    },
    {
      title: 'Section Name',
      dataIndex: 'SectionName',
      sorter: (a, b) => a.SectionName.localeCompare(b.SectionName),
    },
    {
      title: 'IsTechnician',
      dataIndex: ['Employee', 'IsTechnician'],
      sorter: (a, b) => a.Employee.IsTechnician.localeCompare(b.Employee.IsTechnician),
      render: (isTechnician) => (
        <span>
          {isTechnician ? 'Technician' : 'Not a technician'}
        </span>
      ),
    }
  ];
