import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Tooltip } from "@mui/material";
import Header from "../../../components/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { StyleModalDelete } from "../../global/StyleCSS";
import { deleteTran } from "../../../Api/Master/DeleteData";

const DeleteTran = ({ data, onDelete }) => {

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    try {
      await deleteTran(data.TranIdx);
      enqueueSnackbar("Tran data has been successfully deleted.", { variant: "success" });
      onDelete(data.TranIdx);
      setOpen(false);
    } catch (error) {
      enqueueSnackbar("Tran data failed to delete.", { variant: "error" });
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip title="Delete" fontSize="large">
        <IconButton onClick={handleOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <Modal keepMounted open={open}>
        <Box sx={StyleModalDelete}>
          <Box display="flex" justifyContent="space-between">
            <Header
              title="DELETE TRAN"
              subtitle="Delete Data a Tran"
            />
          </Box>

          <Box>
            <p>
              Are you sure you want to delete data with this TranIdx {data.TranIdx} ?
            </p>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
              gap: "10px",
            }}
          >
            <Button type="button" color="error" variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="button" color="secondary" variant="contained" onClick={handleDelete}>
              Delete Data
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DeleteTran;
