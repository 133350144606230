import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from "react-router-dom";

const ViewTranApproval = ({ data }) => {

    return (
        <Box>

            <Tooltip title="View" fontSize="large">
                <Link to={`/transaction/${data.TranName.replace(/\s/g, '').toLowerCase()}/form?action=view&faccode=${data.FacilityCode}&trannumber=${data.TranNumber}`} >
                    <IconButton>
                        <VisibilityIcon />
                    </IconButton>
                </Link>
            </Tooltip>

        </Box>
    );
};



export default ViewTranApproval