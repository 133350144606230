export const columnsFacility = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        key: 'FacilityCode',
        title: 'Facility Code',
        dataIndex: 'FacilityCode',
        width: 150,
        fixed: 'left',
        sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
        key: 'FacilityName',
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        key: 'Address',
        title: 'Address',
        dataIndex: 'Address',
        width: 400,
        sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
        key: 'City',
        title: 'City',
        dataIndex: 'City',
        sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
        key: 'Country',
        title: 'Country',
        dataIndex: 'Country',
        sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
        key: 'Phone1',
        title: 'Phone Number 1',
        dataIndex: 'Phone1',
        sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
        key: 'Phone2',
        title: 'Phone Number 2',
        dataIndex: 'Phone2',
        sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
        key: 'Fax',
        title: 'Fax',
        dataIndex: 'Fax',
        sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
        key: 'ContactPerson',
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
];

export const columnsWORequest = [
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'WO Request Number',
        dataIndex: ['WORequest', 'WORNumber'],
        sorter: (a, b) => a.WORequest.WORNumber.localeCompare(b.WORequest.WORNumber),
    },
    {
        title: 'WO Request Date',
        dataIndex: ['WORequest', 'WORDate'],
        sorter: (a, b) => a.WORequest.WORDate.localeCompare(b.WORequest.WORDate),
    },

    {
        title: 'Request By',
        dataIndex: 'EmployeeName',
        sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
    },
    {
        title: 'Location Name',
        dataIndex: 'LocationName',
        sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
        render: (_, record) => {
            if (!record.LocationName) {
                return "-";
            }
            return record.LocationName;
        }
    },
    {
        title: 'WO Type',
        dataIndex: ['WORequest', 'WOType'],
        sorter: (a, b) => a.WORequest.WOType.localeCompare(b.WORequest.WOType),
    },
    {
        title: 'WO Type Referensi',
        dataIndex: 'WOTypeRefName',
        sorter: (a, b) => a.WOTypeRefName.localeCompare(b.WOTypeRefName),
    },
    {
        title: 'AppStatus',
        dataIndex: ['WORequest', 'AppStatus'],
        sorter: (a, b) => a.WORequest.AppStatus.localeCompare(b.WORequest.AppStatus),
        render: (_, record) => {
            if (!record.WORequest.AppStatus) {
                return "-";
            }
            return record.WORequest.AppStatus;
        }
    },
]


export const columnsCategory = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Category Code',
        dataIndex: 'CatCode',
        fixed: 'left',
        sorter: (a, b) => a.CatCode.localeCompare(b.CatCode),
    },
    {
        title: 'Category Name',
        dataIndex: 'CatName',
        sorter: (a, b) => a.CatName.localeCompare(b.CatName),
    },
    {
        title: 'Module ID',
        dataIndex: 'ModuleID',
        sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
    }
];


export const columnsSubCategory = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Module ID',
        dataIndex: ['SubCategory', 'ModuleID'],
        width: 200,
        sorter: (a, b) => a.SubCategory.ModuleID.localeCompare(b.SubCategory.ModuleID),
    },
    {
        title: 'Category Name',
        dataIndex: 'CatName',
        width: 200,
        sorter: (a, b) => a.CatName.localeCompare(b.CatName),
    },
    {
        title: 'SubCategory Code',
        dataIndex: ['SubCategory', 'SubCatCode'],
        width: 200,
        sorter: (a, b) => a.SubCategory.SubCatCode.localeCompare(b.SubCategory.SubCatCode),
    },
    {
        title: 'SubCategory Name',
        dataIndex: ['SubCategory', 'SubCatName'],
        width: 200,
        sorter: (a, b) => a.SubCategory.SubCatName.localeCompare(b.SubCategory.SubCatName),
    }
]