import React, { useEffect, useState } from 'react';
import { Form, Table, Typography, Checkbox } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { Box, IconButton, InputBase, Paper } from '@mui/material';
import { SearchGrid, StyleSearchGrid } from '../../global/StyleCSS';
import SearchIcon from "@mui/icons-material/Search";
import { getTran } from '../../../Api/Configuration/GetData';

const { Title } = Typography;


const TableTran = ({ onSaveData, groupTran, selectUserGroup }) => {
    // console.log({ selectUserGroup });
    const [form] = Form.useForm();
    const [dataColumns, setDataColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedData, setSelectedData] = useState({});
    const [filterValue, setFilterValue] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);

    // const [selectedUserGroup, setSelectedUserGroup] = useState([]);

    const fetchTran = async () => {
        setIsLoading(true);
        try {
            const response = await getTran();
            const filterRes = response.filter((item) => item.TranType !== "Admin").map((row, index) => ({ ...row, key: index + 1 }));
            setDataColumns(filterRes);
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data;
                enqueueSnackbar(errorMessage.msg, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to load.", { variant: "error" });
            }
            console.error("error code", error.response.data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchTran();
    }, []);

    useEffect(() => {

        let initialSelectedData = {};

        if (groupTran || groupTran > 0) {
            if (selectUserGroup === undefined) {
                const show = groupTran.GroupTran.split(',').map((item) => item.trim());
                const add = groupTran.AddAuthority.split(',').map((item) => item.trim());
                const edit = groupTran.EditAuthority.split(',').map((item) => item.trim());
                const deletee = groupTran.DelAuthority.split(',').map((item) => item.trim());
                const print = groupTran.PrnAuthority.split(',').map((item) => item.trim());
                const process = groupTran.PrsAuthority.split(',').map((item) => item.trim());


                // Inisialisasikan kolom yang dicentang berdasarkan prop show
                dataColumns.forEach((item) => {
                    initialSelectedData[item.key] = {
                        Show: show.includes(item.TranIdx),
                        Add: add.includes(item.TranIdx),
                        Edit: edit.includes(item.TranIdx),
                        Delete: deletee.includes(item.TranIdx),
                        Print: print.includes(item.TranIdx),
                        Process: process.includes(item.TranIdx),
                    };
                });

                setSelectedData(initialSelectedData);

            } else {

                const extractData = (property, data) =>
                    data
                        .filter((item) => item.GroupCode === selectUserGroup)
                        .map((item) => item[property])
                        .join('')
                        .split(',')
                        .filter((item) => item !== '')
                        .map((item) => item.trim());

                const show = extractData('GroupTran', groupTran);
                const add = extractData('AddAuthority', groupTran);
                const edit = extractData('EditAuthority', groupTran);
                const deletee = extractData('DelAuthority', groupTran);
                const print = extractData('PrnAuthority', groupTran);
                const process = extractData('PrsAuthority', groupTran);
                // console.log({ show, add, edit, deletee, print, process });
                dataColumns.forEach((item) => {
                    initialSelectedData[item.key] = {
                        Show: show.includes(item.TranIdx),
                        Add: add.includes(item.TranIdx),
                        Edit: edit.includes(item.TranIdx),
                        Delete: deletee.includes(item.TranIdx),
                        Print: print.includes(item.TranIdx),
                        Process: process.includes(item.TranIdx),
                    };
                });
                setSelectedData(initialSelectedData);
            }
            // console.log(initialSelectedData);
        }
    }, [groupTran, selectUserGroup, dataColumns]);

    useEffect(() => {
        if (Object.keys(selectedData).length > 0 && dataColumns.length > 0) {
            const selectedRows = dataColumns
                .filter((row) => {
                    const selectedRow = selectedData[row.key];
                    return (
                        selectedRow &&
                        (selectedRow.Show || selectedRow.Add || selectedRow.Edit || selectedRow.Delete || selectedRow.Print || selectedRow.Process)
                    );
                })
                .map((row) => ({
                    ...row,
                    ...selectedData[row.key],
                }));

            onSaveData(selectedRows);
            // console.log("Automatically saving data:", selectedRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData, dataColumns]);


    const handleCheckboxChange = (key, column, checked) => {
        let updatedData;

        if ((column === 'Add' || column === 'Edit' || column === 'Delete' || column === 'Print' || column === 'Process') && checked) {
            updatedData = {
                ...selectedData,
                [key]: {
                    ...selectedData[key],
                    [column]: checked,
                    Show: checked,
                },
            };
        } else {
            updatedData = {
                ...selectedData,
                [key]: {
                    ...selectedData[key],
                    [column]: checked,
                },
            };
        }


        setSelectedData(updatedData);


        const selectedRows = dataColumns
            .filter((row) => {
                const selectedRow = updatedData[row.key];
                return (
                    selectedRow &&
                    (selectedRow.Show || selectedRow.Add || selectedRow.Edit || selectedRow.Delete || selectedRow.Print || selectedRow.Process)
                );
            })
            .map((row) => ({
                ...row,
                ...updatedData[row.key],
            }));


        onSaveData(selectedRows);
        console.log("Selected All Data:", selectedRows);
    };


    const handleSaveAllData = () => {
        const selectedRows = dataColumns
            .filter((row) => {
                const selectedRow = selectedData[row.key];
                return (
                    selectedRow &&
                    (selectedRow.Show || selectedRow.Add || selectedRow.Edit || selectedRow.Delete || selectedRow.Print || selectedRow.Process)
                );
            })
            .map((row) => ({
                ...row,
                ...selectedData[row.key],
            }));


        onSaveData(selectedRows);
        enqueueSnackbar("Authority User Group data saved successfully.", { variant: "success" });
        console.log("Selected All Data:", selectedRows);
    };

    const handleCancelAllData = () => {
        setSelectedData({});
        onSaveData([]);
    }

    const handleTranNameClick = (key) => {
        const selectedRow = selectedData[key];
        const newStatus = !areAllChecked(selectedRow);

        const updatedSelectedData = {
            ...selectedData,
            [key]: {
                Show: newStatus,
                Add: newStatus,
                Edit: newStatus,
                Delete: newStatus,
                Print: newStatus,
                Process: newStatus,
            },
        };


        setSelectedData(updatedSelectedData);


        // Extract the selected data
        const selectedRows = dataColumns
            .filter((row) => {
                const selectedRow = updatedSelectedData[row.key];
                return (
                    selectedRow &&
                    (selectedRow.Show || selectedRow.Add || selectedRow.Edit || selectedRow.Delete || selectedRow.Print || selectedRow.Process)
                );
            })
            .map((row) => ({
                ...row,
                ...updatedSelectedData[row.key],
            }));


        // Call onSaveData with the extracted data
        onSaveData(selectedRows);
        console.log("Selected All Data:", selectedRows);
    };

    const areAllChecked = (row) => {
        return row && row.Show && row.Add && row.Edit && row.Delete && row.Print && row.Process;
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 50,
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Tran Name',
            dataIndex: 'TranName',
            render: (text, record) => (
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleTranNameClick(record.key)}
                >
                    {text}
                </span>
            ),
        },
        {
            title: 'Show Data',
            dataIndex: 'Show',
            render: (text, record) => (
                <Checkbox
                    checked={selectedData[record.key]?.Show}
                    onChange={(e) =>
                        handleCheckboxChange(record.key, 'Show', e.target.checked)
                    }
                >
                    Show
                </Checkbox>
            ),
        },
        {
            title: 'Add Data',
            dataIndex: 'Add',
            render: (text, record) => (
                <Checkbox
                    checked={selectedData[record.key]?.Add}
                    onChange={(e) =>
                        handleCheckboxChange(record.key, 'Add', e.target.checked)
                    }
                >
                    Add
                </Checkbox>
            ),
        },
        {
            title: 'Edit Data',
            dataIndex: 'Edit',
            render: (text, record) => (
                <Checkbox
                    checked={selectedData[record.key]?.Edit}
                    onChange={(e) =>
                        handleCheckboxChange(record.key, 'Edit', e.target.checked)
                    }
                >
                    Edit
                </Checkbox>
            ),
        },
        {
            title: 'Delete Data',
            dataIndex: 'Delete',
            render: (text, record) => (
                <Checkbox
                    checked={selectedData[record.key]?.Delete}
                    onChange={(e) =>
                        handleCheckboxChange(record.key, 'Delete', e.target.checked)
                    }
                >
                    Delete
                </Checkbox>
            ),
        },
        {
            title: 'Print Data',
            dataIndex: 'Print',
            render: (text, record) => (
                <Checkbox
                    checked={selectedData[record.key]?.Print}
                    onChange={(e) =>
                        handleCheckboxChange(record.key, 'Print', e.target.checked)
                    }
                >
                    Print
                </Checkbox>
            ),
        },
        {
            title: 'Process Data',
            dataIndex: 'Process',
            render: (text, record) => (
                <Checkbox
                    checked={selectedData[record.key]?.Process}
                    onChange={(e) =>
                        handleCheckboxChange(record.key, 'Process', e.target.checked)
                    }
                >
                    Process
                </Checkbox>
            ),
        },
    ];

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);

        const filteredData = dataColumns.filter((row) => {
            return (
                row.TranName.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
    };


    return (
        <Form form={form} component={false}>
            <Box
                sx={{
                    margin: "20px 0",
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: "0px 0px 10px #00000026",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "0 10px 10px"
                    }}
                >
                    <Title level={3} style={{ margin: "0" }}>
                        Tran Authority
                    </Title>
                    <Box sx={StyleSearchGrid}>
                        <Paper component="form" sx={SearchGrid}>
                            <IconButton
                                type="button"
                                sx={{ p: "10px" }}
                                aria-label="search"
                                disabled
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                sx={{ m: "0 10px", flex: 1 }}
                                placeholder="Search Authority..."
                                value={filterValue}
                                onChange={handleFilterChange}
                                autoFocus
                                type="search"
                            />
                        </Paper>
                    </Box>
                </div>
                <Table
                    loading={isLoading}
                    bordered
                    dataSource={filterValue ? filteredRows : dataColumns}
                    columns={columns}
                    pagination={{
                        pageSize: 50,
                    }}
                    scroll={{
                        y: 600,
                        x: 1000,
                    }}
                />
                {/* <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}>
                        <Button
                            type="button"
                            color="error"
                            variant="contained"
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <LoadingButton
                        color="primary"
                        onClick={handleSaveAllData}
                        // loading={loading}
                        variant="contained"
                    >
                        <span>Save</span>
                    </LoadingButton>

                </div> */}
            </Box>
        </Form>
    );
};
export default TableTran;