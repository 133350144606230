import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import Authentication from "../../auth";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getPMSchedule } from "../../../Api/Report/GetData";
import dayjs from "dayjs";

const PMSchedule = () => {

  Authentication();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const fetchData = async () => {
    try {
      const response = await getPMSchedule();
      setData(response);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);


  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Schedule Date',
      dataIndex: 'ScheduleDate',
      fixed: 'left',
      width: 150,
      sorter: (a, b) => a.ScheduleDate.localeCompare(b.ScheduleDate),
      render: (value) => <p>{dayjs(value).format("YYYY-MM-DD")}</p>
    },
    {
      title: 'PM Name',
      dataIndex: 'PMName',
      sorter: (a, b) => a.PMName.localeCompare(b.PMName),
    },
    {
      title: 'PM Type',
      dataIndex: 'PMType',
      sorter: (a, b) => a.PMType.localeCompare(b.PMType),
    },
    {
      title: 'PM Type Ref Code',
      dataIndex: 'PMTypeRefCode',
      sorter: (a, b) => a.PMTypeRefCode.localeCompare(b.PMTypeRefCode),
    },
    {
      title: 'Schedule Start',
      dataIndex: 'ScheduleStart',
      sorter: (a, b) => a.ScheduleStart.localeCompare(b.ScheduleStart),
      render: (value) => <p>{dayjs(value).format("YYYY-MM-DD")}</p>
    },
    {
      title: 'Schedule End',
      dataIndex: 'ScheduleEnd',
      sorter: (a, b) => a.ScheduleEnd.localeCompare(b.ScheduleEnd),
      render: (value) => <p>{dayjs(value).format("YYYY-MM-DD")}</p>
    },
    {
      title: 'Day To Complete',
      dataIndex: 'DayToComplete',
      sorter: (a, b) => a.DayToComplete.localeCompare(b.DayToComplete),
      render: (value) => <p>{value} day</p>
    },
    {
      title: 'Estimation Cost',
      dataIndex: 'EstimationCost',
      sorter: (a, b) => a.EstimationCost - b.EstimationCost, // Menggunakan angka untuk sorting
      render: (value) => {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0,
        }).format(value);
      },
    },

  ];

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="PM SCHEDULE"
          subtitle="All PM Schedule"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
      scroll={{ x: 1500 }}
      />

    </StyleContent>
  );
};

export default PMSchedule