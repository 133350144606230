import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

// WO TRANSACTION

export const postWORequest = async (WORequestData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/worequest`, WORequestData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const PostWOTransaction = async (WOTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/wotransaction`, WOTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// PMTransaction
export const PostPMTransaction = async (PMTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/pmtransaction`, PMTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// DMTransaction
export const PostDMTransaction = async (DMTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/dmtransaction`, DMTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// MPTransaction
export const PostMPTransaction = async (MPTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/mptransaction`, MPTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// AdjustmentTransaction
export const PostAdjustmentTransaction = async (AdjustmentTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.post(`${baseUrl}/post/adjustment`, AdjustmentTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}
