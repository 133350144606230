import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import UploadImage from "../../global/components/UploadImage";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getIcon } from "../../../Api/Master/GetData";
import { postIcon } from "../../../Api/Master/PostData";

const FormIconMenu = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [iconId, setIconId] = useState("");

  const fetchIconId = async () => {
    try {
      const response = await getIcon();
      if (response.length > 0) {
        const ICData = response.filter(
          (item) => item.IconId && item.IconId.startsWith("IC")
        );
        if (ICData.length > 0) {
          const lastCode = ICData[ICData.length - 1].IconId;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setIconId(`IC${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setIconId("IC0001");
        }
      } else {
        setIconId("IC0001");
      }
    } catch (error) {
      setIconId("IC0001");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIconId();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("IconId", iconId);
    }
  }, [iconId]);

  const handleCancel = () => {
    formRef.current.resetForm();
    setSelectedFile(null);
    fetchIconId();
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);

    if (!selectedFile) {
      enqueueSnackbar("Please upload an image.", { variant: "error" });
      setLoading(false);
      return;
    }

    try {
      const modifiedValues = {
        ...values,
        IconId: iconId,
        imgs: selectedFile,
      };

      const response = await postIcon(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/icon");
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data;
        enqueueSnackbar(errorMessage.msg, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialValues, IconId: iconId }}
        validationSchema={checkoutSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box>
            <StyleContent>
              <Header title="ICON" subtitle="Create a Icon" />
              <StyleForm>
                {/* Upload Image */}
                <UploadImage files={(files) => setSelectedFile(files)} />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Icon Id*"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setIconId(e.target.value);
                  }}
                  value={iconId}
                  name="IconId"
                  error={!!touched.IconId && !!errors.IconId}
                  helperText={touched.IconId && errors.IconId}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{ maxLength: 6 }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Icon Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.IconName}
                  name="IconName"
                  error={!!touched.IconName && !!errors.IconName}
                  helperText={touched.IconName && errors.IconName}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  rows={4}
                />
              </StyleForm>
            </StyleContent>

            <ButtonForm
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  IconId: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Icon Id."),
  IconName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Icon Name."),
});
const initialValues = {
  IconId: "",
  IconName: "",
  Description: "",
};

export default FormIconMenu;
