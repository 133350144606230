import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


export const updateDivision = async (DivisionCode, DivisionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/division?DivisionCode=${DivisionCode}`, DivisionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateDepartment = async (DivisionCode, DepartmentCode, DepartmentData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/department?DivisionCode=${DivisionCode}&DepCode=${DepartmentCode}`, DepartmentData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateSection = async (DivisionCode, DepartmentCode, SectionCode, SectionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/section?DivisionCode=${DivisionCode}&DepCode=${DepartmentCode}&SectionCode=${SectionCode}`, SectionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateEmployee = async (EmployeeCode, EmployeeData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/employee?EmpCode=${EmployeeCode}`, EmployeeData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}