import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import { StyleContent } from "../../global/StyleCSS";
import EditTran from "./edit";
import DeleteTran from "./delete";
import { Space, Table } from "antd";
import { customExpandIcon } from "../../global/customTable";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import { getTran } from "../../../Api/Configuration/GetData";

const Tran = () => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getTran();
      setData(response);
    } catch (error) {
      //console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Tran Idx',
      dataIndex: 'TranIdx',
      width: 200,
      sorter: (a, b) => a.TranIdx.localeCompare(b.TranIdx),
    },
    {
      title: 'Tran Name',
      dataIndex: 'TranName',
      width: 200,
      sorter: (a, b) => a.TranName.localeCompare(b.TranName),
    },
    {
      title: 'Tran Form Name',
      dataIndex: 'TranFormName',
      width: 200,
      sorter: (a, b) => a.TranFormName.localeCompare(b.TranFormName),
    },
    {
      title: 'Show Idx',
      dataIndex: 'ShowIdx',
      width: 200,
      sorter: (a, b) => a.ShowIdx.localeCompare(b.ShowIdx),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Space>
          <EditTran data={record} onEdit={fetchData} />

          <DeleteTran data={record} onDelete={fetchData} />
        </Space>
      ),
    },
  ];

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      // Sesuaikan dengan kolom yang ingin Anda filter
      return (
        row.TranIdx.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "Tran Index", key: "TranIdx" },
    { title: "Tran Name", key: "TranName" },
    { title: "Tran Form Name", key: "TranFormName" },
    { title: "Show Index", key: "ShowIdx" }
  ];

  return (
    <Box sx={StyleContent}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header title="TRAN" subtitle="All Tran Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          <ExportData
            data={selectedRows.length > 0 ? selectedRows : data}
            columns={columnsExport}
            name="Tran"
          />

          <ButtonAddNew
            link="/master/tran/form"
          />
        </Box>

      </Box>

      <SearchData
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </div>
          ),
          rowExpandable: (record) => record.Description !== '',
          expandIcon: customExpandIcon,
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 1000 }}
      />

    </Box>
  );
};

export default Tran;
