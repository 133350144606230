import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import TableTran from "./tableTran";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getUserGroup } from "../../../Api/Configuration/GetData";
import { postUserGroup } from "../../../Api/Configuration/PostData";

const FormUserGroup = () => {

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [GroupCode, setGroupCode] = useState("");

  const [loading, setLoading] = useState(false);

  const [tranData, setTranData] = useState([]);

  const handleSaveData = (selectedData) => {
    setTranData(selectedData);
  };

  const FetchUserGroupCode = async () => {
    try {
      const response = await getUserGroup();
      if (response.length > 0) {
        const UGData = response.filter(item => item.GroupCode && item.GroupCode.startsWith('UG'));
        if (UGData.length > 0) {
          const lastCode = UGData[UGData.length - 1].GroupCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setGroupCode(`UG${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setGroupCode("UG0001");
        }
      } else {
        setGroupCode("0");
      }
    } catch (error) {
      setGroupCode("UG0001");
      console.log(error.response.statusText);
    }
  };

  useEffect(() => {
    FetchUserGroupCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("GroupCode", GroupCode);
    }
  }, [GroupCode]);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);

      if (tranData.length === 0) {
        enqueueSnackbar("Tran Authority cannot be empty!", { variant: "warning" });
        setLoading(false);
        return;
      }

      const TranIdx = tranData.map((item) => item.TranIdx).join(',');

      const TranAdd = tranData.filter((item) => item.Add).map((item) => item.TranIdx).join(',');
      const TranEdit = tranData.filter((item) => item.Edit).map((item) => item.TranIdx).join(',');
      const TranDelete = tranData.filter((item) => item.Delete).map((item) => item.TranIdx).join(',');
      const TranPrint = tranData.filter((item) => item.Print).map((item) => item.TranIdx).join(',');
      const TranProcess = tranData.filter((item) => item.Process).map((item) => item.TranIdx).join(',');

      const modifiedValues = {
        ...values,
        // KodeCabang: GroupCode,
        GroupTran: TranIdx,
        AddAuthority: TranAdd.length === 0 ? '' : TranAdd,
        EditAuthority: TranEdit.length === 0 ? '' : TranEdit,
        DelAuthority: TranDelete.length === 0 ? '' : TranDelete,
        PrnAuthority: TranPrint.length === 0 ? '' : TranPrint,
        PrsAuthority: TranProcess.length === 0 ? '' : TranProcess,
        GroupCode: GroupCode,
      };

      // console.log('selectData', tranData);
      // console.log(modifiedValues);
      // console.log(savedData);
      const response = await postUserGroup(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/usergroup");
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data;
        enqueueSnackbar(errorMessage.msg, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response.data);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{ ...initialValues, GroupCode: GroupCode }}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box>
          <StyleContent>
            <Header title="USER GROUP" subtitle="Create a User Group" />
            <StyleForm>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Group Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e)
                  setGroupCode(e.target.value)
                }}
                value={GroupCode}
                name="GroupCode"
                inputProps={{
                  maxLength: 5
                }}
                error={!!touched.GroupCode && !!errors.GroupCode}
                helperText={touched.GroupCode && errors.GroupCode}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Kode Cabang*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.KodeCabang}
                name="KodeCabang"
                error={!!touched.KodeCabang && !!errors.KodeCabang}
                helperText={touched.KodeCabang && errors.KodeCabang}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Group Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GroupName}
                name="GroupName"
                error={!!touched.GroupName && !!errors.GroupName}
                helperText={touched.GroupName && errors.GroupName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />
            </StyleForm>

            <TableTran
              onSaveData={handleSaveData}
            />

          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />

        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  GroupCode: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Group Code."),
  KodeCabang: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Kode Cabang."),
  GroupName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Group Name."),
});
const initialValues = {
  GroupCode: "",
  KodeCabang: "",
  GroupName: "",
  Description: "",
};

export default FormUserGroup;
