import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Modal } from "antd";

const ExportData = ({ data, columns, name }) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleOpen = () => {
        if (data.length === 0) {
            enqueueSnackbar(`${name} data is empty!!!`, { variant: "warning" });
        } else {
            setOpen(true);
        }
    };

    const getValueByKeyPath = (obj, keyPath) => {
        return keyPath.reduce((acc, key) => (acc && acc[key] !== 'undefined') ? acc[key] : null, obj);
    };

    const handleExcel = () => {
        const headers = ["No", ...columns.map((column) => column.title)];
        const rows = data.map((item, index) => [
            index + 1,
            ...columns.map((column) =>
                Array.isArray(column.key) ? getValueByKeyPath(item, column.key) : item[column.key]
            )
        ]);

        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        ws["!rows"] = [{ hpt: 20, hpx: 16 }];
        ws["!cols"] = columns.map((_, idx) => ({ wpx: 100 }));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `${name}`);
        XLSX.writeFile(wb, `${name}_data.xlsx`);
        setOpen(false);
        enqueueSnackbar(
            `The ${name} has been successfully exported to Excel '${name}_data.xlsx' `,
            { variant: "success" }
        );
    };

    const handlePDF = () => {
        const doc = new jsPDF();
        const headers = ["No", ...columns.map((column) => column.title)];
        const rows = data.map((item, index) => [
            index + 1,
            ...columns.map((column) =>
                Array.isArray(column.key) ? getValueByKeyPath(item, column.key) : item[column.key]
            )
        ]);

        doc.autoTable({
            head: [headers],
            body: rows,
        });
        doc.save(`${name}_data.pdf`);
        setOpen(false);
        enqueueSnackbar(
            `The ${name} was successfully exported to PDF '${name}_data.pdf' `,
            { variant: "success" }
        );
    };

    return (
        <Box>
            <Button color="error" variant="contained" onClick={handleOpen}>Export</Button>
            <Modal
                centered
                open={open}
                onCancel={() => setOpen(false)}
                title={`Export Data ${name}`}
                footer={false}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    paddingTop: 2,
                }}>
                    <Button component="label" variant="contained" color="success" size="large" startIcon={<NoteAddIcon />} onClick={handleExcel}>
                        Export to Excel
                    </Button>
                    <Button component="label" variant="contained" color="error" size="large" startIcon={<PictureAsPdfIcon />} onClick={handlePDF}>
                        Export to PDF
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default ExportData;
