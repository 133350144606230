import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Table, Typography } from 'antd';
import { getWOContactTrasaction } from '../../Api/GetData';

const DataWOCT = ({ record }) => {

  const [dataCT, setDataCT] = useState([]);

  useEffect(() => {
    const fetchDataCT = async () => {
      try {
        const response = await getWOContactTrasaction();
        const filterData = response.filter((item) =>
          item.FacilityCode === record.FacilityCode &&
          item.WONumber === record.WONumber
        )
        setDataCT(filterData);
      } catch (error) {
        console.log(error.response.data);
      }
    }
    fetchDataCT();
  }, [record]);

  const columns = [
    {
      key: 'DetailNo',
      title: 'No',
      dataIndex: 'DetailNo',
      width: 60,
    },
    {
      title: 'Contact Name',
      dataIndex: 'ContactName',
      width: 200,
    },
    {
      title: 'Contact Email',
      dataIndex: 'ContactEmail',
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      width: 200,
    },
  ];

  return (
    <div key="document-tab" style={{ margin: "5px 0" }}>
      <Typography.Title level={3} style={{ marginBottom: "5px" }}>
        Contact
      </Typography.Title>
      <Table
        bordered
        columns={columns}
        dataSource={dataCT}
        pagination={false}
      />
    </div>
  )
}

export default DataWOCT