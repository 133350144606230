import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


// TRANSACTION WO REQUEST
export const deleteWORequest = async (FacilityCode, WORNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/worequest?FacilityCode=${FacilityCode}&WORNumber=${WORNumber}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}


// WO TRANSACTION
export const deleteWOTransaction = async (FacilityCode, WONumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/wotransaction?FacilityCode=${FacilityCode}&WONumber=${WONumber}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// PM TRANSACTION
export const deletePMTransaction = async (FacilityCode, PMNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/pmtransaction?FacilityCode=${FacilityCode}&PMNumber=${PMNumber}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// DM TRANSACTION
export const deleteDMTransaction = async (FacilityCode, DMNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/dmtransaction?FacilityCode=${FacilityCode}&DMNumber=${DMNumber}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// MP TRANSACTION
export const deleteMPTransaction = async (FacilityCode, MPNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/mptransaction?FacilityCode=${FacilityCode}&MPNumber=${MPNumber}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// Adjustment TRANSACTION
export const deleteAdjustmentTransaction = async (FacilityCode, ADNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.delete(`${baseUrl}/delete/adjustment?FacilityCode=${FacilityCode}&ADNumber=${ADNumber}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}