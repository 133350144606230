import { Box } from '@mui/material'
import React from 'react'

const StyleEditHeader = ({ children }) => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: "24px",
            alignItems: "center",
        }}>
            {children}
        </Box>
    )
}

export default StyleEditHeader;