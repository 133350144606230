import { useSidebarContext } from '../Sidebar/SidebarContext';
import { useSelector } from 'react-redux';

const EditAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const EditAuthIdx = user && user.EditAuthority.filter(item => item === authIdx).join(',')
    return EditAuthIdx
}

export default EditAuthorityIdx;