import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import Header from "../../../components/Header";
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from "notistack";
import { ButtonDelete } from "../../global/components/ButtonAction";
import { Modal } from "antd";
import { deleteAdjustmentTransaction } from "../Api/DeleteData";


const DeleteAdjustmentTransaction = ({ data, onDelete }) => {

    const deleteData = data.Adjustment;

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const response = await deleteAdjustmentTransaction(deleteData.FacilityCode, deleteData.ADNumber);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onDelete(deleteData.FacilityCode && deleteData.ADNumber);
            setOpen(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to delete.", { variant: "error" });
            }
            console.error("error code", error.response);
            setOpen(false);
        }
        setLoading(false);
    };

    return (
        <Box>
            <Tooltip title="Cancel" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <CancelIcon />
                </IconButton>
            </Tooltip>

            <Modal
                title={
                    <Header
                        title="Adjustment"
                        subtitle="Cancel Data a Adjustment"
                    />
                }
                centered
                open={open}
                closable={false}
                footer={() => (
                    <ButtonDelete
                        handleClose={handleClose}
                        handleDelete={handleDelete}
                        loading={loading}
                    />
                )}
            >

                <Box>
                    <p>Are you sure you want to cancel data with this Facility Code {deleteData.FacilityCode} and PM Number Code {deleteData.ADNumber} ?</p>
                </Box>

            </Modal>

        </Box>
    );
};



export default DeleteAdjustmentTransaction