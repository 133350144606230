import React, { useRef, useState } from "react";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import TabsSchedule from "./tab";
import TabMeter from "./meter";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import { updateSchedule } from "../../../Api/Master/UpdateData";

const EditSchedule = ({ data, onEdit }) => {

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [scheduleInput, setScheduleInput] = useState(data.OccursEvery);
  const [scheduleTabs, setscheduleTabs] = useState(data.PeriodeType);
  const [scheduleDays, setScheduleDays] = useState(data.Days.split(','));
  const [scheduleMonth, setScheduleMonth] = useState(data.Month.split(','));

  const [selectedScheduleType, setSelectedScheduleType] = useState(
    data.ScheduleType
  );

  const [isSuspend, setIsSuspend] = useState(data.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    setSelectedScheduleType(data.ScheduleType)
    formRef.current.resetForm();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setIsSuspend(data.IsSuspend)
    setOpen(false);
  };

  const handleInputChange = (tab, value, days, checked) => {
    if (tab === "daily") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleDays([]);
      setScheduleMonth([]);
    } else if (tab === "weekly") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleMonth([]);
      setScheduleDays(days);
    } else if (tab === "monthly") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleDays(days);
      setScheduleMonth(checked);
    } else if (tab === "runtime") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleDays([]);
      setScheduleMonth([]);
    }
    // Tambahkan logika untuk tab lain jika perlu
  };

  const handleScheduleSelection = (event, value) => {
    if (value) {
      setSelectedScheduleType(value.Name);
    } else {
      setSelectedScheduleType("");
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      if (scheduleTabs === "runtime" && scheduleInput > 9999) {
        enqueueSnackbar("current cannot exceed 9999!", { variant: "warning" });
        setLoading(false);
        return;
      }

      const modifiedValues = {
        ...values,
        PeriodeType: scheduleTabs,
        OccursEvery: scheduleInput,
        Days: scheduleDays.join(","),
        Month: scheduleMonth.join(","),
        IsSuspend: isSuspend,
      };

      const response = await updateSchedule(data.ScheduleCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(values.ScheduleCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="SCHEDULE"
                    subtitle="Edit Data a Schedule"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Schedule Code*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ScheduleCode}
                  name="ScheduleCode"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Schedule Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ScheduleName}
                  name="ScheduleName"
                  error={!!touched.ScheduleName && !!errors.ScheduleName}
                  helperText={touched.ScheduleName && errors.ScheduleName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={ScheduleType}
                  getOptionLabel={(option) => option.Name}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleScheduleSelection(event, value);
                    setFieldValue("ScheduleType", value ? value.Name : "");
                  }}
                  value={
                    ScheduleType.find(
                      (option) => option.Name === selectedScheduleType
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Schedule Type*"
                      onBlur={handleBlur}
                      error={!!touched.ScheduleType && !!errors.ScheduleType}
                      helperText={touched.ScheduleType && errors.ScheduleType}
                    />
                  )}
                  readOnly={isSuspend}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                {open ? (
                  <Box sx={{ gridColumn: "span 4" }}>
                    {selectedScheduleType === "Meter" ? (
                      <TabMeter
                        onInputChange={handleInputChange}
                        editData={data}
                      />
                    ) : (
                      <TabsSchedule
                        onInputChange={handleInputChange}
                        editData={data}
                      />
                    )}
                  </Box>
                ) : null}
              </StyleForm>
            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  ScheduleName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Schedule Name."),
  ScheduleType: yup.string().required("Please complete this Schedule Type."),
});

const ScheduleType = [
  {
    Name: "Calendar",
  },
  {
    Name: "Meter",
  },
];

export default EditSchedule;
