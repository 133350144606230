import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

export const getTran = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/tran`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getTranApproval = async (FacilityCode, UserName, TranIdx, TranNumber, ApprovalNo) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/TranApproval${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${UserName ? `&UserName=${UserName}` : ''}${TranIdx ? `&TranIdx=${TranIdx}` : ''}${TranNumber ? `&TranNumber=${TranNumber}` : ''}${ApprovalNo ? `&ApprovalNo${ApprovalNo}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }))
}


export const getVendorEmployee = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/vendoremployee`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


export const getTranApproverAppStatus = async (TranIdx, Approver) => {
    try {
        const token = Cookies.get('accessToken');
        const response = await axios.get(`${baseUrl}/get/tranapprover/appstatus${TranIdx ? `?TranIdx=${TranIdx}` : ''}${Approver ? `&Approver=${Approver}` : ''}`, {
            headers: {
                Authorization: `Bearer ${token} `
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


// WO REQUEST TRANSACTION
export const getWORequest = async (FacilityCode, WORNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/worequest${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${WORNumber ? `&WORNumber=${WORNumber}` : ''} `, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


// WO TRANSACTION
export const getWOMainTrasaction = async (FacilityCode, WONumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/womaintransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${WONumber ? `&WONumber=${WONumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getWOResourceTrasaction = async (FacilityCode, WONumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/woresourcetransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${WONumber ? `&WONumber=${WONumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getWODocumentTrasaction = async (FacilityCode, WONumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/wodoctransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${WONumber ? `&WONumber=${WONumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getWOContactTrasaction = async (FacilityCode, WONumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/wocontacttransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${WONumber ? `&WONumber=${WONumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


// PM TRANSACTION
export const getPMMainTrasaction = async (FacilityCode, PMNumber, IsProcess) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/pmmaintransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${PMNumber ? `&PMNumber=${PMNumber}` : ''}${IsProcess ? `&IsProcess=${IsProcess}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


export const getPMTaskTrasaction = async (FacilityCode, PMNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/pmtasktransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${PMNumber ? `&PMNumber=${PMNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getPMResourceTrasaction = async (FacilityCode, PMNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/pmresourcetransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${PMNumber ? `&PMNumber=${PMNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getPMDocumentTrasaction = async (FacilityCode, PMNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/pmdoctransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${PMNumber ? `&PMNumber=${PMNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data;
}

export const getPMContactTrasaction = async (FacilityCode, PMNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/pmcontacttransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${PMNumber ? `&PMNumber=${PMNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data;
}

export const getPMSchduleTrasaction = async (FacilityCode, PMNumber, ScheduleCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/pmscheduletransaction${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${PMNumber ? `&PMNumber=${PMNumber}` : ''}${ScheduleCode ? `&ScheduleCode=${ScheduleCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}




// DM TRANSACTION
export const getDMMainTrasaction = async (FacilityCode, DMNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/dmmaintransaction${FacilityCode ? `?FaclilityCode=${FacilityCode}` : ''}${DMNumber ? `&DMNumber=${DMNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDMResourceTrasaction = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/dmresourcetransaction`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDMPartTrasaction = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/dmparttransaction`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDMDocumentTrasaction = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/dmdoctransaction`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDMContactTrasaction = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/dmcontacttransaction`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


// MP TRANSACTION
export const getMPMainTrasaction = async (FacilityCode, MPNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/mpmaintransaction${FacilityCode ? `?FaclilityCode=${FacilityCode}` : ''}${MPNumber ? `&MPNumber=${MPNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMPResourceTrasaction = async (FacilityCode, MPNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/mpresourcetransaction${FacilityCode ? `?FaclilityCode=${FacilityCode}` : ''}${MPNumber ? `&MPNumber=${MPNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMPPartTrasaction = async (FacilityCode, MPNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/mpparttransaction${FacilityCode ? `?FaclilityCode=${FacilityCode}` : ''}${MPNumber ? `&MPNumber=${MPNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMPDocumentTrasaction = async (FacilityCode, MPNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/mpdoctransaction${FacilityCode ? `?FaclilityCode=${FacilityCode}` : ''}${MPNumber ? `&MPNumber=${MPNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMPContactTrasaction = async (FacilityCode, MPNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/mpcontacttransaction${FacilityCode ? `?FaclilityCode=${FacilityCode}` : ''}${MPNumber ? `&MPNumber=${MPNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


// ADJUSTMENT

export const getPartHistory = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/parthistory`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getAdjustment = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/adjustmentmaster`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMainAdjustmentTransaction = async (FacilityCode, ADNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/adjustmenttransactionmaster${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${ADNumber ? `&ADNumber=${ADNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDetailAdjustmentTransaction = async (FacilityCode, ADNumber) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/adjustmenttransactiondetail${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${ADNumber ? `&ADNumber=${ADNumber}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token} `
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}