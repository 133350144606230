import React from 'react'
import Chart from 'react-google-charts'

const TechnicianPerformance = () => {

    const data = [
        ["Name", "Value"],
        ["Dimas", 7],
        ["Roni", 14],
        ["Raka", 4],
        ["Helmi", 10],
    ];

    const options = {
        // title: "My Daily Activities",
        pieHole: 0.4,
        is3D: false,
        sliceVisibilityThreshold: 0.02,
        legend: {
            position: "bottom",
            alignment: "center",
            textStyle: {
                color: "#233238",
                fontSize: 12,
            },
        },
        pieSliceText: "value",
    };

    return (
        <Chart
            chartType="PieChart"
            width="100%"
            height="260px"
            data={data}
            options={options}
        />
    )
}

export default TechnicianPerformance