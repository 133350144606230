import React, { useEffect, useState } from 'react'
import { Table, Typography } from 'antd';
import { getDetailAdjustmentTransaction } from '../../Api/GetData';

const DataDetailAdjustment = ({ record }) => {

    const [dataDetailAD, setDataDetailAD] = useState([]);

    useEffect(() => {
        const fetchDataDetailAD = async () => {
            try {
                const response = await getDetailAdjustmentTransaction(record.FacilityCode, record.ADNumber);
                setDataDetailAD(response);
            } catch (error) {
                console.log(error.response.data);
            }
        }
        fetchDataDetailAD();
    }, [record]);

    const columns = [
        {
            key: 'key',
            title: 'No',
            dataIndex: 'key',
            width: 60,
        },
        {
            title: 'Adjustment Number',
            dataIndex: 'ADNumber',
        },
        {
            title: 'Part Name',
            dataIndex: 'PartName',
        },
        // {
        //     title: 'Location Name',
        //     dataIndex: 'LocationName',
        // },
        // {
        //     title: 'Batch No',
        //     dataIndex: 'BatchNo',
        // },
        {
            title: 'Purchase Date',
            dataIndex: 'PurchaseDate',
        },
        {
            title: 'QTY',
            dataIndex: 'QTY',
        },
        {
            title: 'COGS',
            dataIndex: 'COGS',
        },
    ];

    return (
        <div key="document-tab" style={{ margin: "5px 0" }}>
            <Typography.Title level={3} style={{ margin: " 0  0 10px" }}>
                Detail
            </Typography.Title>
            <Table
                bordered
                columns={columns}
                dataSource={dataDetailAD}
                pagination={false}
                scroll={{ x: 800 }}
            />
        </div>
    )
}

export default DataDetailAdjustment