import { Box, TextField } from '@mui/material'
import { Button, Modal, Table, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import Header from '../Header';
import SearchData from '../../pages/global/components/FeatureTable/SearchData';
import { Link } from 'react-router-dom';

const InputModal = ({
    title,
    label,
    name,
    onBlur,
    onChange,
    value,
    error,
    helperText,
    dataSource,
    columns,
    loading,
    propsSelecteRow,
    propsOpenModal,
    LinktoCreate
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isSearching, setIsSearching] = useState(false); // State untuk loading pencarian

    const showModal = () => {
        setIsModalOpen(true);
        if (propsOpenModal) {
            propsOpenModal(true);
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setIsSearching(true); // Set loading saat pencarian dimulai
    };

    // Filter data saat searchText berubah
    const filteredData = dataSource.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    // Hentikan loading setelah data difilter
    useEffect(() => {
        if (isSearching) {
            const timeoutId = setTimeout(() => {
                setIsSearching(false);
            }, 500); // Misalnya 500ms untuk simulasi delay pencarian
            return () => clearTimeout(timeoutId);
        }
    }, [filteredData, isSearching]);

    const rowSelection = {
        type: "radio",
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            propsSelecteRow(selectedRows[0]);
            setIsModalOpen(false);
        },
    };

    const handleRowClick = (record, rowIndex) => {
        setSelectedRowKeys([record.key]);
        propsSelecteRow(record);
        setIsModalOpen(false);
    };

    return (
        <>
            <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={label}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                name={name}
                error={error}
                helperText={helperText}
                sx={{ gridColumn: "span 2" }}
                onClick={showModal}
                InputProps={{
                    readOnly: true,
                }}
            />

            <Modal
                centered
                title={<Header title={title} subtitle="" />}
                open={isModalOpen}
                closable={false}
                footer={<Button onClick={handleClose}>Close</Button>}
                style={{
                    maxWidth: "90%",
                    minWidth: "80%",
                }}
                bodyStyle={{
                    maxHeight: "65vh",
                    overflow: "auto",
                }}
            >
                {dataSource.length > 0 ? (
                    <>
                        <div>
                            <SearchData
                                filterValue={searchText}
                                handleFilterChange={handleSearch}
                            />
                        </div>
                        <Table
                            loading={loading || isSearching} // Tampilkan loading saat isSearching true
                            columns={columns}
                            dataSource={filteredData}
                            rowSelection={rowSelection}
                            onRow={(record, rowIndex) => ({
                                onClick: () => {
                                    handleRowClick(record, rowIndex);
                                },
                            })}
                            scroll={columns.length > 6 && { x: 2000 }}
                        />
                    </>
                ) : (
                    <Box sx={{ textAlign: 'center', padding: '16px' }}>
                        <Typography variant="body1" color="textSecondary">
                            No data available.
                        </Typography>
                        <Link
                            to={LinktoCreate}
                            target="_blank"
                            onClick={handleClose}
                            style={{ marginTop: '12px', display: 'inline-block' }}
                        >
                            Click to create in the {title} data.
                        </Link>
                    </Box>
                )}
            </Modal>
        </>
    );
}

export default InputModal;
