import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import Authentication from "../../auth";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getPMSchedule } from "../../../Api/Report/GetData";
import dayjs from "dayjs";



const dataSource = [
  {
    FacilityName: "Citra Xperience Kemayoran",
    WORNumber: "WOR001",
    WORDate: "23-11-2024",
    RequestBy: "Dimas",
    WOType: "Location",
    WOTypeRef: "Living Room",
    Status: "Pending",
  },
  {
    FacilityName: "Citra Xperience Kemayoran",
    WORNumber: "WOR002",
    WORDate: "10-11-2024",
    RequestBy: "Olivia",
    WOType: "Equipment",
    WOTypeRef: "Water Heater",
    Status: "Process",
  },
  {
    FacilityName: "Citra Xperience Kemayoran",
    WORNumber: "WOR003",
    WORDate: "03-11-2024",
    RequestBy: "Reza",
    WOType: "Equipment",
    WOTypeRef: "AC",
    Status: "Finish",
  },
]

const WORequestStatus = () => {

  Authentication();

  const data = dataSource.map((row, index) => ({ ...row, key: index + 1 }));

  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await getPMSchedule();
  //     setData(response);
  //   } catch (error) {
  //     console.log(error.response.data);
  //   }
  //   setIsLoading(false)
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);


  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'FacilityName',
      dataIndex: 'FacilityName',
      fixed: 'left',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'WORNumber',
      dataIndex: 'WORNumber',
      sorter: (a, b) => a.WORNumber.localeCompare(b.WORNumber),
    },
    {
      title: 'WORDate',
      dataIndex: 'WORDate',
      sorter: (a, b) => a.WORDate.localeCompare(b.WORDate),
    },
    {
      title: 'RequestBy',
      dataIndex: 'RequestBy',
      sorter: (a, b) => a.RequestBy.localeCompare(b.RequestBy),
    },
    {
      title: 'WOType',
      dataIndex: 'WOType',
      sorter: (a, b) => a.WOType.localeCompare(b.WOType),
    },
    {
      title: 'WOTypeRef',
      dataIndex: 'WOTypeRef',
      sorter: (a, b) => a.WOTypeRef.localeCompare(b.WOTypeRef),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="WO REQUEST STATUS"
          subtitle="All WO Request Status"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        // scroll={{ x: 1800 }}
      />

    </StyleContent>
  );
};

export default WORequestStatus