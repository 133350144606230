import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, DatePicker, Select, InputNumber } from 'antd';

import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab"
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { getDetailAdjustmentTransaction, getPartHistory } from '../../Api/GetData';
import { getLocation, getPart } from '../../../../Api/Master/GetData';

const { Option } = Select;
const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    PartData,
    fetchPart,
    LocationData,
    fetchLocation,
    getData,
    ...restProps
}) => {

    // const usedPartNames = getUsedPartNames();

    // console.log("Part", PartData);

    return (
        <td {...restProps}>
            {editing ? (
                <div>
                    {dataIndex === 'QTY' || dataIndex === 'COGS' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                        >
                            <InputNumber placeholder={title} min={1} style={{ width: '100%' }} />
                        </Form.Item>
                    ) : dataIndex === 'BatchNo' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                        >
                            <Input placeholder={title} maxLength={50} />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {dataIndex === 'PartName' && editing ? (
                                <Select
                                    maxTagCount="responsive"
                                    showSearch
                                    placeholder={title}
                                    onDropdownVisibleChange={fetchPart}
                                    notFoundContent={
                                        <Link to={`/master/part/form`} target="_blank" style={{ textDecoration: 'none' }}>
                                            There is no option, click to fill in part data
                                        </Link>
                                    }
                                >
                                    {PartData.filter(item => !getData.includes(item.Part.PartName)).map((item) => (
                                        <Option key={item.key} value={item.Part.PartName}>
                                            {item.Part.PartName}
                                        </Option>
                                    ))}
                                </Select>
                            ) : dataIndex === 'LocationName' && editing ? (
                                <Select
                                    maxTagCount="responsive"
                                    showSearch
                                    placeholder={title}
                                    onDropdownVisibleChange={fetchLocation}
                                    notFoundContent={
                                        <Link to={`/master/location/form`} target="_blank" style={{ textDecoration: 'none' }}>
                                            There is no option, click to fill in location data
                                        </Link>
                                    }
                                >
                                    {LocationData.map((item) => (
                                        <Option key={item.key} value={item.Location.LocationName}>
                                            {item.Location.LocationName}
                                        </Option>
                                    ))}
                                </Select>
                            ) : dataIndex === 'PurchaseDate' && editing ? (
                                <DatePicker format={dateFormat} />
                            ) : (
                                <Input placeholder={title} maxLength={50} />
                            )}
                        </Form.Item>
                    )}
                </div>
            ) : (
                children
            )}
        </td>

    );
};


const FormAdjustmentDetail = ({ onSaveData, FacilityCode, ADNumber, onEdit, onApproval, ADType }) => {

    console.log("ADType", ADType);

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [PartData, setPartData] = useState([]);
    const [LocationData, setLocationData] = useState([]);

    useEffect(() => {
        if (onEdit || onApproval) {
            const fetchData = async () => {
                try {
                    const response = await getDetailAdjustmentTransaction(FacilityCode, ADNumber);
                    const filter = response.map((row, index) => ({ ...row, key: index + 1 })).reverse()
                    setData(filter)
                    setCount(filter.length === 0 ? 0 : filter.map((item) => item.key)[0])
                    onSaveData(filter)
                } catch (error) {
                    setData([]);
                    setCount(0);
                    onSaveData([]);
                    console.log(error);
                }
            }

            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FacilityCode, ADNumber, onEdit]);

    const fetchPart = useCallback(async (open) => {
        if (open) {
            try {
                if (ADType === 0) {
                    const response = await getPartHistory();
                    const filterRes = response.filter((item => item.Part.IsSuspend !== true));
                    setPartData(filterRes);
                } else {
                    const response = await getPart();
                    const filterRes = response.filter((item => item.Part.IsSuspend !== true));
                    setPartData(filterRes);
                }
            } catch (error) {
                console.log(error);
                if (error.response && error.response.status === 404) {
                    setPartData([]);
                }
            }
        }
    }, [ADType]);

    useEffect(() => {
        fetchPart();
    }, [fetchPart]);

    const getData = data.map(item => item.PartName);


    const fetchLocation = useCallback(async (open) => {
        if (open) {
            try {
                const response = await getLocation(FacilityCode);
                const filterRes = response.filter((item => item.Location.IsSuspend !== true));
                setLocationData(filterRes);
            } catch (error) {
                console.log(error);
            }
        }
    }, [FacilityCode]);

    useEffect(() => {
        fetchLocation();
    }, [fetchLocation]);

    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        const PurchaseDate = record.PurchaseDate ? dayjs(record.PurchaseDate) : null;
        const part = record.PartName ? record.PartName : [];
        const location = record.LocationName ? record.LocationName : [];

        form.setFieldsValue({
            Description: '',
            ...record,
            PartName: part,
            LocationName: location,
            PurchaseDate: PurchaseDate,
        });
        setEditingKey(record.key);
    };

    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1, DetailNo: row.DetailNo - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.PartName) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);

            if (index > -1) {
                const item = newData[index];
                const PurchaseDate = row.PurchaseDate.format('YYYY-MM-DD');
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    PartCode: PartData.find(item => item.Part.PartName === row.PartName)?.Part.PartCode,
                    LocationCode: LocationData.find(item => item.Location.LocationName === row.LocationName)?.Location.LocationCode,
                    PurchaseDate: PurchaseDate,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            } else {
                newData.push({
                    ...row,
                    LocationCode: LocationData.find(item => item.Location.LocationName === row.LocationName)?.Location.LocationCode,
                    PartCode: PartData.find(item => item.Part.PartName === row.PartName)?.Part.PartCode,
                })
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;

        if (editingKey) {
            enqueueSnackbar("Complete the input form !", { variant: "warning" });
            return; // Stop saving if duplicate found
        }

        const newData = {
            key: num,
            FacilityCode: FacilityCode,
            ADNumber: ADNumber,
            // DetailNo: num,
            PartCode: '',
            LocationCode: '',
            BatchNo: ADNumber,
            PurchaseDate: '',
            QTY: 0,
            COGS: 0,
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {
            onSaveData(data);
            console.log("PostData", data);
            enqueueSnackbar("Success add form table data!!", { variant: "success" });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCancelAllData = () => {
        setData([]);
        setCount(0);
        onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            sorter: (a, b) => a.key - b.key,
            width: 80,
            fixed: 'left',
        },
        {
            title: 'Part Name',
            dataIndex: 'PartName',
            width: 200,
            editable: true,
        },
        // {
        //     title: 'Location Name',
        //     dataIndex: 'LocationName',
        //     editable: true,
        // },
        // {
        //     title: 'BatchNo',
        //     dataIndex: 'BatchNo',
        //     editable: true,
        // },
        {
            title: 'Purchase Date',
            dataIndex: 'PurchaseDate',
            width: 200,
            editable: true,
        },
        {
            title: 'QTY',
            dataIndex: 'QTY',
            width: 100,
            editable: true,
        },
        {
            title: 'COGS',
            dataIndex: 'COGS',
            width: 100,
            editable: true,
        },
    ];

    if (!onApproval) {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <DeleteOutlined style={{ fontSize: '18px' }} />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        });
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                PartData: PartData,
                fetchPart,
                LocationData: LocationData,
                fetchLocation,
                getData,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 10px 10px"
                }}
            >
                <Title level={3} style={{ margin: "0" }}>
                    Detail Adjustment
                </Title>
                {!onApproval && (
                    <Button
                        onClick={handleAdd}
                        color="primary"
                        variant="contained"
                        disabled={!!editingKey || !FacilityCode || !ADNumber}
                    >
                        + Add Data
                    </Button>
                )}
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
                scroll={{
                    x: 1300,
                }}
            />
            {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                    >
                        <Button
                            type="button"
                            color="error"
                            variant="contained"
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <LoadingButton
                        color="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        variant="contained"
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </LoadingButton>

                </div>
            )}
        </Form>
    );
};
export default FormAdjustmentDetail;