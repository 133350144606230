import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getDepartment, getDivision } from "../../../Api/Employee/GetData";
import { postDepartment } from "../../../Api/Employee/PostData";
import InputModal from "../../../components/Global/InputModal";

const FormDepartment = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsDivCode = searchParams.get('division');

  useEffect(() => {
    if (paramsDivCode) {
      setOpenModalDivision(true);
    }
  }, [paramsDivCode]);

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [departmentCode, setDepartmentCode] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [dataDivision, setDataDivision] = useState([]);
  const [selectDivision, setSelectDivision] = useState("");
  const [openModalDivision, setOpenModalDivision] = useState("");
  const DivisionCode = selectDivision ? selectDivision.DivisionCode : '';
  const DivisionName = selectDivision ? selectDivision.DivisionName : '';


  // DIVISION
  useEffect(() => {
    const fetchDivision = async () => {
      try {
        const res = await getDivision();
        setDataDivision(res);

        if (paramsDivCode) {
          const selected = res.filter((item) => item.DivisionCode === paramsDivCode);
          setSelectDivision(selected[0]);
        }

      } catch (error) {
        console.log(error);
      }
    }

    if (openModalDivision) {
      fetchDivision();
      setIsLoading(false);
      setOpenModalDivision(false);
    }

  }, [openModalDivision, paramsDivCode]);


  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("DivisionName", DivisionName);
    }
  }, [DivisionName]);

  const FetchDepartmentCode = useCallback(async () => {
    try {
      const response = await getDepartment(DivisionCode);
      if (response.length > 0) {
        const DPData = response.filter(
          (item) =>
            item.Department.DepCode && item.Department.DepCode.startsWith("DP")
        );
        if (DPData.length > 0) {
          const lastCode = DPData[DPData.length - 1].Department.DepCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setDepartmentCode(`DP${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setDepartmentCode("DP0001");
        }
      } else {
        setDepartmentCode("DP0001");
      }
    } catch (error) {
      setDepartmentCode("DP0001");
      console.log(error);
    }
  }, [DivisionCode]);

  useEffect(() => {
    if (DivisionCode) {
      FetchDepartmentCode();
    }
  }, [DivisionCode, FetchDepartmentCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("DepCode", departmentCode);
    }
  }, [departmentCode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const modifiedValues = {
        ...values,
        DivisionCode: DivisionCode,
        DepCode: departmentCode,
      };

      const response = await postDepartment(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/employee/department");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box>
          <StyleContent>
            <Header title="DEPARTMENT" subtitle="Create a Department" />
            <StyleForm>
              <InputModal
                title="DIVISION"
                label="Division Name"
                name="DivisionName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.DivisionName}
                error={!!touched.DivisionName && !!errors.DivisionName}
                helperText={touched.DivisionName && errors.DivisionName}
                dataSource={dataDivision}
                columns={columnsDivision}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectDivision(values)}
                propsOpenModal={(values) => setOpenModalDivision(values)}
                LinktoCreate={`/employee/division/form`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Department Code*"
                onBlur={handleBlur}
                value={departmentCode}
                onChange={(e) => {
                  handleChange(e);
                  setDepartmentCode(e.target.value);
                }}
                name="DepCode"
                error={!!touched.DepCode && !!errors.DepCode}
                helperText={touched.DepCode && errors.DepCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 10 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Department Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.DepName}
                name="DepName"
                error={!!touched.DepName && !!errors.DepName}
                helperText={touched.DepName && errors.DepName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />
            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  DivisionName: yup.string().required("Please complete this Division Name."),
  DepCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Department Code."),
  DepName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Department Name."),
});
const initialValues = {
  DivisionName: "",
  DepCode: "",
  DepName: "",
  Description: "",
};

export default FormDepartment;


const columnsDivision = [
  {
    key: 'key',
    title: 'No',
    dataIndex: 'key',
    width: 80,
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Division Code',
    dataIndex: 'DivisionCode',
    sorter: (a, b) => a.DivisionCode.localeCompare(b.DivisionCode),
  },
  {
    title: 'Division Name',
    dataIndex: 'DivisionName',
    sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
  },
]
