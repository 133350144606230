import React, { useEffect, useState } from 'react'
import { Table, Typography } from 'antd';
import { getWODocumentTrasaction } from '../../Api/GetData';

const DataWODoc = ({ record }) => {

  const [dataDoc, setDataDoc] = useState([]);

  useEffect(() => {
    const fetchDataDoc = async () => {
      try {
        const response = await getWODocumentTrasaction();
        const filterData = response.filter((item) =>
          item.FacilityCode === record.FacilityCode &&
          item.WONumber === record.WONumber
        )
        setDataDoc(filterData);
      } catch (error) {
        console.log(error.response.data);
      }
    }
    fetchDataDoc();
  }, [record]);

  const columns = [
    {
      key: 'DetailNo',
      title: 'No',
      dataIndex: 'DetailNo',
      width: 60,
    },
    {
      title: 'Document Name',
      dataIndex: 'DocName',
      width: 200,
    },
    {
      title: 'Document File',
      dataIndex: 'DocFile',
      width: 200,
    },
    {
      title: 'Document ExpDate',
      dataIndex: 'DocExpDate',
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      width: 200,
    },
  ];

  return (
    <div key="document-tab" style={{ margin: "5px 0" }}>
      <Typography.Title level={3} style={{ marginBottom: "5px" }}>
        Document
      </Typography.Title>
      <Table
        bordered
        columns={columns}
        dataSource={dataDoc}
        pagination={false}
      />
    </div>
  );
}

export default DataWODoc