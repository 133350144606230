import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


// MASTER DATA

export const getModuleID = async (Type) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/moduleID${Type ? `?Type=${Type}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;

}

export const getFacility = async (FacilityCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/facility${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));

}

export const getBuilding = async (FacilityCode, BuildingCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/building${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${BuildingCode ? `&BuildingCode=${BuildingCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getFloor = async (FacilityCode, BuildingCode, FloorLevelCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/floor${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${BuildingCode ? `&BuildingCode=${BuildingCode}` : ''}${FloorLevelCode ? `&FloorLevelCode=${FloorLevelCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getType = async (ModuleID, TypeCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/type${ModuleID ? `?ModuleID=${ModuleID}` : ''}${TypeCode ? `&TypeCode=${TypeCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getEquipmentModel = async (ModelCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/equipmentmodel${ModelCode ? `?ModelCode=${ModelCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getEquipment = async (FacilityCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/equipment${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getCategory = async (ModuleID) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/category${ModuleID ? `?ModuleID=${ModuleID}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSubCategory = async (ModuleID, CatCode, SubCatCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/subcategory${ModuleID ? `?ModuleID=${ModuleID}` : ''}${CatCode ? `&CatCode=${CatCode}` : ''}${SubCatCode ? `&SubCatCode=${SubCatCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getIcon = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/icon`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSchedule = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/schedule`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSensor = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/sensor`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getMap = async (FacilityCode, MapCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/maps${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}${MapCode ? `&MapCode=${MapCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getLocation = async (FacilityCode) => {
    const token = Cookies.get('accessToken');
    const url = `${baseUrl}/get/location${FacilityCode ? `?FacilityCode=${FacilityCode}` : ''}`;
    const response = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


export const getVehicle = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/vehicle`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getVendor = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/vendor`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getPart = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/part`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


// GET MASTER

export const getEquipmentVehicle = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/equipmentvehicle`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getUnits = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/unit`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}