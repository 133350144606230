import { Box } from '@mui/material'
import React from 'react'

const StyleGridHeader = ({ children }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            {children}
        </Box>
    )
}

export default StyleGridHeader