import React, { useState, useEffect } from 'react';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { AuthRoutes } from "./routes";
import { SnackbarProvider } from 'notistack';
import LoadingPage from './components/Global/LoadingPage';
import { MyProSidebarProvider } from './components/Global/Sidebar/SidebarContext';
import RouteDashboard from './routes/RouteDashboard';
// import Authentication from './pages/auth';
import Cookies from "js-cookie";
import { useDispatch } from 'react-redux';
import { getMe } from './features/authSlice';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [theme, colorMode] = useMode();

  useEffect(() => {
    // Simulasi proses loading data
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Mengganti angka ini untuk mengatur durasi loading
  }, []);

  const AuthRoute = ({ element }) => {
    const [isAuth, setIsAuth] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();

    const cekAuth = async () => {
      try {
        const token = Cookies.get('accessToken');
        if (!token) {
          setIsAuth(false);
          return;
        } else {
          dispatch(getMe(token));
          setIsAuth(true);
        }
      } catch (error) {
        setIsAuth(false);
      }
    }

    useEffect(() => {
      cekAuth();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (isAuth === null) return null;

    return isAuth ? element : <Navigate to="/" />

  }

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <SnackbarProvider
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Routes>
              {AuthRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              <Route
                path="/*"
                element={
                  <AuthRoute element={
                    <>
                      <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                          <CssBaseline />

                          <MyProSidebarProvider>

                            <RouteDashboard />

                          </MyProSidebarProvider>

                        </ThemeProvider>
                      </ColorModeContext.Provider>
                    </>
                  }
                  />
                }
              />
            </Routes>
          </SnackbarProvider>
        </>
      )
      }
    </>
  );
};

export default App;
