import { useSidebarContext } from '../Sidebar/SidebarContext';
import { useSelector } from 'react-redux';

const DeleteAuthorityIdx = () => {
    const { authIdx } = useSidebarContext();
    const { user } = useSelector((state) => state.auth);
    const DeleteAuthIdx = user && user.DelAuthority.filter(item => item === authIdx).join(',')
    return DeleteAuthIdx
}

export default DeleteAuthorityIdx;