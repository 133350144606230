import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser, reset } from "../../features/authSlice";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const token = Cookies.get('accessToken');

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
      dispatch(reset()); // Reset setelah sukses
    } else if (isError) {
      if (token) {
        enqueueSnackbar("please Login Again", { variant: "warning" });
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
      navigate("/");
      dispatch(reset()); // Reset setelah error
    }
  }, [user, isSuccess, isError, message, token, enqueueSnackbar, dispatch, navigate]);


  const handleFormSubmit = async (values) => {
    try {
      await dispatch(LoginUser(values));
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
  }

  const initialValues = {
    Email: "",
    Password: "",
  };

  const checkoutSchema = yup.object().shape({
    Email: yup
      .string()
      .email('Invalid email address format')
      .max(50, "Maximum of 50 characters allowed")
      .required("Please complete this Address Email."),
    Password: yup
      .string()
      .max(50, "Maximum of 50 characters allowed")
      .required("Please complete this Password."),
  });

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            component={motion.div}
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
              component={motion.div}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Address Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Email}
                name="Email"
                error={!!touched.Email && !!errors.Email}
                helperText={touched.Email && errors.Email}
                autoFocus
              />

              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Password}
                name="Password"
                error={!!touched.Password && !!errors.Password}
                helperText={touched.Password && errors.Password}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? (
                          <Icon icon="eva:eye-fill" />
                        ) : (
                          <Icon icon="eva:eye-off-fill" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

              />
            </Box>

            <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  to="#"
                  underline="hover"
                >
                  Forgot password?
                </Link>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {isLoading ? "Loading..." : "Login"}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
