import React from "react";
import { CircularProgress, Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const LoadingPage = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: `${colors.grey[400]} !important`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <CircularProgress />
        </Box>
    );
};

export default LoadingPage;
