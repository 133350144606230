import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { onlyNumber } from "../../global/Components";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ButtonForm } from "../../global/components/ButtonAction";
import MapIcon from "../Location/map";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import dayjs from "dayjs";
import { getEquipment, getEquipmentModel, getFacility, getLocation } from "../../../Api/Master/GetData";
import { postEquipment } from "../../../Api/Master/PostData";
import InputModal from "../../../components/Global/InputModal";
import { getEmployee } from "../../../Api/Employee/GetData";
import { Image } from "antd";
import { columnsEquipmentModel, columnsEquipmentOwner, columnsFacility, columnsLocation } from "./columnsEquipment";

const FormEquipment = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsFacCode = searchParams.get('faccode');

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [dataFacility, setDataFacility] = useState([]);
  const [selectFacility, setSelectFacility] = useState("");
  const [openModalFacility, setOpenModalFacility] = useState("");
  const FacilityCode = selectFacility ? selectFacility.FacilityCode : '';
  const FacilityName = selectFacility ? selectFacility.FacilityName : '';

  const [EquipmentCode, setEquipmentCode] = useState("");

  const [dataEquipmentModel, setDataEquipmentModel] = useState([]);
  const [selectEquipmentModel, setSelectEquipmentModel] = useState("");
  const [openModalEquipmentModel, setOpenModalEquipmentModel] = useState("");
  const EquipmentModelCode = selectEquipmentModel ? selectEquipmentModel.ModelCode : '';
  const EquipmentModelName = selectEquipmentModel ? selectEquipmentModel.ModelName : '';

  const [dataLocation, setDataLocation] = useState([]);
  const [selectLocation, setSelectLocation] = useState("");
  const [openModalLocation, setOpenModalLocation] = useState("");
  const LocationCode = selectLocation ? selectLocation.Location.LocationCode : '';
  const LocationName = selectLocation ? selectLocation.Location.LocationName : '';

  const [dataEquipmentOwner, setDataEquipmentOwner] = useState([]);
  const [selectEquipmentOwner, setSelectEquipmentOwner] = useState("");
  const [openModalEquipmentOwner, setOpenModalEquipmentOwner] = useState("");
  const EquipmentOwnerCode = selectEquipmentOwner ? selectEquipmentOwner.Employee.EmpCode : '';
  const EquipmentOwnerName = selectEquipmentOwner ? selectEquipmentOwner.Employee.EmpName : '';

  const [InstallDate, setInstallDate] = useState(dayjs());
  const [PurchaseDate, setPurchaseDate] = useState(null);
  const [WarrantyExpDate, setWarrantyExpDate] = useState(null);

  const [IconName, setIconName] = useState("");
  const [IconLocY, setIconLocY] = useState("");
  const [IconLocX, setIconLocX] = useState("");
  const [IconUrl, setIconUrl] = useState("");

  // DATA FACILITY
  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const res = await getFacility();
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataFacility(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalFacility) {
      fetchFacility();
      setIsLoading(false);
      setOpenModalFacility(false);
    }
  }, [openModalFacility]);


  // EQUIPMENT MODEL
  useEffect(() => {
    const fetchEquipmentModel = async () => {
      try {
        const res = await getEquipmentModel();
        const filterRes = res.filter((item) => item.IsSuspend !== true);
        setDataEquipmentModel(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalEquipmentModel) {
      fetchEquipmentModel();
      setIsLoading(false);
      setOpenModalEquipmentModel(false);
    }
  }, [openModalEquipmentModel]);

  // LOCATION
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const res = await getLocation(FacilityCode);
        const filterRes = res.filter((item) => item.Location.IsSuspend !== true);
        setDataLocation(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalLocation && FacilityCode) {
      fetchLocation();
      setIsLoading(false);
      setOpenModalLocation(false);
    }
  }, [FacilityCode, openModalLocation]);


  // EMPLOYEE
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const res = await getEmployee();
        const filterRes = res.filter((item) => item.Employee.IsSuspend !== true);
        setDataEquipmentOwner(filterRes);
      } catch (error) {
        console.log(error);
      }
    }

    if (openModalEquipmentOwner) {
      fetchEmployee();
      setIsLoading(false);
      setOpenModalEquipmentOwner(false);
    }
  }, [openModalEquipmentOwner]);



  const FetchEquipmentCode = useCallback(async () => {
    try {
      const response = await getEquipment(FacilityCode);
      if (response.length > 0) {
        const EQData = response.filter(
          (item) =>
            item.Equipment.EquipmentCode &&
            item.Equipment.EquipmentCode.startsWith("EQ")
        );
        if (EQData.length > 0) {
          const lastCode = EQData[EQData.length - 1].Equipment.EquipmentCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setEquipmentCode(`EQ${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setEquipmentCode("EQ0001");
        }
      } else {
        setEquipmentCode("EQ0001");
      }
    } catch (error) {
      setEquipmentCode("EQ0001");
      console.log(error);
    }
  }, [FacilityCode])

  useEffect(() => {
    if (FacilityCode) {
      FetchEquipmentCode();
    }
  }, [FetchEquipmentCode, FacilityCode]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("FacilityName", FacilityName);
      formRef.current.setFieldValue("EquipmentCode", EquipmentCode);
      formRef.current.setFieldValue("EquipmentModelName", EquipmentModelName);
      formRef.current.setFieldValue("LocationName", LocationName);
      formRef.current.setFieldValue("EquipmentOwner", EquipmentOwnerName);
    }
  }, [EquipmentCode, EquipmentModelName, EquipmentOwnerName, FacilityName, LocationName]);


  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      // if (!IconUrl) {
      //   enqueueSnackbar(
      //     "Fill in the data first, drag and drop the icon onto the map.",
      //     { variant: "warning" }
      //   );
      //   setLoading(false);
      //   return;
      // }

      const InstallDateNow = InstallDate.format("YYYY-MM-DD");
      const PurchaseDateNow = PurchaseDate.format("YYYY-MM-DD");
      const WarrantyExpDateNow = WarrantyExpDate.format("YYYY-MM-DD");

      const modifiedValues = {
        ...values,
        FacilityCode: FacilityCode,
        EquipmentCode: EquipmentCode,
        ModelCode: EquipmentModelCode,
        LocationCode: LocationCode,
        InstallDate: InstallDateNow,
        PurchaseDate: PurchaseDateNow,
        WarrantyExpDate: WarrantyExpDateNow,
        MapCode: getLocationMapCode,
        EquipmentOwner: EquipmentOwnerCode,
        IconId: IconName,
        IconLocationY: `${IconLocY}`,
        IconLocationX: `${IconLocX}`,
        url: IconUrl,
      };

      const response = await postEquipment(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/equipment");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (!onlyNumber.test(e.key)) {
      e.preventDefault();
    }
  };

  const getLocationMapCode = selectLocation ? selectLocation.Location.MapCode : "";

  // console.log(getLocationMapCode);


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="FORM EQUIPMENT" subtitle="Create a Equipment" />
            <StyleForm>

              <InputModal
                title="FACILITY"
                label="Facility Name"
                name="FacilityName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.FacilityName}
                error={!!touched.FacilityName && !!errors.FacilityName}
                helperText={touched.FacilityName && errors.FacilityName}
                dataSource={dataFacility}
                columns={columnsFacility}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectFacility(values)}
                propsOpenModal={(values) => setOpenModalFacility(values)}
                LinktoCreate={`/master/facility/form`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Equipment Code*"
                onBlur={handleBlur}
                value={EquipmentCode}
                onChange={(e) => {
                  handleChange(e);
                  setEquipmentCode(e.target.value);
                }}
                name="EquipmentCode"
                error={!!touched.EquipmentCode && !!errors.EquipmentCode}
                helperText={touched.EquipmentCode && errors.EquipmentCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 20 }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Equipment Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.EquipmentName}
                name="EquipmentName"
                error={!!touched.EquipmentName && !!errors.EquipmentName}
                helperText={touched.EquipmentName && errors.EquipmentName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Serial Number*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.SN}
                name="SN"
                error={!!touched.SN && !!errors.SN}
                helperText={touched.SN && errors.SN}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Asset No*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.AssetNo}
                name="AssetNo"
                error={!!touched.AssetNo && !!errors.AssetNo}
                helperText={touched.AssetNo && errors.AssetNo}
                sx={{ gridColumn: "span 2" }}
              />

              <InputModal
                title="EQUIPMENT MODEL"
                label="Equipment Model Name"
                name="EquipmentModelName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.EquipmentModelName}
                error={!!touched.EquipmentModelName && !!errors.EquipmentModelName}
                helperText={touched.EquipmentModelName && errors.EquipmentModelName}
                dataSource={dataEquipmentModel}
                columns={columnsEquipmentModel}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectEquipmentModel(values)}
                propsOpenModal={(values) => setOpenModalEquipmentModel(values)}
                LinktoCreate={`/master/equipmentmodel/form`}
              />

              <InputModal
                title="LOCATION"
                label="Location Name"
                name="LocationName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.LocationName}
                error={!!touched.LocationName && !!errors.LocationName}
                helperText={touched.LocationName && errors.LocationName}
                dataSource={dataLocation}
                columns={columnsLocation}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectLocation(values)}
                propsOpenModal={(values) => setOpenModalLocation(values)}
                LinktoCreate={`/master/location/form`}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Install Date*"
                  value={InstallDate}
                  onBlur={handleBlur}
                  onChange={(date) => setInstallDate(date)}
                  name="InstallDate"
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Purchase Date*"
                  value={PurchaseDate}
                  onBlur={handleBlur}
                  onChange={(date) => setPurchaseDate(date)}
                  name="PurchaseDate"
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Warranty Exp Date*"
                  value={WarrantyExpDate}
                  onBlur={handleBlur}
                  onChange={(date) => {
                    setWarrantyExpDate(date);
                    setFieldValue("WarrantyExpDate", date);
                  }}
                  name="WarrantyExpDate"
                  sx={{ gridColumn: "span 2" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!touched.WarrantyExpDate && !!errors.WarrantyExpDate}
                      helperText={touched.WarrantyExpDate && errors.WarrantyExpDate}
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Meter Reading*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.MeterReading}
                name="MeterReading"
                sx={{ gridColumn: "span 2" }}
                onKeyPress={handleKeyPress}
              />

              <InputModal
                title="EMPLOYEE"
                label="Equipment Owner"
                name="EquipmentOwner"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.EquipmentOwner}
                error={!!touched.EquipmentOwner && !!errors.EquipmentOwner}
                helperText={touched.EquipmentOwner && errors.EquipmentOwner}
                dataSource={dataEquipmentOwner}
                columns={columnsEquipmentOwner}
                loading={isLoading}
                propsSelecteRow={(values) => setSelectEquipmentOwner(values)}
                propsOpenModal={(values) => setOpenModalEquipmentOwner(values)}
                LinktoCreate={`/employee/employee/form`}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 4" }}
                multiline
                rows={4}
              />

              <MapIcon
                propsFacilityCode={FacilityCode}
                propsMapCode={getLocationMapCode}
                propsIconName={(values) => setIconName(values)}
                propsIconLocY={(values) => setIconLocY(values)}
                propsIconLocX={(values) => setIconLocX(values)}
                propsIconUrl={(values) => setIconUrl(values)}
              />
            </StyleForm>
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  FacilityName: yup
    .string()
    .required("Please complete this Facility Name."),
  EquipmentCode: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this Equipment Code."),
  EquipmentName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Equipment Name."),
  EquipmentModelName: yup.string().required("Please complete this Model Name."),
  LocationName: yup.string().required("Please complete this Location Name."),
  SN: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this SN."),
  AssetNo: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Asset No."),
  // MeterReading: yup.number().required("Please complete this Meter Reading."),
  EquipmentOwner: yup
    .string()
    .required("Please complete this Equipment Owner."),
});
const initialValues = {
  FacilityName: "",
  EquipmentCode: "",
  EquipmentName: "",
  EquipmentModelName: "",
  LocationName: "",
  SN: "",
  AssetNo: "",
  InstallDate: null,
  PurchaseDate: null,
  WarrantyExpDate: "",
  MeterReading: 0,
  EquipmentOwner: "",
  Description: "",
};

export default FormEquipment;
