import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

export const getDivision = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/division`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getDepartment = async (DivisionCode, DepartmentCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/department${DivisionCode ? `?DivisionCode=${DivisionCode}` : ''}${DepartmentCode ? `&DepCode=${DepartmentCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getSection = async (DivisionCode, DepCode, SectionCode) => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/section${DivisionCode ? `?DivisionCode=${DivisionCode}` : ''}${DepCode ? `&DepCode=${DepCode}` : ''}${SectionCode ? `&SectionCode=${SectionCode}` : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getEmployee = async () => {
    const token = Cookies.get('accessToken');
    const response = await axios.get(`${baseUrl}/get/employee`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}