import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Autocomplete from "@mui/material/Autocomplete";
import { StyleContent, StyleFormInput } from "../../global/StyleCSS";
import Authentication from "../../auth";
import { ButtonForm } from "../../global/components/ButtonAction";
import MapIcon from "./MapIcon";
import { getBuilding, getFacility, getFloor } from "../../../Api/Master/GetData";

const Mapping = () => {

    Authentication();

    const formRef = React.useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [autocompleteOpened, setAutocompleteOpened] = useState(false);

    const [facilityCode, setFacilityCode] = useState([]);
    const [selectedFacilityCode, setSelectedFacilityCode] = useState(null);

    const [buildingCode, setBuildingCode] = useState([]);
    const [selectedBuildingCode, setSelectedBuildingCode] = useState(null);

    const [floorLevel, setFloorLevel] = useState([]);
    const [selectedFloorLevel, setSelectedFloorLevel] = useState(null);

    const FetchCode = useCallback(async () => {
        try {
            setIsLoading(true);

            const resFacility = await getFacility();
            setFacilityCode(resFacility);

            if (selectedFacilityCode) {

                const resBuilding = await getBuilding();
                const filterData = resBuilding.filter((item) =>
                    item.Building.FacilityCode === selectedFacilityCode
                )
                setBuildingCode(filterData);
            }
            if (selectedBuildingCode) {

                const resFloorLevel = await getFloor();
                const filterData = resFloorLevel.filter((item) => item.FloorLevel.BuildingCode === selectedBuildingCode)
                setFloorLevel(filterData);
            }

        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }, [selectedFacilityCode, selectedBuildingCode]);

    useEffect(() => {
        if (autocompleteOpened) {
            FetchCode();
            setAutocompleteOpened(false); // Reset state setelah memanggil API
        }
    }, [autocompleteOpened, FetchCode, selectedFloorLevel]);

    const handleFacilitySelection = (event, value) => {
        if (value) {
            setSelectedFacilityCode(value.FacilityCode);
        } else {
            setSelectedFacilityCode(null);
        }
    };

    const handleBuildingSelection = (event, value) => {
        if (value) {
            setSelectedBuildingCode(value.Building.BuildingCode);
        } else {
            setSelectedBuildingCode(null);
        }
    };

    const handleFloorLevelSelection = (event, value) => {
        if (value) {
            setSelectedFloorLevel(value.FloorLevel.FloorLevelCode);
        } else {
            setSelectedFloorLevel(null);
        }
    };

    const handleCancel = () => {
        formRef.current.resetForm();
        setFacilityCode([]);
        setSelectedFacilityCode(null);
        setBuildingCode([]);
        setSelectedBuildingCode(null);
        setFloorLevel([]);
        setSelectedFloorLevel(null);
    };

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {
            const modifiedValues = {
                ...values,
                FloorLevelCode: selectedFloorLevel,
            };

            console.log(modifiedValues);

            //   const response = await postFloor(modifiedValues);
            //   enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            // navigate("/master/floorLevel");

        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response);
        }
        setLoading(false);
    };

    const getFloorLevelMapCode = floorLevel.filter((item) =>
        item.FloorLevel.FloorLevelCode === selectedFloorLevel).map((item) =>
            item.FloorLevel.MapCode).join('');

    return (

        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
            innerRef={formRef}
            validateOnChange={true}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <Box>
                    <Box sx={StyleContent}>
                        <Header title="MAPPING" subtitle="Update Mapping Data" />
                        <Box sx={StyleFormInput}>

                            <Autocomplete
                                fullWidth
                                disablePortal
                                onOpen={() => setAutocompleteOpened(true)}
                                loading={isLoading}
                                options={facilityCode}
                                getOptionLabel={(option) => option.FacilityName}
                                sx={{ gridColumn: "span 2" }}
                                onChange={(event, value) => {
                                    handleFacilitySelection(event, value);
                                    setFieldValue("FacilityName", value ? value.FacilityName : "");
                                }}
                                value={
                                    facilityCode.find((option) => option.FacilityCode === selectedFacilityCode) || null
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Facility Name*"
                                        onBlur={handleBlur}
                                        error={!!touched.FacilityName && !!errors.FacilityName}
                                        helperText={touched.FacilityName && errors.FacilityName}
                                    />
                                )}
                                // Jika options kosong, tambahkan link ke halaman fasilitas
                                noOptionsText={
                                    facilityCode.length === 0 ?
                                        <Link to="/master/facility/form" style={{ textDecoration: 'none' }}>there is no option, please fill in the facilities data</Link>
                                        : "No options"
                                }
                            />

                            <Autocomplete
                                fullWidth
                                disablePortal
                                onOpen={() => setAutocompleteOpened(true)}
                                loading={isLoading}
                                options={buildingCode}
                                getOptionLabel={(option) => option.Building.BuildingName}
                                sx={{ gridColumn: "span 2" }}
                                onChange={(event, value) => {
                                    handleBuildingSelection(event, value);
                                    setFieldValue("BuildingName", value ? value.Building.BuildingName : "");
                                }}
                                value={
                                    buildingCode.find((option) => option.Building.BuildingCode === selectedBuildingCode) || null
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Building Name*"
                                        onBlur={handleBlur}
                                        error={!!touched.BuildingName && !!errors.BuildingName}
                                        helperText={touched.BuildingName && errors.BuildingName}
                                    />
                                )}
                                noOptionsText={
                                    selectedFacilityCode && buildingCode.length === 0 ?
                                        <Link to="/master/buildings/form" style={{ textDecoration: 'none' }}>
                                            there is no option, please fill in the buildings data
                                        </Link>
                                        : "No options"
                                }
                            />

                            <Autocomplete
                                fullWidth
                                disablePortal
                                onOpen={() => setAutocompleteOpened(true)}
                                loading={isLoading}
                                options={floorLevel}
                                getOptionLabel={(option) => option.FloorLevel.FloorLevelName}
                                sx={{ gridColumn: "span 4" }}
                                onChange={(event, value) => {
                                    handleFloorLevelSelection(event, value);
                                    setFieldValue("FloorLevelName", value ? value.FloorLevel.FloorLevelName : "");
                                }}
                                value={
                                    floorLevel.find((option) => option.FloorLevel.FloorLevelCode === selectedFloorLevel) || null
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Floor Level Name*"
                                        onBlur={handleBlur}
                                        error={!!touched.FloorLevelName && !!errors.FloorLevelName}
                                        helperText={touched.FloorLevelName && errors.FloorLevelName}
                                    />
                                )}
                                // Jika options kosong, tambahkan link ke halaman fasilitas
                                noOptionsText={
                                    selectedBuildingCode && floorLevel.length === 0 ?
                                        <Link to="/master/floorLevel/form" style={{ textDecoration: 'none' }}>there is no option, please fill in the floor level data</Link>
                                        : "No options"
                                }
                            />

                            <MapIcon
                                propsMapCode={getFloorLevelMapCode}
                            // propsIconName={(values) => setIconName(values)}
                            // propsIconLocY={(values) => setIconLocY(values)}
                            // propsIconLocX={(values) => setIconLocX(values)}
                            // propsIconUrl={(values) => setIconUrl(values)}
                            />

                        </Box>
                    </Box>

                    <ButtonForm
                        handleCancel={handleCancel}
                        handleSubmit={handleSubmit}
                        loading={loading}
                    />

                </Box>
            )}
        </Formik>

    );
};


const checkoutSchema = yup.object().shape({
    //   FloorLevelName: yup
    //     .string()
    //     .required("Please complete this Floor Level Name."),
    //   MapName: yup
    //     .string()
    //     .required("Please complete this Map Name."),
});

const initialValues = {
    FacilityName: "",
    BuildingName: "",
    FloorLevelName: "",
};

export default Mapping;
