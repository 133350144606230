import React, { useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography } from 'antd';

import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab"
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { getPMTaskTrasaction } from '../../Api/GetData';
import { getCategory } from '../../../../Api/Master/GetData';
import InputDetailModal from '../../../../components/Global/InputDetailModal';

const { TextArea } = Input;
const { Title } = Typography;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    onDataTask,
    onEdit,
    ...restProps
}) => {

    useEffect(() => {
        if (onEdit) {
            setOpenTask(true);
        }
    }, [onEdit]);


    const [isLoading, setIsLoading] = useState(true);

    const [dataTask, setDataTask] = useState([]);
    const [selectTask, setSelectTask] = useState("");
    const [openTask, setOpenTask] = useState(null);

    useEffect(() => {
        const fetchTask = async () => {
            try {
                const res = await getCategory("Work");
                setDataTask(res);

                if (onEdit) {
                    const selected = res.filter(item => item.TaskCode === record.VendorCode);
                    setSelectTask(selected[0]);
                }

            } catch (error) {
                console.log(error);
            }
        }

        if (openTask) {
            fetchTask();
            setOpenTask(false);
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openTask]);

    useEffect(() => {
        if (selectTask) {
            onDataTask(selectTask);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTask]);

    return (
        <td {...restProps}>
            {editing ? (
                <div>

                    {dataIndex === 'Description' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                        >

                            <TextArea rows={4} placeholder={title} />
                        </Form.Item>

                    ) : (

                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {dataIndex === 'TaskName' && editing ? (

                                <InputDetailModal
                                    title="TASK"
                                    label="Task"
                                    name={dataIndex}
                                    dataSource={dataTask}
                                    loading={isLoading}
                                    columns={columnsTask}
                                    onData={(values) => setSelectTask(values)}
                                    onOpenModal={(values) => setOpenTask(values)}
                                    onDetail={true}
                                />

                            ) : (

                                <Input placeholder={title} maxLength={20} />

                            )}
                        </Form.Item>
                    )}

                </div>
            ) : (
                children
            )
            }
        </td >
    );
};


const FormPMTask = ({ onSaveData, FacilityCode, PMNumber, onEdit, onApproval }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [dataTask, setDataTask] = useState(null);

    useEffect(() => {
        if (form && dataTask){
            form.setFieldsValue({
                TaskName: dataTask.CatName
            })
        }
    }, [dataTask, form, onEdit]);


    useEffect(() => {
        if (onEdit || onApproval) {
            const fetchData = async () => {
                try {
                    const resTask = await getPMTaskTrasaction(FacilityCode, PMNumber);
                    const filter = resTask.map((row, index) => ({
                        ...row, key: index + 1
                    })).reverse();
                    setData(filter);
                    setCount(filter.length === 0 ? 0 : filter.map((item) => item.key)[0]);
                    onSaveData(filter);
                } catch (error) {
                    if (!onApproval) {
                        setData([]);
                        setCount(0);
                        onSaveData([]);
                        console.log(error);
                    }
                }
            };
            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FacilityCode, PMNumber]);



    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        const task = record.TaskName ? record.TaskName : [];

        form.setFieldsValue({
            Description: '',
            ...record,
            TaskName: task,
        });
        setEditingKey(record.key);
    };


    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1, DetailNo: row.DetailNo - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.TaskName) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);
            const TaskCode = dataTask.CatCode;

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    TaskCode: TaskCode,
                });
                setData(newData);
                setEditingKey('');
                onSaveData(newData);
            } else {
                newData.push({
                    ...row,
                    TaskCode: TaskCode,
                });
                setData(newData);
                setEditingKey('');
                onSaveData(newData);
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;

        if (editingKey) {
            enqueueSnackbar("Complete the input form !", { variant: "warning" });
            return; // Stop saving if duplicate found
        }

        const newData = {
            key: num,
            FacilityCode: FacilityCode,
            PMNumber: PMNumber,
            DetailNo: num,
            TaskCode: '',
            TaskName: '',
            Description: '',
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {
            const newData = data.map(item => ({
                ...item,
                // TaskCode: categoryWork.find(cat => cat.CatName === item.TaskName)?.CatCode,
            }));
            onSaveData(newData);
            console.log("PostData", newData);
            enqueueSnackbar("Success add form table data!!", { variant: "success" });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleCancelAllData = () => {
        setData([]);
        setCount(0);
        onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'Task Name',
            dataIndex: 'TaskName',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            editable: true,
        },
    ];

    if (!onApproval) {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <DeleteOutlined style={{ fontSize: '18px' }} />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        },)
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onDataTask: (values) => setDataTask(values),
                onEdit: onEdit
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 10px 10px"
                }}
            >
                <Title level={3} style={{ margin: "0" }}>
                    Task
                </Title>
                {!onApproval && (
                    <Button
                        onClick={handleAdd}
                        color="primary"
                        variant="contained"
                        disabled={!!editingKey || !FacilityCode || !PMNumber}
                    >
                        + Add Data
                    </Button>
                )}
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
            />
            {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                    >
                        <Button
                            type="button"
                            color="error"
                            variant="contained"
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <LoadingButton
                        color="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        variant="contained"
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </LoadingButton>

                </div>
            )}
        </Form>
    );
};
export default FormPMTask;


const columnsTask = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Category Code',
        dataIndex: 'CatCode',
        fixed: 'left',
        sorter: (a, b) => a.CatCode.localeCompare(b.CatCode),
    },
    {
        title: 'Category Name',
        dataIndex: 'CatName',
        sorter: (a, b) => a.CatName.localeCompare(b.CatName),
    },
    {
        title: 'Module ID',
        dataIndex: 'ModuleID',
        sorter: (a, b) => a.ModuleID.localeCompare(b.ModuleID),
    },
]