import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import Authentication from "../../auth";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getPMSchedule } from "../../../Api/Report/GetData";
import dayjs from "dayjs";



const dataSource = [
  {
    FacilityName: "Citra Xperience Kemayoran",
    MPNumber: "PM001",
    MPDate: "04-11-2024",
    MPStatus: "Success",
    MPType: "Location",
    DueDate: "30-10-2024",
    MeterReading: "0",
    StartDate: "01-11-2024",
    StartEnd: "03-11-2024",
    LocationName: "Living Room",
    Status: "Process",
  },
  {
    FacilityName: "Citra Xperience Kemayoran",
    MPNumber: "PM002",
    MPDate: "04-10-2024",
    MPStatus: "Done",
    MPType: "Location",
    DueDate: "01-10-2024",
    MeterReading: "0",
    StartDate: "30-09-2024",
    StartEnd: "03-10-2024",
    LocationName: "Kitchen Room",
    Status: "Finish",
  },
]

const MPTransactionStatus = () => {

  Authentication();

  const data = dataSource.map((row, index) => ({ ...row, key: index + 1 }));

  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await getPMSchedule();
  //     setData(response);
  //   } catch (error) {
  //     console.log(error.response.data);
  //   }
  //   setIsLoading(false)
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);


  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'FacilityName',
      dataIndex: 'FacilityName',
      fixed: 'left',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'MPNumber',
      dataIndex: 'MPNumber',
      sorter: (a, b) => a.MPNumber.localeCompare(b.MPNumber),
    },
    {
      title: 'MPDate',
      dataIndex: 'MPDate',
      sorter: (a, b) => a.MPDate.localeCompare(b.MPDate),
    },
    {
      title: 'MPStatus',
      dataIndex: 'MPStatus',
      sorter: (a, b) => a.MPStatus.localeCompare(b.MPStatus),
    },
    {
      title: 'MPType',
      dataIndex: 'MPType',
      sorter: (a, b) => a.MPType.localeCompare(b.MPType),
    },
    {
      title: 'DueDate',
      dataIndex: 'DueDate',
      sorter: (a, b) => a.DueDate.localeCompare(b.DueDate),
    },
    {
      title: 'MeterReading',
      dataIndex: 'MeterReading',
      sorter: (a, b) => a.MeterReading.localeCompare(b.MeterReading),
    },
    {
      title: 'StartDate',
      dataIndex: 'StartDate',
      sorter: (a, b) => a.StartDate.localeCompare(b.StartDate),
    },
    {
      title: 'StartEnd',
      dataIndex: 'StartEnd',
      sorter: (a, b) => a.StartEnd.localeCompare(b.StartEnd),
    },
    {
      title: 'LocationName',
      dataIndex: 'LocationName',
      sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="MP TRANSACTION STATUS"
          subtitle="All MP Transaction Status"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 1800 }}
      />

    </StyleContent>
  );
};

export default MPTransactionStatus