import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditEmployee from "./edit";
import DeleteEmployee from "./delete";
import { Space, Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import AddAuthorityIdx from "../../../components/Global/Authority/AddAuthority";
import EditAuthorityIdx from "../../../components/Global/Authority/EditAuthority";
import DeleteAuthorityIdx from "../../../components/Global/Authority/DeleteAuthority";
import PrintAuthorityIdx from "../../../components/Global/Authority/PrintAuthority";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getEmployee } from "../../../Api/Employee/GetData";

const Employee = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getEmployee();
      setData(response);
    } catch (error) {
      //console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Employee Name',
      dataIndex: ['Employee', 'EmpName'],
      sorter: (a, b) => a.Employee.EmpName.localeCompare(b.Employee.EmpName),
    },
    {
      title: 'Address',
      dataIndex: ['Employee', 'Address'],
      width: 400,
      sorter: (a, b) => a.Employee.Address.localeCompare(b.Employee.Address),
    },
    {
      title: 'Phone Number 1',
      dataIndex: ['Employee', 'Phone1'],
      sorter: (a, b) => a.Employee.Phone1.localeCompare(b.Employee.Phone1),
    },
    {
      title: 'Phone Number 2',
      dataIndex: ['Employee', 'Phone2'],
      sorter: (a, b) => a.Employee.Phone2.localeCompare(b.Employee.Phone2),
    },
    {
      title: 'NIK',
      dataIndex: ['Employee', 'NIK'],
      sorter: (a, b) => a.Employee.NIK.localeCompare(b.Employee.NIK),
    },
    {
      title: 'Division Name',
      dataIndex: 'DivisionName',
      sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
    },
    {
      title: 'Department Name',
      dataIndex: 'DepName',
      sorter: (a, b) => a.DepName.localeCompare(b.DepName),
    },
    {
      title: 'Section Name',
      dataIndex: 'SectionName',
      sorter: (a, b) => a.SectionName.localeCompare(b.SectionName),
    },
    {
      title: 'IsTechnician',
      dataIndex: ['Employee', 'IsTechnician'],
      sorter: (a, b) => a.Employee.IsTechnician.localeCompare(b.Employee.IsTechnician),
      render: (isTechnician) => (
        <span>
          {isTechnician ? 'Technician' : 'Not a technician'}
        </span>
      ),
    },
    {
      title: 'Suspend',
      dataIndex: ['Employee', 'IsSuspend'],
      width: 120,
      render: (isSuspend) =>
        isSuspend ? (
          <Tooltip title="Suspend">
            <CheckCircleIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip title="Unsuspend">
            <CancelIcon color="error" />
          </Tooltip>
        ),
      sorter: (a, b) => a.Employee.IsSuspend.localeCompare(b.Employee.IsSuspend),
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Space>
          {myEditAuthIdx && (
            <EditEmployee data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.Employee.IsSuspend && (
            <DeleteEmployee data={record} onDelete={fetchData} />
          )}
        </Space>
      ),
    },)
  }

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    const filteredData = data.filter((row) => {
      // Sesuaikan dengan kolom yang ingin Anda filter
      return (
        row.Employee.EmpName.toLowerCase().includes(value.toLowerCase()) ||
        row.Employee.Address.toLowerCase().includes(value.toLowerCase()) ||
        row.Employee.Phone1.toLowerCase().includes(value.toLowerCase()) ||
        row.Employee.Phone2.toLowerCase().includes(value.toLowerCase()) ||
        row.Employee.NIK.toLowerCase().includes(value.toLowerCase()) ||
        row.DivisionName.toLowerCase().includes(value.toLowerCase()) ||
        row.DepName.toLowerCase().includes(value.toLowerCase()) ||
        row.SectionName.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = [
    { title: "key", key: "key" },
    { title: "EmpCode", key: ["Employee", "EmpCode"] },
    { title: "EmpName", key: ["Employee", "EmpName"] },
    { title: "Address", key: ["Employee", "Address"] },
    { title: "Phone1", key: ["Employee", "Phone1"] },
    { title: "Phone2", key: ["Employee", "Phone2"] },
    { title: "NIK", key: ["Employee", "NIK"] },
    { title: "DivisionName", key: "DivisionName" },
    { title: "DepName", key: "DepName" },
    { title: "SectionName", key: "SectionName" },
    { title: "IsTechnician", key: ["Employee", "IsTechnician"] },
  ];

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="EMPLOYEE" subtitle="All employee Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={selectedRows.length > 0 ? selectedRows : data}
              columns={columnsExport}
              name="employee"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="/employee/employee/form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filterValue ? filteredRows : data}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.every((record) => record.Description === "") ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 2400 }}
      />

    </StyleContent>
  );
};

export default Employee;
