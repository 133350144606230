import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;

export const updateTranApproval = async (FacilityCode, TranIdx, ApprovalNo, TranNumber, TranApprovalData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/tranapproval?FacilityCode=${FacilityCode}&TranIdx=${TranIdx}&ApprovalNo=${ApprovalNo}&TranNumber=${TranNumber}`, TranApprovalData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}



// WO REQUEST TRANSACTION
export const updateWORequest = async (FacilityCode, WORNumber, WORequestData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/worequest?FacilityCode=${FacilityCode}&WORNumber=${WORNumber}`, WORequestData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}


// WO TRANSACTION
export const updateWOTransaction = async (FacilityCode, WONumber, WOTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/wotransaction?FacilityCode=${FacilityCode}&WONumber=${WONumber}`, WOTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}


// PM TRANSACTION
export const updatePMTransaction = async (FacilityCode, PMNumber, PMTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/pmtransaction?FacilityCode=${FacilityCode}&PMNumber=${PMNumber}`, PMTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// DM TRANSACTION
export const updateDMTransaction = async (FacilityCode, DMNumber, DMTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/dmtransaction?FacilityCode=${FacilityCode}&DMNumber=${DMNumber}`, DMTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// MP TRANSACTION
export const updateMPTransaction = async (FacilityCode, MPNumber, MPTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/mptransaction?FacilityCode=${FacilityCode}&MPNumber=${MPNumber}`, MPTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

// Adjustment TRANSACTION
export const updateAdjustmentTransaction = async (FacilityCode, ADNumber, ADTransactionData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/adjustment?FacilityCode=${FacilityCode}&ADNumber=${ADNumber}`, ADTransactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}