import { Autocomplete, Box, IconButton, TextField, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import Header from '../../../components/Header';
import { ButtonUpdate } from '../../global/components/ButtonAction';
import { Formik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Link } from 'react-router-dom';
import { getPMMainTrasaction } from '../Api/GetData';
import { updateMPTransaction } from '../Api/UpdateData';
import { useSnackbar } from 'notistack';
import FormMPRE from './MP_RE/form';
import FormMPPart from './MP_Part/form';
import FormMPDoc from './MP_Doc/form';
import FormMPCT from './MP_CT/form';
import dayjs from 'dayjs';
import StyleForm from '../../../components/Global/StyleContent/StyleForm';
import StyleEditHeader from '../../../components/Global/StyleContent/StyleEdit/StyleEditHeader';
import { onlyNumber } from '../../global/Components';

const EditMPTransaction = ({ data, onEdit }) => {

    const dataEdit = data.MPTransaction;

    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [MPDate, setMPDate] = useState(dayjs(dataEdit.MPDate));

    const [StartScheduleDate, setStartScheduleDate] = useState(dayjs(dataEdit.StartScheduleDate));

    const [FinishScheduleDate, setFinishScheduleDate] = useState(dayjs(dataEdit.FinishScheduleDate));

    const [dataPM, setDataPM] = useState([]);
    const valuePMNumber = dataPM && dataPM.MPTransaction ? dataPM.PMTransaction.PMNumber : '';
    console.log("Data PM", dataPM);

    // const [PMNumber, setPMNumber] = useState([]);
    // const [selectedPMNumber, setSelectedPMNumber] = useState(dataEdit.PMNumber);

    const [PMSchedule, setPMSchedule] = useState([]);
    const [selectedPMSchedule, setSelectedPMSchedule] = useState(dataEdit.ScheduleCode);

    const [RequestBy, setRequestBy] = useState("");
    const [MPType, setMPType] = useState("");
    const [LocationName, setLocationName] = useState("");
    const [LocationCode, setLocationCode] = useState("");
    const [workCatName, setWorkCatName] = useState("");
    const [workCatCode, setWorkCatCode] = useState("");
    const [workSubCatName, setWorkSubCatName] = useState("");
    const [workSubCatCode, setWorkSubCatCode] = useState("");
    const [DueDate, setDueDate] = useState(null);

    const [dataPart, setDataPart] = useState([]);
    const [dataRE, setDataRE] = useState([]);
    const [dataDoc, setDataDoc] = useState([]);
    const [dataCT, setDataCT] = useState([]);

    useEffect(() => {
        if (open) {
            const fetchPMNumber = async () => {
                try {
                    const res = await getPMMainTrasaction(dataEdit.FacilityCode, dataEdit.PMNumber);
                    setDataPM(res[0]);
                } catch (error) {
                    console.log(error);
                }
            }
            fetchPMNumber();
        }
    }, [dataEdit.FacilityCode, dataEdit.PMNumber, open]);

    useEffect(() => {
        if (open && dataPM && dataPM.PMTransaction) {
            setIsLoading(true);
            setPMSchedule(dataPM.PMHistories)
            setIsLoading(false);
        }
    }, [dataPM, open]);

    useEffect(() => {
        if (open && dataPM && dataPM.PMTransaction && PMSchedule) {
            setRequestBy(dataPM.PMTransaction.RequestBy);
            setMPType(dataPM.PMTransaction.PMType);
            setLocationName(dataPM.LocationName);
            setLocationCode(dataPM.PMTransaction.LocationCode);
            setWorkCatName(dataPM.WorkCategory);
            setWorkCatCode(dataPM.PMTransaction.WorkCatCode);
            setWorkSubCatName(dataPM.WorkSubCategory);
            setWorkSubCatCode(dataPM.PMTransaction.WorkSubCatCode);
            setDueDate(dayjs(PMSchedule.ScheduleDate));
        }
    }, [PMSchedule, dataPM, open]);

    const handlePMScheduleSelection = (event, value) => {
        if (value) {
            setSelectedPMSchedule(value.ScheduleCode);
        } else {
            setSelectedPMSchedule(null);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };

    const handleFormSubmit = async (values) => {
        // setLoading(true)
        try {

            // if (dataRE.length === 0 || dataPart.length === 0 || dataDoc.length === 0 || dataCT.length === 0) {
            //     enqueueSnackbar("Complete the detail MP transaction data form!", { variant: "warning" });
            //     setLoading(false);
            //     return;
            // }

            const MPDateNow = MPDate.format('YYYY-MM-DD');
            const DueDateNow = DueDate.format('YYYY-MM-DD');
            const StartScheduleDateNow = StartScheduleDate.format('YYYY-MM-DD');
            const FinishScheduleDateNow = FinishScheduleDate.format('YYYY-MM-DD');

            const modifiedValues = {
                ...values,
                MPDate: MPDateNow,
                DueDate: DueDateNow,
                RequestBy: RequestBy,
                MPType: MPType,
                LocationCode: LocationCode,
                WorkCatCode: workCatCode,
                WorkSubCatCode: workSubCatCode,
                StartScheduleDate: StartScheduleDateNow,
                FinishScheduleDate: FinishScheduleDateNow,
                resources: dataRE,
                parts: dataPart,
                documents: dataDoc,
                contacts: dataCT,
            };

            // console.log('Update Data', modifiedValues);

            const response = await updateMPTransaction(dataEdit.FacilityCode, dataEdit.MPNumber, modifiedValues);
            enqueueSnackbar(response.data.statusMessage, { variant: "success" });
            onEdit(dataEdit.FacilityCode && dataEdit.MPNumber);
            setOpen(false);

        } catch (error) {
            if (error.response && error.response.data && error.response.data.statusMessage) {
                const errorMessage = error.response.data.statusMessage;
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                enqueueSnackbar("Data failed to save.", { variant: "error" });
            }
            console.error("error code", error.response.data);
        }

        setLoading(false)
    };

    const handleKeyPress = (e) => {
        if (!onlyNumber.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <Box>
            <Tooltip title="Edit" fontSize="large">
                <IconButton onClick={handleOpen}>
                    <EditIcon />
                </IconButton>
            </Tooltip>

            <Box>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={dataEdit}
                    // validationSchema={checkoutSchema}
                    innerRef={formRef}
                >

                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Modal
                            title={
                                <StyleEditHeader>
                                    <Header
                                        title="MP TRANSACTION"
                                        subtitle="Edit Data a MP Transaction"
                                    />
                                </StyleEditHeader>
                            }
                            centered
                            open={open}
                            closable={false}
                            style={{
                                maxWidth: "90%",
                                minWidth: "80%",
                            }}
                            bodyStyle={{
                                maxHeight: "65vh",
                                overflow: "auto",
                            }}
                            footer={() => (
                                <ButtonUpdate
                                    handleClose={handleClose}
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                />
                            )}
                        >

                            <StyleForm>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Facility Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={data.FacilityName}
                                    name="FacilityCode"
                                    error={!!touched.FacilityCode && !!errors.FacilityCode}
                                    helperText={touched.FacilityCode && errors.FacilityCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="MP Number*"
                                    onBlur={handleBlur}
                                    value={values.MPNumber}
                                    onChange={handleChange}
                                    name="MPNumber"
                                    error={!!touched.MPNumber && !!errors.MPNumber}
                                    helperText={!!touched.MPNumber && !!errors.MPNumber}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="MP Date*"
                                        value={MPDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setMPDate(date)}
                                        name="MPDate"
                                        error={!!touched.MPDate && !!errors.MPDate}
                                        helperText={touched.MPDate && errors.MPDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="MP Status*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.MPStatus}
                                    name="MPStatus"
                                    error={!!touched.MPStatus && !!errors.MPStatus}
                                    helperText={touched.MPStatus && errors.MPStatus}
                                    sx={{ gridColumn: "span 2" }}
                                />
                                
                                

                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    loading={isLoading}
                                    options={PMSchedule}
                                    getOptionLabel={(option) => option.ScheduleName}
                                    sx={{ gridColumn: "span 2" }}
                                    onChange={(event, value) => {
                                        handlePMScheduleSelection(event, value);
                                        setFieldValue("PMSchedule", value ? value.ScheduleName : "");
                                    }}
                                    value={
                                        PMSchedule.find((option) => option.ScheduleCode === selectedPMSchedule) ||
                                        null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="PM Schedule*"
                                            onBlur={handleBlur}
                                            error={!!touched.PMSchedule && !!errors.PMSchedule}
                                            helperText={touched.PMSchedule && errors.PMSchedule}
                                        />
                                    )}
                                    noOptionsText={
                                        dataEdit.FacilityCode && valuePMNumber ? PMSchedule.length === 0 ?
                                            <Link to="/transaction/pmconfiguration/form" target="_blank" style={{ textDecoration: 'none' }}>There is no option, click to fill in PM Configuration data</Link>
                                            : "No options"
                                            : "Select the facility & pm number"
                                    }
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Request By*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={RequestBy}
                                    name="RequestBy"
                                    error={!!touched.RequestBy && !!errors.RequestBy}
                                    helperText={touched.RequestBy && errors.RequestBy}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="MP Type*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={MPType}
                                    name="MPType"
                                    error={!!touched.MPType && !!errors.MPType}
                                    helperText={touched.MPType && errors.MPType}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Location Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={LocationName}
                                    name="LocationName"
                                    error={!!touched.LocationName && !!errors.LocationName}
                                    helperText={touched.LocationName && errors.LocationName}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Work Category Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={workCatName}
                                    name="WorkCatCode"
                                    error={!!touched.WorkCatCode && !!errors.WorkCatCode}
                                    helperText={touched.WorkCatCode && errors.WorkCatCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Work SubCategory Name*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={workSubCatName}
                                    name="WorkSubCatCode"
                                    error={!!touched.WorkSubCatCode && !!errors.WorkSubCatCode}
                                    helperText={touched.WorkSubCatCode && errors.WorkSubCatCode}
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />


                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Due Date"
                                        value={DueDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setDueDate(date)}
                                        name="DueDate"
                                        error={!!touched.DueDate && !!errors.DueDate}
                                        helperText={touched.DueDate && errors.DueDate}
                                        sx={{ gridColumn: "span 2" }}
                                        readOnly
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Current Meter Reading*"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.CurrentMeterReading}
                                    name="CurrentMeterReading"
                                    error={!!touched.CurrentMeterReading && !!errors.CurrentMeterReading}
                                    helperText={touched.CurrentMeterReading && errors.CurrentMeterReading}
                                    sx={{ gridColumn: "span 2" }}
                                    onKeyPress={handleKeyPress}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Schedule Date"
                                        value={StartScheduleDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setStartScheduleDate(date)}
                                        name="StartScheduleDate"
                                        error={!!touched.StartScheduleDate && !!errors.StartScheduleDate}
                                        helperText={touched.StartScheduleDate && errors.StartScheduleDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Finish Schedule Date"
                                        value={FinishScheduleDate}
                                        onBlur={handleBlur}
                                        onChange={(date) => setFinishScheduleDate(date)}
                                        name="FinishScheduleDate"
                                        error={!!touched.FinishScheduleDate && !!errors.FinishScheduleDate}
                                        helperText={touched.FinishScheduleDate && errors.FinishScheduleDate}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Comments"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Comments}
                                    name="Comments"
                                    sx={{ gridColumn: "span 2" }}
                                    multiline
                                // rows={4}
                                />

                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.Description}
                                    name="Description"
                                    sx={{ gridColumn: "span 4" }}
                                    multiline
                                    rows={4}
                                />

                            </StyleForm>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPRE
                                    onSaveData={(data) => setDataRE(data)}
                                    FacilityCode={dataEdit.FacilityCode}
                                    MPNumber={dataEdit.MPNumber}
                                    onEdit={true}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPPart
                                    onSaveData={(data) => setDataPart(data)}
                                    FacilityCode={dataEdit.FacilityCode}
                                    MPNumber={dataEdit.MPNumber}
                                    onEdit={true}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPDoc
                                    onSaveData={(data) => setDataDoc(data)}
                                    FacilityCode={dataEdit.FacilityCode}
                                    MPNumber={dataEdit.MPNumber}
                                    onEdit={true}
                                />
                            </Box>

                            <Box sx={{
                                margin: "20px 0",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0px 0px 10px #00000026",
                            }}>
                                <FormMPCT
                                    onSaveData={(data) => setDataCT(data)}
                                    FacilityCode={dataEdit.FacilityCode}
                                    MPNumber={dataEdit.MPNumber}
                                    onEdit={true}
                                />
                            </Box>

                        </Modal>
                    )}
                </Formik>
            </Box>
        </Box >

    )
};

export default EditMPTransaction