import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_BASEURL;


export const updateUser = async (RefAccountId, UserName, Email, UserData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/user?RefAccountId=${RefAccountId}&UserName=${UserName}&Email=${Email}`, UserData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateUserGroup = async (KodeCabang, GroupCode, UserGroupData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/userGroup?KodeCabang=${KodeCabang}&GroupCode=${GroupCode}`, UserGroupData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}

export const updateTranAppMap = async (MainTranIdx, TranIdx, TranAppMapData) => {
    const token = Cookies.get('accessToken');
    const response = await axios.put(`${baseUrl}/put/tranappmap?MTranIdx=${MainTranIdx}&TranIdx=${TranIdx}`, TranAppMapData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
}