import { useTheme } from '@emotion/react';
import { Box } from '@mui/material'
import React from 'react'
import { tokens } from '../../../theme';

const StyleContent = ({ children }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{
            margin: "20px",
            padding: "20px 25px",
            borderRadius: "4px",
            backgroundColor: `${colors.grey[100]} !important`,
        }}>
            {children}
        </Box>
    )
}

export default StyleContent