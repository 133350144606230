import React, { useEffect, useState } from 'react'
import { Table, Typography } from 'antd';
import { getPMTaskTrasaction } from '../../Api/GetData';

const DataPMTask = ({ record }) => {

    const [dataRE, setDataRE] = useState([]);

    useEffect(() => {
        const fetchDataRE = async () => {
            try {
                const response = await getPMTaskTrasaction();
                const filterData = response.filter((item) =>
                    item.FacilityCode === record.FacilityCode &&
                    item.PMNumber === record.PMNumber
                )
                setDataRE(filterData);
            } catch (error) {
                console.log(error.response.data);
            }
        }
        fetchDataRE();
    }, [record]);

    const columns = [
        {
            key: 'DetailNo',
            title: 'No',
            dataIndex: 'DetailNo',
            width: 60,
        },
        {
            title: 'Task Name',
            dataIndex: 'TaskName',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
        },
    ];

    return (
        <div key="document-tab" style={{ margin: "5px 0" }}>
            <Typography.Title level={3} style={{ margin: " 0  0 10px" }}>
                Task
            </Typography.Title>
            <Table
                bordered
                columns={columns}
                dataSource={dataRE}
                pagination={false}
                scroll={{ x: 800 }}
            />
        </div>
    )
}

export default DataPMTask