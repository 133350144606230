import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Autocomplete, Box, IconButton, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import Header from "../../../components/Header";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { ButtonUpdate } from "../../global/components/ButtonAction";
import { Link } from "react-router-dom";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { Modal } from "antd";
import StyleEditHeader from "../../../components/Global/StyleContent/StyleEdit/StyleEditHeader";
import SwitchComponent from "../../../components/Global/SwitchSuspend";
import InputModal from "./InputModal";
import { getType } from "../../../Api/Master/GetData";
import { updateVehicle } from "../../../Api/Master/UpdateData";

const EditVehicle = ({ data, onEdit }) => {

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [purchaseDate, setPurchaseDate] = useState(dayjs(data.PurchaseDate));
  const [warrantyExpDate, setWarrantyExpDate] = useState(dayjs(data.WarrantyExpDate));

  const [Type, setType] = useState([]);
  const [selectedType, setSelectedType] = useState(data.ModelTypeCode);

  const [employee, setEmployee] = useState([]);

  const [isSuspend, setIsSuspend] = useState(data.IsSuspend);

  const handleSwitchChange = (checked) => {
    setIsSuspend(checked);
    setPurchaseDate(dayjs(data.PurchaseDate))
    setWarrantyExpDate(dayjs(data.WarrantyExpDate))
    setSelectedType(data.ModelTypeCode)
    formRef.current.resetForm();
  };

  useEffect(() => {
    const fetchDataType = async () => {
      const response = await getType('Vehicle');
      const filterRes = response.filter((item) => item.IsSuspend !== true);
      setType(filterRes);
    }
    fetchDataType();
  }, []);

  const handleTypeSelection = (event, value) => {
    if (value) {
      setSelectedType(value.TypeCode);
    } else {
      setSelectedType(null);
    }
  }

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {

      const purchaseDateNow = purchaseDate.format('YYYY-MM-DD');
      const warrantyExpDateNow = warrantyExpDate.format('YYYY-MM-DD');

      const modifiedValues = {
        ...values,
        PurchaseDate: purchaseDateNow,
        WarrantyExpDate: warrantyExpDateNow,
        ModelTypeCode: selectedType,
        IsSuspend: isSuspend,
      };

      const response = await updateVehicle(data.VehicleCode, modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      onEdit(data.VehicleCode);
      setOpen(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to update.", { variant: "error" });
      }
      console.error("error code", error.response);
      formRef.current.resetForm();
      setOpen(false);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setPurchaseDate(dayjs(data.PurchaseDate))
    setWarrantyExpDate(dayjs(data.WarrantyExpDate))
    setSelectedType(data.ModelTypeCode)
    setOpen(false);
  };

  return (
    <Box>
      <Tooltip title="Edit" fontSize="large">
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>


      <Box>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={data}
          validationSchema={checkoutSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Modal
              title={
                <StyleEditHeader>
                  <Header
                    title="VEHICLE"
                    subtitle="Edit Data a Vehicle"
                  />
                  <SwitchComponent
                    isSuspend={isSuspend}
                    handleSwitchChange={handleSwitchChange}
                  />
                </StyleEditHeader>
              }
              centered
              open={open}
              closable={false}
              style={{
                maxWidth: "90%",
                minWidth: "80%",
              }}
              bodyStyle={{
                maxHeight: "65vh",
                overflow: "auto",
              }}
              footer={() => (
                <ButtonUpdate
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            >
              <StyleForm>

                <TextField
                  fullWidth
                  variant="outlined"
                  label="Vehicle Code*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VehicleCode}
                  name="VehicleCode"
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Vehicle Name*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VehicleName}
                  name="VehicleName"
                  error={!!touched.VehicleName && !!errors.VehicleName}
                  helperText={touched.VehicleName && errors.VehicleName}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <Autocomplete
                  fullWidth
                  disablePortal
                  options={Type}
                  getOptionLabel={(option) => option.TypeName}
                  sx={{ gridColumn: "span 2" }}
                  onChange={(event, value) => {
                    handleTypeSelection(event, value);
                    setFieldValue("ModelTypeCode", value ? value.TypeName : "");
                  }}
                  value={
                    Type.find((option) => option.TypeCode === selectedType) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Model Type Name*"
                      onBlur={handleBlur}
                      error={!!touched.setFieldValue && !!errors.setFieldValue}
                      helperText={touched.setFieldValue && errors.setFieldValue}
                    />
                  )}
                  noOptionsText={
                    Type.length === 0 ?
                      <Link to="/master/type/form" target="_blank" style={{ textDecoration: 'none' }}>
                        there is no option, please fill in the buildings data
                      </Link>
                      : "No options"
                  }
                  readOnly={isSuspend}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="No Pol*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.NoPol}
                  name="NoPol"
                  error={!!touched.NoPol && !!errors.NoPol}
                  helperText={touched.NoPol && errors.NoPol}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="AssetNo*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.AssetNo}
                  name="AssetNo"
                  error={!!touched.AssetNo && !!errors.AssetNo}
                  helperText={touched.AssetNo && errors.AssetNo}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend,
                  }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Purchase Date*"
                    value={purchaseDate}
                    onBlur={handleBlur}
                    onChange={(date) => setPurchaseDate(date)}
                    name="PurchaseDate"
                    error={!!touched.PurchaseDate && !!errors.PurchaseDate}
                    helperText={touched.PurchaseDate && errors.PurchaseDate}
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isSuspend}
                  />
                </LocalizationProvider>


                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Warranty Exp Date*"
                    value={warrantyExpDate}
                    onBlur={handleBlur}
                    onChange={(date) => setWarrantyExpDate(date)}
                    name="WarrantyExpDate"
                    error={!!touched.WarrantyExpDate && !!errors.WarrantyExpDate}
                    helperText={touched.WarrantyExpDate && errors.WarrantyExpDate}
                    sx={{ gridColumn: "span 2" }}
                    readOnly={isSuspend}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Meter Reading*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.MeterReading}
                  name="MeterReading"
                  error={!!touched.MeterReading && !!errors.MeterReading}
                  helperText={touched.MeterReading && errors.MeterReading}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend
                  }}
                />

                <InputModal
                  setValues={(values) => setEmployee(values)}
                  setValuesEdit={data}
                  setHandleBlur={handleBlur}
                  setHandleChange={handleChange}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Vehicle Owner*"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.VehicleOwner}
                  name="VehicleOwner"
                  error={!!touched.VehicleOwner && !!errors.VehicleOwner}
                  helperText={touched.VehicleOwner && errors.VehicleOwner}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: isSuspend
                  }}
                />

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  name="Description"
                  sx={{ gridColumn: "span 2" }}
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: isSuspend
                  }}
                />
              </StyleForm>

            </Modal>
          )}
        </Formik>
      </Box>
    </Box>
  );
};


const checkoutSchema = yup.object().shape({
  VehicleName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Name"),
  ModelTypeCode: yup
    .string()
    .required("Please complete this Model Type Code"),
  NoPol: yup
    .string()
    .max(20, "Maximum of 20 characters allowed")
    .required("Please complete this No Pol"),
  AssetNo: yup
    .string()
    .max(30, "Maximum of 30 characters allowed")
    .required("Please complete this Asset No"),
  MeterReading: yup
    .string()
    .required("Please complete this Meter Reading"),
  VehicleOwner: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Vehicle Owner"),
});

export default EditVehicle;
